import { FC, lazy, Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Navigate } from 'react-router-dom'
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'

import { Banner } from '@/components/componentLibrary'
import LoadAnimation from '@/main/loadAnimation'

export const wrapRoute = (path: string) => {
  const LazyComponent = lazy(() => import(`../../pages/${path}.tsx`))

  const Component: FC = () => (
    <SentryErrorBoundary
      fallback={
        <Banner
          styleType="high-urgency"
          text="An error has occurred and has been logged. Please reload the page."
          className="empty-state-banner"
        />
      }
    >
      <Suspense fallback={<LoadAnimation />}>
        <LazyComponent />
      </Suspense>
    </SentryErrorBoundary>
  )

  return { Component }
}

export const wrapPublicRoute = (path: string) => {
  const LazyComponent = lazy(() => import(`../../pages/${path}.tsx`))

  const Component: FC = () => (
    <ErrorBoundary fallback={<Navigate to="/" replace />}>
      <Suspense fallback={<LoadAnimation />}>
        <LazyComponent />
      </Suspense>
    </ErrorBoundary>
  )

  return { Component }
}
