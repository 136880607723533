import classNames from 'classnames'

import './index.scss'

export interface DividerProps {
  className?: string
  color?: 'standard' | 'accent'
  vertical?: boolean
  weight?: 'light' | 'medium' | 'heavy'
}

export const Divider = ({ className, color, vertical, weight }: DividerProps) => {
  return (
    <div
      className={classNames(
        'populus-divider',
        color || 'standard',
        weight || 'medium',
        vertical ? 'vertical' : 'horizontal',
        className
      )}
    />
  )
}
