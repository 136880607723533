import { NavLink, To } from 'react-router-dom'
import classNames from 'classnames'

import { DrawerItem, DrawerItemProps } from '@/components/drawer/DrawerItem'

export interface DrawerItemNavLinkProps extends DrawerItemProps {
  isActive?: boolean
  to: To
}

/**
 * Drawer items wrapped in react-router-dom NavLink component
 * @param isActive used to manually style link as active
 */
export const DrawerItemNavLink = ({ to, ...props }: DrawerItemNavLinkProps) => {
  return (
    <NavLink className={classNames({ 'pointer-events-none': props.disabled })} end to={to}>
      {({ isActive }) => <DrawerItem active={isActive || props.isActive} {...props} />}
    </NavLink>
  )
}
