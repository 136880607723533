import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-locize-backend'
import { locizePlugin } from 'locize'
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  .use(locizePlugin)
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en-US',
    debug: !import.meta.env.VITEST,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      projectId: '83a8987e-be2d-48ee-9c18-11e19bf45ecd',
      apiKey: '8543ae1b-308f-4d6c-a282-b4264421500d',
      referenceLng: 'en-US',
    },
    // https://www.i18next.com/overview/configuration-options#missing-keys
    saveMissing: true,
    saveMissingPlurals: true,
    detection: {
      caches: [],
    },
    react: {
      bindI18n: 'languageChanged editorSaved',
    },
  })

export default i18n
