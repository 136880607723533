import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'

import { useCurrentProduct, useCurrentRegion } from '@/modules/urlRouting/hooks'
import { generatePathWithRegionId, useGetProductOptions } from '@/modules/urlRouting/utils'

const ProductSelectorMUI: FC = () => {
  const currentProduct = useCurrentProduct()

  const {
    data: { regionId },
  } = useCurrentRegion()

  const productOptions = useGetProductOptions()

  return (
    <Box sx={{ px: 2.5, mt: 2 }}>
      <FormControl size="small" sx={{ width: '100%' }}>
        <InputLabel id="product-selector-mui-label">Product</InputLabel>
        <Select
          labelId="product-selector-mui-label"
          label="Product"
          value={productOptions.find(option => option.product === currentProduct)?.text}
        >
          {productOptions.map(option => (
            // @ts-ignore
            // These two libraries aren't too happy about having their typing mashed together,
            // but this works as intended.
            <MenuItem
              key={option.text}
              value={option.text}
              component={NavLink}
              to={generatePathWithRegionId(option.landingPage, regionId)}
            >
              {option.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default ProductSelectorMUI
