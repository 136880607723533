import { useSuspenseQuery } from '@tanstack/react-query'

import { ReportTypeEnum } from '@/modules/api/openapi/models/ReportTypeEnum'
import { internalApi } from '@/modules/api/request'
import { useCurrentRegion } from '@/modules/urlRouting/hooks'

export const useGetGBFSReportMetaData = () => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  return useSuspenseQuery({
    queryKey: [`/regions/${regionId}/mobility/reports/bikeshare_download_metadata`],
    queryFn: async () => internalApi.mobility.getGbfsMetadata({ regionId }),
  })
}

export const useDownloadBikeshareReport = () => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  return async (operator: string, requestedMonth: string, reportType: ReportTypeEnum) => {
    const url = await internalApi.mobility.getBikeshareReportDownloadUrl({
      regionId,
      operator,
      requestedMonth,
      reportType,
    })

    window.location = url as unknown as Location
  }
}
