import { valid } from 'semver'

import { InternalApi } from '@/modules/api/openapi'
import { ApiRequestOptions } from '@/modules/api/openapi/core/ApiRequestOptions'
import { ExternalV2Api } from '@/modules/api/v2'
import { getToken, signOut } from '@/modules/authentication'
import { getImpersonatedUserId } from '@/modules/authentication/impersonation'
import { monitorBreadcrumb, monitorMessage } from '@/modules/monitoring'
import { setUpdateAvailable } from '@/modules/update'
import appUIStore from '@/stores/appUIStore'

export let APIPY_URL: string = `${window.location.origin}/apipy`
export let FASTAPI_INTERNAL_URL: string = `${window.location.origin}/internal`
export let FASTAPI_V2_URL: string = `${window.location.origin}/v2`
export const PUBLIC_API_KEY = '97c11348858819b060124afe30dd7000'

if (import.meta.env.DEV) {
  if (import.meta.env.VITEST) {
    APIPY_URL = 'http://127.0.0.1:8765/apipy'
    FASTAPI_INTERNAL_URL = 'http://127.0.0.1:8000/internal'
    FASTAPI_V2_URL = 'http://127.0.0.1:8000/v2'
  } else {
    APIPY_URL = `${window.location.protocol}//${window.location.hostname}:8765/apipy`
    FASTAPI_INTERNAL_URL = `${window.location.protocol}//${window.location.hostname}:8000/internal`
    FASTAPI_V2_URL = `${window.location.protocol}//${window.location.hostname}:8000/v2`
  }
}

export const internalApi = new InternalApi({
  BASE: FASTAPI_INTERNAL_URL,
  HEADERS: async (options: ApiRequestOptions) => {
    const impersonated_user = getImpersonatedUserId()
    const token = await getToken()
    const headers: HeadersInit = {
      'accept-language': 'en',
      ...(token ? { 'X-TOKEN': token } : {}),
      ...(impersonated_user ? { 'IMPERSONATED-USER': impersonated_user } : {}),
      ...(options.headers ? options.headers : {}),
    }
    return headers
  },
})

export const apiV2 = new ExternalV2Api({
  BASE: FASTAPI_V2_URL,
  HEADERS: async (options: ApiRequestOptions) => {
    const headers: HeadersInit = {
      'x-api-key': PUBLIC_API_KEY, // the public api key is required for public endpoints
      'accept-language': 'en',
      ...(options.headers ? options.headers : {}),
    }
    return headers
  },
})

const VITE_REACT_BUNDLE_VERSION = valid(import.meta.env.VITE_REACT_BUNDLE_VERSION)

export const validateApiVersionMatch = (response: Response) => {
  const API_VERSION = valid(response.headers.get('x-api-version'))

  if (VITE_REACT_BUNDLE_VERSION && API_VERSION && VITE_REACT_BUNDLE_VERSION !== API_VERSION) {
    setUpdateAvailable()
  }
}

/**
 * @deprecated This function shouldn't be added to any new code if we can help it,
 * and should be phased out as we move over to FastAPI.

 * This was the original request function for the application. It connects to the
 * Pyramid based API endpoints.
 *
 * This function expects the data to always be returned with a HTTP code of 200,
 * regardless of success or not. It checks the response body for the key of `success`.
 * This function is verbose with Sentry errors by using `captureMessage` on almost every
 * response that isn't a full success. This removes the flexibility to use a try/catch block
 * to prevent errors, and forces and error to be thrown to sentry even if you are accounting
 * for it in your code that does the fetch.
 *
 * This function is also _locked_ to the `APIPY_URL`, which is the Pyramid based API endpoints.
 * This function is also _locked_ to the HTTP verb of `POST`. This is fine as is, but using the
 * correct HTTP verbs on new API endpoints is an accepted path forward.
 */
export const apipyRequest = async (
  endpoint: string,
  data = {},
  opts: {
    useMultipartFormData?: boolean
    reject?: boolean
    headers?: { [key: string]: string }
  } = {},
  signal?: AbortSignal
): Promise<any> => {
  const options = { useMultipartFormData: false, reject: false, ...opts }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const region_id = appUIStore.metro && appUIStore.metro.regionId

    const token = await getToken()

    const impersonated_user = getImpersonatedUserId()
    const bodyDefault = {
      token,
      region_id,
      impersonated_user: impersonated_user,
      ...(data || {}),
    }

    const headers = {
      'accept-language': 'en',
      ...(opts.headers || {}),
    }

    const debugBody = {
      region_id,
      ...(data || {}),
    }

    let body: string | FormData = ''

    if (options.useMultipartFormData) {
      const formData = new FormData()
      Object.entries(bodyDefault).forEach(([key, value]) => {
        formData.append(key, value)
      })
      body = formData
    } else {
      body = JSON.stringify(bodyDefault)
    }

    monitorBreadcrumb(`apipyRequest ${endpoint}`, debugBody)

    fetch(APIPY_URL + endpoint, { method: 'POST', body, signal, headers })
      .then(response => {
        validateApiVersionMatch(response)

        response
          .text()
          .then(text => {
            try {
              const data = JSON.parse(text)

              if (data.success === true) {
                resolve(data)
              } else {
                monitorMessage(`APIPY non-success ${endpoint} ${data.error}`, { debugBody, text })

                if (data.error === 'Invalid user' && response.status === 403) {
                  signOut()
                }

                if (options.reject) reject(data.error)
              }
            } catch (e) {
              monitorMessage(`APIPY non-JSON ${endpoint}: ${e}, body: ${body}`, {
                debugBody,
                text,
              })
              if (options.reject) reject()
            }
          })
          .catch(error => {
            monitorMessage(`APIPY non-text ${endpoint} ${error}`, { debugBody })
            if (options.reject) reject()
          })
      })
      .catch(error => {
        if (error.name === 'AbortError') monitorBreadcrumb(error.name, { endpoint })
        else monitorMessage(`APIPY request-error ${endpoint} ${error}`, { debugBody })
        if (options.reject) reject()
      })
  })
}

/**
 * @deprecated This function shouldn't be added to any new code if we can help it,
 * and should be phased out as we move everything over to the Openapi Client Code.
 * This function is a duplicate of the `apipyRequest`, but locked with the `FASTAPI_URL` instead
 * of the `APIPY_URL`. As far as I can tell, it was added to the codebase to allow for easy
 * migration of Pyramid based API endpoints. If someone wanted to change an existing Pyramid
 * based API endpoint, they would do all the backend code, and then just substitute `apipyRequest`
 * for `fastApiRequest` in the frontend, and it would work!
 *
 * This allowed for the quick decoupling of development on the Frontend and Backend to allow
 * easier migration from Pyramid to FastAPI.

 * For the same reasons listed in `apipyRequest`, this request is limited in usefulness:
 * - It is limited to the `POST` HTTP verb
 * - It is verbose with Sentry, not allow for flexibility in try/catch blocks, and often times
 *   throwing sentry errors when not needed.
 */
export const fastApiRequest = async (
  endpoint: string,
  data = {},
  opts: { reject: boolean; headers?: { [key: string]: string } } = { reject: false },
  signal?: AbortSignal
): Promise<any> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const region_id = appUIStore.metro && appUIStore.metro.regionId

    const token = await getToken()
    const impersonated_user = getImpersonatedUserId()

    const body = {
      token,
      region_id,
      ...(data || {}),
    }

    const debugBody = {
      region_id,
      ...(data || {}),
    }

    monitorBreadcrumb(`populusApiRequest ${endpoint}`, debugBody)

    fetch(FASTAPI_INTERNAL_URL + endpoint, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'accept-language': 'en',
        ...(impersonated_user ? { 'IMPERSONATED-USER': impersonated_user } : {}),
        ...opts.headers,
      },
      signal,
    })
      .then(response => {
        validateApiVersionMatch(response)

        response
          .text()
          .then(text => {
            try {
              const data = JSON.parse(text)

              if (data.success === true) {
                resolve(data)
              } else {
                if (data.detail === 'Could not validate credentials' && response.status === 403) {
                  monitorBreadcrumb('Signing out user due to invalid credentials')
                  signOut()
                } else {
                  monitorMessage(`populusApi non-success ${endpoint} ${data.error}`, {
                    debugBody,
                    text,
                  })
                }
                if (opts.reject) reject(data.error)
              }
            } catch (e) {
              monitorMessage(`populusApi non-JSON ${endpoint}: ${e}, body: ${body}`, {
                debugBody,
                text,
              })
              if (opts.reject) reject()
            }
          })
          .catch(error => {
            monitorMessage(`populusApi non-text ${endpoint} ${error}`, { debugBody })
            if (opts.reject) reject()
          })
      })
      .catch(error => {
        if (error.name === 'AbortError') monitorBreadcrumb(error.name, { endpoint })
        else monitorMessage(`populusApi request-error ${endpoint} ${error}`, { debugBody })
        if (opts.reject) reject()
      })
  })
}
