import { FC, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Grid, Header, Menu, Segment } from 'semantic-ui-react'
import { useReadLocalStorage } from 'usehooks-ts'

import { LocalStorageKey, removeLocalStorageValue } from '@/common/utils/localStorage'
import LoginFormOIDC from '@/modules/authentication/LoginFormOIDC'
import LoginFormPassword from '@/modules/authentication/LoginFormPassword'

const LoginForm: FC = () => {
  const { t } = useTranslation()

  const previouslyLoggedInUser = useReadLocalStorage<{
    providers?: string[]
    email: string
  }>(LocalStorageKey.USER) ?? { providers: undefined, email: '' }
  const { providers, email: previousEmail } = previouslyLoggedInUser

  useEffect(() => {
    removeLocalStorageValue(LocalStorageKey.LAST_ACTIVE_TIME)
  }, [])

  const oidcProvider = providers?.find(provider => provider.startsWith('oidc.'))

  return (
    <div>
      <Menu style={{ borderRadius: 'unset', backgroundColor: 'rgb(24, 47, 70)' }} inverted>
        <Menu.Item>
          <h1>
            <img src="/populus_logo.png" alt="Populus" width="155" />
          </h1>
        </Menu.Item>
      </Menu>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Grid centered verticalAlign="middle" style={{ width: '100%' }}>
          <Grid.Column style={{ maxWidth: 600, margin: 15 }}>
            <Segment className="login-form" data-testid="login-form">
              <div style={{ padding: '30px 20px' }}>
                <Header as="h2" color="teal" textAlign="center">
                  {t('login.pageTitle', 'Login').toLocaleUpperCase()}
                </Header>
                {oidcProvider ? (
                  <LoginFormOIDC initialEmail={previousEmail} providerId={oidcProvider} />
                ) : (
                  <LoginFormPassword initialEmail={previousEmail} />
                )}
                <br />
                <Trans i18nKey={'login.termsOfService'}>
                  By logging in you agree to the following{' '}
                  <a
                    href="https://www.populus.ai/legal/terms-of-service"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Terms of Service
                  </a>
                  .
                </Trans>
                <br />
                <Trans i18nKey={'login.requestDemo'}>
                  {`If you don't have an account, you may want to `}
                  <a
                    href="https://www.populus.ai/contact/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    request a demo
                  </a>
                  .
                </Trans>
              </div>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    </div>
  )
}

export default LoginForm
