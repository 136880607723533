// Original code from https://usehooks.com/useLocalStorage/
export enum LocalStorageKey {
  REGION_ID = 'regionId',
  USER = 'user',
  IMPERSONATED_USER = 'impersonated_user',
  IMPERSONATED_USER_EMAIL = 'impersonated_user_email',
  LAST_ACTIVE_TIME = 'last_active_time',
  PARAMS = 'params',
  ENROLL_MFA = 'want_to_enroll_mfa',
  UNENROLL_MFA = 'want_to_unenroll_mfa',
}

export const getLocalStorageValue = (key: LocalStorageKey | string) => {
  if (typeof window === 'undefined') return undefined

  try {
    const item = window.localStorage.getItem(key)
    return item ? JSON.parse(item) : undefined
  } catch (error) {
    console.error(error)
    return undefined
  }
}

export const removeLocalStorageValue = (key: LocalStorageKey | string) => {
  if (typeof window === 'undefined') return undefined

  window.localStorage.removeItem(key)
}

export const setLocalStorageValue = (key: LocalStorageKey | string, value: any) => {
  if (typeof window === 'undefined') return
  try {
    window.localStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    console.error(error)
  }
}
