import { forwardRef, useImperativeHandle, useState } from 'react'
import DayPicker, { DateUtils, Modifier, Modifiers } from 'react-day-picker'
import classNames from 'classnames'
import { isEqual } from 'lodash'

import { CaptionElement } from '../CaptionElement'
import { DayElement } from '../DayElement'
import { NavbarElement } from '../NavbarElement'
import { CalendarMonthProps, CalendarProps, CalendarRef, CalendarUpdateMonthProps } from '../types'
import { getPreviousMonthDate } from '../utils'

import 'react-day-picker/lib/style.css'
import './index.scss'

/**
 * Calendar built with react-day-picker \
 * Calendar uses two other components to render:
 * - NavbarElement shows the title and toggles between different months
 * - DayElement renders each individual day
 *
 * See all available props: https://react-day-picker-v7.netlify.app/api/DayPicker/
 * @param activeInput - whether the active input is "to" or "from"
 * @param from - date of 'from' in the range
 * @param noEnd - whether the date range should appear with no end date
 * @param noStart - whether the date range should appear with no start date
 * @param to - date of 'to' in the range
 * @param setFrom - function to set 'from' date
 * @param setTo - function to set 'to' date
 * @param singleDay - whether the date range should appear as a single day
 */
const Calendar = forwardRef<CalendarRef, CalendarProps>(
  (
    { activeInput, className, from, noEnd, noStart, to, setFrom, setTo, singleDay, ...props },
    ref
  ) => {
    const [enteredFrom, setEnteredFrom] = useState<Date | undefined | null>() // tracks 'from' date the user is hovering
    const [enteredTo, setEnteredTo] = useState<Date | undefined | null>() // tracks 'to' date the user is hovering
    const [dayPickerMonths, setDayPickerMonths] = useState<CalendarMonthProps>(
      {} as CalendarMonthProps
    ) // current months shown by the Calendar
    const [visibleMonth, setVisibleMonth] = useState<Date | undefined>() // prop passed into the Calendar when we want it to show a particular month

    const showOnlyOneDate = singleDay || noEnd || noStart

    /**
     * This is a callback that the DatePicker uses to update visible months when the inputs are focused \
     * We use a ref to do this because we don't manage the visible months directly for react-day-picker \
     * When we want a particular month shown we pass in a prop to update the calendar \
     * Below logic updates the visible months only if the date range is not in view
     */
    useImperativeHandle(ref, () => ({
      updateMonth(updates: CalendarUpdateMonthProps) {
        // do nothing if empty range or if the calendar isn't showing anything yet
        if ((!updates.from && !updates.to) || !dayPickerMonths.month || !dayPickerMonths.nextMonth)
          return
        const visibleRange = {
          from: dayPickerMonths.month,
          to: getPreviousMonthDate(dayPickerMonths.nextMonth),
        }
        const fromIsVisible = updates.from && DateUtils.isDayInRange(updates.from, visibleRange)
        const toIsVisible = updates.to && DateUtils.isDayInRange(updates.to, visibleRange)
        if (updates.activeInput === 'from') {
          if (!updates.from && updates.to && !toIsVisible) setVisibleMonth(updates.to)
          else if (updates.from && !fromIsVisible) setVisibleMonth(updates.from)
        } else if (updates.activeInput === 'to') {
          if (!updates.to && updates.from && !fromIsVisible) setVisibleMonth(updates.from)
          else if (updates.to && !toIsVisible) setVisibleMonth(updates.to!)
        }
      },
    }))

    const handleDayClick = (day: Date) => {
      // IF 'from' date is greater than 'to'
      // OR 'to' date is less than 'from' date
      // THEN set provided date as the 'from' date and focus the 'to' input
      if (
        (activeInput === 'from' && to && day > to) ||
        (activeInput === 'to' && from && from > day)
      ) {
        setTo(null)
        setFrom(day)
      } else if (activeInput === 'from') setFrom(day)
      else if (activeInput === 'to') setTo(day)
    }

    /**
     * This tracks hovered days to show temporary selection when to or from === null
     */
    const handleDayMouseEnter = (day: Date) => {
      if (activeInput === 'to') setEnteredTo(day)
      if (activeInput === 'from') setEnteredFrom(day)
    }

    // this is what shows as selected with little circles
    const modifiers = { start: from, end: to } as Partial<Modifiers>

    // this is the highlighted background color
    const selectedDays = [
      // the from day
      !noStart && (from as Date),
      // the to day
      !singleDay && !noEnd && (to as Date),
      // the days in between
      !showOnlyOneDate && {
        from: activeInput === 'from' && to && !from ? enteredFrom : (from as Date),
        to: activeInput === 'to' && from && !to ? enteredTo : (to as Date),
      },
      noStart && {
        before: !to ? enteredTo : to,
      },
      noEnd && {
        after: !from ? enteredFrom : from,
      },
    ] as Modifier[]

    return (
      <DayPicker
        captionElement={CaptionElement}
        className={classNames('populus-calendar', showOnlyOneDate && 'single-calendar', className)}
        initialMonth={(activeInput === 'from' ? from || to : to || from) || undefined}
        modifiers={modifiers}
        month={visibleMonth}
        navbarElement={(props: any) => {
          // these props tell us what months the calendar is showing
          const { month, previousMonth, nextMonth } = props
          const newDayPickerMonths = { month, previousMonth, nextMonth }
          if (!isEqual(dayPickerMonths, newDayPickerMonths)) {
            // use setTimeout to avoid warning for setting state before rendering a component
            setTimeout(() => setDayPickerMonths(newDayPickerMonths), 0)
          }
          return <NavbarElement {...props} />
        }}
        numberOfMonths={showOnlyOneDate ? 1 : 2}
        onDayClick={handleDayClick}
        onDayMouseEnter={handleDayMouseEnter}
        renderDay={(date, modifiers) => (
          <DayElement
            activeInput={activeInput}
            date={date}
            modifiers={modifiers}
            singleDay={singleDay}
          />
        )}
        selectedDays={selectedDays}
        {...props}
      />
    )
  }
)

Calendar.displayName = 'Calendar'

export default Calendar
