// These are how permissions for maps are stored in config_regions.allowed_maps
export const MAP_CONSTANTS = {
  LIVE: 'live',
  ISSUE: 'issue',
  DISTRIBUTION: 'distrib',
  OPERATOR_DROP_OFFS: 'operatorDropOffs',
  PARKING: 'parking',
  ROUTES: 'routes',
  TRIPS: 'trips',
}
