import decamelizeKeys from 'decamelize-keys'
import { saveAs } from 'file-saver'
import queryString from 'query-string'

import analytics from '@/modules/analytics'
import { fileFormats } from '@/modules/api/types'

export const toUrlSearchParamsString = (searchParams: Record<string, any>): string =>
  queryString.stringify(decamelizeKeys(searchParams), {
    skipEmptyString: true,
  })

export const getNextPageParam = <T>(lastPage: PopulusPage<T>) =>
  !lastPage.page || lastPage.page === lastPage.pages ? undefined : lastPage.page + 1

export const getPreviousPageParam = <T>(firstPage: PopulusPage<T>) =>
  !firstPage.page || firstPage.page === 1 ? undefined : firstPage.page - 1

export type PopulusPage<T> = {
  items: Array<T>
  total: number
  page?: number
  size?: number
  pages?: number
}

export const emptyPaginatedResponse = {
  items: [],
  total: 0,
  page: 1,
  size: 0,
}

export const saveFile = async (
  data: any,
  fileName: string,
  outputFormat: keyof typeof fileFormats
) => {
  analytics.track(`Download ${fileName}`, {
    category: 'Download',
  })

  const blob = outputFormat === 'json' ? new Blob([JSON.stringify(data)]) : new Blob([data])

  saveAs(blob, fileName)
}
