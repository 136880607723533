import { Text } from '@/components/componentLibrary'
import { SpatialPolicy as Policy } from '@/models/spatialPolicy'

import './index.scss'

export const MinMaxWrapper = ({ policy, children }: { policy: Policy; children: any }) => {
  const hasMin = policy.minimum || policy.minimum === 0
  const hasMax = policy.maximum || policy.maximum === 0

  return (
    <div className="min-max-container">
      {policy.minMaxUnitLabel && (hasMin || hasMax) && (
        <Text className="min-max-text" styleType="tertiary-header">
          {policy.minMaxUnitLabel}
        </Text>
      )}
      <div className="min-max-fields">{children}</div>
    </div>
  )
}
