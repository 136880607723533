import { FC, PropsWithChildren } from 'react'
import { observer } from 'mobx-react'

import LoadingOverlay from '@/components/componentLibrary/LoadingOverlay'
import layerStore from '@/modules/layers/layerStore'

/**
 * Require layerStore to load before rendering children
 */
const RequireLayerStore: FC<PropsWithChildren> = observer(({ children }) => {
  if (!layerStore.loaded) return <LoadingOverlay />

  return children
})

export default RequireLayerStore
