import { generatePath, useLocation, useParams } from 'react-router-dom'

import { PermissionKey } from '@/modules/api/openapi/models/PermissionKey'
import { useAuth } from '@/modules/authentication'
import { PARAMS, SEGMENTS } from '@/modules/urlRouting/paths'
import {
  useGetCurbsLandingPage,
  useGetMobilityLandingPage,
} from '@/modules/urlRouting/ProductSelector'
import { useCurrentPermissions, usePermission } from '@/modules/user/hooks'

export const usePathWithNewRegionId = () => {
  const { pathname } = useLocation()
  const { regionId } = useParams()
  return (newRegionId: string) =>
    generatePathWithRegionId(
      regionId ? pathname.replace(regionId, `:${PARAMS.REGION_ID}`) : pathname,
      newRegionId
    )
}

export const generatePathWithRegionId = (path: string, regionId?: string) => {
  return generatePath(path, {
    [PARAMS.REGION_ID]: regionId,
  })
}

export const useOrganizationUsersPageAccess = () => {
  const isAdmin = usePermission(PermissionKey.IS_ADMIN)
  return isAdmin
}

export const useProfilePageAccess = () => {
  const { data: currentUser } = useAuth()
  return !!currentUser?.providerData.some(provider => provider?.providerId === 'password')
}

export const removeRegionIdFromPath = (path: string | undefined) => {
  if (path === undefined) return ''

  return path.split('/').slice(2).join('/')
}

export const useGetProductOptions = () => {
  const { data: permissions } = useCurrentPermissions()

  return [
    {
      disabled: !permissions?.organizationSettings.curbManager,
      product: SEGMENTS.CURB,
      landingPage: useGetCurbsLandingPage(),
      text: 'Curb Manager',
    },
    {
      disabled: !permissions?.organizationSettings.mobilityManager,
      product: SEGMENTS.MOBILITY,
      landingPage: useGetMobilityLandingPage(),
      text: 'Mobility Manager',
    },
  ]
}
