import {
  getLocalStorageValue,
  LocalStorageKey,
  removeLocalStorageValue,
  setLocalStorageValue,
} from '@/common/utils/localStorage'
import { queryClientInstance } from '@/modules/api/queryClient'

export const startImpersonatingUser = (user_id: string, user_email: string) => {
  queryClientInstance.clear()
  setLocalStorageValue(LocalStorageKey.IMPERSONATED_USER, { user_id, user_email })
  removeLocalStorageValue(LocalStorageKey.REGION_ID)
}

export const stopImpersonatingUser = () => {
  queryClientInstance.clear()
  removeLocalStorageValue(LocalStorageKey.IMPERSONATED_USER)
}

/**
 * Get the impersonated user id
 * @returns user_id if it exists in local storage
 */
export const getImpersonatedUserId = (): string | undefined =>
  getLocalStorageValue(LocalStorageKey.IMPERSONATED_USER)?.user_id

/**
 * Get the impersonated user email (for display purposes only)
 * @returns user_email if it exists in local storage
 */
export const getImpersonatedUserEmail = (): string | undefined =>
  getLocalStorageValue(LocalStorageKey.IMPERSONATED_USER)?.user_email
