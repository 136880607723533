import { FC, PropsWithChildren, useEffect, useState } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { init as initSentry, reactRouterV6BrowserTracingIntegration } from '@sentry/react'

import LoadAnimation from '@/main/loadAnimation'
import { APIPY_URL } from '@/modules/api/request'

export let regionsJson: any, operatorsJson: any, mdsPublicJson: any, frontendOperators: any

const POPULUS_DATACENTER = import.meta.env.VITE_POPULUS_DATACENTER
const POPULUS_ENVIRONMENT = import.meta.env.VITE_POPULUS_ENVIRONMENT
export const publicBucket = `https://public-${POPULUS_ENVIRONMENT}-${POPULUS_DATACENTER}.populus.ai`

export const Configuration: FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    const getDeprecatedConfig = async () => {
      try {
        const response = await fetch(APIPY_URL + '/config/public', { method: 'POST' })
        const config = await response.json()

        regionsJson = config.regions
        operatorsJson = config.operators
        mdsPublicJson = config.mds_public
        frontendOperators = config.frontend_operators

        setLoading(false)
      } catch {
        setError(true)
      }
    }

    console.debug(`bundle version: ${import.meta.env.VITE_REACT_BUNDLE_VERSION}`)
    console.debug(`sentry: ${!!import.meta.env.VITE_SENTRY_DSN}`)

    if (import.meta.env.VITE_SENTRY_DSN) {
      initSentry({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        release: `frontend@${import.meta.env.VITE_REACT_BUNDLE_VERSION}`,
        environment: `frontend-${import.meta.env.VITE_POPULUS_DATACENTER}-${
          import.meta.env.VITE_POPULUS_ENVIRONMENT
        }`,
        integrations: [
          reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),
        ],
        tracesSampleRate: 0.2,
        ignoreErrors: [
          // Random plugins/extensions
          'top.GLOBALS',
          // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
          'originalCreateNotification',
          'canvas.contentDocument',
          'MyApp_RemoveAllHighlights',
          'http://tt.epicplay.com',
          "Can't find variable: ZiteReader",
          'jigsaw is not defined',
          'ComboSearch is not defined',
          'http://loading.retry.widdit.com/',
          'atomicFindClose',
          // Facebook borked
          'fb_xd_fragment',
          // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
          // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
          'bmi_SafeAddOnload',
          'EBCallBackMessageReceived',
          // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
          'conduitPage',
          // Generic error code from errors outside the security sandbox
          // You can delete this if using raven.js > 1.0, which ignores these automatically.
          'Script error.',
          '/.*@webkit-masked-url.*/',
        ],
        denyUrls: [
          // Facebook flakiness
          /graph\.facebook\.com/i,
          // Facebook blocked
          /connect\.facebook\.net\/en_US\/all\.js/i,
          // Woopra flakiness
          /eatdifferent\.com\.woopra-ns\.com/i,
          /static\.woopra\.com\/js\/woopra\.js/i,
          // Chrome extensions
          /extensions\//i,
          /^chrome:\/\//i,
          /^chrome-extension:\/\//i,
          // Other plugins
          /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
          /webappstoolbarba\.texthelp\.com\//i,
          /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        ],
      })
    }

    getDeprecatedConfig()
  }, [])

  if (loading) return <LoadAnimation />

  if (error)
    return (
      <div style={{ marginTop: 50 }}>
        <h2>Database Connection Error</h2>
        <div>The App is undergoing maintenance. Please try again later.</div>
      </div>
    )

  return children
}
