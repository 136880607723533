import { FC, useEffect, useState } from 'react'
import _ from 'lodash'
import { observer } from 'mobx-react'
import { Button, Confirm, Icon, Modal, Search, SearchProps } from 'semantic-ui-react'

import permissionsModalStore from '@/modules/permissions/adminPanel/permissionsModalStore'
import PermissionsUI from '@/modules/permissions/adminPanel/PermissionsUI'

interface PermissionsModalProps {
  onClose: () => void
}

type Result = {
  title: string
  organization_uuid: string
  organization_name: string
}

const PermissionsModal: FC<PermissionsModalProps> = ({ onClose }) => {
  const [results, setResults] = useState<Result[]>([])
  const [value, setValue] = useState<string | undefined>('')
  const [confirmOrganizationDeleteOpen, setConfirmOrganizationDeleteOpen] = useState(false)

  useEffect(() => {
    permissionsModalStore.loadOrganizationNames()
    permissionsModalStore.loadPlans()
    permissionsModalStore.setActiveTab(0)
  }, [])

  const handleResultSelect = (e: React.MouseEvent, { result }: SearchProps) => {
    setValue(result.title)
    permissionsModalStore.setActiveTab(0)
    permissionsModalStore.loadOrganization(result.organization_uuid)
  }

  const handleSearchChange = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    { value }: SearchProps
  ) => {
    setValue(value)

    const re = new RegExp('^' + value?.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'), 'i') //starts with entered value
    const isMatch = (result: Result) => value === '*' || re.test(result.organization_name)

    setResults(_.filter(permissionsModalStore.organizationNames, isMatch))
  }

  const deleteOrganizationConfirmed = () => {
    permissionsModalStore.deleteOrganization(() => setValue(''))
    setConfirmOrganizationDeleteOpen(false)
  }

  const { organizationName } = permissionsModalStore

  return (
    <Modal
      open={true}
      style={{ height: 550 }}
      size="large"
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
      onClose={onClose}
    >
      <div style={{ position: 'absolute', top: 14, right: 330 }}>
        {/* if you put this in the header it becomes huge */}
        <Search
          onResultSelect={handleResultSelect}
          onSearchChange={handleSearchChange}
          results={results.map(o => ({ ...o, title: o.organization_name }))}
          value={value}
          size="tiny"
          placeholder={organizationName}
        />
      </div>
      <div style={{ position: 'absolute', top: 14, right: 80 }}>
        {/* if you put this in the header it becomes huge */}
        <Button.Group>
          {permissionsModalStore.hasEdits ? (
            <Button
              basic
              size="tiny"
              color="blue"
              onClick={() => permissionsModalStore.saveOrganization()}
            >
              Save Org
            </Button>
          ) : (
            <Button
              basic
              size="tiny"
              color="green"
              onClick={() => permissionsModalStore.newOrganization()}
            >
              New Org
            </Button>
          )}
          {permissionsModalStore.mode === 'create' ? (
            <Button
              basic
              size="tiny"
              color="yellow"
              onClick={() => permissionsModalStore.cancelNewOrganization()}
            >
              Cancel New Org
            </Button>
          ) : (
            <Button
              basic
              size="tiny"
              color="red"
              disabled={permissionsModalStore.organizationName === 'Populus'}
              onClick={() => setConfirmOrganizationDeleteOpen(true)}
            >
              Delete Org
            </Button>
          )}
        </Button.Group>
      </div>
      <Modal.Header>
        Manage Users
        <div style={{ float: 'right' }}>
          <Icon link name="close" onClick={onClose} />
        </div>
      </Modal.Header>
      <Modal.Content scrolling>
        <PermissionsUI />
      </Modal.Content>

      <Confirm
        open={confirmOrganizationDeleteOpen}
        onCancel={() => setConfirmOrganizationDeleteOpen(false)}
        onConfirm={() => deleteOrganizationConfirmed()}
        content="Are you sure you want to delete this organization?"
        style={{ width: 280 }}
      />
    </Modal>
  )
}

export default observer(PermissionsModal)
