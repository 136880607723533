import { decodeNumber, decodeString, encodeNumber, encodeString } from 'serialize-query-params'

type Param = string | (string | null)[] | null | undefined

type PseudoEnum = Record<string, string>

/**
 * String Parameter with validation
 * @param validationEnum object that a param will be validated against
 * @returns `string` if one of the values in the provided object, else `undefined`
 */
export function ValidatedStringParam<Enum extends PseudoEnum>(validationEnum: Enum) {
  return {
    encode(value: Param) {
      return encodeString(value)
    },
    decode(value: Param) {
      const valueString = decodeString(value)
      const match = Object.entries(validationEnum).find(([, val]) => val === valueString)
      return match ? validationEnum[match[0] as keyof Enum] : undefined
    },
  }
}

export const ValidatedNumericParam = (allowedValues: number[]) => {
  return {
    encode: (value: number | null | undefined) => encodeNumber(value),
    decode: (value: Param) => {
      const number = decodeNumber(value)
      const match = typeof number === 'number' && allowedValues.includes(number)
      return match ? number : undefined
    },
  }
}
