/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GBFSProxyResponseModel } from '../models/GBFSProxyResponseModel'
import type { ListEdgeTagCategoriesResultModel } from '../models/ListEdgeTagCategoriesResultModel'
import type { MobilityMetricsResponse } from '../models/MobilityMetricsResponse'
import type { Page_MDSOperatorResponse_ } from '../models/Page_MDSOperatorResponse_'
import type { Page_OperatorResponse_ } from '../models/Page_OperatorResponse_'
import type { ReportMetadata } from '../models/ReportMetadata'
import type { ReportTypeEnum } from '../models/ReportTypeEnum'
import type { RouteDateRangeResponse } from '../models/RouteDateRangeResponse'
import type { RouteStatisticDataModel } from '../models/RouteStatisticDataModel'
import type { RouteWithTripDataModel } from '../models/RouteWithTripDataModel'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class MobilityService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Gbfs Proxy
   * @returns GBFSProxyResponseModel Successful Response
   * @throws ApiError
   */
  public gbfsProxy({
    regionId,
    gbfsEndpoint,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * GBFS endpoint name
     */
    gbfsEndpoint: 'station_information.json' | 'station_status.json' | 'system_information.json'
  }): CancelablePromise<GBFSProxyResponseModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/mobility/gbfs_proxy/{gbfs_endpoint}',
      path: {
        region_id: regionId,
        gbfs_endpoint: gbfsEndpoint,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Gbfs Metadata
   * @returns ReportMetadata Successful Response
   * @throws ApiError
   */
  public getGbfsMetadata({
    regionId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
  }): CancelablePromise<ReportMetadata> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/mobility/reports/bikeshare_download_metadata',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Bikeshare Report Download Url
   * @returns string Successful Response
   * @throws ApiError
   */
  public getBikeshareReportDownloadUrl({
    regionId,
    operator,
    requestedMonth,
    reportType,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Operator filter
     */
    operator: string
    /**
     * Month of GBFS aggregated reports requested
     */
    requestedMonth: string
    /**
     * Type of report
     */
    reportType: ReportTypeEnum
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/mobility/reports/bikeshare_report_download_url',
      path: {
        region_id: regionId,
      },
      query: {
        operator: operator,
        requested_month: requestedMonth,
        report_type: reportType,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Mobility Metrics
   * Get Mobility Metrics for a region within a certain date period,
   * with an option to specify operator and/or vehicle type.
   * @returns MobilityMetricsResponse Successful Response
   * @throws ApiError
   */
  public getMobilityMetrics({
    regionId,
    startDate,
    endDate,
    scooterReplacementRate = 30,
    eBikeReplacementRate = 30,
    vehicleType,
    operator,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Start date in format YYYY-MM-DD
     */
    startDate: string
    /**
     * End date in format YYYY-MM-DD
     */
    endDate: string
    /**
     * Percent of Scooter Trips Replacing Car Trips
     */
    scooterReplacementRate?: number
    /**
     * Percent of E-Bike Trips Replacing Car Trips
     */
    eBikeReplacementRate?: number
    /**
     * Include routes for this vehicle type
     */
    vehicleType?: string
    /**
     * Include routes for this operator
     */
    operator?: string
  }): CancelablePromise<MobilityMetricsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/mobility/metrics',
      path: {
        region_id: regionId,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
        scooter_replacement_rate: scooterReplacementRate,
        e_bike_replacement_rate: eBikeReplacementRate,
        vehicle_type: vehicleType,
        operator: operator,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Mds Operators For Region
   * List all MDS operators in the region for the requesting user
   * @returns Page_MDSOperatorResponse_ Successful Response
   * @throws ApiError
   */
  public getMdsOperatorsForRegion({
    regionId,
    page = 1,
    size = 50,
    slug,
    name,
    search,
    orderBy = 'slug',
    disableDownload,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    page?: number
    size?: number
    slug?: string
    name?: string
    search?: string
    orderBy?: string
    disableDownload?: boolean
  }): CancelablePromise<Page_MDSOperatorResponse_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/mobility/mds_operators',
      path: {
        region_id: regionId,
      },
      query: {
        page: page,
        size: size,
        slug: slug,
        name: name,
        search: search,
        order_by: orderBy,
        disable_download: disableDownload,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Date Range
   * @returns RouteDateRangeResponse Successful Response
   * @throws ApiError
   */
  public getDateRange({
    regionId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
  }): CancelablePromise<RouteDateRangeResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/routes/date_range',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Network Types
   * @returns ListEdgeTagCategoriesResultModel Successful Response
   * @throws ApiError
   */
  public networkTypes({
    regionId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
  }): CancelablePromise<Array<ListEdgeTagCategoriesResultModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/routes/network_types',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Routes Vehicle Types
   * @returns string Successful Response
   * @throws ApiError
   */
  public getRoutesVehicleTypes({
    regionId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
  }): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/routes/vehicle_types',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Routes Operators
   * List routes operators for the requesting user
   * @returns Page_OperatorResponse_ Successful Response
   * @throws ApiError
   */
  public getRoutesOperators({
    regionId,
    page = 1,
    size = 50,
    slug,
    name,
    search,
    orderBy = 'slug',
  }: {
    /**
     * Region identifier
     */
    regionId: string
    page?: number
    size?: number
    slug?: string
    name?: string
    search?: string
    orderBy?: string
  }): CancelablePromise<Page_OperatorResponse_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/routes/operators',
      path: {
        region_id: regionId,
      },
      query: {
        page: page,
        size: size,
        slug: slug,
        name: name,
        search: search,
        order_by: orderBy,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Route Statistics
   * @returns RouteStatisticDataModel Successful Response
   * @throws ApiError
   */
  public routeStatistics({
    regionId,
    startDate,
    endDate,
    startTimeHour,
    endTimeHour = 23,
    operator,
    vehicleType,
    networkType,
    daysOfWeek,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Start date in format YYYY-MM-DD
     */
    startDate: string
    /**
     * End date in format YYYY-MM-DD
     */
    endDate: string
    /**
     * Start Hour
     */
    startTimeHour?: number
    /**
     * End Hour
     */
    endTimeHour?: number
    /**
     * Operator
     */
    operator?: string
    /**
     * Include routes for this vehicle type
     */
    vehicleType?: string
    /**
     * Include a certain network type
     */
    networkType?: string
    /**
     * Days of the Week to include
     */
    daysOfWeek?: Array<number>
  }): CancelablePromise<RouteStatisticDataModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/routes/statistics',
      path: {
        region_id: regionId,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
        start_time_hour: startTimeHour,
        end_time_hour: endTimeHour,
        operator: operator,
        vehicle_type: vehicleType,
        network_type: networkType,
        days_of_week: daysOfWeek,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Routes
   * @returns RouteWithTripDataModel Successful Response
   * @throws ApiError
   */
  public routes({
    regionId,
    startDate,
    endDate,
    startTimeHour,
    endTimeHour = 23,
    operator,
    vehicleType,
    networkType,
    daysOfWeek,
    accept = 'application/json',
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Start date in format YYYY-MM-DD
     */
    startDate: string
    /**
     * End date in format YYYY-MM-DD
     */
    endDate: string
    /**
     * Start Hour
     */
    startTimeHour?: number
    /**
     * End Hour
     */
    endTimeHour?: number
    /**
     * Operator
     */
    operator?: string
    /**
     * Include routes for this vehicle type
     */
    vehicleType?: string
    /**
     * Include a certain network type
     */
    networkType?: string
    /**
     * Days of the Week to include
     */
    daysOfWeek?: Array<number>
    accept?: string
  }): CancelablePromise<Array<RouteWithTripDataModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/routes',
      path: {
        region_id: regionId,
      },
      headers: {
        accept: accept,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
        start_time_hour: startTimeHour,
        end_time_hour: endTimeHour,
        operator: operator,
        vehicle_type: vehicleType,
        network_type: networkType,
        days_of_week: daysOfWeek,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
