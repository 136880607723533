import { FC, PropsWithChildren, ReactElement, ReactNode } from 'react'

import './index.scss'

interface LayoutPageProps {
  navBar?: ReactElement | number | string | (ReactElement | number | string)[] | ReactNode
}

const LayoutTemplate: FC<PropsWithChildren<LayoutPageProps>> = props => {
  return (
    <div className="navigation-layout">
      {props.navBar && <div className="navbar">{props.navBar}</div>}
      <div className="content">{props.children}</div>
    </div>
  )
}

export default LayoutTemplate
