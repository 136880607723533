import { Option } from '@/components/componentLibrary/Dropdown'
import { frontendOperators } from '@/modules/initialization/config'
import { monitorMessage } from '@/modules/monitoring'

// regional operators do not have thier names in config_operators
const customOperatorNames: { [k: string]: string } = {
  bird_oakland: 'Bird Oakland',
  grin_guadalajara: 'Grow Guadalajara',
  grin_zapopan: 'Grow Zapopan',
  jump_bike: 'JUMP Bikes',
  lime_oakland: 'Lime Oakland',
  lime_sac: 'Lime Sacramento',
  lime_sf: 'Lime SF',
  lime_wsac: 'Lime West Sacramento',
}

export const operatorExists = (operator: string) => {
  if (!operator) return false
  return Object.prototype.hasOwnProperty.call(frontendOperators, operator.toLowerCase())
}

export const getOperatorColor = (slug: string) => {
  const operator = frontendOperators[slug.toLowerCase()]

  if (!operator) {
    monitorMessage(`operatorData.ts missing data: ${slug.toLowerCase()}`)
    return '#999999'
  }

  if (!operator.color_hex) {
    monitorMessage(`operatorData.ts missing color: ${slug.toLowerCase()}`)
    return '#999999'
  }

  return '#' + operator.color_hex
}

export const getOperatorDisplayName = (slug: string) => {
  slug = slug.toLowerCase()
  const operator = frontendOperators[slug]

  if (!operator) {
    monitorMessage(`operatorData.ts missing data: ${slug}`)
    return 'Unknown Operator'
  }

  if (!operator.name) {
    monitorMessage(`operatorData.ts missing name: ${slug}`)
    return 'Unknown Operator'
  }

  return customOperatorNames[slug] || operator.name
}

export const operatorOptions = (operators: string[]): Option[] =>
  operators.map(operator => ({
    key: operator,
    text: getOperatorDisplayName(operator),
    value: operator,
  }))

export { OperatorLogo } from '@/modules/operator/OperatorLogo'
