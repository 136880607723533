import { startCase } from '@/common/utils/text'
import i18n from '@/modules/i18n/i18n'

export function enumStringValues<T extends string>(enm: { [s: string]: T }): string[] {
  return Object.values(enm)
}

/**
 * Converts an enum into a type-matched object with the same keys and
 * translated values.
 * @param enumObject Enum to translate
 * @param enumName String used to generate translation keys - should be string
 * representation of the enum's name
 * @returns Object of translated values
 */
export const generateEnumTranslations = <T extends Record<string, string>>(
  enumObject: T,
  enumName: string
): { [K in keyof T]: string } & typeof enumObject => {
  const { t } = i18n

  return Object.entries(enumObject).reduce(
    (result, [key, value]) => {
      return {
        ...result,
        [key]: t(
          `common.enum${enumName}${startCase(key.toLowerCase()).replaceAll(' ', '')}`,
          value
        ),
      }
    },
    {} as { [K in keyof typeof enumObject]: string }
  ) as {
    [K in keyof T]: string
  } & typeof enumObject
}
