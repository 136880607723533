import { action, makeObservable, observable } from 'mobx'

import { Metro } from '@/models/metro'
import { regionsJson } from '@/modules/initialization/config'
import { monitorAlertDeprecated } from '@/modules/monitoring'

class MetroStore {
  metroMap = observable.map()

  constructor() {
    makeObservable(this, {
      metroMap: observable,
      init: action,
    })
  }

  init() {
    if (!regionsJson) {
      monitorAlertDeprecated(`regionsJson not initialized correctly, reload app`)
      return
    }

    for (const metroDoc of regionsJson) {
      const metro = new Metro(metroDoc)
      this.metroMap.set(metro.regionId, metro)
    }
  }

  getByRegionId(regionId: string) {
    if (!this.metroMap.has(regionId)) {
      monitorAlertDeprecated(`regionId missing from metro store: ${regionId}`)
      return
    }
    return this.metroMap.get(regionId)
  }
}

/**
 * @deprecated Please use the useCurrentRegion or useRegion hooks
 */
export default new MetroStore()
