import { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'
import * as UI from 'semantic-ui-react'

import { Text } from '..'

import './index.scss'

interface CheckboxProps extends UI.CheckboxProps {
  dashed?: boolean
  editable?: boolean
  style?: 'primary'
}

export const Checkbox = ({
  className,
  dashed,
  editable = true,
  style,
  ...props
}: CheckboxProps) => {
  return editable ? (
    <UI.Checkbox
      className={classNames(
        'populus-checkbox',
        style || 'primary',
        dashed && 'dashed',
        !editable && 'uneditable',
        className
      )}
      {...props}
    />
  ) : (
    <Uneditable checked={props.checked} className={className} label={props.label} />
  )
}

const Uneditable: FC<CheckboxProps> = ({ checked, className, label }) => {
  return (
    <div className={classNames('populus-checkbox-uneditable', className)}>
      <div className={classNames('checkbox-container', checked && 'checked')}></div>
      <Text className={classNames('checkbox-text', !checked && 'unchecked')} styleType="body">
        {label?.toString()}
      </Text>
    </div>
  )
}
