/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class LayersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get Shape Layer Vector
   * Retrieve a layer by slug, only showing it if it is available for the requesting user as an MVT
   * @returns string Successful Response
   * @throws ApiError
   */
  public getShapeLayerVector({
    shapeLayerUuid,
    z,
    x,
    y,
    ifModifiedSince,
  }: {
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
    z: number
    x: number
    y: number
    ifModifiedSince?: string
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/vector/{z}/{x}/{y}',
      path: {
        shape_layer_uuid: shapeLayerUuid,
        z: z,
        x: x,
        y: y,
      },
      query: {
        if_modified_since: ifModifiedSince,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
