import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'

const NavigationLayoutMobile = () => {
  return (
    <Box height="100vh">
      <Outlet />
    </Box>
  )
}

export default NavigationLayoutMobile
