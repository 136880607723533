import { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import classNames from 'classnames'
import { Input } from 'semantic-ui-react'
import { useDebounceValue } from 'usehooks-ts'

import {
  Icon,
  NavbarDropdown,
  NavbarDropdownItem,
  NavbarDropdownItemLink,
  NavbarDropdownMenu,
  NavbarDropdownTrigger,
  Text,
} from '@/components/componentLibrary'
import { useCurrentRegion, useRegions } from '@/modules/urlRouting/hooks'
import { usePathWithNewRegionId } from '@/modules/urlRouting/utils'

import './index.scss'

const INFINITE_SCROLL_BOTTOM_OFFSET = 3
const DEBOUNCE_SEARCH_DELAY = 300

/**
 * Region Selector Dropdown
 *
 * This has to be a controlled component because focusing the dropdown's input invokes the onBlur event,
 * which closes the dropdown before you can type anything. In-menu search within a Semantic Dropdown is
 * not well supported.
 */
const RegionSelector = () => {
  const { data: activeRegion } = useCurrentRegion()
  const generatePathWithNewRegionId = usePathWithNewRegionId()
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const searchRef = useRef<Input>(null)

  const [debouncedInputValue] = useDebounceValue(inputValue, DEBOUNCE_SEARCH_DELAY)

  const {
    data: filteredRegions,
    hasNextPage: hasNextFilteredRegionsPage,
    fetchNextPage: fetchNextFilteredRegionsPage,
    isLoading: isFilteredRegionsLoading,
  } = useRegions({ ...(inputValue && { search: debouncedInputValue }) })

  const { data: regions } = useRegions()
  const regionsCount = regions?.pages[0].total ?? 0

  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView && hasNextFilteredRegionsPage) fetchNextFilteredRegionsPage()
  }, [inView, fetchNextFilteredRegionsPage, hasNextFilteredRegionsPage])

  // handles KeyboardEvents when the input is focused
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        setOpen(false)
        setInputValue('')
      }
    }
    document.addEventListener('keydown', listener)
    return () => document.removeEventListener('keydown', listener)
  }, [searchRef])

  useEffect(() => {
    if (open && searchRef && searchRef.current) {
      searchRef.current.focus()
      // prevent onBlur from closing the menu
      setOpen(true)
      // onBlur still closes in FF
      setTimeout(() => setOpen(true))
    }
  }, [open])

  const onDropdownClick = () => {
    setOpen(!open)
  }

  const onDropdownBlur = () => {
    setOpen(false)
    setInputValue('')
  }

  return (
    <>
      {regionsCount === 1 && (
        <div className="single-region-display" data-testid="single-region-text">
          <Icon className="region-selector-icon" icon="CitySelectionIcon" />
          <Text styleType="secondary-light">{filteredRegions?.pages[0].items?.[0].text}</Text>
        </div>
      )}
      {regionsCount > 1 && (
        <NavbarDropdown
          className="region-selector"
          onClick={onDropdownClick}
          onBlur={onDropdownBlur}
          open={open}
          trigger={
            <NavbarDropdownTrigger className="region-selector-trigger" open={open}>
              <Icon
                className="region-selector-icon"
                color={open ? 'ocean-1' : 'black-1'}
                icon="CitySelectionIcon"
              />
              <Text styleType="secondary-light">
                {activeRegion?.text || t('regionSelector.selectRegion', 'Select Region')}
              </Text>
              <div className={classNames('dropdown-icon-background', open && 'open')}>
                <Icon color={open ? 'white' : 'black-1'} icon="ArrowDown" />
              </div>
            </NavbarDropdownTrigger>
          }
          data-testid="region-selector-dropdown"
        >
          <NavbarDropdownMenu className="region-selector-menu">
            <Input
              className="search-input"
              fluid
              transparent
              ref={searchRef}
              icon={<Icon className="search-icon" color="black-1" icon="Search" />}
              placeholder={t('regionSelector.searchCity', 'Search for a city')}
              key="searchForCity"
              // Workaround for Semantic UI bug that doesn't allow spaces
              onKeyDown={(e: KeyboardEvent) => e.key === ' ' && e.stopPropagation()}
              onChange={(e, data) => setInputValue(data.value)}
              input={{ tabIndex: '0' }}
              value={inputValue}
              data-testid="region-selector-search-input"
            />
            {filteredRegions?.pages.reduce((prev, curr) => prev + curr.items.length, 0) === 0 && (
              <NavbarDropdownItem
                className="no-results-loading"
                key="noResults"
                // keep the dropdown open when this is clicked
                onClick={(e: SyntheticEvent) => e.stopPropagation()}
              >
                <Text styleType="body">{t('regionSelection.noResults', 'No results')}</Text>
              </NavbarDropdownItem>
            )}
            {filteredRegions?.pages.map(page =>
              page.items.map((regionOption, idx) => {
                const isSelected = regionOption.regionId === activeRegion?.regionId
                const lastItemRef =
                  idx === page.items.length - INFINITE_SCROLL_BOTTOM_OFFSET ? { ref: ref } : {}
                return (
                  <NavbarDropdownItemLink
                    active={isSelected}
                    className={classNames('region-selector-item', isSelected && 'selected')}
                    key={regionOption.regionId}
                    to={generatePathWithNewRegionId(regionOption.regionId)}
                  >
                    <div className="region-text" {...lastItemRef}>
                      <Text styleType="body">{regionOption.text}</Text>
                    </div>
                    {isSelected && (
                      <Icon
                        className="region-selected-icon"
                        color="ocean-1"
                        icon="ProductSwitcherCheckmark"
                      />
                    )}
                  </NavbarDropdownItemLink>
                )
              })
            )}
            {isFilteredRegionsLoading && (
              <NavbarDropdownItem
                className="no-results-loading"
                onClick={(e: SyntheticEvent) => e.stopPropagation()}
              >
                <Text styleType="body">{t('regionSelection.loading', 'Loading...')}</Text>
              </NavbarDropdownItem>
            )}
          </NavbarDropdownMenu>
        </NavbarDropdown>
      )}
    </>
  )
}

export default RegionSelector
