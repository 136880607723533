import { useQuery } from '@tanstack/react-query'

import { internalApi } from '@/modules/api/request'
import { useCurrentUser } from '@/modules/user/hooks'

export const useCurrentUserIntercomCompany = () => {
  const { isSuccess } = useCurrentUser()

  return useQuery({
    queryKey: ['/users/me/company_from_intercom'],
    queryFn: async () => await internalApi.users.getUserCompany(),
    enabled: isSuccess,
  })
}

export const useIntercomProductTours = (tourPath: string) =>
  useQuery({
    queryKey: [`/intercom/tours`, tourPath],
    queryFn: async () => await internalApi.intercom.getToursByUrlPath({ tourPath }),
  })
