/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class IntercomService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get Tours By Url Path
   * @returns number Successful Response
   * @throws ApiError
   */
  public getToursByUrlPath({
    tourPath,
  }: {
    /**
     * URL path to match tour(s) to
     */
    tourPath: string
  }): CancelablePromise<Array<number>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/intercom/tours',
      query: {
        tour_path: tourPath,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
