import { FC, FormEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Form } from 'semantic-ui-react'

import { LocalStorageKey, removeLocalStorageValue } from '@/common/utils/localStorage'
import { useAuth } from '@/modules/authentication'

const LoginFormOIDC: FC<{ initialEmail: string; providerId: string }> = ({
  initialEmail,
  providerId,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { signOut } = useAuth()

  return (
    <Form
      size="large"
      onSubmit={(e: FormEvent) => {
        e.preventDefault()
        navigate(`/oidc/login?gidp_provider_id=${encodeURIComponent(providerId)}`)
      }}
    >
      <Form.Input
        fluid
        disabled
        icon="user"
        name="email"
        value={initialEmail}
        iconPosition="left"
        placeholder={t('login.emailAddress', 'EMAIL ADDRESS')}
      />
      <Form.Button color="teal" fluid size="large" content={t('login.SSOLogin', 'Login via SSO')} />
      <Form.Button
        type="button"
        onClick={async () => {
          removeLocalStorageValue(LocalStorageKey.USER)
          signOut()
        }}
        fluid
        size="medium"
        basic
      >
        {t('login.SSOSwitchEmail', 'Not {{initialEmail}}? Login with a different email address.', {
          initialEmail,
        })}
      </Form.Button>
    </Form>
  )
}

export default LoginFormOIDC
