import { useCallback } from 'react'

import { useCurrentRegion } from '@/modules/urlRouting/hooks'

type Value = number | undefined | null

export const CENTS_IN_DOLLAR = 100

/**
 * Throws errors if minDecimals is greater than maxDecimals or either are negative
 * @returns minDecimals
 */
function validateFractionDigits(maxDecimals: number, minDecimals?: number) {
  minDecimals = minDecimals ?? maxDecimals
  if (minDecimals > maxDecimals)
    throw RangeError('minDecimals must be less than or equal to maxDecimals')
  if (minDecimals < 0 || maxDecimals < 0) throw RangeError('decimalPlaces must be greater than 0')
  return minDecimals
}

/**
 * @returns Function that formats currency value according to region_id
 */
export const useFormatCurrency = () => {
  const { data: region } = useCurrentRegion()
  return useCallback(
    (value: Value) => formatCurrency(value, window.navigator.language, region?.currencyCode),
    [region]
  )
}

/**
 * Format number into localized currency
 * @param value Number to be formatted as currency (`1000`)
 * @param localeCode Locale code to format (`en-us`)
 * @param currencyCode Currency code to format (`USD`)
 * @returns Number formatted as currency string (`$1,000.00`)
 */
export const formatCurrency = (
  value: Value,
  localeCode: string = 'en-us',
  currencyCode: string = 'USD',
  minimumFractionDigits: number = 2,
  maximumFractionDigits: number = 2
) => {
  return new Intl.NumberFormat(localeCode, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value || 0)
}

/**
 * Format number with localization and specified number of decimals
 * @param value the number to format (`1000.230`)
 * @param maxDecimals (default: 0) If the only value specified, all numbers will have this many decimals (`2`)
 * @param minDecimals (default: undefined) If specified, numbers can have fewer decimal places than the max (`0`)
 * @returns Number formatted as string (`1,000.23`)
 */
export function formatNumber(value: Value, maxDecimals: number = 0, minDecimals?: number) {
  minDecimals = validateFractionDigits(maxDecimals, minDecimals)
  return new Intl.NumberFormat(window.navigator.language, {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals,
  }).format(value || 0)
}

/**
 * Format percent with localization and specified number of decimals
 * @param value the number to format
 * @param maxDecimals (default: 1) If the only value specified, all numbers will have this many decimals
 * @param minDecimals (default: undefined) If specified, numbers can have fewer decimal places than the max
 */
export function formatPercent(value: Value, maxDecimals: number = 1, minDecimals?: number) {
  minDecimals = validateFractionDigits(maxDecimals, minDecimals)
  return new Intl.NumberFormat(window.navigator.language, {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals,
    style: 'percent',
  }).format(value || 0)
}

// See: https://github.com/unicode-org/cldr/blob/main/common/validity/unit.xml
type Unit = 'hour' | 'kilometer' | 'meter' | 'mile'
type UnitDisplay = Intl.NumberFormatOptions['unitDisplay']

export function formatUnit({
  value,
  unit,
  unitDisplay,
  minDecimals,
  maxDecimals,
}: {
  value: Value
  unit: Unit
  unitDisplay: UnitDisplay
  minDecimals?: number
  maxDecimals?: number
}) {
  if (minDecimals && maxDecimals) validateFractionDigits(maxDecimals, minDecimals)
  return new Intl.NumberFormat(window.navigator.language, {
    style: 'unit',
    unit,
    ...(minDecimals && { minimumFractionDigits: minDecimals }),
    ...(maxDecimals && { maximumFractionDigits: maxDecimals }),
    ...(unitDisplay && { unitDisplay }),
  }).format(value || 0)
}

interface formatShorthandNumberOptions {
  includePlusSignForPositives?: boolean
  formatAbsoluteValueString?: (value: string) => string
}

/**
 * Converts a number into a shorthand representation using conventional one-letter shorthands
 * (e.g. "K" for thousands, "M" millions, etc.).
 * @param value value to be converted. -999,500,000,000 < value < 999,500,000,000
 * @param options
 *    - includePlusSignForPositives: If true, will prepend on a "+" for positive results.
 *          Negative numbers will always begin with a "-".
 *    - formatAbsoluteValueString: Function to modify the results of shortening, before any
 *          "+" or "-" are prepended on the final string.
 * @returns string
 */
export const formatShorthandNumber = (
  value: number,
  options: formatShorthandNumberOptions = {}
) => {
  const includePlusSignForPositives = options.includePlusSignForPositives || false

  const formatAbsoluteValueString = options.formatAbsoluteValueString
    ? options.formatAbsoluteValueString
    : (value: string) => value

  const isNegative = value < 0
  const workingValue = Math.abs(value)

  let shortHand = Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 0,
  }).format(workingValue)

  shortHand = formatAbsoluteValueString(shortHand)

  if (isNegative) {
    shortHand = `-${shortHand}`
  } else if (includePlusSignForPositives && value >= 0) {
    shortHand = `+${shortHand}`
  }

  return shortHand
}
