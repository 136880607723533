import { FC, useState } from 'react'
import { Backdrop, CircularProgress, Stack, Typography } from '@mui/material'
import { useTimeout } from 'usehooks-ts'

import { MILLISECONDS_IN_A_SECOND } from '@/common/utils/time'

const LoadingOverlay: FC = () => {
  const [message, setMessage] = useState<string>('')

  useTimeout(
    () => setMessage('Please hold tight while we process your data.'),
    2 * MILLISECONDS_IN_A_SECOND
  )

  useTimeout(
    () =>
      setMessage(
        `This is taking a while, please check back later —
        but do not close this page or you will have to begin again.`
      ),
    10 * MILLISECONDS_IN_A_SECOND
  )

  return (
    <Backdrop open>
      <Stack justifyContent="center" alignItems="center" sx={{ p: 3 }}>
        <CircularProgress />
        {message && (
          <Typography
            variant="body2"
            align="center"
            sx={{ color: '#FFF', textShadow: '#FFF 1px 0 10px', whiteSpace: 'pre-line' }}
          >
            {message}
          </Typography>
        )}
      </Stack>
    </Backdrop>
  )
}

export default LoadingOverlay
