import { ReactNode } from 'react'

import { Divider } from '../../componentLibrary'

import './index.scss'

interface InvalidRouteTemplateProps {
  actionsContent?: ReactNode
  imageContent?: ReactNode
  primaryTextContent?: ReactNode
  secondaryTextContent?: ReactNode
}

const InvalidRouteTemplate = ({
  actionsContent,
  imageContent,
  primaryTextContent,
  secondaryTextContent,
}: InvalidRouteTemplateProps) => {
  return (
    <div className="invalid-route-template-container">
      {imageContent && <div className="image-container">{imageContent}</div>}
      {primaryTextContent && <div className="primary-text-container">{primaryTextContent}</div>}
      <Divider className="divider" weight="heavy" />
      {secondaryTextContent && (
        <div className="secondary-text-container">{secondaryTextContent}</div>
      )}
      {actionsContent && <div className="actions-container">{actionsContent} </div>}
    </div>
  )
}

export default InvalidRouteTemplate
