import { useTranslation } from 'react-i18next'

import { getYearsFromDateRange } from '@/common/utils/date'
import { DateType } from '@/components/componentLibrary/DatePicker/types'

export const allMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

/**
 * Generates a dictionary where each key is a year in the provided date range and associated value is a list of months available in that year.
 * @param minDate minimum Date
 * @param maxDate maximum Date
 */
export const getAvailableMonthsByYear = (minDate: Date, maxDate: Date) => {
  const monthsByYear: { [key: number]: typeof allMonths } = {}

  if (minDate > maxDate) return monthsByYear

  getYearsFromDateRange(minDate, maxDate).forEach(year => {
    if (year > minDate.getFullYear() && year < maxDate.getFullYear()) {
      monthsByYear[year] = allMonths
    } else if (year === minDate.getFullYear() && year === maxDate.getFullYear()) {
      monthsByYear[year] = allMonths.filter(
        (_, i) => i >= minDate.getMonth() && i <= maxDate.getMonth()
      )
    } else if (year === minDate.getFullYear()) {
      monthsByYear[year] = allMonths.filter((_, i) => i >= minDate.getMonth())
    } else if (year === maxDate.getFullYear()) {
      monthsByYear[year] = allMonths.filter((_, i) => i <= maxDate.getMonth())
    }
  })

  return monthsByYear
}

/**
 * Generates list of options for the year dropdown.
 * @param bound Whether the options are for the 'from' or 'to' side of the date range
 * @param years List of years in the date range
 * @returns
 */
export const getYearOptions = (bound: 'from' | 'to', years: number[]) => {
  return years.map(year => ({
    key: year.toString(),
    value: year.toString(),
    text: year.toString(),
    'data-testid': `${bound}-${year.toString()}`,
  }))
}

/**
 * Generates list of options for the month dropdown
 * @param bound Whether the options are for the 'from' or 'to' side of the date range
 * @param boundDate Actual from or to date of the date range
 * @param availableMonths List of months available in the dropdown. These will be limited by the year.
 */
export const getMonthOptions = (
  bound: 'to' | 'from',
  boundDate: DateType,
  monthsByYear: ReturnType<typeof getAvailableMonthsByYear>
) => {
  const fullYear = boundDate?.getFullYear()
  const availableMonths = fullYear && fullYear in monthsByYear ? monthsByYear[fullYear] : []

  return allMonths.map((month, index) => ({
    key: month,
    value: index.toString(),
    text: month,
    disabled: !availableMonths.includes(month),
    'data-testid': `${bound}-${month}`,
  }))
}

/**
 * Return a provided date that falls within given min and max dates.
 */
export const getDateWithinMinMaxDates = ({
  date,
  minDate,
  maxDate,
}: {
  date: Date
  minDate: Date
  maxDate: Date
}) => {
  if (minDate > date) {
    return minDate
  } else if (maxDate < date) {
    return maxDate
  } else {
    return date
  }
}
