import { FC, PropsWithChildren } from 'react'
import { onlineManager } from '@tanstack/react-query'

import { Modal } from '@/components/componentLibrary'

const Online: FC<PropsWithChildren> = ({ children }) => {
  if (!onlineManager.isOnline())
    return (
      <Modal>
        <div style={{ marginTop: 50 }}>
          <h2>No Internet Connection</h2>
          <div>Please reconnect in order to continue using the Populus App</div>
        </div>
      </Modal>
    )

  return children
}

export default Online
