import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, Outlet, useLocation } from 'react-router-dom'
import { Button, Dropdown, Header, Icon } from 'semantic-ui-react'

import { getDashboardOptions } from '@/modules/dashboard/utils/dashboardConstants'
import { useNavigate } from '@/modules/urlRouting/hooks'

import './index.scss'

const DashboardLayout: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const GROUP_TITLES = {
    trip: t('dashboard.timeAndDistance', 'Time and Distance'),
    parking: t('dashboard.micromobilityParking', 'Micromobility Parking'),
  }

  const activeOption = getDashboardOptions().find(
    option => !!matchPath(option.value, location.pathname)
  )
  if (!activeOption) return null

  const filteredOptions = getDashboardOptions().filter(
    option => option.group === activeOption.group
  )

  const activeOptionIdx = filteredOptions.findIndex(option => option.value === activeOption.value)

  const onButtonClick = (idx: number) => navigate(filteredOptions[idx].value)

  return (
    <div className="dashboard-layout">
      <Header as="h3" color="teal" className="slide-changer">
        <span>
          <b>{GROUP_TITLES[activeOption.group as keyof typeof GROUP_TITLES]}</b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            disabled={activeOptionIdx === 0}
            icon
            onClick={() => onButtonClick(activeOptionIdx - 1)}
          >
            <Icon name="angle double left" />
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            disabled={activeOptionIdx + 1 === filteredOptions.length}
            icon
            onClick={() => onButtonClick(activeOptionIdx + 1)}
          >
            <Icon name="angle double right" />
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Dropdown
            scrolling
            selectOnBlur={false}
            value={activeOption.value}
            onChange={(e, data) => navigate(data.value as string)}
            options={filteredOptions}
          />
        </span>
      </Header>
      <Outlet />
    </div>
  )
}

export default DashboardLayout
