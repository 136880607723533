import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import * as UI from '@/components/componentLibrary'
import { SpatialPolicy as Policy } from '@/models/spatialPolicy'

import { HideableField } from '../components'
import { EditableFieldProps } from '..'

import './index.scss'

const DESCRIPTION_TITLE = 'Description'

export const Description = observer(({ policy, ...props }: { policy: Policy }) => {
  const { t } = useTranslation()

  return (
    <HideableField
      hidden={!policy.description}
      titleText={t('policiesLibrary.formDescriptionTitle', 'Description')}
      {...props}
    >
      <div className="policy-description-uneditable">
        {/* DO NOT TRANSLATE: DB Value */}
        <UI.Text styleType="body">{policy.description}</UI.Text>
      </div>
    </HideableField>
  )
})

export const EditableDescription = observer(
  ({ editable, onValidate, policy, ...props }: EditableFieldProps) => {
    const { t } = useTranslation()

    return editable ? (
      <HideableField
        editable
        titleText={t('policiesLibrary.formDescriptionTitle', 'Description')}
        {...props}
      >
        <UI.TextArea
          error={props.error}
          name="description"
          onBlur={onValidate}
          onChange={(e, { value }) => policy.setDescription(value)}
          placeholder={t(
            'policiesLibrary.formDescriptionPlaceholder',
            'What do you want to do with this policy?'
          )}
          value={policy.description || undefined}
        />
      </HideableField>
    ) : (
      <Description policy={policy} {...props} />
    )
  }
)
