import { fromHHMM, toHHMM } from '@/common/utils/time'
import { Time } from '@/common/utils/types'

import { FormattedInput, FormattedInputProps } from '../Input'

export interface TimeInputProps extends Omit<FormattedInputProps, 'onChange' | 'value'> {
  hourInputOnly?: boolean
  value: Time | undefined
  onAfterChange?: (value: Time | undefined) => void
}

/**
 * An Input control for entering time-of-day in HH:MM format
 * @param value object having the shape { hour: number, minute: number }
 */
export const TimeInput = ({ onAfterChange, value, hourInputOnly, ...props }: TimeInputProps) => {
  return (
    <FormattedInput
      icon="Clock"
      onAfterChange={value => {
        const time = fromHHMM(value) || undefined
        if (time && hourInputOnly) time.minute = 0
        onAfterChange?.(time)
      }}
      placeholder={'00:00'}
      maxLength={5}
      value={toHHMM(value)}
      {...props}
    />
  )
}
