import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'

import {
  Frequency,
  PolicyComplianceNotification,
  PolicyComplianceNotificationDoc,
} from '@/models/policyComplianceNotification'
import { apipyRequest } from '@/modules/api/request'
import { getOperatorDisplayName } from '@/modules/operator'
import permissionsModalStore from '@/modules/permissions/adminPanel/permissionsModalStore'

class PolicyComplianceNotificationsStore {
  hasNotifications = false
  allNotifications = observable.array()
  newNotificationEmail = ''
  newNotificationOperators: string[] = []
  newNotificationFrequency: Frequency = Frequency.daily
  confirmOpen: boolean = false
  frequencyOptions = [
    { value: 'daily', text: 'Daily' },
    { value: 'weekly', text: 'Weekly' },
    { value: 'monthly', text: 'Monthly' },
  ]

  constructor() {
    makeObservable(this, {
      hasNotifications: observable,
      newNotificationEmail: observable,
      newNotificationOperators: observable,
      newNotificationFrequency: observable,
      confirmOpen: observable,
      toggleHasNotifications: action,
      setHasNotifications: action,
      setAllNotifications: action,
      deleteNotification: action,
      removeNotification: action,
      setNewNotificationEmail: action,
      setNewNotificationOperators: action,
      setNewNotificationFrequency: action,
      setConfirmOpen: action,
      confirmContent: computed,
      createNotification: action,
      pushNotification: action,
      operatorOptions: computed,
    })
  }

  toggleHasNotifications() {
    this.hasNotifications = !this.hasNotifications
    permissionsModalStore.setHasEdits(true)
  }

  updateNotificationsStartDate() {
    if (this.hasNotifications) {
      const payload = {
        organization_uuid: permissionsModalStore.organizationUuid,
        timezone: permissionsModalStore.regionMetros[0].timezone,
      }
      apipyRequest('/notifications/update_start_date_policy_compliance', payload)
    }
  }

  setHasNotifications(bool: boolean) {
    this.hasNotifications = bool
  }

  setAllNotifications(notifications?: PolicyComplianceNotification[]) {
    if (notifications) this.allNotifications.replace(notifications)
    else this.allNotifications.clear()
  }

  loadAllNotifications() {
    const payload = { organization_uuid: permissionsModalStore.organizationUuid }
    apipyRequest('/notifications/list_policy_compliance', payload).then(
      (response: { success: string; notifications: PolicyComplianceNotificationDoc[] }) => {
        const newNotifications: PolicyComplianceNotification[] = response.notifications.map(
          notification => new PolicyComplianceNotification(notification)
        )
        this.setAllNotifications(newNotifications)
      }
    )
  }

  operatorDisplayNames(operators: string[]): string {
    return _.map(operators, operator => {
      return operator === 'all' ? 'All' : getOperatorDisplayName(operator)
    }).join(', ')
  }

  deleteNotification(notification: PolicyComplianceNotification) {
    const payload = { policy_compliance_notification_uuid: notification.uuid }
    apipyRequest('/notifications/delete_policy_compliance', payload).then(() => {
      this.removeNotification(notification)
    })
  }

  removeNotification(notification: PolicyComplianceNotification) {
    this.allNotifications.remove(notification)
  }

  setNewNotificationEmail(email: string) {
    this.newNotificationEmail = email
  }

  setNewNotificationOperators(operators: string[]) {
    this.newNotificationOperators = operators
  }

  setNewNotificationFrequency(frequency: Frequency) {
    this.newNotificationFrequency = frequency
  }

  setConfirmOpen(bool: boolean) {
    this.confirmOpen = bool
  }

  get confirmContent(): string {
    return `Please confirm that you would like to create a notification for ${
      this.newNotificationEmail
    } for the following operators: ${this.operatorDisplayNames(this.newNotificationOperators)}`
  }

  createNotification() {
    if (this.invalidNewEmail()) return
    const operators = this.newNotificationOperators.slice().includes('all')
      ? null
      : this.newNotificationOperators
    const email = this.newNotificationEmail
    const frequency = this.newNotificationFrequency
    permissionsModalStore.regionMetros.forEach(metro => {
      const regionId = metro.regionId
      const payload = {
        organization_uuid: permissionsModalStore.organizationUuid,
        target_region_id: regionId,
        email_address: email,
        operator_notifications: operators,
        frequency: frequency,
        timezone: metro.timezone,
      }
      apipyRequest('/notifications/create_policy_compliance', payload).then(response => {
        this.pushNotification(
          new PolicyComplianceNotification({
            policy_compliance_notification_uuid: response.uuid,
            email_address: email,
            region_id: regionId,
            operator_notifications: operators,
            frequency: frequency,
          })
        )
      })
    })
    this.setNewNotificationEmail('')
    this.setNewNotificationOperators([])
    this.setNewNotificationFrequency(Frequency.daily)
  }

  invalidNewEmail(): boolean {
    // Taken from https://masteringjs.io/tutorials/fundamentals/email-validation

    /* eslint-disable-next-line */
    return !/^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/.test(
      this.newNotificationEmail
    )
  }

  pushNotification(notification: PolicyComplianceNotification) {
    this.allNotifications.push(notification)
  }

  get operatorOptions() {
    const operators = permissionsModalStore.allMetroOperators
    let allDisabled = false
    let othersDisabled = false
    if (this.newNotificationOperators.slice().includes('all')) {
      othersDisabled = true
    } else if (this.newNotificationOperators.length > 0) {
      allDisabled = true
    }
    return [
      { value: 'all', text: 'All', disabled: allDisabled },
      ...permissionsModalStore.availableOperatorsMenuOptions(operators, othersDisabled),
    ]
  }
}

const policyComplianceNotificationsStore = new PolicyComplianceNotificationsStore()

export default policyComplianceNotificationsStore
