import { sortBy } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'

// prices_by_duration is an array of this type
type VariableDurationDatabaseModel = {
  price: number
  end_duration: number | null
  start_duration: number | null
}

export type VariableDuration = {
  price: number
  endDuration: number | null
  startDuration: number | null
}

/**
 * Model for a parking_fee_type: variable_price_parking_duration in policies.policy.fee_properties \
 * fee_properties will be stored like this:
 * {
 *     "parking_fee_type": "variable_price_parking_duration",
 *     "prices_by_duration": [
 *         {
 *             "price": 0.1,
 *             "end_duration": 1000,
 *             "start_duration": 0,
 *         }
 *     ]
 * }
 */
export class VariablePriceDuration {
  prices: VariableDuration[] = []

  constructor(pricesByDuration?: VariableDurationDatabaseModel[]) {
    makeObservable(this, {
      prices: observable,
      add: action,
      remove: action,
      json: computed,
    })

    if (pricesByDuration) this.prices = variablePriceDurations(pricesByDuration)
  }

  add = (duration?: VariableDuration) => {
    this.prices = [...this.prices, duration || emptyDuration()]
  }

  remove = (index: number) => {
    this.prices = this.prices.filter((val, idx) => idx !== index)
  }

  get json(): VariableDurationDatabaseModel[] {
    return this.prices.map(({ endDuration, price, startDuration }) => ({
      end_duration: toFloat(endDuration),
      price: toFloat(price)!,
      start_duration: toFloat(startDuration),
    }))
  }
}

function variablePriceDurations(
  pricesByDuration: VariableDurationDatabaseModel[]
): VariableDuration[] {
  const prices = pricesByDuration.map(({ end_duration, price, start_duration }) => ({
    endDuration: end_duration,
    price,
    startDuration: start_duration,
  }))
  return sortBy(prices, 'startDuration')
}

function toFloat(value?: number | null) {
  return value || value === 0 ? parseFloat(value.toString()) : null
}

function emptyDuration(): VariableDuration {
  return {
    endDuration: null,
    price: 0,
    startDuration: null,
  }
}
