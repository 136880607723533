import { featureCollection } from '@turf/helpers'
import { GeoJSON, Position } from 'geojson'
import { action, computed, makeObservable, observable } from 'mobx'

import { ParkingEventModel } from '@/modules/api/openapi/models/ParkingEventModel'
import { queryClientInstance } from '@/modules/api/queryClient'
import { apipyRequest, internalApi } from '@/modules/api/request'
import { OpenApiQueryParamsOmitRegionId } from '@/modules/api/types'
import appUIStore from '@/stores/appUIStore'

interface MostRecentParkingEvent {
  start_lon: number
  start_lat: number
}

interface TripEvent {
  lon: number
  lat: number
}
type Event = ParkingEventModel | MostRecentParkingEvent | TripEvent

export abstract class PointLocationHeatmapStore {
  ready = true
  events: Event[] | null = null
  isVisible = false
  // this is a cache of queries so we don't redo them
  cache = new Map()

  constructor() {
    makeObservable(this, {
      ready: observable,
      events: observable,
      isVisible: observable,
      setVisibility: action,
      toggleVisibility: action,
      setEvents: action,
      setReady: action,
      eventsGeojson: computed,
    })
  }

  setVisibility(isVisible: boolean) {
    this.isVisible = isVisible
  }

  toggleVisibility() {
    this.isVisible = !this.isVisible
  }

  setEvents(events: Event[]) {
    this.setReady(true)
    this.events = events
  }

  setReady(ready: boolean) {
    this.ready = ready
  }

  abstract query(): void

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCoordinates(event: Event): Position {
    return [0, 0]
  }

  eventGeojson(event: Event): GeoJSON {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: this.getCoordinates(event),
        // coordinates: this.getCoordinates(event),
      },
      properties: {},
    }
  }

  get eventsGeojson() {
    if (!this.events) return featureCollection([])

    return {
      type: 'FeatureCollection',
      features: this.events.map(e => this.eventGeojson(e)),
    }
  }
}

class ParkingHeatmapStore extends PointLocationHeatmapStore {
  apiQueryParams?: OpenApiQueryParamsOmitRegionId<typeof internalApi.parking.getParkingEvents> =
    undefined

  query() {
    this.setEvents([])
    this.setReady(false)

    if (!this.apiQueryParams) {
      this.setReady(true)
      return
    }

    const regionId = appUIStore.metro.regionId
    queryClientInstance
      .fetchQuery({
        queryKey: [`/regions/${regionId}/parking/parking_events`, this.apiQueryParams],
        queryFn: async () =>
          await internalApi.parking.getParkingEvents({
            regionId,
            purposes: ['micromobility'],
            ...this.apiQueryParams!,
          }),
      })
      .then(data => {
        this.setEvents(data)
      })
      .catch(() => {
        this.setReady(true)
      })
  }

  getCoordinates(event: ParkingEventModel) {
    return [event.startLon, event.startLat]
  }
}

export const parkingHeatmapStore = new ParkingHeatmapStore()

class TripsHeatmapStore extends PointLocationHeatmapStore {
  query(location: 'origin' | 'destination' = 'origin') {
    this.setEvents([])
    this.setReady(false)
    const url = '/trips/most_recent_10k'
    queryClientInstance
      .fetchQuery({
        queryKey: [appUIStore.metro.regionId, url, location],
        queryFn: async () => apipyRequest(url, { location }),
      })
      .then(({ data }: { data: Event[] }) => {
        this.setEvents(data)
      })
      .catch(() => {
        this.setReady(true)
      })
  }

  getCoordinates(event: TripEvent) {
    return [event.lon, event.lat]
  }
}

export const tripsHeatmapStore = new TripsHeatmapStore()
