/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PopulusPage_RateResponse_ } from '../models/PopulusPage_RateResponse_'
import type { RateResponse } from '../models/RateResponse'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class RatesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get All Rates
   * List all non-deleted rates for the current region.
   * @returns PopulusPage_RateResponse_ Successful Response
   * @throws ApiError
   */
  public getAllRates({
    regionId,
    page = 1,
    size = 50,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    page?: number
    size?: number
  }): CancelablePromise<PopulusPage_RateResponse_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/rates',
      path: {
        region_id: regionId,
      },
      query: {
        page: page,
        size: size,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Rate
   * Get the rate by UUID.
   * @returns RateResponse Successful Response
   * @throws ApiError
   */
  public getRate({
    regionId,
    id,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    id: string
  }): CancelablePromise<RateResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/rates/{id}',
      path: {
        region_id: regionId,
        id: id,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
