// @ts-nocheck
import _ from 'lodash'
import { observer } from 'mobx-react'
import { Icon, Message, Tab } from 'semantic-ui-react'

import OrganizationModal from '@/modules/organization/OrganizationModal'
import CurbManager from '@/modules/permissions/adminPanel/CurbManager'
import MobilityManager from '@/modules/permissions/adminPanel/MobilityManager'
import { Notifications } from '@/modules/permissions/adminPanel/Notifications'
import permissionsModalStore from '@/modules/permissions/adminPanel/permissionsModalStore'
import Users from '@/modules/permissions/adminPanel/Users'

const OrganizationError = observer(() => {
  const message = permissionsModalStore.organizationMessage

  if (!message) return null

  return (
    <Message negative={!!message.error} positive={!message.error}>
      <Message.Header>
        {message.heading}
        <Icon
          name="x"
          size="large"
          style={{ position: 'absolute', right: 8, top: 12, cursor: 'pointer' }}
          onClick={() => permissionsModalStore.clearOrganizationMessage()}
        />
      </Message.Header>
      <p>{message.text}</p>
    </Message>
  )
})

const tabStyle = {
  height: 450,
  overflowY: 'auto',
  overflowX: 'hidden',
}

const panes = [
  {
    menuItem: {
      content: 'Organization',
      icon: 'building',
      key: 'Organization',
    },
    render: () => (
      <Tab.Pane style={tabStyle}>
        <OrganizationError />
        <OrganizationModal />
      </Tab.Pane>
    ),
  },
  {
    menuItem: {
      content: 'Mobility Manager',
      key: 'Mobility Manager',
      icon: 'bicycle',
    },
    render: () => (
      <Tab.Pane style={tabStyle}>
        <OrganizationError />
        <MobilityManager />
      </Tab.Pane>
    ),
  },
  {
    menuItem: {
      content: 'Curb Manager',
      key: 'Curb Manager',
      icon: 'shipping fast',
    },
    render: () => (
      <Tab.Pane style={tabStyle}>
        <OrganizationError />
        <CurbManager />
      </Tab.Pane>
    ),
  },
  {
    menuItem: {
      content: 'Notifications',
      key: 'Notifications',
      icon: 'bell',
    },
    render: () => (
      <Tab.Pane style={tabStyle}>
        <OrganizationError />
        <Notifications />
      </Tab.Pane>
    ),
  },
  {
    menuItem: {
      content: 'Users',
      key: 'Users',
      icon: 'users',
    },
    render: () => (
      <Tab.Pane style={tabStyle}>
        <OrganizationError />
        <Users />
      </Tab.Pane>
    ),
  },
]

const PermissionsUI = observer(() => {
  const { activeTab, curbManager, mobilityManager, organizationUuid, organizationType } =
    permissionsModalStore

  return (
    <Tab
      menu={{ fluid: true, vertical: true, tabular: true }}
      panes={_.map(panes, p => {
        if (p.menuItem.key === 'Curb Manager')
          p.menuItem.className = curbManager ? 'paneOnStyle' : 'paneOffStyle'
        else if (p.menuItem.key === 'Mobility Manager')
          p.menuItem.className = mobilityManager ? 'paneOnStyle' : 'paneOffStyle'
        else if (p.menuItem.key === 'Notifications')
          p.menuItem.className = organizationType === 'City' ? 'paneOnStyle' : 'paneOffStyle'
        else if (p.menuItem.key === 'Users')
          p.menuItem.className = organizationUuid ? 'paneOnStyle' : 'paneOffStyle'
        return p
      })}
      activeIndex={activeTab}
      onTabChange={(e, { activeIndex }) => permissionsModalStore.setActiveTab(activeIndex)}
    />
  )
})

export default PermissionsUI
