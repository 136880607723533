import { useEffect, useState } from 'react'

const MAX_WIDTH_SMALL_SCREEN = 768
export const MINIMUM_DESKTOP_WIDTH = 1000
const MINIMUM_DESKTOP_HEIGHT = 560

interface WindowSize {
  width: number
  height: number
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  } as WindowSize)

  useEffect(() => {
    const onResize = () => {
      const { innerWidth: width, innerHeight: height } = window
      setWindowSize({ width, height })
    }

    window.addEventListener('resize', onResize)

    onResize()

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return windowSize
}

const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(true)

  useEffect(() => {
    const matchMedia = window.matchMedia(query)

    setMatches(matchMedia.matches)

    matchMedia.addEventListener('change', () => setMatches(matchMedia.matches))

    return () => matchMedia.removeEventListener('change', () => setMatches(matchMedia.matches))
  }, [query])

  return matches
}

export const useDeviceDetect = () => {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent
  const isAndroid = () => !!userAgent.match(/Android/i)
  const isIos = () => !!userAgent.match(/iPhone|iPad|iPod/i)
  const isOpera = () => !!userAgent.match(/Opera Mini/i)
  const isSSR = () => !!userAgent.match(/SSR/i)

  const isMobile = (): boolean => Boolean(isAndroid() || isIos() || isOpera())
  const isDesktop = (): boolean => Boolean(!isMobile() && !isSSR())

  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isSSR,
  }
}

export const useScreenSize = () => {
  const isSmallScreen = useMediaQuery(`(max-width: ${MAX_WIDTH_SMALL_SCREEN}px)`)
  const isDesktopHeight = useMediaQuery(`(min-height: ${MINIMUM_DESKTOP_HEIGHT}px)`)
  const isDesktopWidth = useMediaQuery(`(min-width: ${MINIMUM_DESKTOP_WIDTH}px)`)

  return {
    isSmallScreen,
    isDesktopHeight,
    isDesktopWidth,
  }
}
