import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

import { Button, Text } from '@/components/componentLibrary'
import { InvalidRouteTemplate } from '@/components/layouts'
import { Populus404 } from '@/modules/404Routes/Populus404'
import { PATHS } from '@/modules/urlRouting/paths'

const InvalidRoutePage: FC = () => {
  const navigate = useNavigate()
  const { showNewMessage } = useIntercom()

  const primaryTextContent = (
    <div>
      <Text styleType="title-light">Sorry, we can’t seem to find what you’re looking for...</Text>
    </div>
  )

  const secondaryTextContent = (
    <div>
      <Text styleType="body">
        It may be the case that your URL is misspelled, the link you used is broken, or the page
        content has moved somewhere else. If you’re unsure why you can’t find the page you’re
        looking for, please reach out to our support team.
      </Text>
    </div>
  )

  const actionsContent = (
    <>
      <Button
        extraWide
        onClick={() => {
          navigate(PATHS.ROOT)
        }}
        small
        text="Return to Populus App"
      />
      <Button extraWide onClick={() => showNewMessage()} small text="Contact The Populus Team" />
    </>
  )

  const props = {
    actionsContent,
    secondaryTextContent,
    primaryTextContent,
    imageContent: <Populus404 />,
  }

  return <InvalidRouteTemplate {...props} />
}

export default InvalidRoutePage
