import { FC, useEffect } from 'react'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react'
import { Checkbox, Dropdown, Form, Radio } from 'semantic-ui-react'

import { isoToDate } from '@/common/utils/date'
import { DatePicker } from '@/components/componentLibrary'
import { frontendOperators } from '@/modules/initialization/config'
import permissionsModalStore from '@/modules/permissions/adminPanel/permissionsModalStore'
import { MAP_CONSTANTS } from '@/modules/permissions/constants'
import authStore from '@/stores/authStore'

import './MobilityManager.scss'

const mapCheckboxes = [
  { label: 'Live Map', mapName: MAP_CONSTANTS.LIVE },
  { label: 'Issue Map', mapName: MAP_CONSTANTS.ISSUE },

  // TODO rename to geographies
  { label: 'Shapes Map', mapName: 'shapes' },
  { label: 'Routes Map', mapName: MAP_CONSTANTS.ROUTES },
  { label: 'Distrib Map', mapName: MAP_CONSTANTS.DISTRIBUTION },

  { label: 'Trips Map', mapName: MAP_CONSTANTS.TRIPS },
  { label: 'Parking Map', mapName: MAP_CONSTANTS.PARKING },
  { label: 'Operator Drop Offs Map', mapName: MAP_CONSTANTS.OPERATOR_DROP_OFFS },
]

const dashboardCheckboxes = [
  { label: 'Time and Distance', dashboardName: 'micromobility_use' },
  { label: 'Parking', dashboardName: 'micromobility_parking' },
]

const MobilityManagerCheckbox: FC = () => (
  <Checkbox
    toggle
    label="Mobility Manager Access"
    checked={permissionsModalStore.mobilityManager}
    onChange={() => permissionsModalStore.toggleMobilityManager()}
  />
)

const MobilityManager: FC = () => {
  useEffect(() => {
    permissionsModalStore.setTrialDateRangeDefault()
  }, [])

  const {
    availableOperatorsMenuOptions,
    selectedOperatorsJS,
    vehicleAndTripCounts,
    allOperators,
    allMaps,
    allMobilityDashboards,
  } = permissionsModalStore

  if (!permissionsModalStore.mobilityManager) {
    return <MobilityManagerCheckbox />
  }

  return (
    <>
      <div className="admin-page-section">
        <MobilityManagerCheckbox />
      </div>

      <div className="admin-page-section">
        <h5>Operators</h5>
        <Form>
          <Form.Field>
            <Checkbox
              label="All Operators"
              toggle
              checked={allOperators}
              onChange={() => permissionsModalStore.setAllOperators(!allOperators)}
            />
          </Form.Field>
          {!allOperators && (
            <Form.Field>
              <Dropdown
                onChange={(e, d) => permissionsModalStore.setSelectedOperators(d.value)}
                placeholder="Operators organization can access"
                fluid
                multiple
                selection
                options={availableOperatorsMenuOptions(Object.keys(frontendOperators))}
                value={selectedOperatorsJS}
              />
            </Form.Field>
          )}
        </Form>
      </div>

      <div className="admin-page-section">
        <h5>Trial Period</h5>
        <Form>
          <Form.Field>
            <Checkbox
              label="Enable Trial Period"
              toggle
              checked={permissionsModalStore.enableTrialPeriod}
              onChange={() => permissionsModalStore.toggleEnableTrialPeriod()}
            />
          </Form.Field>
          {permissionsModalStore.enableTrialPeriod && (
            <Form.Field>
              <DatePicker
                range={{
                  from:
                    // @ts-ignore
                    isoToDate(permissionsModalStore.trialDateRange.start?.toISO()) ||
                    DateTime.now().toJSDate(),
                  to:
                    // @ts-ignore
                    isoToDate(permissionsModalStore.trialDateRange.end?.toISO()) ||
                    DateTime.now().plus({ months: 1 }).toJSDate(),
                }}
                requireDates
                disableDropdown
                setRange={range => {
                  permissionsModalStore.setTrialDateRange({
                    start: DateTime.fromJSDate(range.from as Date),
                    end: DateTime.fromJSDate(range.to as Date),
                  })
                }}
              />
            </Form.Field>
          )}
        </Form>
      </div>

      <div className="admin-page-section">
        <h5>Maps</h5>
        <Form>
          <Form.Field>
            <Checkbox
              label="All Maps"
              toggle
              checked={allMaps}
              onChange={() => permissionsModalStore.setAllMaps(!allMaps)}
            />
          </Form.Field>
          {!allMaps && (
            <>
              <Form.Group className="flex flex-wrap">
                {mapCheckboxes.map(({ label, mapName }) => (
                  <Form.Field key={label}>
                    <Checkbox
                      className="admin-checkbox"
                      label={label}
                      checked={permissionsModalStore.mapIsChecked(mapName)}
                      onChange={() => permissionsModalStore.toggleMapChecked(mapName)}
                    />
                  </Form.Field>
                ))}
              </Form.Group>
            </>
          )}
          <Form.Field>
            <Checkbox
              label="Map Downloads"
              toggle
              checked={permissionsModalStore.mobilityMapsDownload}
              onChange={() => permissionsModalStore.toggleMobilityMapsDownload()}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Live Map mobile"
              toggle
              checked={permissionsModalStore.liveMapMobile}
              onChange={() => permissionsModalStore.toggleLiveMapMobile()}
            />
          </Form.Field>
        </Form>
      </div>

      <div className="admin-page-section">
        <h5>Reporting</h5>
        <p>Vehicle and Trip Counts:</p>
        <Form>
          <Form.Group inline>
            <Form.Field>
              <Radio
                className="admin-checkbox"
                label="Basic"
                checked={vehicleAndTripCounts === 'basic'}
                onChange={() => permissionsModalStore.setVehicleAndTripCounts('basic')}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                className="admin-checkbox"
                label="Advanced"
                checked={vehicleAndTripCounts === 'advanced'}
                onChange={() => permissionsModalStore.setVehicleAndTripCounts('advanced')}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <Checkbox
              label="Home Screen"
              toggle
              checked={permissionsModalStore.home}
              onChange={() => permissionsModalStore.toggleHome()}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Mobility Metrics"
              toggle
              checked={permissionsModalStore.mobilityMetrics}
              onChange={() => permissionsModalStore.toggleMobilityMetrics()}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="All Dashboards"
              toggle
              checked={allMobilityDashboards}
              onChange={() =>
                permissionsModalStore.setAllMobilityDashboards(!allMobilityDashboards)
              }
            />
          </Form.Field>
          {!allMobilityDashboards && (
            <Form.Group inline>
              {dashboardCheckboxes.map(({ label, dashboardName }) => (
                <Form.Field key={label}>
                  <Checkbox
                    className="admin-checkbox"
                    label={label}
                    checked={permissionsModalStore.mobilityDashboardIsChecked(dashboardName)}
                    onChange={() =>
                      permissionsModalStore.toggleMobilityDashboardChecked(dashboardName)
                    }
                  />
                </Form.Field>
              ))}
            </Form.Group>
          )}
        </Form>
      </div>

      <div className="admin-page-section">
        <h5>Policy and Compliance</h5>
        <Form>
          <Form.Field>
            <Checkbox
              label="Policy Library"
              toggle
              checked={permissionsModalStore.policiesLibrary}
              onChange={() => permissionsModalStore.togglePoliciesLibrary()}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Compliance Reporting"
              toggle
              checked={permissionsModalStore.complianceReporting}
              onChange={() => permissionsModalStore.toggleComplianceReporting()}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Compliance Details (Daily)"
              toggle
              checked={permissionsModalStore.complianceDetails}
              onChange={() => permissionsModalStore.toggleComplianceDetails()}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Fees Reporting"
              toggle
              checked={permissionsModalStore.feeSummary}
              onChange={() => {
                authStore.toggleFeeSummary()
                permissionsModalStore.toggleFeeSummary()
              }}
            />
          </Form.Field>
        </Form>
      </div>

      <div className="admin-page-section">
        <h5>Custom</h5>
        <Form>
          <Form.Field>
            <Checkbox
              label="Export Raw Data / Reports"
              toggle
              checked={permissionsModalStore.export}
              onChange={() => permissionsModalStore.toggleExport()}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Export Bikeshare Station Reports"
              toggle
              checked={permissionsModalStore.bikeshareStationReport}
              onChange={() => permissionsModalStore.toggleBikeshareStationReport()}
            />
          </Form.Field>
        </Form>
      </div>
    </>
  )
}

export default observer(MobilityManager)
