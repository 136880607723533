import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Header, Menu, Message, Segment } from 'semantic-ui-react'

import { apipyRequest } from '@/modules/api/request'
import { useSignInWithOIDC } from '@/modules/authentication'
import { fbAuth } from '@/modules/authentication'
import { monitorMessage } from '@/modules/monitoring'

const OIDCLoginPage = () => {
  const { signInWithOIDCToken, loggedInUser: user } = useSignInWithOIDC(fbAuth)
  const [errorCode, setErrorCode] = useState<string | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    if (user) navigate('/')
  }, [navigate, user])

  useEffect(() => {
    const queryString = window.location.search

    const urlParams = new URLSearchParams(queryString)
    const issuerUrl = urlParams.get('iss')
    let providerId = urlParams.get('gidp_provider_id')

    if (!issuerUrl && !providerId) {
      setErrorCode('MISSING_ISS_PARAMETER')
      return
    }

    const handleLogin = async () => {
      try {
        if (issuerUrl) {
          const { gidp_provider_id } = await apipyRequest(
            '/config/oidc',
            { oidc_issuer_url: issuerUrl },
            { reject: true }
          ).catch(() => {
            throw new Error('ISSUER_UNKNOWN')
          })
          providerId = gidp_provider_id
        }

        if (!providerId) {
          throw new Error('ISSUER_NOT_PROVIDED')
        }

        await signInWithOIDCToken(providerId)
      } catch (error: any) {
        const errorCode = error && error.message
        monitorMessage('Error logging in via SSO in OIDCLogin component')
        setErrorCode(errorCode)
      }
    }

    handleLogin()
  }, [signInWithOIDCToken])

  return (
    <>
      <Menu style={{ borderRadius: 'unset', backgroundColor: 'rgb(24, 47, 70)' }} inverted>
        <Menu.Item>
          <h1>
            <img src="/populus_logo.png" alt="Populus" width="155" />
          </h1>
        </Menu.Item>
      </Menu>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Grid centered verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 650, width: 600 }}>
            <Segment className="login-form">
              <Header as="h2" color="teal" textAlign="center">
                Logging you in via SSO...
              </Header>
              {errorCode && (
                <Message negative>
                  <Message.Header>Login Error</Message.Header>
                  <p>
                    There was an error logging you in via your SSO provider. Please contact your
                    account manager to resolve this issue. ({errorCode})
                  </p>
                </Message>
              )}
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    </>
  )
}

export default OIDCLoginPage
