import { startCase } from '@/common/utils/text'
import { PolicyTypeId, SpatialPolicyDocument } from '@/models/spatialPolicyTypes'
import i18n from '@/modules/i18n/i18n'

export enum PolicyGroup {
  VEHICLE = 'vehicle',
  TRIP = 'trip',
  PARKING = 'parking',
  NONE = 'none',
}

/**
 * The granularity at which a policy can be evaluated
 */
export enum PolicyTimeFrameType {
  DAYS, // start/end dates accepted, but not start/end times
  HOURS, // start/end times in hour increments accepted
  MINUTES, // start/end times in minute increments accepted
}

/**
 * TODO: Implement policy type definitions as a backend endpoint to make them reusable across the backend and frontend. See https://github.com/populus-ai/terminal/pull/4439
 *
 * Read about class enums: https://medium.com/swlh/class-based-enums-in-typescript-are-they-worth-the-trouble-6b6dfa512706
 */
export class PolicyType {
  private static asMap: { [key: string]: PolicyType } = {}
  private static asArray: PolicyType[] = []

  public static fromValue(value: PolicyTypeId): PolicyType {
    return PolicyType.asMap[value] || PolicyType.UNKNOWN
  }

  public static values = (): readonly PolicyType[] => {
    return PolicyType.asArray
  }

  public static byGroup = (group: PolicyGroup): readonly PolicyType[] => {
    return PolicyType.values().filter(value => value._group === group)
  }

  static readonly UNKNOWN = new PolicyType({
    id: 'unknown',
    title: 'Unknown',
    description: 'Unknown policy type',
    group: PolicyGroup.NONE,
    basedOnVehicledCounts: false,
    timeframeType: PolicyTimeFrameType.DAYS,
  })
  static readonly DISTRIBUTION = new PolicyType({
    id: 'distribution',
    title: 'Distribution',
    description: 'Prescribe the desired concentration of vehicles by geography.',
    group: PolicyGroup.VEHICLE,
    basedOnVehicledCounts: true,
    timeframeType: PolicyTimeFrameType.HOURS,
  })
  static readonly NO_PARKING = new PolicyType({
    id: 'no_parking',
    title: 'No Parking',
    description: 'Establish areas and times where and when parking is not allowed.',
    group: PolicyGroup.PARKING,
    basedOnVehicledCounts: false,
    timeframeType: PolicyTimeFrameType.HOURS,
  })
  static readonly NO_RIDE = new PolicyType({
    id: 'no_ride',
    title: 'No Ride',
    description: 'Establish areas and times where and when riding is not allowed.',
    group: PolicyGroup.TRIP,
    basedOnVehicledCounts: false,
    timeframeType: PolicyTimeFrameType.HOURS,
  })
  static readonly OPERATOR_DROP_OFFS = new PolicyType({
    id: 'operator_drop_offs',
    title: 'Operator Drop-offs',
    description:
      'Designate where and how many vehicles could or should be deployed in certain areas.',
    group: PolicyGroup.VEHICLE,
    basedOnVehicledCounts: true,
    timeframeType: PolicyTimeFrameType.HOURS,
  })
  static readonly PARKING_FEES = new PolicyType({
    id: 'parking_fees',
    title: 'Parking Fees',
    description: 'Establish a schedule of fees based on parking events.',
    group: PolicyGroup.PARKING,
    basedOnVehicledCounts: false,
    timeframeType: PolicyTimeFrameType.HOURS,
  })
  static readonly PARKING_TIME_LIMIT = new PolicyType({
    id: 'parking_time_limit',
    title: 'Parking Time Limit',
    description: 'Establish a time limited parking area(s).',
    group: PolicyGroup.PARKING,
    basedOnVehicledCounts: false,
    timeframeType: PolicyTimeFrameType.DAYS,
  })
  static readonly PREFERRED_PARKING = new PolicyType({
    id: 'preferred_parking',
    title: 'Preferred Parking',
    description: 'Define areas in which the placement of vehicles is preferred.',
    group: PolicyGroup.PARKING,
    basedOnVehicledCounts: false,
    timeframeType: PolicyTimeFrameType.MINUTES,
  })
  static readonly SLOW_RIDE = new PolicyType({
    id: 'slow_ride',
    title: 'Slow Ride',
    description:
      'Establish areas, times, and speeds where and when a specified speed limit is in effect.',
    group: PolicyGroup.TRIP,
    basedOnVehicledCounts: false,
    timeframeType: PolicyTimeFrameType.MINUTES,
  })
  static readonly TRIP_FEES = new PolicyType({
    id: 'trip_fees',
    title: 'Trip Fees',
    description: 'Establish per trip program fees.',
    group: PolicyGroup.TRIP,
    basedOnVehicledCounts: false,
    timeframeType: PolicyTimeFrameType.DAYS,
  })
  static readonly UTILIZATION = new PolicyType({
    id: 'utilization',
    title: 'Utilization',
    description: 'Set values to monitor the # of rides per vehicle per day.',
    group: PolicyGroup.TRIP,
    basedOnVehicledCounts: true,
    timeframeType: PolicyTimeFrameType.DAYS,
  })
  static readonly VEHICLE_CAP = new PolicyType({
    id: 'vehicle_cap',
    title: 'Vehicle Cap',
    description: 'Specify the number of vehicles allowed on city streets.',
    group: PolicyGroup.VEHICLE,
    basedOnVehicledCounts: true,
    timeframeType: PolicyTimeFrameType.DAYS,
  })
  static readonly VEHICLE_FEES = new PolicyType({
    id: 'vehicle_fees',
    title: 'Vehicle Fees',
    description: 'Establish per vehicle program fees.',
    group: PolicyGroup.VEHICLE,
    basedOnVehicledCounts: false,
    timeframeType: PolicyTimeFrameType.DAYS,
  })

  public readonly id: PolicyTypeId
  public readonly _title: string
  public readonly _description: string
  public readonly _group: PolicyGroup
  public readonly basedOnVehicleCounts: boolean
  public readonly timeframeType: PolicyTimeFrameType

  /**
   *
   * @param id
   * @param title
   * @param description
   * @param group
   * @param basedOnVehicleCounts policies that work with vehicle counts
   * @param timeframeType the type of start/end time range this policy can be configured with. 'hour' = hourly start/end times, 'minute' = start/end times down to the minute, undefined = no time frame can be specified
   * @returns
   */
  private constructor(config: {
    id: PolicyTypeId
    title: string
    description: string
    group: PolicyGroup
    basedOnVehicledCounts: boolean
    timeframeType: PolicyTimeFrameType
  }) {
    this.id = config.id
    this._title = config.title
    this._description = config.description
    this._group = config.group
    this.basedOnVehicleCounts = config.basedOnVehicledCounts
    this.timeframeType = config.timeframeType

    if (this.id === 'unknown') return

    PolicyType.asArray.push(this)
    PolicyType.asMap[this.id] = this
  }

  get title(): string {
    return i18n.t(
      `policiesLibrary.policyTypeTitle${startCase(this.id).replaceAll(' ', '')}`,
      this._title
    )
  }

  get description(): string {
    return i18n.t(
      `policiesLibrary.policyTypeDescription${startCase(this.id).replaceAll(' ', '')}`,
      this._description
    )
  }

  get group(): string {
    const groupTitle = startCase(this._group)

    return i18n.t(`policiesLibrary.policyGroupTitle${groupTitle.replaceAll(' ', '')}`, groupTitle)
  }

  get dropdownOption(): { key: string; value: string; text: string } {
    return {
      key: this.id,
      value: this.id,
      text: this.title,
    }
  }
}

export type FormErrors = { [key in PolicyFormFieldName]?: string }

export type PolicyFormFieldName =
  | 'allocation'
  | 'days'
  | 'description'
  | 'dateRange'
  | 'maximum'
  | 'minimum'
  | 'messages'
  | 'operators'
  | 'parkingFeeType'
  | 'policyName'
  | 'policyType'
  | 'pricingUnit'
  | 'shapeLayerUUID'
  | 'timeFrame'
  | 'unit'
  | 'value'
  | 'variablePrices'
  | 'vehicleCountMethod'
  | 'vehicleStates'
  | 'vehicleTypes'

export type PolicyStatus = 'published' | 'saved' | 'expired'

export interface PolicyFetchProps {
  uuid?: string
  policyJson?: SpatialPolicyDocument
}
