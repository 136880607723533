import { DayModifiers, DayPickerProps, NavbarElementProps } from 'react-day-picker'

import i18n from '@/modules/i18n/i18n'

export type DateType = Date | null | undefined

// DatePicker
export interface DatePickerRange {
  from: DateType
  to: DateType
}
export interface DatePickerProps {
  disableDropdown?: boolean
  dropdownDefault?: DatePickerDropdownValue
  dropdownOptions?: DatePickerDropdownValue[]
  range: DatePickerRange
  rangeLimit?: number
  maxDate?: DateType
  minDate?: DateType
  requireDates?: boolean
  onOpen?: () => void
  onClose?: () => void
  setRange: (range: DatePickerRange) => void
}

export const getDatePickerErrors = () => {
  const { t } = i18n

  return {
    invalid: t('datePicker.invalidDate', 'Invalid date format'),
    required: t('datePicker.dateRequired', 'Date input is required'),
  } as const
}

export interface DatePickerErrorProps {
  to?: string
  from?: string
}
export type DatePickerDropdownValue =
  | 'custom'
  | 'singleDay'
  | 'noStart'
  | 'noEnd'
  | 'thisMonth'
  | 'lastMonth'
export type DatePickerActiveInput = 'from' | 'to' | undefined

// Calendar
export interface CalendarRef {
  updateMonth: (props: CalendarUpdateMonthProps) => void
}
export interface CalendarProps extends DayPickerProps {
  activeInput?: DatePickerActiveInput
  className?: string
  singleDay?: boolean
  noStart?: boolean
  noEnd?: boolean
  from: DateType
  to: DateType
  setFrom: (day: DateType, focusTo?: boolean) => void
  setTo: (day: DateType, focusFrom?: boolean) => void
}
export interface CalendarUpdateMonthProps {
  activeInput?: DatePickerActiveInput
  from?: DateType
  to?: DateType
}
export type CalendarMonthProps = Pick<NavbarElementProps, 'month' | 'nextMonth' | 'previousMonth'>

// DayElement
export interface DayElementProps {
  activeInput?: DatePickerActiveInput
  date: Date
  modifiers: DayModifiers
  singleDay?: boolean
}

// DateInput
export interface DateInputProps {
  date: DateType
  appearFocused: boolean
  errorText?: string
  handleError?: (errorType?: string) => void
  handleFocus?: () => void
  setDate: (date: DateType) => void
  disabled?: boolean
}
