/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PolicyFiltersResponse } from '../models/PolicyFiltersResponse'
import type { PolicyTypes } from '../models/PolicyTypes'
import type { PolicyV2Response } from '../models/PolicyV2Response'
import type { PolicyV2RuleResponse } from '../models/PolicyV2RuleResponse'
import type { PopulusPage_FilteredCurbPolicy_ } from '../models/PopulusPage_FilteredCurbPolicy_'
import type { PopulusPage_PolicyV2Response_ } from '../models/PopulusPage_PolicyV2Response_'
import type { PopulusPage_PolicyV2RuleResponse_ } from '../models/PopulusPage_PolicyV2RuleResponse_'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class PoliciesV2Service {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get Curb Policies
   * List all policies for the current region.
   * @returns PopulusPage_PolicyV2Response_ Successful Response
   * @throws ApiError
   */
  public getCurbPolicies({
    regionId,
    page = 1,
    size = 300,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    page?: number
    size?: number
  }): CancelablePromise<PopulusPage_PolicyV2Response_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/policies_v2',
      path: {
        region_id: regionId,
      },
      query: {
        page: page,
        size: size,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Curb Policies Filters
   * Returns a list of policy_id, rule_ids that conform to applied filters
   * @returns PopulusPage_FilteredCurbPolicy_ Successful Response
   * @throws ApiError
   */
  public getCurbPoliciesFilters({
    regionId,
    purposes,
    vehicleTypes,
    userClasses,
    types,
    hasRate,
    maximum,
    unit,
    startDate,
    endDate,
    startTime,
    endTime,
    daysOfWeek,
    page = 1,
    size = 300,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Filter for purposes
     */
    purposes?: Array<string>
    /**
     * Filter for vehicle_types
     */
    vehicleTypes?: Array<string>
    /**
     * Filter for user classes
     */
    userClasses?: Array<string>
    /**
     * Filter for types
     */
    types?: Array<string>
    /**
     * True to filter for policy rules with rates
     */
    hasRate?: boolean
    /**
     * Filter policy rule maximum by providing two numbers representing a range (inclusive)
     */
    maximum?: Array<number>
    /**
     * Filter by policy rule unit
     */
    unit?: string
    /**
     * Filter policy rules that apply on or after the provided ISO date (inclusive)
     */
    startDate?: string
    /**
     * Filter policy rules that apply on or before the provided ISO date (inclusive)
     */
    endDate?: string
    /**
     * Filter policy rules that apply after the provided time in format HH:mm (inclusive)
     */
    startTime?: string
    /**
     * Filter policy rules that apply before the provided time in format HH:mm (exclusive)
     */
    endTime?: string
    /**
     * Filter policy rules for those that apply weekly on ISO days of week
     */
    daysOfWeek?: Array<number>
    page?: number
    size?: number
  }): CancelablePromise<PopulusPage_FilteredCurbPolicy_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/policies_v2/filter',
      path: {
        region_id: regionId,
      },
      query: {
        purposes: purposes,
        vehicle_types: vehicleTypes,
        user_classes: userClasses,
        types: types,
        has_rate: hasRate,
        maximum: maximum,
        unit: unit,
        start_date: startDate,
        end_date: endDate,
        start_time: startTime,
        end_time: endTime,
        days_of_week: daysOfWeek,
        page: page,
        size: size,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Curb Policy Rules
   * List all curb policy rules for the current region.
   * @returns PopulusPage_PolicyV2RuleResponse_ Successful Response
   * @throws ApiError
   */
  public getCurbPolicyRules({
    regionId,
    page = 1,
    size = 300,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    page?: number
    size?: number
  }): CancelablePromise<PopulusPage_PolicyV2RuleResponse_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/policies_v2/rules',
      path: {
        region_id: regionId,
      },
      query: {
        page: page,
        size: size,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Curb Policy Rule
   * Get a single curb policy rule from a UUID.
   * @returns PolicyV2RuleResponse Successful Response
   * @throws ApiError
   */
  public getCurbPolicyRule({
    regionId,
    id,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Policy rule identifier
     */
    id: string
  }): CancelablePromise<PolicyV2RuleResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/policies_v2/rules/{id}',
      path: {
        region_id: regionId,
        id: id,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Policy Types
   * Returns a list of policy types, sorted by display priority.
   * @returns PolicyTypes Successful Response
   * @throws ApiError
   */
  public getPolicyTypes({
    regionId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
  }): CancelablePromise<Array<PolicyTypes>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/policies_v2/types',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Policy Filter Options
   * @returns PolicyFiltersResponse Successful Response
   * @throws ApiError
   */
  public getPolicyFilterOptions({
    regionId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
  }): CancelablePromise<PolicyFiltersResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/policies_v2/filter_options',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Curb Activity Statistics
   * Returns the count of shapes in the region that are associated
   * with each policy type (e.g. loading, no_stopping, parking, etc.)
   * @returns number Successful Response
   * @throws ApiError
   */
  public getCurbActivityStatistics({
    regionId,
    purposes,
    vehicleTypes,
    userClasses,
    types,
    hasRate,
    maximum,
    unit,
    startDate,
    endDate,
    startTime,
    endTime,
    daysOfWeek,
    policyIds,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Filter for purposes
     */
    purposes?: Array<string>
    /**
     * Filter for vehicle_types
     */
    vehicleTypes?: Array<string>
    /**
     * Filter for user classes
     */
    userClasses?: Array<string>
    /**
     * Filter for types
     */
    types?: Array<string>
    /**
     * True to filter for policy rules with rates
     */
    hasRate?: boolean
    /**
     * Filter policy rule maximum by providing two numbers representing a range (inclusive)
     */
    maximum?: Array<number>
    /**
     * Filter by policy rule unit
     */
    unit?: string
    /**
     * Filter policy rules that apply on or after the provided ISO date (inclusive)
     */
    startDate?: string
    /**
     * Filter policy rules that apply on or before the provided ISO date (inclusive)
     */
    endDate?: string
    /**
     * Filter policy rules that apply after the provided time in format HH:mm (inclusive)
     */
    startTime?: string
    /**
     * Filter policy rules that apply before the provided time in format HH:mm (exclusive)
     */
    endTime?: string
    /**
     * Filter policy rules for those that apply weekly on ISO days of week
     */
    daysOfWeek?: Array<number>
    /**
     * List of policy IDs to filter by
     */
    policyIds?: Array<string>
  }): CancelablePromise<Record<string, number>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/policies_v2/curb_activity_statistics',
      path: {
        region_id: regionId,
      },
      query: {
        purposes: purposes,
        vehicleTypes: vehicleTypes,
        userClasses: userClasses,
        types: types,
        hasRate: hasRate,
        maximum: maximum,
        unit: unit,
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        daysOfWeek: daysOfWeek,
        policyIds: policyIds,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Curb Policy
   * Get a single curb policy from a UUID.
   * @returns PolicyV2Response Successful Response
   * @throws ApiError
   */
  public getCurbPolicy({
    regionId,
    id,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Policy identifier
     */
    id: string
  }): CancelablePromise<PolicyV2Response> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/policies_v2/{id}',
      path: {
        region_id: regionId,
        id: id,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
