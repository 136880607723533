/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListShapesPayload } from '../models/ListShapesPayload'
import type { ListShapesResponseModel } from '../models/ListShapesResponseModel'
import type { PopulusPage_MatchedPolicyRuleSchedule_ } from '../models/PopulusPage_MatchedPolicyRuleSchedule_'
import type { ShapesResultModel } from '../models/ShapesResultModel'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class ShapesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get Shape
   * Get a single shape from a UUID.
   * @returns ShapesResultModel Successful Response
   * @throws ApiError
   */
  public getShape({
    regionId,
    shapeUuid,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Shape identifier
     */
    shapeUuid: string
  }): CancelablePromise<ShapesResultModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/shapes/{shape_uuid}',
      path: {
        region_id: regionId,
        shape_uuid: shapeUuid,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Policies By Shape
   * Get Policies relating to a single shape from a UUID.
   * @returns PopulusPage_MatchedPolicyRuleSchedule_ Successful Response
   * @throws ApiError
   */
  public getPoliciesByShape({
    regionId,
    shapeUuid,
    startDate,
    endDate,
    purposes,
    vehicleTypes,
    userClasses,
    types,
    hasRate,
    maximum,
    unit,
    daysOfWeek,
    startTime,
    endTime,
    page = 1,
    size = 50,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Shape identifier
     */
    shapeUuid: string
    /**
     * Filter for policies and compliance records that end after the provided ISO start date (inclusive)
     */
    startDate: string
    /**
     * Filter for policies and compliance records that started on or before the provided ISO end date (inclusive)
     */
    endDate: string
    /**
     * Filter for purposes
     */
    purposes?: Array<string>
    /**
     * Filter for vehicle_types
     */
    vehicleTypes?: Array<string>
    /**
     * Filter for user_classes
     */
    userClasses?: Array<string>
    /**
     * Filter for types
     */
    types?: Array<string>
    /**
     * True to filter for policy rules with rates
     */
    hasRate?: boolean
    /**
     * Filter policy rule maximum by providing two numbers representing a range (inclusive)
     */
    maximum?: Array<number>
    /**
     * Filter by policy rule unit
     */
    unit?: string
    /**
     * Filter policy rules for those that apply weekly on ISO days of week
     */
    daysOfWeek?: Array<number>
    /**
     * Filter policy rules that apply after the provided time in format HH:mm (inclusive)
     */
    startTime?: string
    /**
     * Filter policy rules that apply before the provided time in format HH:mm (exclusive)
     */
    endTime?: string
    page?: number
    size?: number
  }): CancelablePromise<PopulusPage_MatchedPolicyRuleSchedule_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/shapes/{shape_uuid}/all_policies',
      path: {
        region_id: regionId,
        shape_uuid: shapeUuid,
      },
      query: {
        purposes: purposes,
        vehicle_types: vehicleTypes,
        user_classes: userClasses,
        types: types,
        has_rate: hasRate,
        maximum: maximum,
        unit: unit,
        days_of_week: daysOfWeek,
        start_date: startDate,
        end_date: endDate,
        start_time: startTime,
        end_time: endTime,
        page: page,
        size: size,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * List Shapes Deprecated
   * Returns shapes for a specific layer uuid.  region_id is also required
   * to make sure the user has access to this layer.
   * @returns ListShapesResponseModel Successful Response
   * @throws ApiError
   */
  public listShapesDeprecated({
    regionId,
    requestBody,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    requestBody: ListShapesPayload
  }): CancelablePromise<ListShapesResponseModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/regions/{region_id}/shapes/list',
      path: {
        region_id: regionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
