import { useQuery } from '@tanstack/react-query'

import { EditUserRoleItem } from '@/modules/api/openapi/models/EditUserRoleItem'
import { Permission } from '@/modules/api/openapi/models/Permission'
import { PermissionKey } from '@/modules/api/openapi/models/PermissionKey'
import { PermissionsObjType } from '@/modules/api/openapi/models/PermissionsObjType'
import { UserRoleWithPermissions } from '@/modules/api/openapi/models/UserRoleWithPermissions'
import { internalApi } from '@/modules/api/request'
import { OpenApiQueryParams } from '@/modules/api/types'
import { useCurrentPermissions } from '@/modules/user/hooks'

export const buildGetPermissionsForObjQueryOptions = (
  params: OpenApiQueryParams<typeof internalApi.permissions.getPermissionsForObj>
) => {
  return {
    queryKey: [`/permissions/${params.objType}/${params.objId}`],
    queryFn: async () => await internalApi.permissions.getPermissionsForObj(params),
  }
}

export const usePermissionsForObj = (
  params: Parameters<typeof buildGetPermissionsForObjQueryOptions>[0]
) => {
  return useQuery(buildGetPermissionsForObjQueryOptions(params))
}

export const buildGetRolesForUserQueryOptions = (
  params: OpenApiQueryParams<typeof internalApi.users.getRolesForUser>
) => {
  return {
    queryKey: [`/user_roles/${params.userId}`],
    queryFn: async () => await internalApi.users.getRolesForUser(params),
  }
}

export const useRolesForUser = (params: Parameters<typeof buildGetRolesForUserQueryOptions>[0]) => {
  return useQuery(buildGetRolesForUserQueryOptions(params))
}

export const useAvailableRolesWithPermissions = () => {
  return useQuery({
    queryKey: [`/user_roles/`],
    queryFn: async () => await internalApi.users.getAvailableRolesWithPermissions(),
  })
}

export interface UserRolesAndPermissionsForUser {
  isRolesAndPermissionsReady: boolean
  availableRolesWithPermissions: UserRoleWithPermissions[]
  otherAvailablePermissions: { label: string; key: string }[]
  currentRoles: EditUserRoleItem[]
  currentPermissions: Permission[]
}

/**
 * Combine roles & permissions for a userId to be used in the admin modal
 */
export const useUserRolesAndPermissionsForUser = (params: {
  userId: string
  availableUserPermissions: { label: string; key: PermissionKey }[]
  editedUserRoles: EditUserRoleItem[]
  editedUserPermissions: Permission[]
}) => {
  const { userId, availableUserPermissions, editedUserRoles, editedUserPermissions } = params
  const {
    data: availableRolesWithPermissionsResult,
    isLoading: availableRolesWithPermissionsLoading,
  } = useAvailableRolesWithPermissions()
  const availableRolesWithPermissions = availableRolesWithPermissionsResult ?? []

  // permissions not assigned to any roles
  const otherAvailablePermissions = availableUserPermissions.filter(
    availablePermission =>
      !availableRolesWithPermissions
        .flatMap(availableRole => availableRole.permissionsKeys)
        .includes(availablePermission.key)
  )
  const { data: userPermissions, isLoading: userPermissionsLoading } = usePermissionsForObj({
    objType: PermissionsObjType.USER,
    objId: userId,
  })
  const { data: rolesForUser, isLoading: rolesForUserLoading } = useRolesForUser({
    userId: userId,
  })

  const isRolesAndPermissionsReady =
    !availableRolesWithPermissionsLoading && !userPermissionsLoading && !rolesForUserLoading

  const currentPermissions: Permission[] = availableUserPermissions.map(({ label, key }) => {
    const editedEnabled = editedUserPermissions.find(p => p.key === key)?.enabled
    const originalEnabled = !!userPermissions?.find(p => p.key === key)?.enabled
    return {
      key,
      source: PermissionsObjType.USER,
      enabled: editedEnabled ?? originalEnabled,
    }
  })

  const currentRoles: EditUserRoleItem[] = availableRolesWithPermissions.map(availableRole => {
    const editedEnabled = editedUserRoles?.find(
      editedRole => editedRole.roleId === availableRole.roleId
    )?.enabled
    const originalEnabled = !!rolesForUser?.some(role => role.roleId === availableRole.roleId)
    return {
      roleId: availableRole.roleId,
      enabled: editedEnabled ?? originalEnabled,
    }
  })

  return {
    isRolesAndPermissionsReady,
    availableRolesWithPermissions,
    otherAvailablePermissions,
    currentRoles,
    currentPermissions,
  }
}

export const useHasBikeshareStationReportPermissions = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.bikeshareStationReport
}
