// @ts-nocheck
import { FC, useState } from 'react'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import { observer } from 'mobx-react'
import {
  Button,
  Checkbox,
  Confirm,
  Dropdown,
  Form,
  Header,
  Icon,
  Input,
  Message,
  Modal,
  Table,
} from 'semantic-ui-react'

import { EditUserRoleItem } from '@/modules/api/openapi/models/EditUserRoleItem'
import { Permission } from '@/modules/api/openapi/models/Permission'
import { UserResponse } from '@/modules/api/openapi/models/UserResponse'
import { startImpersonatingUser } from '@/modules/authentication/impersonation'
import EditUserPermissionsSection from '@/modules/permissions/adminPanel/EditUserPermissionsSection'
import permissionsModalStore from '@/modules/permissions/adminPanel/permissionsModalStore'
import { useUserRolesAndPermissionsForUser } from '@/modules/permissions/hooks'

import './PermissionsGroup.scss'

interface EditUserDialogProps {
  user: any
  onCancel: () => void
  onSave: (user: any, permissions: Permission[], roles: EditUserRoleItem[]) => void
  editUser: () => void
}

export const EditUserDialog: FC<EditUserDialogProps> = ({ user, onCancel, onSave, editUser }) => {
  const { user_uuid, user_regions: selectedRegions } = user

  const [allRegions, setAllRegions] = useState(selectedRegions === null)

  const organizationRegions = permissionsModalStore.selectedRegionsJS

  const [editedUserPermissions, setEditedUserPermissions] = useState<Permission[]>([])
  const [editedUserRoles, setEditedUserRoles] = useState<EditUserRoleItem[]>([])
  const userRolesAndPermissionsForUserResult = useUserRolesAndPermissionsForUser({
    userId: user_uuid,
    availableUserPermissions: permissionsModalStore.availableUserPermissions,
    editedUserPermissions,
    editedUserRoles,
  })

  return (
    <Modal open={!!user} onClose={() => onCancel()} size="mini">
      <Header icon="user" content="Edit User" />
      <Modal.Content style={{ display: 'flex', flexDirection: 'column' }}>
        <Form>
          <Form.Group style={{ marginTop: 15 }}>
            <Checkbox
              label="Same Regions as Organization"
              toggle
              checked={allRegions}
              onChange={() => setAllRegions(!allRegions)}
            />
            {!allRegions && (
              <Dropdown
                style={{ marginTop: 7 }}
                onChange={(e, d) => editUser({ ...user, user_regions: d.value })}
                placeholder="Regions this user can access"
                fluid
                multiple
                selection
                options={permissionsModalStore.availableRegionsMenuOptions.filter(
                  o => permissionsModalStore.allRegions || _.includes(organizationRegions, o.value)
                )}
                value={selectedRegions}
              />
            )}
          </Form.Group>
          <EditUserPermissionsSection
            {...userRolesAndPermissionsForUserResult}
            onChangeUserPermissions={editedUserPermissions =>
              setEditedUserPermissions(editedUserPermissions)
            }
            onChangeUserRoles={editedUserRoles => setEditedUserRoles(editedUserRoles)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="green"
          onClick={() => {
            onSave(
              {
                user_uuid: user.user_uuid,
                regions: allRegions === true ? null : selectedRegions,
              },
              editedUserPermissions,
              editedUserRoles
            )
          }}
          basic
        >
          <Icon name="save" /> Save
        </Button>
        &nbsp;&nbsp;
        <Button color="red" onClick={() => onCancel()} basic>
          <Icon name="cancel" /> Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const Users = observer(() => {
  const { pathname } = useLocation()

  const [confirmUserDeleteOpen, setConfirmUserDeleteOpen] = useState(false)
  const [deleteUserUuid, setDeleteUserUuid] = useState(undefined)
  const [editUser, setEditUser] = useState(undefined)

  const deleteUserConfirmed = () => {
    permissionsModalStore.deleteUser(deleteUserUuid)
    setDeleteUserUuid(undefined)
    setConfirmUserDeleteOpen(false)
  }

  const startEditUser = editUser => {
    setEditUser(editUser)
  }

  const stopEditUser = () => {
    setEditUser(undefined)
  }

  const doEditUser = (
    user: any,
    editedPermissions: Permission[],
    editedRoles: EditUserRoleItem[]
  ) => {
    permissionsModalStore.editUser(user, editedPermissions, editedRoles)
    setEditUser(undefined)
  }

  const message = permissionsModalStore.userMessage
  const { availableUserPermissions, userPermissionsFilter, organizationUuid } =
    permissionsModalStore

  if (!organizationUuid) {
    return <div>Create organization first before adding users</div>
  }

  const userTable = (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>SSO</Table.HeaderCell>
          <Table.HeaderCell>View As</Table.HeaderCell>
          <Table.HeaderCell>Edit</Table.HeaderCell>
          <Table.HeaderCell>Delete</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.sortBy([...permissionsModalStore.users.values()], u => u.email).map(user => (
          <Table.Row key={user.user_uuid}>
            <Table.Cell>{user.email}</Table.Cell>
            <Table.Cell>
              {user.created_via_gidp_provider_id && user.oidc_issuer_url ? (
                <Button
                  icon
                  as="a"
                  href={user.oidc_issuer_url}
                  title={`${user.oidc_issuer_url} (${user.created_via_gidp_provider_id})`}
                  target="_blank"
                >
                  <Icon name="linkify" />
                </Button>
              ) : null}
            </Table.Cell>
            <Table.Cell>
              <Button
                basic
                icon
                color="purple"
                onClick={() => {
                  startImpersonatingUser(user.user_uuid, user.email)
                  // reload the app at the page the user was at before opening the settings modal
                  window.location.replace(pathname)
                }}
              >
                <Icon name="eye" />
              </Button>
            </Table.Cell>
            <Table.Cell>
              <Button basic icon color="blue" onClick={() => startEditUser(user)}>
                <Icon name="edit" />
              </Button>
            </Table.Cell>
            <Table.Cell>
              <Button
                basic
                icon
                color="red"
                onClick={() => {
                  setDeleteUserUuid(user.user_uuid)
                  setConfirmUserDeleteOpen(true)
                }}
              >
                <Icon name="delete" />
              </Button>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )

  return (
    <>
      <h5>New User</h5>
      <Form.Group inline>
        <Input
          label="Email"
          type="email"
          value={permissionsModalStore.newUserEmail}
          onChange={(e, d) => permissionsModalStore.setNewUserEmail(d.value)}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button basic color="green" onClick={() => permissionsModalStore.addUser()}>
          Add User
        </Button>
      </Form.Group>

      {message && (
        <Message negative={!!message.error} positive={!message.error}>
          <Message.Header>
            {message.heading}
            <Icon
              name="x"
              size="large"
              style={{ position: 'absolute', right: 8, top: 12, cursor: 'pointer' }}
              onClick={() => permissionsModalStore.clearUserMessage()}
            />
          </Message.Header>
          <p>{message.text}</p>
        </Message>
      )}

      {permissionsModalStore.users.size === 0 ? (
        <h5>There are no users in this organization</h5>
      ) : (
        userTable
      )}

      <Confirm
        open={confirmUserDeleteOpen}
        onCancel={() => setConfirmUserDeleteOpen(false)}
        onConfirm={() => deleteUserConfirmed()}
        content="Are you sure you want to delete this user?"
        style={{ width: 280 }}
      />

      {editUser && (
        <EditUserDialog
          user={editUser}
          onSave={(user, permissions, roles) => doEditUser(user, permissions, roles)}
          onCancel={() => stopEditUser()}
          editUser={startEditUser}
        />
      )}
    </>
  )
})

export default Users
