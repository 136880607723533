import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useSetDocumentTitle } from '@/common/hooks'
import LoginForm from '@/modules/authentication/LoginForm'

const LoginPage: FC = () => {
  const { t } = useTranslation()
  useSetDocumentTitle(t('login.pageTitle', 'Login'))

  return <LoginForm />
}

export default LoginPage
