import { FC, PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import { Modal } from 'semantic-ui-react'

import { Button, Text } from '@/components/componentLibrary'
import { PATHS } from '@/modules/urlRouting/paths'
import { useCurrentUser, usePatchCurrentUser } from '@/modules/user/hooks'

import './TermsOfServiceGatekeeper.scss'

const TermsOfServiceGateKeeper: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation()

  const [canceled, setCanceled] = useState(false)

  const { data: currentUser } = useCurrentUser()
  const { mutate: patchCurrentUser } = usePatchCurrentUser()

  if (currentUser?.acceptedTermsOfService) return <>{children}</>

  if (canceled) return <Navigate to={PATHS.LOGIN} />

  const handleAccept = () => patchCurrentUser({ acceptedTermsOfService: true })

  return (
    <Modal open size="tiny" closeOnDimmerClick={false}>
      <Modal.Content>
        <div className="terms-of-service-content">
          <div className="terms-of-service-header">
            <Text styleType="primary">
              {t('termsOfService.headerText', `Required Review of Terms of Service`)}
            </Text>
            <Text styleType="body">
              {t(
                'termsOfService.bodyText',
                `Populus values our customers, the security of their data, and the 
                safety of our platform. Kindly review our terms of service as part 
                of our recently updated security protocols and guidelines.`
              )}
            </Text>
          </div>
          <div className="terms-of-service-buttons">
            <Button
              extraWide
              text={t('termsOfService.reviewTermsButton', 'Review our Terms')}
              onClick={() =>
                window.open('https://www.populus.ai/legal/terms-of-service', '_blank', 'noreferrer')
              }
            />
            <Button
              extraWide
              text={t('termsOfService.reviewPrivacyPolicyButton', 'Review our Privacy Policy')}
              onClick={() =>
                window.open('https://www.populus.ai/legal/privacy-policy', '_blank', 'noreferrer')
              }
            />
          </div>
          <div className="continue-text">
            <Text styleType="body">
              {t(
                'termsOfService.continueText',
                'To continue your use of the Populus app, please accept the updated Terms below.'
              )}
            </Text>
          </div>
          <div className="accept-cancel-buttons">
            <Button small text={t('common.accept', 'Accept')} onClick={handleAccept}></Button>
            <Button
              small
              secondary
              text={t('common.cancel', 'Cancel')}
              onClick={() => setCanceled(true)}
            ></Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default TermsOfServiceGateKeeper
