import { useMemo } from 'react'

import { useLayers, useSuspenseLayers } from '@/modules/layers/queries'

export const useReferenceGeographyValues = () => {
  // TODO: This only gets the first page of potential ReferenceGeographyLayers. This needs to be changed
  const { data } = useLayers()
  const { items } = data || { items: [] }
  return useMemo(() => items?.map(({ shapeLayerUuid }) => shapeLayerUuid) ?? [], [items])
}

export const useReferenceGeographyValuesSuspense = () => {
  const { data } = useSuspenseLayers()
  const { items } = data || { items: [] }
  return useMemo(() => items?.map(({ shapeLayerUuid }) => shapeLayerUuid) ?? [], [items])
}
