import {
  fromPairs,
  get,
  has,
  includes,
  keys,
  map,
  mapKeys,
  mapValues,
  omitBy,
  size,
  sum,
  values,
} from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'

import analytics from '@/modules/analytics'
import { apipyRequest } from '@/modules/api/request'
import layerStore from '@/modules/layers/layerStore'
import { monitorMessage } from '@/modules/monitoring'
import appUIStore from '@/stores/appUIStore'

class TopsheetStore {
  loading: boolean = false
  tripsByOperator: any
  vehiclesByOperator: any
  tripsByDay: any
  vehiclesByDay: any
  tripsByL1Data: any
  vehiclesByL1Data: any
  mounted: boolean = false
  timePeriod = 'week'
  // can go back in time using the offset, an offset of -1 with a timePeriod of 'week' is
  // the "week before last".
  timePeriodOffset = 0

  constructor() {
    makeObservable(this, {
      loading: observable,
      tripsByOperator: observable,
      vehiclesByOperator: observable,
      tripsByDay: observable,
      vehiclesByDay: observable,
      tripsByL1Data: observable,
      vehiclesByL1Data: observable,
      mounted: observable,
      timePeriod: observable,
      timePeriodOffset: observable,
      tripsByL1: computed,
      vehiclesByL1: computed,
      setLoading: action,
      setMounted: action,
      setTimePeriod: action,
      oppositeTimePeriod: computed,
      setTimePeriodOffset: action,
      reset: action,
      startDate: computed,
      endDate: computed,
      totalTrips: computed,
      totalDays: computed,
      dailyTrips: computed,
      totalVehicles: computed,
      totalMDSVehicles: computed,
      averageUtilization: computed,
      updateTripsByOperator: action,
      setVehiclesByOperator: action,
      updateVehiclesByOperator: action,
      fetchTripsByDay: action,
      fetchVehiclesByDay: action,
      fetchTripsByL1: action,
      fetchVehiclesByL1: action,
      fetchData: action,
    })
  }

  get tripsByL1() {
    return mapKeys(this.tripsByL1Data, (v, k) => layerStore.mapDistrictUUIDToName(k))
  }

  get vehiclesByL1() {
    return mapKeys(this.vehiclesByL1Data, (v, k) => layerStore.mapDistrictUUIDToName(k))
  }

  setLoading(l: boolean) {
    this.loading = l
  }

  setMounted(v: boolean) {
    this.mounted = v
  }

  setTimePeriod(timePeriod: any) {
    if (timePeriod === this.timePeriod) {
      return
    }

    if (!includes(['month', 'week'], timePeriod)) {
      monitorMessage('Warning invalid time period.')
      window.alert('Warning invalid time period.')
    }

    this.timePeriodOffset = 0
    this.timePeriod = timePeriod
    this.fetchData()

    analytics.track(timePeriod.toString(), {
      category: 'Topsheet - Set Time Period',
    })
  }

  get oppositeTimePeriod() {
    return this.timePeriod === 'week' ? 'month' : 'week'
  }

  setTimePeriodOffset(timePeriodOffset: any) {
    if (timePeriodOffset > 0) return
    this.timePeriodOffset = timePeriodOffset
    this.fetchData()

    analytics.track(timePeriodOffset.toString(), {
      category: 'Topsheet - Set Time Period Offset',
    })
  }

  reset() {
    this.tripsByOperator = undefined
    this.vehiclesByOperator = undefined
    this.tripsByDay = undefined
    this.vehiclesByDay = undefined
    this.tripsByL1Data = undefined
    this.vehiclesByL1Data = undefined
  }

  get startDate() {
    return this.timePeriod === 'week'
      ? this.endDate.minus({ day: 6 })
      : this.endDate.startOf('month')
  }

  get endDate() {
    return this.timePeriod === 'week'
      ? appUIStore.metro.today
          .startOf('week')
          .minus({ day: 1 })
          .plus({ week: this.timePeriodOffset })
      : appUIStore.metro.today.plus({ month: this.timePeriodOffset - 1 }).endOf('month')
  }

  get totalTrips() {
    return sum(values(this.tripsByOperator))
  }

  get totalDays() {
    return this.endDate.diff(this.startDate, 'days').toObject().days + 1
  }

  get dailyTrips() {
    return this.totalTrips / this.totalDays
  }

  get totalVehicles() {
    return sum(values(this.vehiclesByOperator))
  }

  get totalMDSVehicles() {
    return sum(values(omitBy(this.vehiclesByOperator, (v, k) => !has(this.tripsByOperator, k))))
  }

  get averageUtilization() {
    return this.totalMDSVehicles ? this.dailyTrips / this.totalMDSVehicles : 0
  }

  updateTripsByOperator() {
    if (!appUIStore.metro.isMDSRegion) return

    apipyRequest(`/trips/trips_by_${this.timePeriod}`, {
      start_date: this.startDate.toISODate(),
      end_date: this.endDate.toISODate(),
      mode: 'operators',
    }).then(
      action(({ data }) => {
        if (size(data) === 1 && keys(data)[0] === '_totals_') {
          // data isn't loaded yet
          return
        }
        data = mapValues(data, v => v[0].count)
        delete data._totals_
        this.tripsByOperator = data
      })
    )
    this.setLoading(false)
  }

  setVehiclesByOperator(data: any) {
    if (size(data.data) > 1)
      monitorMessage('Returning more than one period of vehicles in topsheet')
    this.vehiclesByOperator = get(data, ['data', 0, 'max_counts'])
  }

  updateVehiclesByOperator() {
    if (!appUIStore.metro.isMDSRegion) return

    apipyRequest(`/vehicles/vehicles_by_${this.timePeriod}`, {
      start_date: this.startDate.toISODate(),
      end_date: this.endDate.toISODate(),
      mode: 'operators',
    }).then(
      action(({ data }) => {
        if (size(data) === 1 && keys(data)[0] === '_totals_') {
          // data isn't loaded yet
          return
        }
        data = mapValues(data, v => v[0].avg) // use the mean count
        delete data._totals_
        this.vehiclesByOperator = data
      })
    )
    this.setLoading(false)
  }

  fetchTripsByDay() {
    return apipyRequest(`/trips/trips_by_day`, {
      mode: 'operators',
      start_date: this.startDate.toISODate(),
      end_date: this.endDate.toISODate(),
    })
  }

  fetchVehiclesByDay() {
    return apipyRequest(`/vehicles/vehicles_by_day`, {
      mode: 'operators',
      start_date: this.startDate.toISODate(),
      end_date: this.endDate.toISODate(),
    })
  }

  fetchTripsByL1() {
    return apipyRequest('/trips/spatial2', {
      location: 'origin',
      agg: 'total_count',
      shape_layer_uuid: layerStore.getLayerUUIDForLevel('l1'),
      start_date: this.startDate.toISODate(),
      end_date: this.endDate.toISODate(),
    })
  }

  fetchVehiclesByL1() {
    return apipyRequest('/vehicles/vehicle_counts_by_geo', {
      shape_layer_uuid: layerStore.getLayerUUIDForLevel('l1'),
      start_date: this.startDate.toISODate(),
      end_date: this.endDate.toISODate(),
    })
  }

  fetchData() {
    this.setLoading(true)
    this.reset()

    this.updateTripsByOperator()
    this.updateVehiclesByOperator()
    this.fetchTripsByDay().then(
      action(({ data }) => {
        this.tripsByDay = data
        this.setLoading(false)
      })
    )
    this.fetchVehiclesByDay().then(
      action(({ data }) => {
        this.vehiclesByDay = data
        this.setLoading(false)
      })
    )
    this.fetchTripsByL1().then(({ data }) => {
      runInAction(() => {
        this.tripsByL1Data = fromPairs(map(data, o => [o.shape_uuid, o.value]))
      })

      this.setLoading(false)
    })
    this.fetchVehiclesByL1().then(({ data }) => {
      runInAction(() => {
        this.vehiclesByL1Data = fromPairs(data.map((o: any) => [o.shape_uuid, o.total_count]))
      })
      this.setLoading(false)
    })
  }
}

const topsheetStore = new TopsheetStore()

export default topsheetStore
