import { useQuery } from '@tanstack/react-query'

import { OperatorResponse } from '@/modules/api/openapi/models/OperatorResponse'
import { queryClientInstance } from '@/modules/api/queryClient'
import { internalApi } from '@/modules/api/request'
import { OpenApiQueryParamsOmitRegionId } from '@/modules/api/types'
import { useCurrentRegion } from '@/modules/urlRouting/hooks'

export const useOperator = (slug: string | null | undefined) =>
  useQuery({
    queryKey: [`/operators/${slug}/`],
    queryFn: async () => await internalApi.operators.getOperatorBySlug({ slug: slug! }),
    enabled: !!slug,
  })

export const buildGetAllOperatorsQueryOptions = (
  searchParams: OpenApiQueryParamsOmitRegionId<typeof internalApi.operators.getAllOperators> = {
    orderBy: 'slug',
    page: 1,
    size: 50,
  }
) => ({
  queryKey: [`/operators`, searchParams],
  queryFn: async () => {
    const operatorsResponse = await internalApi.operators.getAllOperators(searchParams)

    operatorsResponse.items.forEach(operator =>
      queryClientInstance.setQueryData<OperatorResponse>([`/operator/${operator.slug}`], operator)
    )

    return operatorsResponse
  },
})

export const useOperators = (
  searchParams: OpenApiQueryParamsOmitRegionId<typeof internalApi.operators.getAllOperators> = {
    orderBy: 'slug',
    page: 1,
    size: 50,
  }
) => useQuery(buildGetAllOperatorsQueryOptions(searchParams))

export const useMdsOperators = (
  searchParams: OpenApiQueryParamsOmitRegionId<
    typeof internalApi.mobility.getMdsOperatorsForRegion
  > = {
    orderBy: 'slug',
    page: 1,
    size: 50,
  }
) => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  return useQuery({
    queryKey: [`/regions/${regionId}/mobility/mds_operators`, searchParams],
    queryFn: async () => {
      const operatorsResponse = await internalApi.mobility.getMdsOperatorsForRegion({
        regionId,
        ...searchParams,
      })

      operatorsResponse.items.forEach(operator =>
        queryClientInstance.setQueryData<OperatorResponse>([`/operator/${operator.slug}`], operator)
      )

      return operatorsResponse
    },
  })
}
