import classNames from 'classnames'

import * as PopulusIcons from '../../../assets/icons'
import { OnClickEvent, PopulusColor } from '../sharedTypes'

import './index.scss'

interface IconProps {
  backgroundColor?: PopulusColor
  backgroundHoverColor?: PopulusColor
  borderColor?: PopulusColor
  className?: string
  color?: PopulusColor
  disabled?: boolean
  hoverColor?: PopulusColor
  icon: IconName
  onClick?: OnClickEvent
  small?: boolean
}

export type IconName = keyof typeof PopulusIcons

export const Icon = ({
  backgroundColor,
  backgroundHoverColor,
  borderColor,
  className,
  color,
  disabled,
  hoverColor,
  icon,
  onClick,
  small,
}: IconProps) => {
  const IconImage = PopulusIcons[icon]
  const divProps = {
    className: classNames(
      'populus-icon',
      backgroundColor && `background-${backgroundColor}`,
      backgroundHoverColor && `background-hover-${backgroundHoverColor}`,
      borderColor && `border-${borderColor}`,
      disabled && 'disabled',
      small && 'small',
      className
    ),
    onClick: !disabled && onClick ? onClick : undefined,
  }
  return (
    <div {...divProps}>
      {IconImage && (
        <IconImage
          className={classNames(
            `populus-icon-svg-${color}`,
            hoverColor && `populus-icon-svg-hover-${hoverColor}`
          )}
        />
      )}
    </div>
  )
}
