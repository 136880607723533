import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Info } from 'luxon'

import { ISO_DAYS_OF_WEEK } from '@/common/utils/date'

import { Tag } from '../Tag'
import { Toggle } from '../Toggle'

import './index.scss'

// https://en.wikipedia.org/wiki/ISO_week_date
// Weekday number, a digit from 1 through 7, beginning with Monday and ending with Sunday

export interface WeekdaysToggleProps {
  className?: string
  disabled?: boolean
  onChange: (values: number[]) => void
  selectedDays: number[]
}

export const WeekdaysToggle = ({
  className,
  disabled = false,
  onChange,
  selectedDays = [],
}: WeekdaysToggleProps) => {
  const { t } = useTranslation()

  return (
    <Toggle.Group
      className={classNames('populus-weekdays-toggle', className)}
      data-testid="weekday-toggle-component"
    >
      {ISO_DAYS_OF_WEEK.map(weekday => {
        const selected = selectedDays.includes(weekday)
        return (
          <Toggle.Button
            className="populus-weekdays-toggle-btn"
            active={selected}
            key={weekday}
            onClick={e => {
              e.preventDefault()
              const set = new Set(selectedDays)
              if (selected) {
                set.delete(weekday)
              } else {
                set.add(weekday)
              }

              onChange([...set].sort())
            }}
            small
            square
            disabled={disabled}
            text={Info.weekdays('short')[weekday - 1].slice(0, 2)}
          />
        )
      })}
    </Toggle.Group>
  )
}
