import { useEffect } from 'react'

export const useSetDocumentTitle = (title: string) => {
  useEffect(() => {
    document.title = `Populus | ${title}`
    return () => {
      document.title = 'Populus'
    }
  }, [title])
}
