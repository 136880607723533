import { flatten, groupBy, mapValues, sortBy } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'

import { fromHHMM, toHHMM } from '@/common/utils/time'
import { Time } from '@/common/utils/types'

export enum DayEnum {
  sunday = 'Sunday',
  monday = 'Monday',
  tuesday = 'Tuesday',
  wednesday = 'Wednesday',
  thursday = 'Thursday',
  friday = 'Friday',
  saturday = 'Saturday',
  weekday = 'Weekdays',
  all = 'All',
}
export type Day = keyof typeof DayEnum

type FeeSchedule = {
  price: number
  end_time: string
  start_time: string
}

type PricesByDay = Record<Day, FeeSchedule[]>

export type VariableSchedule = {
  day: Day
  startTime: {
    hour: number
    minute: number
  } | null
  endTime: {
    hour: number
    minute: number
  } | null
  price: number
}

/**
 * Model for a parking_fee_type: variable_price_schedule in policies.policy.fee_properties \
 * fee_properties will be stored like this:
 * {
 *     "parking_fee_type": "variable_price_schedule",
 *     "prices": {
 *         "friday": [{
 *             "price": 0.1,
 *             "start_time": "12:00",
 *             "end_time": "17:00",
 *         }]
 *     }
 * }
 */
export class VariablePriceSchedule {
  prices: VariableSchedule[] = []

  constructor(pricesByDay?: PricesByDay) {
    makeObservable(this, {
      prices: observable,
      add: action,
      remove: action,
      json: computed,
    })

    if (pricesByDay) this.prices = variablePriceSchedules(pricesByDay)
  }

  add = (schedule?: VariableSchedule) => {
    this.prices = [...this.prices, schedule || emptySchedule()]
  }

  remove = (index: number) => {
    this.prices = this.prices.filter((val, idx) => idx !== index)
  }

  get json(): PricesByDay {
    const groupedByDay = groupBy(this.prices, 'day')
    return mapValues(groupedByDay, variablePrices =>
      variablePrices.map(({ endTime, price, startTime }) => ({
        start_time: toHHMM(startTime as Time),
        end_time: toHHMM(endTime as Time),
        price: parseFloat(price.toString()),
      }))
    ) as PricesByDay
  }
}

function variablePriceSchedules(prices: PricesByDay): VariableSchedule[] {
  const nestedSchedules = Object.keys(prices).map(day => {
    const feeSchedules = prices[day as Day]
    const feeScheduleArray = Array.isArray(feeSchedules) ? feeSchedules : [feeSchedules]
    return feeScheduleArray.map(({ price, end_time, start_time }) => ({
      day: day as Day,
      endTime: fromHHMM(end_time),
      price,
      startTime: fromHHMM(start_time),
    }))
  })
  const flattenedSchedules = flatten(nestedSchedules)
  return sortBy(flattenedSchedules, ({ day, startTime }) => {
    return [dayOfWeekOrdinals(day), startTime]
  })
}

function dayOfWeekOrdinals(day: string): number | undefined {
  switch (day) {
    case 'sunday':
      return 0
    case 'monday':
      return 1
    case 'tuesday':
      return 2
    case 'wednesday':
      return 3
    case 'thursday':
      return 4
    case 'friday':
      return 5
    case 'saturday':
      return 6
    default:
      return undefined
  }
}

function emptySchedule(): VariableSchedule {
  return {
    day: 'all',
    startTime: null,
    endTime: null,
    price: 0,
  }
}
