import { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'

import { Text } from '../Text'
import { Tooltip, TooltipProps } from '../Tooltip'

import './index.scss'

export interface FieldProps {
  className?: string
  error?: boolean
  errorText?: string
  required?: boolean
  titleText?: string
  tooltipProps?: TooltipProps
}

export const Field: FC<PropsWithChildren<FieldProps>> = ({
  children,
  className,
  error,
  errorText,
  required,
  titleText,
  tooltipProps,
}) => {
  return (
    <div className={classNames('populus-field', className)}>
      {titleText && (
        <div className="populus-field-title">
          <Text className="field-title-text" styleType="secondary-light">
            {titleText}
          </Text>
          {required && <span className="required">*</span>}
          {tooltipProps && <Tooltip className="field-tooltip" {...tooltipProps} />}
        </div>
      )}
      <div className="populus-field-content">{children}</div>
      {error && (
        <div className="populus-field-error">
          <Text className="populus-field-error-text" styleType="caption">
            {errorText}
          </Text>
        </div>
      )}
    </div>
  )
}
