import { startCase } from '@/common/utils/text'
import i18n from '@/modules/i18n/i18n'

export const MODAL_TEXT = {
  ERROR_DELETE: {
    title: 'ERROR DELETING POLICY',
    text: 'This policy cannot be deleted.',
  },
  ERROR_DOWNLOAD_MDS: {
    title: 'ERROR DOWNLOADING MDS DATA',
    text: 'Unable to access MDS data.',
  },
  ERROR_MISSING_POLICY: {
    title: 'ERROR',
    text: 'Unable to modify policy. Please reload the page.',
  },
  ERROR_PUBLISH_EXPIRED: {
    title: 'EXPIRED POLICY',
    text: 'This policy is expired and cannot be published.',
  },
  ERROR_PUBLISH_MISSING_ID: {
    title: 'ERROR MODIFYING POLICY',
    text: 'Save this policy and reload the page to modify.',
  },
  ERROR_PUBLISH_GENERIC: {
    title: 'ERROR MODIFYING POLICY',
    text: 'This policy cannot be modified. Please reload the page and try again.',
  },
  ERROR_SAVE: {
    title: 'ERROR SAVING POLICY',
    text: 'This policy cannot be saved.',
  },
  INFO_CANCEL: {
    title: 'ARE YOU SURE?',
    text: 'Leaving this page will result in any changes to your policy being lost.',
  },
  INFO_DELETE: {
    title: 'ARE YOU SURE?',
    text: 'Are you sure you want to delete this policy?',
  },
  INFO_DATA_PROCESSING: {
    title: 'FEE DATA PROCESSING',
    text: 'Your policy will show up in the Policies Table now, but we’ll need a few hours to calculate any related fees before any invoices can be generated.',
  },
  INFO_PUBLISH: {
    title: 'PUBLISH POLICY?',
    text: 'Publishing the policy will make it viewable through the public MDS feed.',
  },
  INFO_UNPUBLISH: {
    title: 'UNPUBLISH POLICY?',
    text: 'Unpublishing the policy will remove it from the public MDS feed.',
  },
}

type ModalTextReturn = {
  [K in keyof typeof MODAL_TEXT]: {
    title: string
    text: string
  }
}

export const getModalText = () => {
  const { t } = i18n

  return Object.entries(MODAL_TEXT).reduce((result, [key, value]) => {
    const keySuffix = startCase(key.toLowerCase()).replaceAll(' ', '')

    return {
      ...result,
      [key]: {
        title: t(
          `policiesLibrary.modalTitle${keySuffix}`,
          value.title.toLowerCase()
        ).toLocaleUpperCase(),
        text: t(`policiesLibrary.modalText${keySuffix}`, value.text),
      },
    }
  }, {} as ModalTextReturn)
}
