/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Permission } from '../models/Permission'
import type { PermissionsObjType } from '../models/PermissionsObjType'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class PermissionsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get Permissions For Obj
   * Get all permissions for an entity from the has_permissions table
   * @returns Permission Successful Response
   * @throws ApiError
   */
  public getPermissionsForObj({
    objType,
    objId,
  }: {
    objType: PermissionsObjType
    objId: string
  }): CancelablePromise<Array<Permission>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/permissions/{obj_type}/{obj_id}',
      path: {
        obj_type: objType,
        obj_id: objId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Update Permissions For Obj
   * Update permissions in bulk. Permissions passed in with enabled=True will be added if they don't
   * exist and permissions passed in with enabled=False will be removed if they exist. Else, no-op on
   * permissions in the request body that don't require an update.
   * @returns Permission Successful Response
   * @throws ApiError
   */
  public updatePermissionsForObj({
    objType,
    objId,
    requestBody,
  }: {
    objType: PermissionsObjType
    objId: string
    requestBody: Array<Permission>
  }): CancelablePromise<Array<Permission>> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/permissions/{obj_type}/{obj_id}',
      path: {
        obj_type: objType,
        obj_id: objId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
