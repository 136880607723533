import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { observer } from 'mobx-react'

import { Dropdown } from '@/components/componentLibrary'
import { SpatialPolicy as Policy } from '@/models/spatialPolicy'
import {
  generateEnumDropdownOptions,
  ParkingTimeUnitEnum,
  SpeedUnitEnum,
} from '@/models/spatialPolicyTypes'

import { PolicyType } from '../../../../../utils/types'
import { HideableField, SelectionTags } from '../components'
import { EditableFieldProps } from '..'

import './index.scss'

const UNIT_TITLE = 'Unit'

export const Unit = observer(({ policy, ...props }: { policy: Policy }) => {
  const { t } = useTranslation()

  const value =
    policy.unit && getUnitOptions(policy)?.find(option => option.value === policy.unit)?.text
  return (
    <HideableField
      titleText={t('policiesLibrary.formUnitTitle', UNIT_TITLE)}
      hidden={!value}
      {...props}
    >
      {value && <SelectionTags values={[value]} />}
    </HideableField>
  )
})

export const EditableUnit = observer(
  ({ editable, onValidate, policy, ...props }: EditableFieldProps) => {
    const { t } = useTranslation()

    if (editable) {
      const options = getUnitOptions(policy)
      return options ? (
        <HideableField
          editable
          titleText={t('policiesLibrary.formUnitTitle', UNIT_TITLE)}
          {...props}
        >
          <Dropdown
            className="policies-table-form-unit"
            error={props.error}
            name="unit"
            onBlur={onValidate}
            onChange={unit => !_.isArray(unit) && policy.setUnit(unit as Policy['unit'])}
            options={options}
            value={policy.unit || undefined}
          />
        </HideableField>
      ) : null
    } else {
      return <Unit policy={policy} {...props} />
    }
  }
)

const getUnitOptions = (policy: Policy) => {
  switch (policy.policyType) {
    case PolicyType.PARKING_TIME_LIMIT:
      return generateEnumDropdownOptions(ParkingTimeUnitEnum, 'TimeUnits')
    case PolicyType.SLOW_RIDE:
      return generateEnumDropdownOptions(SpeedUnitEnum, 'SpeedUnits')
    default:
      return undefined
  }
}
