import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { isDate } from 'lodash'
import { Popup } from 'semantic-ui-react'

import { Button, Text } from '..'

import Calendar from './Calendar'
import DateInput from './DateInput'
import { DateRangeDropdown } from './DateRangeDropdown'
import {
  CalendarRef,
  DatePickerActiveInput,
  DatePickerDropdownValue,
  DatePickerErrorProps,
  DatePickerProps,
  DatePickerRange,
  DateType,
  getDatePickerErrors,
} from './types'
import {
  firstOfMonth,
  getDateRangeInDays,
  getPreviousMonthDate,
  isDateMatch,
  isLastMonth,
  isThisMonth,
} from './utils'

import './index.scss'

export const allDropDownOptions: DatePickerDropdownValue[] = [
  'custom',
  'singleDay',
  'noStart',
  'noEnd',
  'thisMonth',
  'lastMonth',
]

/**
 * DatePicker inputs and calendar built with react-day-picker
 * See all available props: https://react-day-picker.js.org/api/DayPicker/
 * @param range - date range selected
 * @param rangeLimit - number of days to limit date range selection
 * @param setRange - callback to set date range
 * @param dropdownDefault - optional default dropdown option
 * @param dropdownOptions - options to have available in the dropdown
 * @param requireDates - require a user to have from and to dates (i.e., no null / undefined fields)
 */
export const DatePicker = ({
  disableDropdown,
  dropdownDefault,
  dropdownOptions = allDropDownOptions,
  range,
  rangeLimit,
  requireDates,
  maxDate,
  minDate,
  onOpen,
  onClose,
  setRange,
}: DatePickerProps) => {
  const [from, setFrom] = useState<DateType>() // start date that a user inputs or selects through the calendar
  const [to, setTo] = useState<DateType>() // end date that a user inputs or selects through the calendar
  const [activeInput, setActiveInput] = useState<DatePickerActiveInput>() // tracks whether the user is editing from or to date
  const [dropdownValue, setDropdownValue] = useState<DatePickerDropdownValue>(
    dropdownDefault || dropdownOptions[0]
  ) // preset values in the dropdown
  const [error, setError] = useState<DatePickerErrorProps>({} as DatePickerErrorProps) // error messages to show

  const { t } = useTranslation()

  const calendarRef = useRef<CalendarRef>(null) // control what month is visible in the calendar
  const fromRef = useRef<HTMLInputElement>(null) // focus the from input
  const toRef = useRef<HTMLInputElement>(null) // focus the to input

  // update 'to' and 'from' when range changes
  useEffect(() => setDates(range), [range])

  const setDates = (dates: DatePickerRange) => {
    setTo(dates.to)
    setFrom(dates.from)
  }

  /**
   * Sync Dropdown Options with current date selection
   */
  const syncDropdownValue = () => {
    const startDate = range.from
    const endDate = range.to
    const thisMonthValid = endDate && startDate && isThisMonth(startDate, endDate)
    const lastMonthValid = endDate && startDate && isLastMonth(startDate, endDate)
    const isSameDate = startDate && isDateMatch(startDate, endDate)

    switch (dropdownValue) {
      case 'thisMonth':
        if (!thisMonthValid) setDropdownValue(lastMonthValid ? 'lastMonth' : 'custom')
        break
      case 'lastMonth':
        if (!lastMonthValid) setDropdownValue(thisMonthValid ? 'thisMonth' : 'custom')
        break
      case 'custom':
        if (thisMonthValid) setDropdownValue('thisMonth')
        else if (lastMonthValid) setDropdownValue('lastMonth')
        break
      case 'noStart':
        startDate && setDropdownValue('custom')
        break
      case 'singleDay':
        if ((startDate || endDate) && !isSameDate) setDropdownValue('custom')
        break
      case 'noEnd':
        endDate && setDropdownValue('custom')
    }
  }

  useEffect(() => {
    syncDropdownValue()
  }, [range])

  /**
   * Validates date inputs and raises errors before closing the Calendar
   */
  const validateDates = () => {
    if (requireDates && (!from || !to)) {
      setError({
        from: from ? undefined : getDatePickerErrors().required,
        to: to ? undefined : getDatePickerErrors().required,
      })
      return false
    }

    if (isDate(from) && isDate(to)) {
      if (from > to) {
        setError({ to: getDatePickerErrors().invalid })
        return false
      }
      if (rangeLimit && getDateRangeInDays(from, to) > rangeLimit) {
        setError({
          to: t('datePicker.maximumRangeError', 'Maximum date range is {{rangeLimit}} days', {
            rangeLimit,
          }),
        })
        return false
      }
      if (minDate) {
        if (from && minDate > from) {
          setError({
            from: t('datePicker.minDateError', 'Date is before the earliest allowed'),
          })
          return false
        }
        if (to && minDate > to) {
          setError({
            to: t('datePicker.minDateError', 'Date is before the earliest allowed'),
          })
          return false
        }
      }
      if (maxDate) {
        if (from && maxDate < from) {
          setError({
            from: t('datePicker.maxDateError', 'Date is after the latest allowed'),
          })
          return false
        }
        if (to && maxDate < to) {
          setError({
            to: t('datePicker.maxDateError', 'Date is after the latest allowed'),
          })
          return false
        }
      }
    }

    if (error.to || error.from) {
      return false
    }

    setError({} as DatePickerErrorProps)
    return true
  }

  /**
   * Handles setting of new dates \
   * For date inputs, this will update the visible months in the calendar and focus the other input
   * @param name - whether to set 'to' or 'from'
   * @param date - new date to set
   * @param isInput - whether inputting from a DateInput
   * @returns
   */
  const handleSetDate = (name: 'to' | 'from', date: DateType, isInput?: boolean) => {
    const setFunction = name === 'to' ? setTo : setFrom
    const inputRef = name === 'to' ? fromRef : toRef
    const updateMonthProps = {
      activeInput: name,
      from: name === 'from' ? date : from,
      to: name === 'to' ? date : to,
    }
    // if it's single day, set the 'to' date here as well
    if (dropdownValue === 'singleDay' && name === 'from') setTo(date)
    setFunction(date)
    setError({ [name]: undefined })
    if (['custom', 'thisMonth', 'nextMonth'].includes(dropdownValue))
      setActiveInput(name === 'to' ? 'from' : 'to')
    if (isInput) {
      calendarRef.current?.updateMonth(updateMonthProps)
      inputRef.current?.focus()
    }
  }

  const handleDropdownChange = (value: DatePickerDropdownValue) => {
    const today = new Date()
    switch (value) {
      case 'noEnd':
      case 'singleDay':
        setTo(from || null)
        setActiveInput('from')
        break
      case 'noStart':
        setFrom(null)
        setActiveInput('to')
        break
      case 'thisMonth':
        calendarRef.current?.updateMonth({ activeInput, to: today, from: firstOfMonth(today) })
        if (activeInput) {
          setDates({ to: today, from: firstOfMonth(today) })
        } else setRange({ from: firstOfMonth(today), to: today })
        break
      case 'lastMonth':
        // eslint-disable-next-line no-case-declarations
        const lastMonth = getPreviousMonthDate(today)
        calendarRef.current?.updateMonth({
          activeInput,
          from: firstOfMonth(lastMonth),
          to: lastMonth,
        })
        if (activeInput) setDates({ from: firstOfMonth(lastMonth), to: lastMonth })
        else setRange({ from: firstOfMonth(lastMonth), to: lastMonth })
        break
    }
    setDropdownValue(value)
  }

  /**
   * Actual Elements in the DatePicker
   */

  const dateRangeDropdown = (
    <DateRangeDropdown
      onFocus={() => setActiveInput(undefined)}
      onChange={value => handleDropdownChange(value as DatePickerDropdownValue)}
      value={dropdownValue}
      dropdownOptions={dropdownOptions}
    />
  )

  const fromDateInput = (
    <DateInput
      disabled={dropdownValue === 'noStart'}
      errorText={error.from}
      appearFocused={activeInput === 'from'}
      date={from}
      handleError={(errorType?: string) => setError({ from: errorType })}
      handleFocus={() => {
        setActiveInput('from')
        calendarRef.current?.updateMonth({ activeInput: 'from', from, to })
      }}
      ref={fromRef}
      setDate={day => handleSetDate('from', day, true)}
    />
  )

  const toDateInput = (
    <DateInput
      disabled={dropdownValue === 'noEnd' || dropdownValue === 'singleDay'}
      errorText={error.to}
      appearFocused={activeInput === 'to'}
      date={to}
      handleError={(errorType?: string) => setError({ to: errorType })}
      handleFocus={() => {
        setActiveInput('to')
        calendarRef.current?.updateMonth({ activeInput: 'to', from, to })
      }}
      ref={toRef}
      setDate={day => handleSetDate('to', day, true)}
    />
  )

  const calendar = (
    <Calendar
      activeInput={activeInput}
      from={from}
      noEnd={dropdownValue === 'noEnd'}
      noStart={dropdownValue === 'noStart'}
      ref={calendarRef}
      to={to}
      setFrom={day => handleSetDate('from', day)}
      setTo={day => handleSetDate('to', day)}
      singleDay={dropdownValue === 'singleDay'}
    />
  )

  const cancelButton = (
    <Text
      link
      onClick={() => {
        setDates(range)
        setActiveInput(undefined)
        syncDropdownValue()
        setError({} as DatePickerErrorProps)
      }}
      styleType="link"
    >
      {t('datePicker.cancel', 'CANCEL')}
    </Text>
  )

  const doneButton = (
    <Button
      onClick={() => {
        const isValid = validateDates()
        if (isValid) {
          setRange({ from, to })
          setActiveInput(undefined)
        }
      }}
      text={t('datePicker.done', 'Done')}
    />
  )
  return (
    <Popup
      basic
      className={classNames(
        'date-picker open',
        !['custom', 'thisMonth', 'lastMonth'].includes(dropdownValue) && 'single-calendar'
      )}
      flowing
      trigger={
        <div id="date-picker" className="date-picker" data-testid="date-picker-component">
          {!disableDropdown && dateRangeDropdown}
          <div className="inputs">
            {fromDateInput}
            {toDateInput}
          </div>
        </div>
      }
      open={!!activeInput}
      // use onMount and onUnmount to simulate onOpen and onClose event (onClose gets called before DatePicker closes)
      onMount={onOpen}
      onUnmount={onClose}
      // this makes sure the popup doesn't open off-screen
      popperModifiers={[{ name: 'preventOverflow', options: { boundariesElement: 'window' } }]}
      position="bottom left"
    >
      <div className="calendar-container">
        {calendar}
        <div className="buttons-container">
          <div>{cancelButton}</div>
          {doneButton}
        </div>
      </div>
    </Popup>
  )
}
