import { useCallback, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { orderBy } from 'lodash'
import { DateTime, Interval } from 'luxon'

import { dateToISO, fromJSDateLocal } from '@/common/utils/date'
import { startCase } from '@/common/utils/text'
import { SpatialPolicy } from '@/models/spatialPolicy'
import i18n from '@/modules/i18n/i18n'
import { CITYWIDE_LAYER_UUID, getCitywideLayerName } from '@/modules/layers/layerStore'
import { useLayers, useShapesForLayer } from '@/modules/layers/queries'
import { downloadAsCSV } from '@/modules/map/utils'
import { useOperator } from '@/modules/operator/hooks'
import {
  ComplianceStatus,
  DATE_HEADER,
  NON_COMPLIANT,
} from '@/modules/policiesV1/complianceDetails/constants'
import { PolicyViolation, ShapeUUID } from '@/modules/policiesV1/complianceDetails/models'
import { useComplianceDetailsQueryParams } from '@/modules/policiesV1/complianceDetails/params'
import PolicyComplianceService from '@/modules/policiesV1/complianceDetails/policyComplianceService'
import { useMobilityPolicyFromPath } from '@/modules/policiesV1/hooks'
import { PolicyType } from '@/modules/policiesV1/policyLibrary/utils/types'
import { useCurrentRegion } from '@/modules/urlRouting/hooks'

export const COMPLIANCE_FOR_POLICY_ENDPOINT = 'policy_compliance/for_policy'
export const LIST_POLICIES_ENDPOINT = 'policies/list/mobility'

// TODO: Simplify getting the citywide layer for a policy
const useShapesForCompliancePolicy = (policy?: SpatialPolicy) => {
  // If the policy is entire region / shapeLayerUUID = null then use the 'citywide' shape
  // However, if the policy shape layer is citywide / shapeLayerUuid = 'citywide' then we use the actual 'l1' layer
  const layersSearchParams = policy?.isShapeLayerCitywide ? { level: 'l1' } : {}
  const {
    data: shapeLayers,
    isLoading: isLayersLoading,
    isError: isLayersError,
  } = useLayers(layersSearchParams, !!policy?.isShapeLayerCitywide)

  const shapeLayerUuid = policy?.isEntireRegion
    ? undefined
    : policy?.isShapeLayerCitywide
      ? shapeLayers?.items[0]?.shapeLayerUuid
      : policy?.shapeLayerUUID
  const {
    data: shapesData,
    isLoading: isShapesLoading,
    isError: isShapesError,
  } = useShapesForLayer(shapeLayerUuid ?? undefined)

  const shapes = policy?.isEntireRegion
    ? [{ shape_name: getCitywideLayerName(), shape_uuid: CITYWIDE_LAYER_UUID }]
    : shapesData

  return {
    data: shapes,
    isLoading: isLayersLoading || isShapesLoading,
    isError: isLayersError || isShapesError,
  }
}

// TODO: Upgrade to use fastAPI route
const useGetComplianceForPolicy = (policy?: SpatialPolicy, regionId?: string) => {
  const [{ startDate, endDate, operator }] = useComplianceDetailsQueryParams()
  const policyUUID = policy?.policyUUID
  const searchParams = {
    policyUUID: policyUUID!,
    startDate: fromJSDateLocal(startDate),
    endDate: fromJSDateLocal(endDate),
    operator: operator,
  }
  return useQuery<PolicyViolation[]>({
    queryKey: [`${regionId}/${COMPLIANCE_FOR_POLICY_ENDPOINT}`, searchParams],
    queryFn: async () => await PolicyComplianceService().getViolationsForPolicy(searchParams),
    enabled: !!regionId && !!policyUUID,
  })
}

export const useComplianceData = () => {
  const {
    data: { regionId },
    isLoading: isRegionLoading,
  } = useCurrentRegion()
  const { policy, isLoading: isPolicyLoading, isError: isPolicyError } = useMobilityPolicyFromPath()
  const {
    data,
    isLoading: isDataLoading,
    isError: isDataError,
  } = useGetComplianceForPolicy(policy, regionId ?? undefined)
  const {
    data: shapes,
    isLoading: isShapesLoading,
    isError: isShapesError,
  } = useShapesForCompliancePolicy(policy)
  // TODO: API should return this data as required, we shouldn't need to iterate here.
  const complianceMap = useMemo(() => {
    const result = new Map<string, ShapesMap>()
    data?.forEach(row => {
      if (!result.has(row.date)) {
        result.set(row.date, new Map())
      }
      if (!result.get(row.date)?.has(row.shapeUUID)) {
        result.get(row.date)?.set(row.shapeUUID, { status: row.status, value: 0 })
      }

      let newValue: number | undefined
      switch (policy!.policyType) {
        case PolicyType.DISTRIBUTION: // there should be one value per day representing # or % of vehicles in geography for the operator
        case PolicyType.VEHICLE_CAP:
        case PolicyType.OPERATOR_DROP_OFFS:
          newValue = row.getVehicleCount(policy!.policyType)
          break
        default:
          newValue = (result.get(row.date)!.get(row.shapeUUID)!.value || 0) + 1
          break
      }
      result.get(row.date)!.get(row.shapeUUID)!.value = newValue
    })
    return result
  }, [data, policy])

  return {
    complianceMap,
    complianceData: data,
    shapes,
    policy,
    isPolicyLoading,
    isLoading: isRegionLoading || isPolicyLoading || isDataLoading || isShapesLoading,
    errors: {
      isPolicyError,
      isDataError,
      isShapesError,
    },
  }
}

// TODO: Use react-table for sorting logic
export const useSortedShapes = (
  shapes: ReturnType<typeof useShapesForCompliancePolicy>['data']
) => {
  const [{ sortRowKey, sortRowDescending }] = useComplianceDetailsQueryParams()
  const { complianceMap } = useComplianceData()
  return useMemo(() => {
    const alphabeticalShapes = shapes?.sort((a, b) => {
      if (!a.shape_name) return 1
      else if (!b.shape_name) return -1
      else return a.shape_name.localeCompare(b.shape_name)
    })
    if (!sortRowKey) return alphabeticalShapes
    return alphabeticalShapes?.sort(
      (a, b) =>
        (sortRowDescending ? 1 : -1) *
        ((complianceMap.get(sortRowKey!)?.get(b.shape_uuid)?.value || 0) -
          (complianceMap.get(sortRowKey!)?.get(a.shape_uuid)?.value || 0))
    )
  }, [shapes, sortRowKey, sortRowDescending, complianceMap])
}

type Compliance = {
  status: ComplianceStatus
  value: number | undefined
}
export type ShapesMap = Map<ShapeUUID, Compliance>

export const useComplianceTotals = () => {
  const { complianceMap, policy, isLoading } = useComplianceData()

  return useMemo(() => {
    const totalViolationCount = policy?.policyType.basedOnVehicleCounts
      ? getTotalShapesNonCompliant(complianceMap)
      : getTotalViolationCount(complianceMap)
    const totalDaysNonCompliant = getTotalDaysNonCompliant(complianceMap)
    const totalViolationUnit = policy ? `${getViolationUnit(policy.policyType)}` : ''
    return { totalViolationCount, totalViolationUnit, totalDaysNonCompliant, isLoading }
  }, [complianceMap, isLoading, policy])
}

function getTotalViolationCount(complianceMap: Map<string, ShapesMap>) {
  return [...complianceMap.values()].reduce(
    (total, shapeMapForDate) =>
      total +
      [...shapeMapForDate.values()].reduce(
        (dateTotal, compliance) =>
          dateTotal +
          (compliance.status === NON_COMPLIANT && compliance.value ? compliance.value : 0),
        0
      ),
    0
  )
}

function getTotalShapesNonCompliant(complianceMap: Map<string, ShapesMap>) {
  return [...complianceMap.values()].reduce(
    (total, shapeMapForDate) =>
      total +
      [...shapeMapForDate.values()].filter(compliance => compliance.status === NON_COMPLIANT)
        .length,
    0
  )
}

function getTotalDaysNonCompliant(complianceMap: Map<string, ShapesMap>) {
  return [...complianceMap.values()].reduce(
    (total, shapeMapForDate) =>
      total +
      ([...shapeMapForDate.values()].some(compliance => compliance.status === NON_COMPLIANT)
        ? 1
        : 0),
    0
  )
}

// TODO: Upgrade API to return data exactly as needed and use ReactTable generate rows instead of this
export const useDateRangeFromQueryParams = () => {
  const [{ startDate, endDate }] = useComplianceDetailsQueryParams()
  return useMemo(
    () =>
      Interval.fromDateTimes(
        DateTime.fromJSDate(startDate),
        DateTime.fromJSDate(endDate).plus({ days: 1 })
      )
        .splitBy({ day: 1 })
        .map(day => day.start!.toISODate()),
    [startDate, endDate]
  )
}

// TODO: Use react-table for sorting logic
export const useSortedDates = () => {
  const [{ sortColumnKey, sortColumnDescending }] = useComplianceDetailsQueryParams()
  const dates = useDateRangeFromQueryParams()
  const { complianceMap } = useComplianceData()
  return useMemo(() => {
    if (sortColumnKey === DATE_HEADER) {
      return orderBy(dates, undefined, sortColumnDescending ? 'desc' : 'asc')
    } else {
      return dates.sort(
        (a, b) =>
          (sortColumnDescending ? 1 : -1) *
          ((complianceMap.get(b!)?.get(sortColumnKey!)?.value || 0) -
            (complianceMap.get(a!)?.get(sortColumnKey!)?.value || 0))
      )
    }
  }, [complianceMap, dates, sortColumnKey, sortColumnDescending])
}

export const useOperatorName = () => {
  const [{ operator: slug }] = useComplianceDetailsQueryParams()
  const { data: operator } = useOperator(slug)
  return useMemo(() => operator?.name ?? '', [operator])
}

export const useDownloadCompliance = () => {
  const dates = useDateRangeFromQueryParams()
  const { complianceData, complianceMap, policy, shapes } = useComplianceData()
  const [params] = useComplianceDetailsQueryParams()

  const hasData = !!complianceData
  const operatorName = useOperatorName()
  const startDate = dateToISO(params.startDate)
  const endDate = dateToISO(params.endDate)

  const downloadComplianceSummary = useCallback(() => {
    if (!hasData || !shapes) return
    const rows: any = dates.map(date => ({
      Date: date,
      ...(
        shapes as {
          shape_name: string
          shape_uuid: string
        }[]
      ).reduce<{ [key: string]: number | undefined }>((result, shape) => {
        if (!shape.shape_name) return result
        return {
          ...result,
          [shape.shape_name]: complianceMap.get(date!)?.get(shape.shape_uuid)?.value,
        }
      }, {}),
    }))
    downloadAsCSV(
      rows,
      `${getTableTitle(policy!)} - ${
        policy!.policyName
      } - ${operatorName} - ${startDate}_${endDate}`
    )
  }, [hasData, dates, complianceMap, policy, shapes, startDate, endDate, operatorName])

  const downloadFullComplianceDetails = useCallback(() => {
    if (!hasData) return
    const rows =
      complianceData.map(violation => {
        return {
          policy_uuid: policy?.policyUUID,
          policy_name: policy?.policyName,
          policy_min: policy?.minimum,
          policy_max: policy?.maximum,
          ...violation.data,
        }
      }) ?? []
    downloadAsCSV(
      rows,
      `Non-Compliant ${getViolationUnit(
        policy!.policyType
      )} - ${policy?.policyName} - ${operatorName} - ${startDate}_${endDate}`
    )
  }, [hasData, complianceData, policy, operatorName, startDate, endDate])

  return { downloadComplianceSummary, downloadFullComplianceDetails }
}

const getVehiclesTitle = (policy: SpatialPolicy): string => {
  if (policy.vehicleTypes?.length === 1) {
    const vehicleType = policy.vehicleTypes[0]
    switch (vehicleType) {
      case 'bicycle':
      case 'scooter':
        return i18n.t(`common.vehicleType_${vehicleType}_other`, startCase(vehicleType))
    }
  }
  return i18n.t('common.vehicleType_vehicle_other', startCase('Vehicles'))
}

export const shouldShowPercentValues = (policy: SpatialPolicy): boolean => {
  return (
    policy.policyType.basedOnVehicleCounts &&
    policy.allocation === 'percent_vehicles_per_operator_per_shape'
  )
}

/**
 * https://www.notion.so/populus/Daily-Compliance-Reporting-PRD-f249ab8d69be46d29dbb28111a4f9b95#603cb4807e194fa5b1e66e466a189970
 */
export const getTableTitle = (policy: SpatialPolicy): string => {
  let type: string
  switch (policy.policyType) {
    case PolicyType.DISTRIBUTION:
    case PolicyType.VEHICLE_CAP:
      type = `${getVehiclesTitle(policy)}`
      break
    case PolicyType.PARKING_TIME_LIMIT:
      type = `${getVehiclesTitle(policy)} Parked Over Time Limit`
      type = i18n.t(
        'policyCompliance.parkedOverTimeLimit',
        '{{vehicleType}} Parked Over Time Limit',
        { vehicleType: getVehiclesTitle(policy) }
      )
      break
    case PolicyType.NO_PARKING:
      type = i18n.t('policyCompliance.parkingViolations', 'Parking Violations')
      break
    case PolicyType.NO_RIDE:
      type = i18n.t('policyCompliance.tripsInNoRideZone', 'Trips in No Ride Zones')
      break
    case PolicyType.OPERATOR_DROP_OFFS:
      type = i18n.t('common.dropOffsTitle', 'Drop-Offs')
      break
    default:
      type = i18n.t('common.violations', 'Violations')
      break
  }
  const title = i18n.t('policyCompliance.dailyViolationsTableTitle', 'Daily {{type}}', { type })
  return shouldShowPercentValues(policy)
    ? i18n.t('policyCompliance.percentOfTypes', 'Percent of {{ title }}', { title })
    : title
}

export const getViolationUnit = (policyType: PolicyType) => {
  switch (policyType) {
    case PolicyType.NO_PARKING:
    case PolicyType.PARKING_TIME_LIMIT:
      return i18n.t('common.parkingEventsTitle', 'Parking Events')
    case PolicyType.NO_RIDE:
      return i18n.t('common.tripsTitle', 'Trips')
    case PolicyType.OPERATOR_DROP_OFFS:
    case PolicyType.DISTRIBUTION:
    case PolicyType.VEHICLE_CAP:
      return i18n.t('common.violations', 'Violations')
  }
}
