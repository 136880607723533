import { User } from 'firebase/auth'
import { action, computed, makeObservable, observable, runInAction, toJS, values } from 'mobx'

import { OperatorResponse } from '@/modules/api/openapi/models/OperatorResponse'
import { UserPermissionsResponse } from '@/modules/api/openapi/models/UserPermissionsResponse'
import { UserResponse } from '@/modules/api/openapi/models/UserResponse'
import { queryClientInstance } from '@/modules/api/queryClient'
import { apipyRequest } from '@/modules/api/request'
import { monitorException } from '@/modules/monitoring'
import { buildGetAllOperatorsQueryOptions } from '@/modules/operator/hooks'
import {
  buildGetCurrentUserPermissionsQueryOptions,
  buildGetCurrentUserQueryOptions,
} from '@/modules/user/hooks'

interface DeprecatedPermissions {
  regions: string[] | null // TODO: can remove apipy permissions entirely after dependencies on it's regions have been removed
}

class AuthStore {
  authUser?: User // firebase user from onAuthStateChanged

  deprecatedPermissions?: DeprecatedPermissions
  userResponse?: UserResponse
  userPermissionsResponse?: UserPermissionsResponse
  operatorsResponse?: Array<OperatorResponse>

  constructor() {
    makeObservable(this, {
      authUser: observable,
      deprecatedPermissions: observable,
      userResponse: observable,
      userPermissionsResponse: observable,
      operatorsResponse: observable,
      setUser: action,
      clearUser: action,
      userHasNoPermissions: computed,
      email: computed,
      loaded: computed,
      organizationId: computed,
      organizationName: computed,
      allOperators: computed,
      operators: computed,
      hasMobilityManager: computed,
      hasFeeSummary: computed,
      toggleFeeSummary: action,
      hasExport: computed,
    })
  }

  setUser(authUser: User) {
    this.authUser = authUser
  }

  clearUser() {
    this.authUser = undefined
    this.userResponse = undefined
    this.userPermissionsResponse = undefined
    this.operatorsResponse = undefined
    this.deprecatedPermissions = undefined
  }

  /**
   * TODO: This is probably insignificant, the apipy permissions endpoint hardcodes fields that will
   * always make this return false when permissions are loaded. Therefore, it's probably just the
   * oppostie of loaded()
   */
  get userHasNoPermissions() {
    return this.deprecatedPermissions ? Object.keys(this.deprecatedPermissions).length === 0 : true
  }

  get email() {
    if (!this.authUser) return ''

    return (
      this.authUser.email || this.authUser.providerData.find(provider => !!provider?.email)?.email
    )
  }

  get loaded() {
    return (
      !!this.userResponse &&
      !!this.userPermissionsResponse &&
      !!this.operatorsResponse &&
      !!this.deprecatedPermissions
    )
  }

  async loadPermissions() {
    try {
      const responses = await Promise.all([
        queryClientInstance.fetchQuery(buildGetCurrentUserQueryOptions()),
        queryClientInstance.fetchQuery(buildGetCurrentUserPermissionsQueryOptions()),
        queryClientInstance.fetchQuery(buildGetAllOperatorsQueryOptions()),
        apipyRequest('/config/permissions', {}, { reject: true }),
      ])
      runInAction(() => {
        this.userResponse = responses[0]
        this.userPermissionsResponse = responses[1]
        this.operatorsResponse = responses[2].items
        this.deprecatedPermissions = responses[3].data
      })
    } catch (err) {
      monitorException(err)
      runInAction(() => {
        this.userResponse = undefined
        this.userPermissionsResponse = undefined
        this.operatorsResponse = undefined
        this.deprecatedPermissions = undefined
      })
    }
  }

  get organizationId() {
    return this.userResponse?.organizationId
  }

  get organizationName() {
    return this.userResponse?.organizationName
  }

  get allOperators() {
    return this.userPermissionsResponse?.organizationSettings.operators === null
  }

  get operators() {
    return toJS(this.userPermissionsResponse?.organizationSettings.operators)
  }

  get hasMobilityManager() {
    return !!this.userPermissionsResponse?.organizationSettings.mobilityManager
  }

  get hasFeeSummary() {
    return !!this.userPermissionsResponse?.organizationSettings.feeSummary
  }

  toggleFeeSummary() {
    if (this.userPermissionsResponse) {
      this.userPermissionsResponse.organizationSettings.feeSummary =
        !this.userPermissionsResponse?.organizationSettings.feeSummary
    }
  }

  get hasExport() {
    return !!this.userPermissionsResponse?.organizationSettings.export
  }
}

const authStore = new AuthStore()

/**
 * @deprecated Please use the useCurrentPermissions hooks
 */
export default authStore
