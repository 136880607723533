import { ShelfItem, ShelfItemProps } from '../ShelfItem'

import './index.scss'

/**
 * Items that appears in the lower left hand corner of the vertical shelf menu
 */
export const SettingsShelfItem = (props: ShelfItemProps) => {
  return (
    <ShelfItem
      className="settings-shelf-item"
      iconColor={props.active ? 'white' : 'black-1'}
      {...props}
    />
  )
}
