import classNames from 'classnames'
import * as UI from 'semantic-ui-react'

import { Button, ButtonProps } from '../Button'

import './index.scss'

const ToggleGroup = ({ className, ...props }: UI.ButtonGroupProps) => {
  return <UI.Button.Group className={classNames('populus-toggle-group', className)} {...props} />
}

const ToggleButton = ({ className, active = false, ...props }: ButtonProps) => {
  return (
    <Button
      active={active}
      className={classNames('populus-toggle-button', className)}
      secondary
      {...props}
    />
  )
}

export const Toggle = {
  Button: ToggleButton,
  Group: ToggleGroup,
}
