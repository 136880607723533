import { FC } from 'react'
import { ClimbingBoxLoader } from 'react-spinners'
import { Grid, Image } from 'semantic-ui-react'

import './loadAnimation.scss'

const LoadAnimation: FC = () => {
  return (
    <Grid stretched columns={1} className="launch-grid">
      <Grid.Column width={16} stretched verticalAlign="middle" textAlign="center">
        <Image centered src="/Populus_SecondaryLogo_Dark.png" size="medium" />
        <div className="loader-wrapper">
          <ClimbingBoxLoader size={40} color="#1C9C90" loading={true} />
        </div>
      </Grid.Column>
    </Grid>
  )
}

export default LoadAnimation
