import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { observer } from 'mobx-react'

import { Button } from '@/components/componentLibrary'
import { TO_PARENT_PATH } from '@/modules/urlRouting/paths'

import { usePolicyEditContext } from '../../policyEditStore'
import { getModalText } from '../../utils/modalText'
import { PolicyName } from '../PolicyDetails/Form/fields'

import './index.scss'

/**
 * Policy details header when editing, creating or duplicating a policy
 */
export const EditHeader = observer(() => {
  const { t } = useTranslation()

  const policyEditStore = usePolicyEditContext()
  const { policy } = policyEditStore

  if (!policy) return null

  return (
    <div className="policy-details-header">
      <PolicyName editable onValidate={policyEditStore.validatePolicy} policy={policy} />
      <div className="actions">
        <Link to={TO_PARENT_PATH} state={{ policyJson: policyEditStore.originalPolicy?.toJson }}>
          <Button secondary text={t('common.cancelTitle', 'Cancel')} />
        </Link>
        <Button
          onClick={async () => {
            await policyEditStore.save()
            if (_.includes(policy.parkingFeeType, 'variable'))
              policyEditStore.setModalProps({ ...getModalText().INFO_DATA_PROCESSING })
          }}
          text={t('common.saveTitle', 'Save')}
        />
      </div>
    </div>
  )
})
