import { FC } from 'react'
import { observer } from 'mobx-react'
import { Checkbox, Dropdown, Input, Message } from 'semantic-ui-react'

import permissionsModalStore, {
  organizationTypes,
} from '@/modules/permissions/adminPanel/permissionsModalStore'

const OrganizationModal: FC = () => {
  const {
    curbManager,
    mobilityManager,
    availableRegionsMenuOptions,
    selectedRegionsJS,
    organizationUuid,
    organizationName,
    organizationType,
    organizationLogo,
    allRegions,
    feeStructureOptions,
    mobilityFeeStructure,
    curbFeeStructure,
    mobilityPopulusEnforcedInvoicing,
    curbPopulusEnforcedInvoicing,
  } = permissionsModalStore

  return (
    <>
      <h5 style={{ marginTop: 0 }}>Organization Name</h5>
      <Input
        value={organizationName}
        onChange={(e, d) => permissionsModalStore.setOrganizationName(d.value)}
      />
      <h5>Organization Type</h5>
      <Dropdown
        options={organizationTypes.map(t => ({
          value: t,
          text: t,
        }))}
        clearable
        selection
        placeholder="<not specified>"
        value={organizationType}
        onChange={(e, d) => permissionsModalStore.setOrganizationType(d.value)}
      />

      <h5>Regions</h5>
      {permissionsModalStore.operatorId && (
        <Message>
          <p>
            This organization is an operator ({permissionsModalStore.operatorId}). Region
            permissions are determined by available feeds for operators.
          </p>
        </Message>
      )}
      <Checkbox
        label="All Regions"
        toggle
        checked={allRegions}
        disabled={!!permissionsModalStore.operatorId}
        onChange={() => permissionsModalStore.setAllRegions(!allRegions)}
      />
      {!allRegions && (
        <Dropdown
          style={{ marginTop: 15 }}
          onChange={(e, d) => permissionsModalStore.setSelectedRegions(d.value)}
          placeholder="Regions organization can access"
          fluid
          multiple
          selection
          disabled={!!permissionsModalStore.operatorId}
          options={availableRegionsMenuOptions}
          value={selectedRegionsJS}
        />
      )}

      <h5>Disaggregated Data</h5>
      <Checkbox
        label="Allow Disaggregated Data"
        toggle
        checked={permissionsModalStore.disaggregatedData}
        onChange={() => permissionsModalStore.toggleDisaggregatedData()}
      />

      <h5>Security</h5>
      <Checkbox
        label="Require multi-factor login"
        toggle
        checked={permissionsModalStore.mfaRequired}
        onChange={() => permissionsModalStore.toggleMfaRequired()}
      />
    </>
  )
}

export default observer(OrganizationModal)
