import _ from 'lodash'

import { SpatialPolicy as Policy, SpatialPolicy } from '@/models/spatialPolicy'
import { PolicyTypeId } from '@/models/spatialPolicyTypes'
import i18n from '@/modules/i18n/i18n'
import { isEmpty } from '@/modules/map/utils'

import { PolicyFormFieldName, PolicyTimeFrameType, PolicyType } from '../../../utils/types'

interface PolicyTypeField {
  required: boolean
  validator?: (policy: SpatialPolicy) => string | void // function used to validate input
}

type PolicyTypeFields = { [key in PolicyFormFieldName]?: PolicyTypeField }

const validateRequired = (fieldName: keyof Policy) => {
  return (policy: Policy) => {
    return isEmpty(policy[fieldName])
      ? i18n.t('policiesLibrary.validateRequired', 'Please enter a value')
      : undefined
  }
}

const validateTimeFrame = (policy: Policy) => {
  if (policy.policyType.timeframeType === PolicyTimeFrameType.HOURS) {
    return (policy.startTime && policy.startTime?.split(':')?.[1] !== '00') ||
      (policy.endTime && policy.endTime?.split(':')?.[1] !== '00')
      ? i18n.t(
          'policiesLibrary.validateTimeFrameHour',
          "Policy only supports hour increments. Minutes must be ':00'"
        )
      : undefined
  }
  return undefined
}

const baseFields = {
  description: { required: false },
  dateRange: { required: false },
  operators: { required: false },
  policyName: {
    required: true,
    validator: validateRequired('policyName'),
  },
  policyType: {
    required: true,
    validator: validateRequired('policyType'),
  },
  shapeLayerUUID: {
    required: true,
    validator: validateRequired('shapeLayerUUID'),
  },
  vehicleTypes: { required: false },
}

/**
 * Define fields and associated validator functions for each policy type
 */
const definitions: { [key in PolicyTypeId]: PolicyTypeFields } = {
  unknown: {
    ...baseFields,
  },
  distribution: {
    ...baseFields,
    allocation: {
      required: true,
      validator: validateRequired('allocation'),
    },
    days: { required: false },
    timeFrame: { required: false, validator: validateTimeFrame },
    maximum: { required: false },
    minimum: { required: false },
    vehicleStates: { required: false },
  },
  no_parking: {
    ...baseFields,
    timeFrame: { required: false, validator: validateTimeFrame },
    days: { required: false },
    messages: { required: false },
  },
  no_ride: {
    ...baseFields,
    timeFrame: { required: false, validator: validateTimeFrame },
    days: { required: false },
    messages: { required: false },
  },
  operator_drop_offs: {
    ...baseFields,
    timeFrame: { required: false, validator: validateTimeFrame },
    days: { required: false },
    maximum: { required: false },
    minimum: { required: false },
  },
  parking_fees: {
    ...baseFields,
    parkingFeeType: {
      required: true,
      validator: validateRequired('parkingFeeType'),
    },
    value: {
      required: true,
      validator: (policy: Policy) => {
        // not required for variable fees
        _.includes(policy.parkingFeeType, 'fixed') && validateRequired('parkingFeeType')(policy)
      },
    },
    variablePrices: {
      required: true,
      validator: () => undefined,
    },
    timeFrame: { required: false, validator: validateTimeFrame },
    days: { required: false },
  },
  parking_time_limit: {
    ...baseFields,
    maximum: { required: false },
    unit: { required: true, validator: validateRequired('unit') },
  },
  preferred_parking: {
    ...baseFields,
    timeFrame: { required: false, validator: validateTimeFrame },
    days: { required: false },
    messages: { required: false },
  },
  slow_ride: {
    ...baseFields,
    timeFrame: { required: false, validator: validateTimeFrame },
    days: { required: false },
    maximum: { required: false },
    messages: { required: false },
    unit: { required: true, validator: validateRequired('unit') },
  },
  trip_fees: {
    ...baseFields,
    pricingUnit: { required: false },
    value: { required: true },
    shapeLayerUUID: { required: false },
  },
  utilization: {
    ...baseFields,
    maximum: { required: false },
    minimum: { required: false },
    shapeLayerUUID: { required: false },
  },
  vehicle_cap: {
    ...baseFields,
    maximum: { required: false },
    minimum: { required: false },
    shapeLayerUUID: { required: false },
    vehicleStates: { required: false },
  },
  vehicle_fees: {
    ...baseFields,
    pricingUnit: { required: false },
    value: { required: true, validator: validateRequired('value') },
    vehicleCountMethod: {
      required: true,
      validator: validateRequired('vehicleCountMethod'),
    },
    shapeLayerUUID: { required: false },
  },
}

export function getFields(policyType: PolicyType): PolicyTypeFields {
  return policyType.id in definitions ? definitions[policyType.id] : ({} as PolicyTypeFields)
}
