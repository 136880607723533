import i18n from '@/modules/i18n/i18n'
import { PATHS } from '@/modules/urlRouting/paths'

export enum DashboardGroup {
  TRIP = 'trip',
  PARKING = 'parking',
}

export const getDashboardOptions = () => [
  {
    group: DashboardGroup.TRIP,
    value: PATHS.MOBILITY.REPORTING.TRIP_DISTANCE,
    text: i18n.t('dashboard.tripDistances', 'Trip Distances'),
  },
  {
    group: DashboardGroup.TRIP,
    value: PATHS.MOBILITY.REPORTING.TRIP_DURATION,
    text: i18n.t('dashboard.tripDurations', 'Trip Durations'),
  },
  {
    group: DashboardGroup.TRIP,
    value: PATHS.MOBILITY.REPORTING.TIME_OF_DAY,
    text: i18n.t('dashboard.timesAndDays', 'Times and Days'),
  },
  {
    group: DashboardGroup.TRIP,
    value: PATHS.MOBILITY.REPORTING.TRIP_DISTANCE_TECH,
    text: i18n.t('dashboard.tripTechDistance', 'Trip Distance by Tech'),
  },
  {
    group: DashboardGroup.TRIP,
    value: PATHS.MOBILITY.REPORTING.TRIP_SPEED_TECH,
    text: i18n.t('dashboard.tripTechSpeed', 'Trip Speed by Tech'),
  },
  {
    group: DashboardGroup.PARKING,
    value: PATHS.MOBILITY.REPORTING.PARKING_TIME,
    text: i18n.t('dashboard.parkedTime', 'Parked Time'),
  },
  {
    group: DashboardGroup.PARKING,
    value: PATHS.MOBILITY.REPORTING.PARKING_TIME_DISTRICT,
    text: i18n.t('dashboard.parkedDistrictTime', 'Parked Time by District'),
  },
]
