import { apipyRequest, fastApiRequest } from '@/modules/api/request'

interface MdsOptionalRequestParams {
  policy_category?: 'mobility' | 'curb_rule'
}

enum PoliciesEndpoint {
  DELETE = '/policies/delete',
  GET = '/policies',
  GET_CONSTANT = '/policies/constant',
  GET_MDS_GEOGRAPHIES = '/policies/get_mds_geographies',
  GET_MDS_POLICIES = '/policies/get_mds_policies',
  LIST = '/policies/list',
  PUBLISH = '/policies/publish',
  SAVE = '/policies/save',
}

export class PoliciesService {
  delete(uuid: string) {
    return fastApiRequest(
      PoliciesEndpoint.DELETE,
      {
        policy_uuid: uuid,
      },
      { reject: true }
    )
  }

  get(uuid: string) {
    return fastApiRequest(`${PoliciesEndpoint.GET}/${uuid}`, {}, { reject: true })
  }

  getConstant(uuid: string) {
    return fastApiRequest(`${PoliciesEndpoint.GET_CONSTANT}/${uuid}`, {}, { reject: true })
  }

  getMdsGeographies(props: MdsOptionalRequestParams) {
    return apipyRequest(PoliciesEndpoint.GET_MDS_GEOGRAPHIES, { ...props })
  }

  getMdsPolicies(props: MdsOptionalRequestParams) {
    return apipyRequest(PoliciesEndpoint.GET_MDS_POLICIES, { ...props })
  }

  list(extraRequestParams: object = {}) {
    return fastApiRequest(PoliciesEndpoint.LIST, extraRequestParams, { reject: true })
  }

  publish(policyUUID: string, published: boolean) {
    return fastApiRequest(
      PoliciesEndpoint.PUBLISH,
      { policy_uuid: policyUUID, published },
      { reject: true }
    )
  }

  save(policy: object) {
    return fastApiRequest(PoliciesEndpoint.SAVE, policy, { reject: true })
  }
}
