import { FC, FormEvent, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthError, AuthErrorCodes, multiFactor, RecaptchaVerifier } from 'firebase/auth'
import { ButtonGroup, Form, Grid, GridColumn, GridRow, Message, Modal } from 'semantic-ui-react'

import {
  getLocalStorageValue,
  LocalStorageKey,
  removeLocalStorageValue,
} from '@/common/utils/localStorage'
import LoadAnimation from '@/main/loadAnimation'
import {
  fbAuth,
  finishEnrollMultiFactor,
  startEnrollMfa,
  unenrollMultiFactor,
  useAuth,
} from '@/modules/authentication'
import { useCurrentPermissions } from '@/modules/user/hooks'

export const MfaEnrollmentGatekeeper: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation()
  const { loading, signOut } = useAuth()
  const recaptchaDivRef = useRef<HTMLDivElement>(null)
  const { data: permissions } = useCurrentPermissions()

  const [showSuccess, setShowSuccess] = useState(false)

  const [enrolled, setEnrolled] = useState(false)
  const [mfaCode, setMfaCode] = useState('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [mfaVerificationId, setMfaVerificationId] = useState<string | null>(null)
  const [mfaError, setMfaError] = useState<AuthError>()
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | null>()
  const [mfaRequired, setMfaRequired] = useState<boolean | null>(null)

  const wantEnrollMfa = getLocalStorageValue(LocalStorageKey.ENROLL_MFA) === 'true'

  const wantUnenrollMfa = getLocalStorageValue(LocalStorageKey.UNENROLL_MFA) === 'true'

  useEffect(() => {
    const checkEnrollment = async () => {
      if (fbAuth.currentUser) {
        if (wantUnenrollMfa) {
          const unenrolledSuccess = await unenrollMultiFactor()
          setEnrolled(unenrolledSuccess)
        } else {
          const multiFactorUser = multiFactor(fbAuth.currentUser)
          if (multiFactorUser.enrolledFactors.length > 0) setEnrolled(true)
        }
      }
    }

    checkEnrollment()
  }, [fbAuth])

  useEffect(() => {
    if (typeof permissions?.organizationSettings?.mfaRequired === 'boolean')
      setMfaRequired(permissions?.organizationSettings?.mfaRequired)
  }, [permissions])

  const submitPhoneNumber = async (e: FormEvent) => {
    e.preventDefault()

    let verifier = recaptchaVerifier

    if (!verifier) {
      verifier = new RecaptchaVerifier(fbAuth, recaptchaDivRef.current!, {
        size: 'invisible',
      })
      setRecaptchaVerifier(verifier)
    }

    try {
      const verificationId = await startEnrollMfa(verifier, phoneNumber)
      setMfaVerificationId(verificationId)
      setMfaError(undefined)
    } catch (error: unknown) {
      setMfaError(error as AuthError)
    }
  }

  const submitMfaCode = async () => {
    try {
      const enrolled = await finishEnrollMultiFactor(mfaVerificationId, mfaCode)

      setMfaError(undefined)
      setShowSuccess(true)
      setEnrolled(enrolled)
      removeLocalStorageValue(LocalStorageKey.ENROLL_MFA)
    } catch (error: unknown) {
      setMfaError(error as AuthError)
    }
  }

  if (loading || mfaRequired === null) return <LoadAnimation />

  if (mfaError && mfaError.code === AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN) signOut()

  if (enrolled) {
    if (showSuccess) {
      return (
        <Modal
          open
          size="mini"
          closeOnDimmerClick={true}
          content={t('login.mfaAdded', 'Multi-factor device added')}
          onClose={() => setShowSuccess(false)}
        >
          <Modal.Content>
            <p>{t('login.mfaAdded', 'Multi-factor device added')}</p>
          </Modal.Content>
          <Modal.Actions>
            <Form.Button color="teal" onClick={() => setShowSuccess(false)}>
              {t('common.ok', 'OK')}
            </Form.Button>
          </Modal.Actions>
        </Modal>
      )
    }

    return children
  }
  if (!mfaRequired && !wantEnrollMfa) return children

  return (
    <>
      <div id="recaptcha-container-id" ref={recaptchaDivRef} />
      <Modal open size="tiny" closeOnDimmerClick={false}>
        <Modal.Content>
          <Form size="large">
            <div className="card">
              {mfaError && (
                <Message negative>
                  <Message.Header>
                    {t('login.mfaEnrollmentLoginError', 'Multi-factor Error')}
                  </Message.Header>
                  <p>{t(`login.mfaAuthError_${mfaError.code}`, mfaError.message)}</p>
                </Message>
              )}

              {mfaVerificationId ? (
                <>
                  <p>{t('login.mfaEnrollmentCodeSent', 'A code is being sent to your phone')}</p>
                  <Form.Input
                    type="text"
                    name="mfaCode"
                    value={mfaCode}
                    icon="stopwatch"
                    iconPosition="left"
                    placeholder={t('login.mfaPlaceholder', 'Enter your multi-factor code')}
                    pattern="^[0-9]*$"
                    onChange={e => setMfaCode(e.target.value)}
                    required
                  />

                  <Form.Button
                    fluid
                    color="teal"
                    size="small"
                    type="button"
                    onClick={submitMfaCode}
                  >
                    {t('common.submit', 'Submit')}
                  </Form.Button>
                </>
              ) : (
                <Grid>
                  <GridRow columns={1}>
                    <GridColumn>
                      <p>{t('login.mfaAddDevice', 'Add a multi-factor device')}</p>

                      <Form.Input
                        type="text"
                        name="phoneNumber"
                        value={phoneNumber}
                        icon="phone"
                        iconPosition="left"
                        placeholder={t(
                          'login.phonePlaceHolder',
                          'Enter your phone number (including country code)'
                        )}
                        onChange={e => setPhoneNumber(e.target.value)}
                        required
                      />
                    </GridColumn>
                  </GridRow>

                  <GridRow columns={2}>
                    <GridColumn>
                      <Form.Button
                        fluid
                        color="grey"
                        size="small"
                        type="button"
                        onClick={e => signOut()}
                      >
                        {t('common.cancel', 'Cancel')}
                      </Form.Button>
                    </GridColumn>
                    <GridColumn>
                      <Form.Button
                        fluid
                        color="teal"
                        size="small"
                        type="button"
                        onClick={submitPhoneNumber}
                      >
                        {t('common.submit', 'Submit')}
                      </Form.Button>
                    </GridColumn>
                  </GridRow>
                </Grid>
              )}
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    </>
  )
}

export default MfaEnrollmentGatekeeper
