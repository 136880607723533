/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Alive } from '../models/Alive'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class DefaultService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Index
   * Return current environment
   * @returns any Successful Response
   * @throws ApiError
   */
  public index(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/',
    })
  }
  /**
   * Health
   * Simple ping interface to check if the service is alive
   * @returns Alive Successful Response
   * @throws ApiError
   */
  public health(): CancelablePromise<Alive> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/health',
    })
  }
  /**
   * Ping
   * Check that service is up and can reach the database
   * @returns any Successful Response
   * @throws ApiError
   */
  public ping(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/ping',
    })
  }
}
