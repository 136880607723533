import classNames from 'classnames'

import { Icon } from '../../componentLibrary'
import { ShelfItem, ShelfItemProps } from '../ShelfItem'

import './index.scss'

export interface ExpandableShelfItemProps extends ShelfItemProps {
  expandable?: boolean
  expanded?: boolean
}

/**
 * Item in the vertical shelf menu used to navigate through the app
 * @param expandable whether the item should show a vector arrow > indicating that clicking will open the drawer
 * @param expanded whether the arrow should appear open <
 */
export const ExpandableShelfItem = ({
  active,
  expandable,
  expanded,
  ...props
}: ExpandableShelfItemProps) => {
  const iconColor = active ? 'ocean-1' : 'black-1'
  return (
    <ShelfItem active={active} className="expandable-shelf-item" {...props} iconColor={iconColor}>
      {expandable && active && (
        <Icon
          className={classNames('expandable-vector', expanded && 'expanded')}
          color={iconColor}
          icon="Vector"
        />
      )}
    </ShelfItem>
  )
}
