/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RegionResponseExternal } from '../models/RegionResponseExternal'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class RegionsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get Region By Id
   * Returns data on a specific region that is available to the public
   * @returns RegionResponseExternal Successful Response
   * @throws ApiError
   */
  public getRegionById({
    regionId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
  }): CancelablePromise<RegionResponseExternal> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
