/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyResponse } from '../models/CompanyResponse'
import type { EditUserRoleItem } from '../models/EditUserRoleItem'
import type { UserPermissionsResponse } from '../models/UserPermissionsResponse'
import type { UserResponse } from '../models/UserResponse'
import type { UserRoleResponseItem } from '../models/UserRoleResponseItem'
import type { UserRoleWithPermissions } from '../models/UserRoleWithPermissions'
import type { UserUpdateRequest } from '../models/UserUpdateRequest'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class UsersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get Current User
   * @returns UserResponse Successful Response
   * @throws ApiError
   */
  public getCurrentUser(): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/me',
    })
  }
  /**
   * Update User
   * @returns any Successful Response
   * @throws ApiError
   */
  public updateUser({ requestBody }: { requestBody: UserUpdateRequest }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/users/me',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get User Company
   * Returns a company_id and company_name for a given user.
   *
   * Returns a JSON response in the format:
   * {
   * 'company_id': str
   * 'name': str
   * }
   * @returns CompanyResponse Successful Response
   * @throws ApiError
   */
  public getUserCompany(): CancelablePromise<CompanyResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/me/company_from_intercom',
    })
  }
  /**
   * Get Current User Permissions
   * @returns UserPermissionsResponse Successful Response
   * @throws ApiError
   */
  public getCurrentUserPermissions(): CancelablePromise<UserPermissionsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/me/permissions',
    })
  }
  /**
   * Get Available Roles With Permissions
   * Get all user_roles for an entity from the has_permissions table
   * @returns UserRoleWithPermissions Successful Response
   * @throws ApiError
   */
  public getAvailableRolesWithPermissions(): CancelablePromise<Array<UserRoleWithPermissions>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/user_roles/',
    })
  }
  /**
   * Get Roles For User
   * Get all user_roles for an entity from the has_permissions table
   * @returns UserRoleResponseItem Successful Response
   * @throws ApiError
   */
  public getRolesForUser({
    userId,
  }: {
    userId: string
  }): CancelablePromise<Array<UserRoleResponseItem>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/user_roles/{user_id}',
      path: {
        user_id: userId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Update Roles For User
   * Update user_roles in bulk. User Roles passed in with enabled=True will be added to the user if
   * they don't exist and roles passed in with enabled=False will be removed if they exist on the user.
   * Else, no-op on roles in the request body that don't require an update.
   * @returns UserRoleResponseItem Successful Response
   * @throws ApiError
   */
  public updateRolesForUser({
    userId,
    requestBody,
  }: {
    userId: string
    requestBody: Array<EditUserRoleItem>
  }): CancelablePromise<Array<UserRoleResponseItem>> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/user_roles/{user_id}',
      path: {
        user_id: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
