import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

import { SettingsShelfItem } from '@/components/Shelf/SettingsShelfItem'
import {
  ShelfDropdown,
  ShelfDropdownDivider,
  ShelfDropdownHeader,
  ShelfDropdownItem,
} from '@/components/Shelf/ShelfDropdown'
import { UserAvatar } from '@/components/UserAvatar'
import { useAuth } from '@/modules/authentication'
import { useFeatureFlag } from '@/modules/featureFlags'
import { GlobalModalId, useGlobalModalProvider } from '@/modules/globalModal/context'
import { useIntercomProductTours } from '@/modules/intercom'
import {
  removeRegionIdFromPath,
  useOrganizationUsersPageAccess,
  useProfilePageAccess,
} from '@/modules/urlRouting/utils'
import { useCurrentUser } from '@/modules/user/hooks'

const SettingsLinks = () => {
  const { t } = useTranslation()
  const { setId } = useGlobalModalProvider()

  const Intercom = useIntercom()
  const hasIntercomToursInApp = useFeatureFlag('intercom_tours_in_app')
  const location = useLocation()

  const { signOut } = useAuth()
  const { data: user } = useCurrentUser()

  const { data: intercomTourIds } = useIntercomProductTours(
    removeRegionIdFromPath(location.pathname)
  )

  const [settingsOpen, setSettingsOpen] = useState(false)
  const [helpOpen, setHelpOpen] = useState(false)

  const hasOrganizationUsersPageAccess = useOrganizationUsersPageAccess()
  const hasProfilePageAccess = useProfilePageAccess()

  return (
    <>
      <ShelfDropdown
        onBlur={() => setSettingsOpen(false)}
        open={settingsOpen}
        trigger={
          <SettingsShelfItem
            active={settingsOpen}
            dataTestId="settings"
            iconName="Settings"
            onClick={() => setSettingsOpen(!settingsOpen)}
            text={t('settingsLinks.settings', 'Settings')}
          />
        }
      >
        <ShelfDropdownHeader>
          <div>
            <UserAvatar email={user?.email} initials={user.email[0]} />
          </div>
        </ShelfDropdownHeader>
        <ShelfDropdownDivider />
        <ShelfDropdownItem
          disabled={!hasProfilePageAccess}
          onClick={() => hasProfilePageAccess && setId(GlobalModalId.PROFILE_SETTINGS)}
          text={t('settingsLinks.profileSettings', 'Profile Settings')}
        />
        <ShelfDropdownItem onClick={signOut} text={t('settingsLinks.signOut', 'Sign Out')} />
        {hasOrganizationUsersPageAccess && (
          <>
            <ShelfDropdownDivider />
            <ShelfDropdownItem
              onClick={() => hasOrganizationUsersPageAccess && setId(GlobalModalId.MANAGE_USERS)}
              text={t('settingsLinks.manageUsers', 'Manage Users')}
            />
          </>
        )}
      </ShelfDropdown>
      {hasIntercomToursInApp ? (
        <ShelfDropdown
          onBlur={() => setHelpOpen(false)}
          open={helpOpen}
          trigger={
            <SettingsShelfItem
              active={helpOpen}
              dataTestId="help"
              iconName="Help"
              onClick={() => setHelpOpen(!helpOpen)}
              text={t('settingsLinks.help', 'Help')}
            />
          }
        >
          <ShelfDropdownItem
            disabled={!intercomTourIds || !intercomTourIds.length}
            onClick={() => {
              setHelpOpen(false)
              Intercom.boot()
              if (intercomTourIds) {
                Intercom.startTour(intercomTourIds[0])
              }
            }}
            text={t('settingsLinks.pageTour', 'Tour this page')}
          />
          <ShelfDropdownDivider />
          <ShelfDropdownItem
            onClick={() => {
              setHelpOpen(false)
              window.open('https://help.populus.ai/en/', '_blank')
            }}
            text={t('settingsLinks.populusHelp', 'Populus Help')}
          />
        </ShelfDropdown>
      ) : (
        <SettingsShelfItem
          iconName="Help"
          onClick={() => window.open('https://help.populus.ai/en/', '_blank')}
          text={t('settingsLinks.help', 'Help')}
        />
      )}
    </>
  )
}

export default SettingsLinks
