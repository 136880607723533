import classNames from 'classnames'
import * as UI from 'semantic-ui-react'

import { Icon } from '../Icon'

import './index.scss'
export type TooltipOrientation = 'top' | 'bottom' | 'left' | 'right'
export interface TooltipProps extends UI.PopupProps {
  className?: string
  dark?: boolean
  mouseEnterDelay?: UI.PopupProps['mouseEnterDelay']
  orientation: TooltipOrientation
}

/**
 * @param dark - true if tooltip should implement dark styling
 * @param mouseEnterDelay - delay in milliseconds for the popup to appear
 * @param orientation - side the tooltip should appear
 * @param trigger - React node that should act as the trigger for the popup
 */
export const Tooltip = ({ className, dark, orientation, ...props }: TooltipProps) => {
  return (
    <UI.Popup
      basic
      on="hover"
      position={`${orientation} center`}
      trigger={
        <div className="populus-tooltip-icon-container">
          <Icon
            backgroundColor="black-1"
            backgroundHoverColor="ocean-1"
            hoverColor="ocean-1"
            icon="TooltipSolid"
          />
        </div>
      }
      className={classNames('populus-tooltip', dark && 'dark', orientation, className)}
      id="populus-tooltip"
      {...props}
    ></UI.Popup>
  )
}

export { CollapsedTextTooltip } from './CollapsedTextTooltip'
