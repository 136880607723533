import { action, makeObservable, observable } from 'mobx'

type Size = 'small' | 'mini' | 'tiny' | 'large' | 'fullscreen' | undefined
interface Props {
  size?: Size
  title?: string
  header?: string
  content?: string
  isOpen?: boolean
}

class PopupStore {
  // @observable props
  isOpen: boolean = false
  // Sizes: 'mini', 'tiny', 'small', 'large', 'fullscreen'
  size: Size = 'small'
  // Title for the popup modal
  title?: string = ''
  // Header of the content
  header?: string = ''
  content?: string = ''

  constructor() {
    makeObservable(this, {
      isOpen: observable,
      size: observable,
      title: observable,
      header: observable,
      content: observable,
      create: action,
      close: action,
    })
  }

  create(desc: Props) {
    if (desc.size) this.size = desc.size
    if (desc.title) this.title = desc.title
    if (desc.header) this.header = desc.header
    if (desc.content) this.content = desc.content

    this.isOpen = true
  }

  close() {
    this.isOpen = false
  }
}
export default new PopupStore()
