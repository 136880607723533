import { MouseEvent as ReactMouseEvent } from 'react'
import classNames from 'classnames'
import * as UI from 'semantic-ui-react'

import { Icon, IconName } from '../Icon'
import { Text } from '../Text'
import { Tooltip } from '../Tooltip'

import './index.scss'

export interface ButtonProps extends Omit<UI.ButtonProps, 'onClick'> {
  extraWide?: boolean
  icon?: IconName
  onClick?: (event: ReactMouseEvent<HTMLElement, MouseEvent>, data: UI.ButtonProps) => void
  small?: boolean
  square?: boolean
  text?: string
  tooltipText?: string
  warning?: boolean
}

export const Button = ({
  className,
  fluid,
  icon,
  secondary,
  small,
  square,
  text,
  tooltipText,
  warning,
  extraWide,
  ...props
}: ButtonProps) => {
  const button = (
    <UI.Button
      className={classNames(
        'populus-button',
        secondary ? 'secondary' : 'primary',
        warning && 'warning',
        small ? 'small' : 'large',
        square && 'square',
        text && 'text',
        icon && 'icon',
        fluid && 'fluid',
        extraWide && 'extra-wide',
        className
      )}
      {...props}
    >
      {icon && <Icon icon={icon} color={secondary ? 'midnight-0' : 'white'} />}
      {text && (
        <Text
          className={classNames(icon && 'icon-text')}
          styleType={`button${secondary ? '-light' : ''}`}
        >
          {text}
        </Text>
      )}
    </UI.Button>
  )

  return tooltipText ? (
    <Tooltip content={tooltipText} dark mouseEnterDelay={500} orientation="top" trigger={button} />
  ) : (
    button
  )
}
