import { getImpersonatedUserEmail } from '@/modules/authentication/impersonation'

import { Icon, Text } from '../componentLibrary'

import './index.scss'

interface UserAvatarProps {
  initials?: string
  email?: string
}

export const UserAvatar = ({ initials, email }: UserAvatarProps) => {
  const impersonatedUserEmail = getImpersonatedUserEmail()
  return (
    <div className="user-avatar">
      <div className="user-initials">
        <Text styleType="primary-light">{initials}</Text>
      </div>
      <div className="user-email">
        <Text styleType="primary-light">{email}</Text>
        {impersonatedUserEmail && (
          <div className="impersonated-user">
            <Icon icon="Eye" color="black-1"></Icon>
            <Text styleType="link">{impersonatedUserEmail}</Text>
          </div>
        )}
      </div>
    </div>
  )
}
