import _ from 'lodash'

import { CITYWIDE_LAYER_UUID, getCitywideLayerName } from '@/modules/layers/layerStore'
import { PolicyType } from '@/modules/policiesV1/policyLibrary/utils/types'

import { ComplianceStatus, NON_COMPLIANT } from './constants'

export type ShapeName = string

export type ShapeUUID = string

export type ViolationQueryRow = {
  date: string
  operator: string
  shape_uuid?: string
  shape_name?: string
  start_time_local?: string
  end_time_local?: string
  vehicle_id?: string
  vehicle_type?: string
  trip_id?: string
  status?: ComplianceStatus
  reason?: string
  allocation?: string
  vehicle_count?: number
  drop_offs_count?: number
}

export class PolicyViolation {
  data

  constructor(data: ViolationQueryRow = {} as ViolationQueryRow) {
    this.data = data
  }

  get date(): string {
    return this.data.date
  }

  get operator(): string {
    return this.data.operator
  }

  get shapeUUID(): ShapeUUID {
    return this.data.shape_uuid || CITYWIDE_LAYER_UUID
  }

  get shapeName(): ShapeName {
    return this.data.shape_name || getCitywideLayerName()
  }

  get status(): ComplianceStatus {
    return this.data.status || NON_COMPLIANT
  }

  getVehicleCount(policyType: PolicyType): number | undefined {
    switch (policyType) {
      // there should be one value per day representing count of vehicles/drop-offs in geogrpahy
      case PolicyType.DISTRIBUTION:
        return this.data.vehicle_count
      case PolicyType.OPERATOR_DROP_OFFS:
        return this.data.drop_offs_count
      case PolicyType.VEHICLE_CAP: // there should be a reason string containing the number of vehicles for the operator
        return this.data.reason ? _.parseInt(this.data.reason) : undefined
      default:
        return undefined
    }
  }
}
