import { FC, PropsWithChildren, useCallback, useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'

import {
  getLocalStorageValue,
  LocalStorageKey,
  setLocalStorageValue,
} from '@/common/utils/localStorage'
import { MILLISECONDS_IN_A_SECOND, SECONDS_IN_A_MINUTE } from '@/common/utils/time'
import { useAuth } from '@/modules/authentication'
import { monitorBreadcrumb } from '@/modules/monitoring'
import { useCurrentUser } from '@/modules/user/hooks'

const AUTO_LOGOUT_TIME_LIMIT_MINUTES = 60

const LogoutIdleUserTimer: FC<PropsWithChildren> = ({ children }) => {
  const { signOut } = useAuth()
  const { data: user } = useCurrentUser()

  const handleIdle = useCallback(() => {
    monitorBreadcrumb('Logging out Idle User')
    signOut()
  }, [signOut])

  const handleAction = useCallback(() => {
    const lastActiveTime = getLocalStorageValue(LocalStorageKey.LAST_ACTIVE_TIME)

    if (lastActiveTime) {
      const expiration = new Date(lastActiveTime)
      expiration.setMinutes(expiration.getMinutes() + AUTO_LOGOUT_TIME_LIMIT_MINUTES)

      if (expiration < new Date()) handleIdle()
    }

    setLocalStorageValue(LocalStorageKey.LAST_ACTIVE_TIME, new Date())
  }, [handleIdle])

  useEffect(() => {
    handleAction()
  }, [user, handleAction])

  const idleTimer = useIdleTimer({
    timeout: AUTO_LOGOUT_TIME_LIMIT_MINUTES * SECONDS_IN_A_MINUTE * MILLISECONDS_IN_A_SECOND,
    crossTab: true,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
    ],
    onIdle: handleIdle,
    onAction: handleAction,
    debounce: 500,
  })

  return <>{children}</>
}

export default LogoutIdleUserTimer
