import { FC, useEffect, useMemo, useState } from 'react'
import { DateTime } from 'luxon'

import { getYearsFromDateRange } from '@/common/utils/date'
import { DatePickerRange } from '@/components/componentLibrary/DatePicker/types'
import {
  getAvailableMonthsByYear,
  getDateWithinMinMaxDates,
  getMonthOptions,
  getYearOptions,
} from '@/components/componentLibrary/MonthPicker/utils'

import { Dropdown, Field, Text } from '..'

interface MonthPickerProps {
  range: DatePickerRange
  maxDate?: Date | undefined
  minDate?: Date | undefined
  setRange: (range: DatePickerRange) => void
}

const defaultDates = {
  minDate: DateTime.local(2010).toJSDate(),
  maxDate: DateTime.now().toJSDate(),
}

export const MonthPicker: FC<MonthPickerProps> = ({
  range,
  minDate = defaultDates.minDate,
  maxDate = defaultDates.maxDate,
  setRange,
}) => {
  const [monthsByYear, setMonthsByYear] = useState(getAvailableMonthsByYear(minDate, maxDate))
  const yearsInRange = useMemo(() => getYearsFromDateRange(minDate, maxDate), [minDate, maxDate])
  const { to: toDate, from: fromDate } = range

  useEffect(() => {
    setMonthsByYear(getAvailableMonthsByYear(minDate, maxDate))
  }, [minDate, maxDate])

  const updateFromDate = (value: number, datePart: 'month' | 'year') => {
    if (!(fromDate && toDate)) return
    const date = new Date(fromDate)

    if (datePart === 'month') date.setMonth(value)
    if (datePart === 'year') date.setFullYear(value)

    const newDate = getDateWithinMinMaxDates({ date, minDate, maxDate })
    const newRange = {
      from: newDate,
      to: newDate > toDate ? newDate : toDate,
    }
    setRange(newRange)
  }

  const updateToDate = (value: number, datePart: 'month' | 'year') => {
    if (!(fromDate && toDate)) return
    const date = new Date(toDate)

    if (datePart === 'month') date.setMonth(value)
    if (datePart === 'year') date.setFullYear(value)

    const newDate = getDateWithinMinMaxDates({ date, minDate, maxDate })
    const newRange = {
      to: newDate,
      from: newDate < fromDate ? newDate : fromDate,
    }
    setRange(newRange)
  }

  return (
    <div className="date-picker" data-testid="month-picker-component">
      <Field>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text styleType="body-light">Start Date</Text>
          <div style={{ display: 'flex', marginBottom: '10px' }}>
            <Dropdown
              disabled={!fromDate}
              options={useMemo(
                () => getMonthOptions('from', fromDate, monthsByYear),
                [fromDate, monthsByYear]
              )}
              onChange={value => updateFromDate(Number(value), 'month')}
              value={fromDate?.getMonth().toString()}
              halfSize
              data-testid="from-month-dropdown"
            />
            <Dropdown
              disabled={!fromDate}
              options={useMemo(() => getYearOptions('from', yearsInRange), [yearsInRange])}
              onChange={value => updateFromDate(Number(value), 'year')}
              value={fromDate?.getFullYear().toString()}
              halfSize
              data-testid="from-year-dropdown"
            />
          </div>
          <Text styleType="body-light">End Date</Text>
          <div style={{ display: 'flex' }}>
            <Dropdown
              disabled={!toDate}
              options={useMemo(
                () => getMonthOptions('to', toDate, monthsByYear),
                [toDate, monthsByYear]
              )}
              onChange={value => updateToDate(Number(value), 'month')}
              value={toDate?.getMonth().toString()}
              halfSize
              data-testid="to-month-dropdown"
            />
            <Dropdown
              disabled={!toDate}
              options={useMemo(() => getYearOptions('to', yearsInRange), [yearsInRange])}
              onChange={value => updateToDate(Number(value), 'year')}
              value={toDate?.getFullYear().toString()}
              halfSize
              data-testid="to-year-dropdown"
            />
          </div>
        </div>
      </Field>
    </div>
  )
}
