import camelcaseKeys from 'camelcase-keys'
import {
  BooleanParam,
  createEnumParam,
  DateParam,
  DelimitedNumericArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

import { ISO_DAYS_OF_WEEK, isoToDate } from '@/common/utils/date'
import { QueryParam } from '@/modules/queryParams/constants'
import { useReferenceGeographyValues } from '@/modules/queryParams/utils'

import {
  useRoutesDateRange,
  useRoutesNetworkTypeOptions,
  useRoutesOperatorsOptions,
  useRoutesVehicleTypeOptions,
} from './util'

export const useInternalRoutesQueryParams = () => {
  const layerOptions = useReferenceGeographyValues()

  const { data: dateRange } = useRoutesDateRange()
  const minDate = dateRange ? isoToDate(dateRange.minDate) : undefined
  const maxDate = dateRange ? isoToDate(dateRange.maxDate) : undefined

  const oneMonthAgo = new Date()
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)
  const defaultStartDate = maxDate && maxDate < oneMonthAgo ? (minDate ?? maxDate) : oneMonthAgo
  const defaultEndDate = maxDate ?? new Date()

  const networkTypeOptions = useRoutesNetworkTypeOptions()
  const networkTypes = networkTypeOptions.map(({ value }) => value)

  const vehicleTypeOptions = useRoutesVehicleTypeOptions()
  const vehicleTypes = vehicleTypeOptions.map(({ value }) => value)

  const operatorOptions = useRoutesOperatorsOptions()
  const operators = operatorOptions.map(({ value }) => value)

  const queryParams = camelcaseKeys(
    useQueryParams({
      [QueryParam.DAYS_OF_WEEK]: withDefault(DelimitedNumericArrayParam, ISO_DAYS_OF_WEEK),
      [QueryParam.END_DATE]: withDefault(DateParam, defaultEndDate),
      [QueryParam.END_TIME_HOUR]: withDefault(NumberParam, 24),
      [QueryParam.NETWORK_TYPE]: createEnumParam(networkTypes),
      [QueryParam.OPERATOR]: createEnumParam(operators),
      [QueryParam.REFERENCE_GEOGRAPHY]: createEnumParam(layerOptions),
      [QueryParam.SHOW_CRASH_DATA]: withDefault(BooleanParam, false),
      [QueryParam.START_DATE]: withDefault(DateParam, defaultStartDate),
      [QueryParam.START_TIME_HOUR]: withDefault(NumberParam, 0),
      [QueryParam.VEHICLE_TYPE]: withDefault(createEnumParam(vehicleTypes), vehicleTypes[0]),
      [QueryParam.SELECTED_ROUTE]: withDefault(StringParam, undefined),
    })
  )

  const [{ startDate, endDate }, setQueryParams] = queryParams

  if (minDate && startDate < minDate) setQueryParams({ [QueryParam.START_DATE]: undefined })

  if (maxDate && endDate > maxDate) setQueryParams({ [QueryParam.END_DATE]: undefined })

  if (startDate > endDate)
    setQueryParams({
      [QueryParam.START_DATE]: undefined,
      [QueryParam.END_DATE]: undefined,
    })

  return queryParams
}
