import classNames from 'classnames'

import { Icon, Text } from '../../componentLibrary'

import './index.scss'

export interface DrawerItemProps {
  active?: boolean
  collapsed?: boolean
  disabled?: boolean
  expanded?: boolean
  onClick?: () => void
  text?: string
}

/**
 * Item that appears in the Drawer component to either link to a particular page or open a dropdown with further options
 * @param active - style as the currently chosen item
 * @param collapsed - show icon indicating that dropdown is closed `>`
 * @param disabled - style as a disabled item; prevents onClick events
 * @param expanded - show icon indicating that dropdown is open `<`
 * @returns
 */
export const DrawerItem = ({
  active,
  collapsed,
  disabled,
  expanded,
  onClick,
  text,
}: DrawerItemProps) => {
  const isActive = active && !disabled
  return (
    <div
      className={classNames('drawer-item', isActive && 'active', disabled && 'disabled')}
      onClick={() => onClick && !disabled && onClick()}
    >
      <Text styleType="body">{text}</Text>
      {isActive && !expanded && !collapsed && <span className="active-icon dot" />}
      {(expanded || collapsed) && (
        <Icon
          className={classNames('active-icon vector', expanded && 'expanded')}
          color="black-1"
          icon="Vector"
        />
      )}
    </div>
  )
}
