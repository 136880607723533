import { FC } from 'react'

import { Icon } from '@/components/componentLibrary'

import './Populus404.scss'

export const Populus404: FC = () => {
  return (
    <div className="populus-404">
      <span>4</span>
      <Icon className="invalid-icon" color="black" icon="PopulusLogo" />
      <span>4</span>
    </div>
  )
}
