import { useFormatCurrency } from '@/common/utils/formatting'

import { FormattedInput, FormattedInputProps } from '../Input'

export interface CurrencyInputProps extends Omit<FormattedInputProps, 'onChange' | 'value'> {
  onChange: (value: string) => void
  value: string
}

/**
 * An Input control for entering currency values
 * @param value float
 */
export const CurrencyInput = ({ onChange, value, ...props }: CurrencyInputProps) => {
  const formatCurrency = useFormatCurrency()
  return (
    <FormattedInput
      onAfterChange={value => {
        if (!value) return onChange('')

        onChange(value?.replace(/[^0-9.]/g, '') || '0')
      }}
      placeholder={formatCurrency(0)}
      value={value ? formatCurrency(Number(value)) : ''}
      {...props}
    />
  )
}
