import { ReactNode, SyntheticEvent } from 'react'
import classNames from 'classnames'
import { Dropdown } from 'semantic-ui-react'

import { Text } from '../../componentLibrary'

import './index.scss'

interface ShelfDropdownProps {
  children?: ReactNode
  className?: string
  onBlur?: () => void
  open?: boolean
  trigger?: ReactNode
}

/**
 * Dropdown associated with an item in the Shelf component
 * @param onBlur invoked whenever the dropdown loses focus; use this to close the dropdown
 * @param open true if the dropdown should appear open
 * @param trigger element to render that will trigger the dropdown
 * @example
 * <ShelfDropdown
 *  onBlur={() => setDropdownOpen(false)}
 *  open={dropdownOpen}
 *  trigger={<div onClick={() => setDropdownOpen(true)}> Click me to open the dropdown </div>}
 * />
 */
export const ShelfDropdown = ({ children, className, ...props }: ShelfDropdownProps) => {
  return (
    <Dropdown className={classNames('shelf-dropdown', className)} icon={null} item {...props}>
      <Dropdown.Menu>{children}</Dropdown.Menu>
    </Dropdown>
  )
}

interface ShelfDropdownHeaderProps {
  children?: ReactNode
  className?: string
}

/**
 * Renders a header in the dropdown with appropriate styling
 */
export const ShelfDropdownHeader = ({ children, className }: ShelfDropdownHeaderProps) => {
  return (
    <Dropdown.Header
      className={classNames('shelf-dropdown-header', className)}
      // keep the dropdown open when this is clicked
      onClick={(e: SyntheticEvent) => e.stopPropagation()}
    >
      {children}
    </Dropdown.Header>
  )
}

interface ShelfDropdownItemProps {
  className?: string
  disabled?: boolean
  onClick: () => void
  text: string
}

export const ShelfDropdownItem = ({
  className,
  disabled,
  onClick,
  text,
}: ShelfDropdownItemProps) => {
  return (
    <div
      className={classNames('shelf-dropdown-item', className, disabled && 'disabled')}
      onClick={() => onClick && !disabled && onClick()}
    >
      <Text styleType="body">{text}</Text>
    </div>
  )
}

export const ShelfDropdownDivider = () => <Dropdown.Divider className="shelf-dropdown-divider" />
