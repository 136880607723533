import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Menu, Sidebar } from 'semantic-ui-react'
import { useOnClickOutside } from 'usehooks-ts'

import DrawerLinks from '@/modules/urlRouting/DrawerLinks'
import SettingsLinks from '@/modules/urlRouting/SettingsLinks'
import ShelfLinks from '@/modules/urlRouting/ShelfLinks'

import './index.scss'

const Shelf: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [activeShelfPath, setActiveShelfPath] = useState<string | undefined>()

  // close drawer when clicking Navbar
  const outsideRef = useRef(null)
  useOnClickOutside(outsideRef, () => setDrawerOpen(false))
  // close drawer when URL changes
  useEffect(() => setDrawerOpen(false), [location])

  return (
    <div className="shelf" ref={outsideRef}>
      <Menu vertical compact fluid className="shelf-menu">
        <ShelfLinks
          activePath={activeShelfPath}
          expanded={drawerOpen}
          setActivePath={setActiveShelfPath}
          setExpanded={setDrawerOpen}
        />
        <div className="placeholder-border" />
        <SettingsLinks />
      </Menu>
      <Sidebar.Pushable>
        <Sidebar
          animation="overlay"
          className="drawer"
          onHidden={() => setActiveShelfPath(undefined)}
          style={{ background: '#FFFFFF' }}
          visible={drawerOpen}
        >
          <DrawerLinks activePath={activeShelfPath} />
        </Sidebar>
        {/* close drawer when clicking child content */}
        <Sidebar.Pusher dimmed={drawerOpen} onClick={() => setDrawerOpen(false)}>
          <div className="pusher-content">{children}</div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  )
}

export default Shelf
