import { useTranslation } from 'react-i18next'

import { Button, Icon, Modal, Text } from '@/components/componentLibrary'

import './index.scss'

export interface PolicyInfoModalProps {
  onCancel?: () => void
  onConfirm?: () => void
  text?: string
  title?: string
}

export const PolicyInfoModal = ({ onCancel, onConfirm, text, title }: PolicyInfoModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal>
      <div className="policy-info-modal">
        {title && <div className="modal-title">{<Text styleType="primary">{title}</Text>}</div>}
        <div className="modal-content">
          <div className="modal-warning-icon">
            <Icon color="warning" icon="Alert" />
          </div>
          {text && <div className="modal-warning-text">{<Text styleType="body">{text}</Text>}</div>}
          <div className="modal-buttons">
            {onCancel && (
              <Button onClick={onCancel} secondary small text={t('common.cancelTitle', 'Cancel')} />
            )}
            {onConfirm && (
              <Button onClick={onConfirm} small text={t('common.confirmTitle', 'Confirm')} />
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
