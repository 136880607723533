import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { observer } from 'mobx-react'

import { generateEnumTranslations } from '@/common/utils/enum'
import { Dropdown } from '@/components/componentLibrary'
import { SpatialPolicy as Policy } from '@/models/spatialPolicy'
import {
  generateEnumDropdownOptions,
  VehicleStateGroupEnum,
  VehicleStateGroupType,
} from '@/models/spatialPolicyTypes'

import { HideableField, SelectionTags } from './components'
import { EditableFieldProps } from '.'

const VEHICLE_STATE_TITLE = 'Vehicle States'

export const VehicleStates = observer(({ policy, ...props }: { policy: Policy }) => {
  const { t } = useTranslation()

  return (
    <HideableField
      titleText={t('policiesLibrary.formVehicleStatesTitle', VEHICLE_STATE_TITLE)}
      {...props}
    >
      {policy.vehicleStateGroup && (
        <SelectionTags
          values={[
            generateEnumTranslations(VehicleStateGroupEnum, 'VehicleStateGroupEnum')[
              policy.vehicleStateGroup
            ],
          ]}
        />
      )}
    </HideableField>
  )
})

export const EditableVehicleStates = observer(
  ({ editable, onValidate, policy, ...props }: EditableFieldProps) => {
    const { t } = useTranslation()

    return editable ? (
      <HideableField
        editable
        titleText={t('policiesLibrary.formVehicleStatesTitle', VEHICLE_STATE_TITLE)}
        {...props}
      >
        <Dropdown
          error={props.error}
          name="vehicleStates"
          onBlur={onValidate}
          onChange={vehicleStateGroup =>
            !Array.isArray(vehicleStateGroup) &&
            policy.setVehicleStatesFromGroup(vehicleStateGroup as VehicleStateGroupType)
          }
          options={generateEnumDropdownOptions(VehicleStateGroupEnum, 'VehicleStates')}
          placeholder={t('policiesLibrary.formVehicleStatesPlaceholder', 'Select vehicle states')}
          value={policy.vehicleStateGroup}
        />
      </HideableField>
    ) : (
      <VehicleStates policy={policy} {...props} />
    )
  }
)
