import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { getCurrentScope as getCurrentSentryScope } from '@sentry/react'

import PopulusLogoLink from '@/components/PopulusLogoLink'
import Shelf from '@/components/Shelf'
import TrialExpirationWarning from '@/components/TrialExpirationWarning'
import ImpersonatedUserBanner from '@/modules/authentication/ImpersonatedUserBanner'
import GlobalModal from '@/modules/globalModal'
import { publicBucket } from '@/modules/initialization/config'
import { useCurrentRegion } from '@/modules/urlRouting/hooks'
import ProductSelector from '@/modules/urlRouting/ProductSelector'
import RegionSelector from '@/modules/urlRouting/RegionSelector'
import RequireLayerStore from '@/modules/urlRouting/RequireLayerStore'

import LayoutTemplate from './Template'

import './NavigationLayout.scss'

const NavigationLayout = () => {
  const {
    data: { regionId, logoFilename },
  } = useCurrentRegion()

  useEffect(() => {
    getCurrentSentryScope().setTag('region', regionId)
  }, [regionId])

  const navBar = (
    <>
      <div className="left">
        <PopulusLogoLink />
      </div>
      <div className="center">
        <ProductSelector />
        <TrialExpirationWarning />
        <ImpersonatedUserBanner />
      </div>
      <div className="right">
        {logoFilename && (
          <img
            className="region-logo"
            crossOrigin="anonymous"
            src={`${publicBucket}/regions/${regionId}/${logoFilename}`}
          />
        )}
        <RegionSelector />
      </div>
    </>
  )

  return (
    <RequireLayerStore>
      <GlobalModal />
      <LayoutTemplate navBar={navBar}>
        <Shelf>
          <Outlet />
        </Shelf>
      </LayoutTemplate>
    </RequireLayerStore>
  )
}

export default NavigationLayout
