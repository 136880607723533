import { FC } from 'react'
import { ListItem, ListItemButton, ListItemText } from '@mui/material'

import { BottomSection } from '@/components/layouts/CurbsNav'
import CurbsNavCollapsibleSection from '@/components/layouts/CurbsNavCollapsibleSection'

interface CurbsNavDrawerSettingsHelpSectionProps {
  drawerOpen: boolean
  section: BottomSection
}

const CurbsNavDrawerSettingsHelpSection: FC<CurbsNavDrawerSettingsHelpSectionProps> = ({
  drawerOpen,
  section,
}) => {
  return (
    <CurbsNavCollapsibleSection drawerOpen={drawerOpen} icon={section.icon} title={section.title}>
      {section.items.map(item => (
        <ListItem key={item.title} disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            onClick={item.onClick ? item.onClick : undefined}
            sx={{
              height: '32px',
              px: 7,
            }}
          >
            <ListItemText primary={item.title} />
          </ListItemButton>
        </ListItem>
      ))}
    </CurbsNavCollapsibleSection>
  )
}

export default CurbsNavDrawerSettingsHelpSection
