import { MapProps } from 'react-map-gl'
import { Expression } from 'mapbox-gl'

import { MapFeatureState } from '@/modules/map/featureState'
import { transformPopulusTileRequest } from '@/modules/map/utils'

export const LINE_COLOR_FOR_CHOROPLETH_SHAPE = '#4d4b50'
export const FILL_OPACITY_CHOROPLETH = 0.35
export const MAP_HIGHLIGHT_COLOR = '#d0c613'
export const MAP_SELECTED_COLOR = '#d04913'

export const BASEMAP_STYLES = {
  LIGHT: 'mapbox/light-v9',
  REGULAR: 'populuscto/clcks4rk300dv14qtyjkods6o',
  SATELLITE: 'mapbox/satellite-v9',
}

export const DEFAULT_OPTIONS_REACT_MAP_GL = {
  mapStyle: 'mapbox://styles/' + BASEMAP_STYLES.REGULAR,
  logoPosition: 'bottom-right' as MapProps['logoPosition'],
  transformRequest: transformPopulusTileRequest,
  attributionControl: false,
  mapboxAccessToken: import.meta.env.VITE_MAPBOX_API_TOKEN,
  pitchWithRotate: false,
  dragRotate: false,
}

export const EXPRESSION_LINE_WIDTH_FOR_LINE_GEOMETRY: Expression = [
  'interpolate',
  ['linear'],
  ['zoom'],
  // zoom is 12 (or less) -> line width will be the following
  12,
  [
    'case',
    ['boolean', ['feature-state', MapFeatureState.HOVERED], false],
    6,
    ['boolean', ['feature-state', MapFeatureState.SELECTED], false],
    6,
    1.5,
  ],
  // zoom is 24 (or more) -> line width will be the following
  26,
  [
    'case',
    ['boolean', ['feature-state', MapFeatureState.HOVERED], false],
    8,
    ['boolean', ['feature-state', MapFeatureState.SELECTED], false],
    8,
    6,
  ],
]

export const EXPRESSION_LINE_WIDTH_FOR_CHOROPLETH_SHAPE: Expression = [
  'interpolate',
  ['linear'],
  ['zoom'],
  // zoom is 12 (or less) -> line width will be the following
  12,
  [
    'case',
    ['boolean', ['feature-state', MapFeatureState.HOVERED], false],
    3,
    ['boolean', ['feature-state', MapFeatureState.SELECTED], false],
    3,
    0.5,
  ],
  // zoom is 24 (or more) -> line width will be the following
  26,
  [
    'case',
    ['boolean', ['feature-state', MapFeatureState.HOVERED], false],
    4,
    ['boolean', ['feature-state', MapFeatureState.SELECTED], false],
    4,
    3,
  ],
]

// If you use this for paint and have a map legend, set the map legend opacity to
// FILL_OPACITY_CHOROPLETH so that the legend matches the map
export const EXPRESSION_FILL_OPACITY_FOR_CHOROPLETH_SHAPE: Expression = [
  'case',
  ['boolean', ['feature-state', MapFeatureState.HOVERED], false],
  0.75,
  ['boolean', ['feature-state', MapFeatureState.SELECTED], false],
  0.75,
  FILL_OPACITY_CHOROPLETH,
]

export const EXPRESSION_LINE_OPACITY_COLOR_FOR_CHOROPLETH_SHAPE: Expression = [
  'case',
  ['boolean', ['feature-state', MapFeatureState.HOVERED], false],
  1,
  ['boolean', ['feature-state', MapFeatureState.SELECTED], false],
  1,
  0.2,
]
