import { FC, useState } from 'react'
import classNames from 'classnames'

import {
  Icon,
  NavbarDropdown,
  NavbarDropdownItemLink,
  NavbarDropdownMenu,
  NavbarDropdownTrigger,
  Text,
} from '@/components/componentLibrary'
import {
  useCurbRegulationsLibraryAccess,
  useCurbTransactionsTableAccess,
} from '@/modules/permissions/pageAccessHooks'
import { useCurbFeesAndRevenueAccess } from '@/modules/permissions/pageAccessHooks'
import { useCurbActivityAccess } from '@/modules/permissions/pageAccessHooks'
import { useCurbRegulationsAccess } from '@/modules/permissions/pageAccessHooks'
import { useCurbMetricsAccess } from '@/modules/permissions/pageAccessHooks'
import { useCurbParkingInsightsAccess } from '@/modules/permissions/pageAccessHooks'
import { useCurrentProduct, useCurrentRegion } from '@/modules/urlRouting/hooks'
import { PATHS } from '@/modules/urlRouting/paths'
import { generatePathWithRegionId, useGetProductOptions } from '@/modules/urlRouting/utils'

import './index.scss'

export const useGetCurbsLandingPage = () => {
  const hasCurbMetricsAccess = useCurbMetricsAccess()
  const hasCurbActivityAccess = useCurbActivityAccess()
  const hasCurbRegulationsAccess = useCurbRegulationsAccess()
  const hasCurbFeesAndRevenuePageAccess = useCurbFeesAndRevenueAccess()
  const hasCurbParkingInsightsAccess = useCurbParkingInsightsAccess()
  const hasCurbTransactionsTableAccess = useCurbTransactionsTableAccess()
  const hasCurbRegulationsLibraryAccess = useCurbRegulationsLibraryAccess()

  if (hasCurbMetricsAccess) return PATHS.CURB.MANAGEMENT.METRICS
  if (hasCurbActivityAccess) return PATHS.CURB.MANAGEMENT.ACTIVITY
  if (hasCurbParkingInsightsAccess) return PATHS.CURB.PARKING_INSIGHTS.REVENUE
  if (hasCurbTransactionsTableAccess) return PATHS.CURB.PARKING_INSIGHTS.TRANSACTIONS
  if (hasCurbRegulationsLibraryAccess) return PATHS.CURB.REGULATIONS.LIBRARY
  if (hasCurbRegulationsAccess) return PATHS.CURB.REGULATIONS.MAP
  if (hasCurbFeesAndRevenuePageAccess) return PATHS.CURB.REVENUE.FEES_AND_REVENUE

  return PATHS.ROOT
}

export const useGetMobilityLandingPage = () => {
  return PATHS.MOBILITY.MAPS.LIVE
}

const ProductSelector: FC = () => {
  const currrentProduct = useCurrentProduct()
  const {
    data: { regionId },
  } = useCurrentRegion()
  const productOptions = useGetProductOptions()

  const [isOpen, setIsOpen] = useState(false)

  return (
    <NavbarDropdown
      className="product-selector"
      disabled={!regionId}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      trigger={
        <ProductSelectorTrigger
          open={isOpen}
          text={productOptions.find(option => option.product === currrentProduct)?.text}
        />
      }
    >
      <NavbarDropdownMenu>
        {regionId &&
          productOptions.map(({ product, landingPage, ...props }) => (
            <ProductSelectorItem
              active={currrentProduct === product}
              key={product}
              to={generatePathWithRegionId(landingPage, regionId)}
              {...props}
            />
          ))}
      </NavbarDropdownMenu>
    </NavbarDropdown>
  )
}

interface ProductSelectorItemProps {
  active?: boolean
  disabled?: boolean
  to: string
  text: string
}

const ProductSelectorItem: FC<ProductSelectorItemProps> = ({ active, disabled, text, to }) => {
  const isActive = active && !disabled
  return (
    <NavbarDropdownItemLink
      active={isActive}
      disabled={disabled}
      className={classNames('product-selector-item', isActive && 'active')}
      to={to}
    >
      <Text styleType="primary-light">{text}</Text>
      {isActive && (
        <Icon className="active-product-icon" icon="ProductSwitcherCheckmark" color="ocean-1" />
      )}
    </NavbarDropdownItemLink>
  )
}

interface ProductSelectorTriggerProps {
  open?: boolean
  text?: string
}

const ProductSelectorTrigger: FC<ProductSelectorTriggerProps> = ({ open, text }) => {
  return (
    <NavbarDropdownTrigger className="product-selector-trigger" open={open}>
      <Text styleType="title">{text}</Text>
      <div className="icon-background">
        <Icon
          className="product-selector-icon"
          color={open ? 'white' : 'ocean-1'}
          icon="ProductSwitcherControl"
        />
      </div>
    </NavbarDropdownTrigger>
  )
}

export default ProductSelector
