/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CDSLocationReference } from './CDSLocationReference'
import type { CDSPreviousPolicy } from './CDSPreviousPolicy'
/**
 * The existing CDSCurbZone class is a model that is a close to database representation.
 * This class is specifically for rendering the curb zone for the CDS API response.
 *
 * https://github.com/openmobilityfoundation/curb-data-specification/tree/main/curbs#curb-zone
 */
export type CDSCurbZoneForCDS = {
  curb_zone_id: string
  geometry?: Record<string, any>
  curb_policy_ids: Array<string>
  prev_policies?: Array<CDSPreviousPolicy>
  published_date: string
  last_updated_date: string
  prev_curb_zone_ids?: Array<string>
  start_date: string
  end_date?: string
  location_references?: Array<CDSLocationReference>
  name?: string
  user_zone_id?: string
  street_name?: string
  cross_street_start_name?: string
  cross_street_end_name?: string
  length?: number
  available_space_lengths?: Array<number>
  availability_time?: string
  width?: number
  parking_angle?: CDSCurbZoneForCDS.parking_angle
  num_spaces?: number
  street_side?: string
  median?: boolean
  entire_roadway?: boolean
  curb_area_ids?: Array<string>
  curb_space_ids?: Array<string>
}
export namespace CDSCurbZoneForCDS {
  export enum parking_angle {
    PARALLEL = 'parallel',
    PERPENDICULAR = 'perpendicular',
    ANGLED = 'angled',
  }
}
