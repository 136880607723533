import i18n from '@/modules/i18n/i18n'

export const getTranslatedMapControls = () => {
  const { t } = i18n

  return {
    'NavigationControl.ZoomIn': t('common.zoomIn', 'Zoom in'),
    'NavigationControl.ZoomOut': t('common.zoomOut', 'Zoom out'),
  } as const
}
