import { FC } from 'react'
import { Checkbox, Form, Loader } from 'semantic-ui-react'

import { formatText } from '@/common/utils/text'
import { EditUserRoleItem } from '@/modules/api/openapi/models/EditUserRoleItem'
import { Permission } from '@/modules/api/openapi/models/Permission'
import PermissionsGroup from '@/modules/permissions/adminPanel/PermissionsGroup'
import { UserRolesAndPermissionsForUser } from '@/modules/permissions/hooks'

interface EditUserPermissionsSectionProps extends UserRolesAndPermissionsForUser {
  onChangeUserPermissions: (editedPermissions: Permission[]) => void
  onChangeUserRoles: (editedUserRoles: EditUserRoleItem[]) => void
}

const EditUserPermissionsSection: FC<EditUserPermissionsSectionProps> = ({
  isRolesAndPermissionsReady,
  availableRolesWithPermissions,
  otherAvailablePermissions,
  currentRoles,
  currentPermissions,
  onChangeUserPermissions,
  onChangeUserRoles,
}) => {
  const _onChangeUserPermission = (permissionsKey: string, enable: boolean) => {
    const permissionsCopy = [...currentPermissions]
    const changedPermission = permissionsCopy.find(p => p.key === permissionsKey)!
    changedPermission.enabled = enable
    onChangeUserPermissions(permissionsCopy)
  }

  if (!isRolesAndPermissionsReady) return <Loader />
  return (
    <>
      {availableRolesWithPermissions.length > 0 && (
        <>
          <h5>User Roles & Permissions</h5>
          {availableRolesWithPermissions!.map(availableRole => {
            return (
              <PermissionsGroup
                key={availableRole.roleId}
                groupId={availableRole.roleId}
                groupLabel={formatText(availableRole.role)}
                groupEnabled={
                  !!currentRoles?.find(currentRole => currentRole.roleId === availableRole.roleId)
                    ?.enabled
                }
                groupPermissions={availableRole.permissionsKeys.map(keyInRole => ({
                  key: keyInRole,
                  enabled: !!currentPermissions.find(p => p.key === keyInRole)?.enabled,
                }))}
                onChangePermissionGroup={(groupKey, enable) => {
                  const userRolesCopy = [...currentRoles]
                  const changedRole = userRolesCopy.find(x => x.roleId === groupKey)!
                  changedRole.enabled = enable
                  onChangeUserRoles(userRolesCopy)
                }}
                onChangePermission={(permissionsKey, enable) => {
                  _onChangeUserPermission(permissionsKey, enable)
                }}
              />
            )
          })}
        </>
      )}
      {otherAvailablePermissions.length > 0 && (
        <>
          <h5>Other Permissions</h5>
          {otherAvailablePermissions.map(({ key, label }) => {
            const enabled = currentPermissions.find(p => p.key === key)?.enabled
            return (
              <Form.Field key={key}>
                <Checkbox
                  key={key}
                  label={label}
                  name={label}
                  onClick={() => {
                    _onChangeUserPermission(key, !enabled)
                  }}
                  checked={enabled}
                />
              </Form.Field>
            )
          })}
        </>
      )}
    </>
  )
}

export default EditUserPermissionsSection
