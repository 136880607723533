import { computed, makeObservable } from 'mobx'

export enum Frequency {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
}

export type PolicyComplianceNotificationDoc = {
  policy_compliance_notification_uuid: string
  email_address: string
  region_id: string
  operator_notifications: string[] | null
  frequency: Frequency
}

export class PolicyComplianceNotification {
  notification: PolicyComplianceNotificationDoc

  constructor(notification: PolicyComplianceNotificationDoc) {
    makeObservable(this, {
      uuid: computed,
      email: computed,
      regionId: computed,
      operatorNotifications: computed,
      frequency: computed,
    })
    this.notification = notification
  }

  get uuid(): string {
    return this.notification.policy_compliance_notification_uuid
  }

  get email(): string {
    return this.notification.email_address
  }

  get regionId(): string {
    return this.notification.region_id
  }

  get operatorNotifications(): string[] {
    return this.notification.operator_notifications == null
      ? ['all']
      : this.notification.operator_notifications
  }

  get frequency(): Frequency {
    return this.notification.frequency
  }
}
