import { ChangeEvent, forwardRef, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Input as SemanticInput, InputProps as SemanticInputProps } from 'semantic-ui-react'

import { Icon, IconName } from '../Icon'
import { Text } from '../Text'

import './index.scss'

export interface InputProps extends SemanticInputProps {
  className?: string
  editable?: boolean
  icon?: IconName
}
export interface FormattedInputProps extends Omit<InputProps, 'onChange'> {
  onAfterChange?: (value?: string) => void
}

const UneditableInput = ({ className, icon, value }: InputProps) => {
  return (
    <div className={classNames('populus-input uneditable', className)}>
      {icon && <Icon className="input-icon uneditable" color="black-1" icon={icon} />}
      <Text styleType="body">{value}</Text>
    </div>
  )
}

const Input = forwardRef<any, InputProps>(({ className, editable = true, icon, ...props }, ref) => {
  return editable ? (
    <SemanticInput
      fluid
      className={classNames('populus-input', className)}
      icon={icon ? <Icon className="input-icon" color="black-1" icon={icon} /> : undefined}
      iconPosition={icon ? 'left' : undefined}
      ref={ref}
      {...props}
    />
  ) : (
    <UneditableInput {...{ className, editable, icon, ...props }} />
  )
})

/**
 * An Input control for input that gets formatted after user entry. Stores the raw input and fires
 * onAfterChange when the input component loses focus, allowing the parent to format the value.
 */
export const FormattedInput = ({
  disabled,
  editable = true,
  onBlur,
  onAfterChange,
  value: formattedValue,
  ...props
}: FormattedInputProps) => {
  const [rawInputValue, setRawInputValue] = useState<string | undefined>('')

  // if "disabled" changes, discard the intermediate value
  useEffect(() => {
    setRawInputValue(undefined)
  }, [disabled])
  return (
    <Input
      data-testid="formatted-input"
      disabled={disabled}
      editable={editable}
      onFocus={() => {
        setRawInputValue(formattedValue)
      }}
      onBlur={(e: ChangeEvent, data: any) => {
        onAfterChange?.(rawInputValue || undefined)
        setRawInputValue(undefined)
        onBlur && onBlur(e, data)
      }}
      onChange={(e: ChangeEvent, data: any) => {
        setRawInputValue(data.value)
      }}
      value={rawInputValue !== undefined ? rawInputValue : formattedValue}
      {...props}
    />
  )
}

Input.displayName = 'Input'

export { Input }
