import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { animated, config, useSpring } from 'react-spring'
import { useIntercom } from 'react-use-intercom'
import { useHover, useResizeObserver } from 'usehooks-ts'

import { isoToDate } from '@/common/utils/date'
import { MILLISECONDS_IN_A_DAY } from '@/common/utils/time'
import { useCurrentPermissions } from '@/modules/user/hooks'

import './index.scss'

/**
 * Shows message indicating number of days until the subscription expires
 */
const TrialExpirationWarning: FC = () => {
  const hoverRef = useRef(null)
  const isHovered = useHover(hoverRef)
  const widthRef = useRef<HTMLDivElement>(null)
  const { width = 0 } = useResizeObserver({ ref: widthRef })
  const [forceExpanded, setForceExpanded] = useState(true)
  const { showNewMessage } = useIntercom()

  const { t } = useTranslation()
  const { data: permissions } = useCurrentPermissions()

  const hasSubscription =
    !!permissions?.organizationSettings.subscriptionStartDate &&
    !!permissions?.organizationSettings.subscriptionEndDate

  let daysUntilExpiration = -1
  const today = new Date()

  if (hasSubscription) {
    const subscriptionEndDate = isoToDate(permissions?.organizationSettings.subscriptionEndDate)
    if (subscriptionEndDate && subscriptionEndDate > today) {
      const endsInMilliseconds = subscriptionEndDate.getTime() - today.getTime()
      daysUntilExpiration = Math.round(endsInMilliseconds / MILLISECONDS_IN_A_DAY)
    }
  }

  const props = useSpring({ width: width, config: config.stiff })
  const textProps = useSpring({ to: { opacity: 1 }, from: { opacity: 0 }, config: config.stiff })

  // show whole message for the first 10 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setForceExpanded(false)
    }, 10000)
    return () => clearTimeout(timer)
  }, [])

  if (!hasSubscription) return null

  const daysMessage =
    isHovered || forceExpanded
      ? t(
          'trialExpirationWarning.daysUntilExpireExpanded',
          'Populus Subscription Expires In {{count}} Days',
          {
            count: daysUntilExpiration,
          }
        )
      : t('trialExpirationWarning.daysUntilExpire', '{{count}} Days', {
          count: daysUntilExpiration,
        })

  const message =
    daysUntilExpiration >= 0
      ? daysMessage
      : t('trialExpirationWarning.expired', 'Populus Subscription Has Expired')

  const intercomMessage =
    daysUntilExpiration >= 0
      ? t(
          'trialExpirationWarning.renewCurrent',
          'Hello Populus, can you help me renew my subscription? It’s expiring in {{count}} days.',
          {
            count: daysUntilExpiration,
          }
        )
      : t(
          'trialExpirationWarning.renewExpired',
          'Hello Populus, can you help me renew my expired subscription?'
        )

  return (
    <div className="trial-expiration-container">
      <animated.div ref={hoverRef} className="trial-expiration" style={props}>
        <animated.div className={'trial-expiration-text'} ref={widthRef} style={textProps}>
          {message}
        </animated.div>
      </animated.div>
      <div className="renew" onClick={() => showNewMessage(intercomMessage)}>
        {t('trialExpirationWarning.renew', 'Renew')}
      </div>
    </div>
  )
}

export default TrialExpirationWarning
