import { useCallback, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { csvFormat } from 'd3-dsv'
import saveAs from 'file-saver'

import { dateToISO } from '@/common/utils/date'
import { Keyable } from '@/common/utils/types'
import { PolicyTypeId } from '@/models/spatialPolicyTypes'
import { ComplianceStatus } from '@/modules/api/openapi/models/ComplianceStatus'
import { PolicySummary } from '@/modules/api/openapi/models/PolicySummary'
import { internalApi } from '@/modules/api/request'
import { useMdsOperators } from '@/modules/operator/hooks'
import { useCurrentRegion } from '@/modules/urlRouting/hooks'

import { PolicyType } from '../policyLibrary/utils/types'

import { useComplianceQueryParams } from './params'

export const POLICIES_COMPLIANCE_SUMMARY_ENDPOINT = 'policies/compliance/summary'

/**
 * Return policy data for given query param start_date, end_date, and operators.
 */
export const usePolicyComplianceSummaryWithQueryParams = () => {
  const [{ startDate, endDate }] = useComplianceQueryParams()
  const {
    data: { regionId },
  } = useCurrentRegion()
  const apiQueryParams = {
    startDate: dateToISO(startDate),
    endDate: dateToISO(endDate),
  }

  return useQuery({
    queryKey: [`/regions/${regionId}/${POLICIES_COMPLIANCE_SUMMARY_ENDPOINT}`, apiQueryParams],
    queryFn: async () =>
      await internalApi.policiesV1.getComplianceSummaries({
        regionId,
        ...apiQueryParams,
      }),
  })
}

/**
 * Returned policy compliance data filtered by policyStatuses and policyTypes
 */
export const useFilteredCompliancePolicies = () => {
  const [{ policyStatuses, policyTypes }] = useComplianceQueryParams()
  const { data: policiesData, isLoading, error } = usePolicyComplianceSummaryWithQueryParams()

  return useMemo(() => {
    const filteredData = policiesData?.filter(({ status, policyType }) => {
      if (policyStatuses && !policyStatuses.includes(status)) return false
      if (policyTypes && !policyTypes.includes(policyType as PolicyTypeId)) return false
      return true
    })
    return { data: filteredData, isLoading, error }
  }, [policiesData, isLoading, policyStatuses, policyTypes, error])
}

/**
 * Returns unique policyTypes and policyStatuses present in unfiltered policy compliance data.
 */
export const useAttributesFromCompliancePolicies = () => {
  const { data: policiesData, isLoading } = usePolicyComplianceSummaryWithQueryParams()
  return useMemo(() => {
    const policyTypeIds = new Set<PolicyTypeId>()
    const statuses = new Set<PolicySummary.status>()
    policiesData?.forEach(({ policyType, status }) => {
      policyTypeIds.add(policyType as PolicyTypeId)
      statuses.add(status)
    })
    const policyTypes = [...policyTypeIds].sort().map(id => PolicyType.fromValue(id))
    return { policyTypes, statuses: [...statuses].sort(), isLoading }
  }, [policiesData, isLoading])
}

/**
 * Returns operators that will used to create the table data.
 */
export const useOperatorsInTable = () => {
  const { data, isLoading } = useMdsOperators()
  const [{ operators }] = useComplianceQueryParams()
  const hasOperators = (data?.items ?? []).length > 0
  if (operators) {
    return {
      data: data?.items.filter(operator => operators.includes(operator.slug)),
      isLoading,
      hasOperators,
    }
  }
  return { data: data?.items, isLoading, hasOperators }
}

/**
 * Returns function for a user to download data in the table.
 */
export const useDownloadTable = () => {
  const { data: policies } = useFilteredCompliancePolicies()
  const { data: operators } = useOperatorsInTable()

  return useCallback(() => {
    const data = (policies ?? []).map(({ complianceData, policyName, policyType, status }) => {
      const row: Keyable = {
        name: policyName,
        type: policyType,
        status,
      }
      operators?.forEach(({ slug }) => {
        const operatorCompliance = complianceData?.[slug]
        let operatorComplianceText: string = operatorCompliance?.status ?? ''
        if (operatorCompliance?.status === ComplianceStatus.NON_COMPLIANT) {
          operatorComplianceText = `${operatorCompliance?.daysCount} Days ${operatorCompliance?.violationCount} ${operatorCompliance?.violationType}`
        }
        row[slug] = operatorComplianceText
      })
      return row
    })
    const text = csvFormat(data)
    const blob = new Blob([text], { type: 'text/csv' })
    saveAs(blob, `PolicyCompliance.csv`)
  }, [policies, operators])
}
