import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { observer } from 'mobx-react'

import { Dropdown } from '@/components/componentLibrary'
import { SpatialPolicy as Policy } from '@/models/spatialPolicy'
import { getCitywideLayerName } from '@/modules/layers/layerStore'

import { HideableField, SelectionTags } from './components'
import { EditableFieldProps } from '.'

const SHAPE_LAYER_TITLE = 'Associated Geography'

export const ShapeLayerUUID = observer(({ policy, ...props }: { policy: Policy }) => {
  const { t } = useTranslation()

  return (
    <HideableField
      titleText={t('policiesLibrary.formGeographyTitle', SHAPE_LAYER_TITLE)}
      {...props}
    >
      {policy.isEntireRegion ? (
        <SelectionTags values={[getCitywideLayerName()]} />
      ) : policy.shapeLayerName ? (
        <SelectionTags values={[policy.shapeLayerName]} />
      ) : null}
    </HideableField>
  )
})

export const EditableShapeLayerUUID = observer(
  ({ editable, onValidate, policy, ...props }: EditableFieldProps) => {
    const { t } = useTranslation()

    return editable ? (
      <HideableField
        editable
        titleText={t('policiesLibrary.formGeographyTitle', SHAPE_LAYER_TITLE)}
        {...props}
      >
        {policy.isEntireRegion ? (
          <SelectionTags values={[getCitywideLayerName()]} />
        ) : (
          <Dropdown
            error={props.error}
            name="shapeLayerUUID"
            onBlur={onValidate}
            onChange={uuid => !_.isArray(uuid) && policy.setShapeLayerUuid(uuid || null)}
            options={policy.shapeLayerOptions}
            placeholder={t(
              'policiesLibrary.formGeographyPlaceholder',
              'Define where the policy applies'
            )}
            value={policy.shapeLayerUUID || undefined}
          />
        )}
      </HideableField>
    ) : (
      <ShapeLayerUUID policy={policy} {...props} />
    )
  }
)
