/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type IssueResponse = {
  service_request_id: string
  status: IssueResponse.status
  status_notes?: string
  service_name: string
  service_code: string
  description: string
  requested_datetime: string
  updated_datetime: string
  address: string
  lat: number
  long: number
  media_url?: string
  service_request_url: string
}
export namespace IssueResponse {
  export enum status {
    OPEN = 'open',
    CLOSED = 'closed',
  }
}
