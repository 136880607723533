import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { List, ListItem, ListItemIcon, ListItemText, Stack } from '@mui/material'

interface CurbsNavCollapsibleSectionProps {
  drawerOpen: boolean
  icon: any
  title: string
  testId?: string
}

const CurbsNavCollapsibleSection: FC<PropsWithChildren<CurbsNavCollapsibleSectionProps>> = ({
  drawerOpen,
  icon,
  title,
  testId,
  children,
}) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!drawerOpen) setOpen(false)
  }, [drawerOpen])

  const hoverStyle = {
    '&:hover': { backgroundColor: '#F6F6F6' },
  }

  return (
    <List>
      <ListItem
        disablePadding
        sx={{ display: 'block', cursor: 'pointer', ...hoverStyle }}
        onClick={() => setOpen(curr => !curr)}
        data-testid={`curb-nav-section-${testId}`}
      >
        <Stack direction="row" alignItems="center" spacing={1.9} sx={{ px: 2.5, height: '36px' }}>
          <ListItemIcon
            sx={{
              minWidth: 0,
              justifyContent: 'center',
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={title}
            primaryTypographyProps={{ sx: { fontWeight: 600 } }}
            sx={[
              drawerOpen
                ? {
                    opacity: 1,
                  }
                : {
                    opacity: 0,
                  },
            ]}
          />
        </Stack>
      </ListItem>
      {open && <>{children}</>}
    </List>
  )
}

export default CurbsNavCollapsibleSection
