import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Banner } from '@/components/componentLibrary'
import {
  getImpersonatedUserEmail,
  stopImpersonatingUser,
} from '@/modules/authentication/impersonation'

import './ImpersonatedUserBanner.scss'

const ImpersonatedUserBanner: FC = () => {
  const impersonatedUserEmail = getImpersonatedUserEmail()
  const { t } = useTranslation()
  if (!impersonatedUserEmail) return null
  return (
    <Banner
      className="impersonated-user-banner"
      styleType={'high-urgency'}
      text={impersonatedUserEmail}
      buttonText={t('impersonatedUserBanner.endSession', 'End Session')}
      icon="Eye"
      buttonOnClick={() => {
        stopImpersonatingUser()
        window.location.reload()
      }}
    />
  )
}

export default ImpersonatedUserBanner
