import { FC, PropsWithChildren, useEffect } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'

import { PARAMS } from '@/modules/urlRouting/paths'
import appUIStore from '@/stores/appUIStore'

/**
 * Set regionId in the appUIStore when it changes in the path
 */
const SyncRegionIdParam: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation()
  const matchObject = matchPath(`/:${PARAMS.REGION_ID}/*`, location.pathname)
  const regionIdParam = matchObject?.params?.regionId

  useEffect(() => {
    if (regionIdParam) appUIStore.setRegionId(regionIdParam)

    return () => appUIStore.clearMetro()
  }, [regionIdParam])

  return children
}

export default observer(SyncRegionIdParam)
