import { useCallback, useEffect, useState } from 'react'
import {
  createSearchParams,
  matchPath,
  URLSearchParamsInit,
  useLocation,
  useNavigate as useNavigate_,
  useParams,
} from 'react-router-dom'
import {
  useInfiniteQuery,
  useQuery,
  useSuspenseQuery,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'
import _ from 'lodash'

import { RegionResponse } from '@/modules/api/openapi/models/RegionResponse'
import { queryClientInstance } from '@/modules/api/queryClient'
import { apiV2, internalApi } from '@/modules/api/request'
import { OpenApiQueryParamsOmitRegionId } from '@/modules/api/types'
import { getNextPageParam } from '@/modules/api/util'
import { RegionResponseExternal } from '@/modules/api/v2'
import { featureFlagsQueryObject } from '@/modules/featureFlags'
import { PATHS, SEGMENTS } from '@/modules/urlRouting/paths'
import { LocationState, NavigateOptionsWithParams } from '@/modules/urlRouting/types'
import { generatePathWithRegionId } from '@/modules/urlRouting/utils'
import { useCurrentUser } from '@/modules/user/hooks'

const REGIONS_PER_PAGE = 10

/**
 * @returns the current product path (i.e., mobility/*) selected in the app
 */
export const useProductPath = () => {
  const { pathname } = useLocation()
  return [PATHS.MOBILITY._ANY, PATHS.CURB._ANY, PATHS.PUBLIC._ANY].find(path =>
    matchPath(path, pathname)
  )
}

type CurrentProducts = 'mobility' | 'curb' | 'public' | undefined

export const useCurrentProduct: () => CurrentProducts = () => {
  const { pathname } = useLocation()
  return [SEGMENTS.MOBILITY, SEGMENTS.CURB, SEGMENTS.PUBLIC].find(path =>
    pathname.includes(path)
  ) as CurrentProducts
}

export const useRegions = (
  queryParams: OpenApiQueryParamsOmitRegionId<typeof internalApi.regions.getRegions> = {}
) => {
  return useInfiniteQuery({
    queryKey: [`/regions`, queryParams],
    queryFn: async ({ pageParam }) => {
      const regionsResponse = await internalApi.regions.getRegions({
        page: pageParam,
        size: REGIONS_PER_PAGE,
        ...queryParams,
      })

      regionsResponse.items.forEach(region =>
        queryClientInstance.setQueryData<RegionResponse>([`/regions/${region.regionId}`], region)
      )

      return regionsResponse
    },
    initialPageParam: 1,
    getNextPageParam,
  })
}

/**
 * Gets a region object if the user has access to it
 */
export const useRegion = (regionId?: string) => {
  return useQuery({
    queryKey: [`/regions/${regionId}`],
    queryFn: async () => await internalApi.regions.getRegionById({ regionId: regionId! }),
    enabled: !!regionId,
  })
}

/**
 * @returns Region associated with /:regionId param only if that user has access.
 * Throws an error if the user does not have access.
 * To force RegionReponse as the typed response, use this line:
 * useCurrentRegion<RegionResponse>()
 */
export const useCurrentRegion = <T = RegionResponse | RegionResponseExternal>() => {
  const { regionId } = useParams()
  const productPath = useCurrentProduct()

  if (!regionId) throw Error('Error retreiving regionId from url parameters')

  const externalQueryParams = {
    queryKey: [`/v2/regions/${regionId}`],
    queryFn: async () => await apiV2.regions.getRegionById({ regionId }),
  }

  const internalQueryParams = {
    queryKey: [`/regions/${regionId}`],
    queryFn: async () => await internalApi.regions.getRegionById({ regionId }),
  }

  return useSuspenseQuery(
    productPath === SEGMENTS.PUBLIC ? externalQueryParams : internalQueryParams
  ) as UseSuspenseQueryResult<T>
}

/**
 * Prefetch data to allow instant access to the data, rather than a loading screen
 * when accessing it later.
 */
export const usePrefetchedDataForLoggedInUser = () => {
  const { data: user } = useCurrentUser()

  useEffect(() => {
    if (!user) return

    Promise.all([
      queryClientInstance.prefetchQuery(featureFlagsQueryObject),
      queryClientInstance.prefetchQuery({
        queryKey: ['/users/me/permissions'],
        queryFn: async () => await internalApi.users.getCurrentUserPermissions(),
      }),
    ])
  }, [user])
}

/**
 * @returns location and unpacked location.state
 */
export const useLocationState = () => {
  const location = useLocation()
  const state = location.state as LocationState
  return { location, ...state }
}

/**
 * React Router useNavigate hook that replaces `:regionId` in provided path with current regionId
 */
export const useNavigate = () => {
  const navigate = useNavigate_()
  const {
    data: { regionId },
  } = useCurrentRegion()
  return useCallback(
    (
      to: string,
      { searchParams, ...options }: NavigateOptionsWithParams = {} as NavigateOptionsWithParams
    ) => {
      const pathname = generatePathWithRegionId(to, regionId)
      const search = searchParams
        ? createSearchParams(searchParams as URLSearchParamsInit).toString()
        : undefined
      navigate(_.omitBy({ pathname, search }, _.isUndefined), options)
    },
    [navigate, regionId]
  )
}

/**
 * Navigate to the provided location when the regionId path param changes
 */
export const useNavigateOnRegionChange = ({ to, replace }: { to: string; replace?: boolean }) => {
  const navigate = useNavigate_()
  const { regionId } = useParams()
  const [firstRegionId] = useState(regionId)
  const regionIdHasChanged = regionId !== firstRegionId
  useEffect(() => {
    if (regionIdHasChanged) navigate(generatePathWithRegionId(to, regionId), { replace })
  }, [navigate, regionId, regionIdHasChanged, to, replace])
}
