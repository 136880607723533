import { useEffect } from 'react'

const OauthLoginClosingPage = () => {
  useEffect(() => {
    window.opener.focus()
    window.opener.oauth2Callback(window.location.hash)
    window.close()
  }, [])
  return <div>Closing...</div>
}

export default OauthLoginClosingPage
