import {
  cloneDeep,
  flatten,
  get,
  groupBy,
  includes,
  isEmpty,
  isEqual,
  isNil,
  isNull,
  map,
  mapValues,
  pull,
  sortBy,
} from 'lodash'
import { DateTime } from 'luxon'
import { action, computed, makeObservable, observable, toJS } from 'mobx'

import { renderDate } from '@/common/utils/date'
import { fromHHMM, toHHMM } from '@/common/utils/time'
import { MdsVehicleState, Time } from '@/common/utils/types'
import i18n from '@/modules/i18n/i18n'
import layerStore, {
  CITYWIDE_LAYER_UUID,
  getCitywideLayerName,
  getCitywideLayerOption,
} from '@/modules/layers/layerStore'
import {
  PolicyGroup,
  PolicyTimeFrameType,
  PolicyType,
} from '@/modules/policiesV1/policyLibrary/utils/types'
import appUIStore from '@/stores/appUIStore'

import {
  Allocation,
  Day,
  DropdownOption,
  FeeProperties,
  FeePropertiesPrices,
  ParkingFee,
  ParkingFeeVariablePricesSchedule,
  SpatialPolicyDocument,
  Unit,
  VehicleCount,
  VehicleStateGroupType,
} from './spatialPolicyTypes'
import { VariablePriceDuration } from './variablePriceDuration'
import { VariablePriceSchedule } from './variablePriceSchedule'

// this is used as a type guard
const dayOfWeekList = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'weekday',
  'all',
]

// this is used for sorting
const dayOfWeekOrdinals = (day: string): number | undefined => {
  if (day === 'sunday') return 0
  if (day === 'monday') return 1
  if (day === 'tuesday') return 2
  if (day === 'wednesday') return 3
  if (day === 'thursday') return 4
  if (day === 'friday') return 5
  if (day === 'saturday') return 6
}

type PropulsionType = 'human' | 'electric_assist' | 'electric' | 'combustion'

type PricesByDuration = {
  price: number
  end_duration: number | null
  start_duration: number | null
}

type ParkingFeeVariablePricesDuration = {
  startDuration: number | null
  endDuration: number | null
  price: number
}

const feePropertiesJson = (policy: SpatialPolicy) => {
  const hasFeeProperties = policy.parkingFeeType || policy.vehicleCountMethod
  const prices =
    policy.parkingFeeType === 'variable_price_schedule'
      ? { prices: policy.variablePriceSchedule?.json }
      : {}
  const pricesByDuration =
    policy.parkingFeeType === 'variable_price_parking_duration'
      ? { prices_by_duration: policy.variablePriceDuration?.json }
      : {}
  return hasFeeProperties ? { ...policy.feeProperties, ...prices, ...pricesByDuration } : undefined
}

/*
DEFINE SPATIAL POLICY
*/
export class SpatialPolicy {
  policy

  isDisabled = false // disable when saving or modifying

  variablePriceSchedule?: VariablePriceSchedule
  variablePriceDuration?: VariablePriceDuration

  constructor(policy: SpatialPolicyDocument = {} as SpatialPolicyDocument) {
    makeObservable(this, {
      policy: observable,
      isDisabled: observable,
      variablePriceSchedule: observable,
      variablePriceDuration: observable,
      shapeUUIDs: computed,
      addShapeUUID: action,
      removeShapeUUID: action,
      regionId: computed,
      policyType: computed,
      isFeePolicyType: computed,
      policyUUID: computed,
      constantPolicyUUID: computed,
      policyName: computed,
      agencyName: computed,
      priority: computed,
      importedSource: computed,
      policyCategory: computed,
      description: computed,
      unit: computed,
      allocation: computed,
      published: computed,
      operators: computed,
      propulsionTypes: computed,
      vehicleTypes: computed,
      vehicleStates: computed,
      vehicleStateGroup: computed,
      minimum: computed,
      maximum: computed,
      value: computed,
      feeProperties: computed,
      parkingFeeVariablePricesSchedule: computed,
      parkingFeeVariablePricesDuration: computed,
      startTime: computed,
      endTime: computed,
      days: computed,
      messages: computed,
      shapeLayerUUID: computed,
      isShapeLayerCitywide: computed,
      startDate: computed,
      endDate: computed,
      isExpired: computed,
      dateRangeStr: computed,
      createdByEmail: computed,
      publishedTime: computed,
      optionalEntireRegion: computed,
      optionalOvernight: computed,
      timeDaySelectionIsValid: computed,
      priceStructure: computed,
      riderMessageIsValid: computed,
      grouping: computed,
      isEntireRegion: computed,
      hasUnits: computed,
      useStartDate: computed,
      useEndDate: computed,
      awayTime: computed,
      setIsDisabled: action,
      setCreatedByEmail: action,
      setPolicyUUID: action,
      setConstantPolicyUUID: action,
      setStartDate: action,
      setEndDate: action,
      checkDates: action,
      setDays: action,
      setShapeLayerUuid: action,
      setPublished: action,
      setAllocation: action,
      setPriority: action,
      setCurbMetadata: action,
      setPolicyName: action,
      setAgencyName: action,
      setFeeProperties: action,
      setDescription: action,
      setValue: action,
      setUnit: action,
      setMaximum: action,
      setMinimum: action,
      setOperators: action,
      setVehicleTypes: action,
      setVehicleStatesFromGroup: action,
      setStartTime: action,
      setEndTime: action,
      setMessages: action,
      setPolicyType: action,
      setAsDuplicate: action,
      json: computed,
      setVehicleCountMethod: action,
      setPriceStructure: action,
      setVariablePriceSchedule: action,
      setVariablePriceDuration: action,
      variablePrices: computed,
      minMaxUnitLabel: computed,
      pricingUnit: computed,
      policyGroup: computed,
      parkingFeeType: computed,
      vehicleCountMethod: computed,
      status: computed,
      isPublishable: computed,
      shapeLayerOptions: computed,
      shapeLayerName: computed,
      toJson: computed,
      tableValues: computed,
    })

    this.policy = policy
    // the below incorporates new models for variable prices
    if (get(this.policy.fee_properties, 'prices')) {
      this.variablePriceSchedule = new VariablePriceSchedule(this.policy.fee_properties?.prices)
    }
    if (get(this.policy.fee_properties, 'prices_by_duration')) {
      this.variablePriceDuration = new VariablePriceDuration(
        this.policy.fee_properties?.prices_by_duration
      )
    }
  }

  /*
  DEFAULT VALUES
  */

  get shapeUUIDs(): string[] | null {
    return toJS(this.policy.shape_uuids) || null
  }

  /**
   * Add the shape uuid to the list of shape uuids associated with this policy
   * @param shapeUUID
   * @returns boolean true if the list was updated, false if it was already in the list
   */
  addShapeUUID(shapeUUID: string): boolean {
    if (this.policy.shape_uuids === null) {
      this.policy.shape_uuids = [shapeUUID]
      return true
    } else if (includes(this.policy.shape_uuids, shapeUUID)) {
      return false
    } else {
      this.policy.shape_uuids.push(shapeUUID)
      return true
    }
  }

  /**
   * Remove the shape uuid to the list of shape uuids associated with this policy
   * @param shapeUUID
   * @returns boolean true if the list was updated, false if it was not in the list
   */
  removeShapeUUID(shapeUUID: string): boolean {
    if (this.policy.shape_uuids === null) {
      return false
    } else if (!includes(this.policy.shape_uuids, shapeUUID)) {
      return false
    } else {
      this.policy.shape_uuids = pull(this.policy.shape_uuids, shapeUUID)
      return true
    }
  }

  get regionId(): string {
    return appUIStore.metro.regionId
  }

  get policyType(): PolicyType {
    return PolicyType.fromValue(this.policy.policy_type)
  }

  get isFeePolicyType(): boolean {
    switch (this.policyType) {
      case PolicyType.VEHICLE_FEES:
      case PolicyType.PARKING_FEES:
      case PolicyType.TRIP_FEES:
        return true
      default:
        return false
    }
  }

  get policyUUID(): string | undefined {
    return this.policy.policy_uuid
  }

  get constantPolicyUUID(): string | undefined {
    return this.policy.constant_policy_uuid
  }

  get policyName(): string {
    return this.policy.policy_name || ''
  }

  get agencyName(): string {
    return this.policy.agency_name || ''
  }

  get priority(): string {
    return this.policy.priority || ''
  }

  get importedSource(): string {
    return this.policy.imported_source || ''
  }

  get policyCategory(): string {
    return this.policy.policy_category || 'mobility'
  }

  get description(): string {
    return this.policy.description || ''
  }

  get unit(): Unit {
    return this.policy.unit
  }

  get allocation(): Allocation | null {
    return this.policy.allocation
  }

  get published(): boolean {
    return this.policy.published
  }

  get operators(): string[] | undefined {
    return this.policy?.operators || undefined
  }

  get propulsionTypes(): PropulsionType[] | undefined {
    if (!isEmpty(this.policy.propulsion_types)) return this.policy.propulsion_types
    return undefined
  }

  get vehicleTypes(): string[] | undefined {
    if (!isEmpty(this.policy.vehicle_types)) return this.policy.vehicle_types
    return undefined
  }

  get vehicleStates(): MdsVehicleState[] | null {
    return this.policy.vehicle_states
  }

  get vehicleStateGroup(): VehicleStateGroupType | undefined {
    if (this.vehicleStates === null) return 'all'
    if (isEqual(this.vehicleStates, ['available', 'reserved', 'on_trip'])) return 'operational'
    if (isEqual(this.vehicleStates, ['non_operational'])) return 'non_operational'
    return undefined
  }

  get minimum(): number | null {
    return this.policy.minimum
  }

  get maximum(): number | null {
    return this.policy.maximum
  }

  get value(): number | null {
    return this.policy.value || this.policy.value === 0 ? this.policy.value : null
  }

  get feeProperties(): FeeProperties {
    return this.policy.fee_properties || {}
  }

  get parkingFeeVariablePricesSchedule(): ParkingFeeVariablePricesSchedule[] {
    return observable(
      this.policy.fee_properties?.prices
        ? this.flattenSortFeeVariablePricesSchedule(this.policy.fee_properties.prices)
        : []
    )
  }

  get parkingFeeVariablePricesDuration(): ParkingFeeVariablePricesDuration[] {
    return observable(
      this.policy.fee_properties?.prices_by_duration
        ? this.flattenSortFeeVariablePricesDuration(this.policy.fee_properties.prices_by_duration)
        : []
    )
  }

  get startTime(): string | undefined {
    return this.policy.start_time
  }

  get endTime(): string | undefined {
    return this.policy.end_time === '23:59:59' ? '00:00:00' : this.policy.end_time
  }

  get days(): number[] | undefined {
    if (!isEmpty(this.policy.days)) return this.policy.days
    return undefined
  }

  get messages(): { [key: string]: string } | undefined {
    if (!isEmpty(this.policy.messages)) return this.policy.messages
    return undefined
  }

  get shapeLayerUUID(): string | null {
    // Some policy types have an option to be the entire region, which  is stored in the DB as NULL
    // We represent this selection with a string even though in the db it is NULL
    // to distinguish between policies with a default selection and new policies
    // where the user still needs to select a shapeLayerUUID
    return this.optionalEntireRegion && !this.policy.shape_layer_uuid
      ? CITYWIDE_LAYER_UUID
      : this.policy.shape_layer_uuid
  }

  get isShapeLayerCitywide(): boolean {
    return !this.shapeLayerUUID || this.shapeLayerUUID === CITYWIDE_LAYER_UUID
  }

  get startDate(): DateTime | undefined {
    if (this.policy.start_date) return DateTime.fromISO(this.policy.start_date)
    return undefined
  }

  get endDate(): DateTime | undefined {
    if (this.policy.end_date) return DateTime.fromISO(this.policy.end_date)
    return undefined
  }

  get isExpired(): boolean {
    return isNil(this.endDate) ? false : this.endDate.toMillis() - Date.now() < 0
  }

  get dateRangeStr(): string {
    if (this.startDate && this.endDate) {
      return `${renderDate(this.startDate, DateTime.DATE_SHORT)} - ${renderDate(
        this.endDate,
        DateTime.DATE_SHORT
      )}`
    } else if (this.startDate && !this.endDate) {
      return `${renderDate(this.startDate, DateTime.DATE_SHORT)} - No End Date`
    } else if (!this.startDate && this.endDate) {
      return `No Start Date  - ${renderDate(this.endDate, DateTime.DATE_SHORT)}`
    }
    return 'No start or end date'
  }

  get createdByEmail(): string {
    return this.policy.created_by_email || ''
  }

  get publishedTime(): DateTime | undefined {
    if (this.policy.published_time) return DateTime.fromISO(this.policy.published_time)
    return undefined
  }

  get optionalEntireRegion(): boolean {
    // these are policies where entire region is an option but it's also possible to use layers
    // changing this requires updating web/fastapi/app/api/internal/endpoints/policies_routes.py
    switch (this.policyType) {
      case PolicyType.NO_RIDE:
      case PolicyType.NO_PARKING:
      case PolicyType.PARKING_TIME_LIMIT:
        return true
      default:
        return false
    }
  }

  get optionalOvernight(): boolean {
    // any policy other than Daily (i.e Hourly and Minute) supports optional overnight
    return this.policyType.timeframeType !== PolicyTimeFrameType.DAYS
  }

  get isOvernight(): boolean {
    return (
      this.optionalOvernight && !!this.startTime && !!this.endTime && this.startTime > this.endTime
    )
  }

  get timeDaySelectionIsValid(): boolean {
    switch (this.policyType) {
      case PolicyType.VEHICLE_CAP:
      case PolicyType.PARKING_TIME_LIMIT:
      case PolicyType.UTILIZATION:
      case PolicyType.TRIP_FEES:
      case PolicyType.VEHICLE_FEES:
        return false
      default:
        return this.priceStructure !== 'variable_price_schedule'
    }
  }

  get priceStructure(): ParkingFee | undefined {
    return this.feeProperties?.parking_fee_type
  }

  get riderMessageIsValid(): boolean {
    switch (this.policyType) {
      case PolicyType.VEHICLE_CAP:
      case PolicyType.UTILIZATION:
      case PolicyType.PARKING_TIME_LIMIT:
      case PolicyType.DISTRIBUTION:
      case PolicyType.OPERATOR_DROP_OFFS:
      case PolicyType.TRIP_FEES:
      case PolicyType.VEHICLE_FEES:
      case PolicyType.PARKING_FEES:
        return false
      default:
        return true
    }
  }

  get grouping(): 'vehicle_type_and_operators' | 'operators' {
    // used in the trips and vehicle counts dashboard to turn a policy into a group by
    if (this.operators && this.vehicleTypes) return 'vehicle_type_and_operators'
    if (this.vehicleTypes) return 'vehicle_type_and_operators'
    return 'operators'
  }

  get isEntireRegion(): boolean {
    // geography is comprised of entire region
    switch (this.policyType) {
      case PolicyType.VEHICLE_CAP:
      case PolicyType.UTILIZATION:
      case PolicyType.TRIP_FEES:
      case PolicyType.VEHICLE_FEES:
        return true
      default:
        return false
    }
  }

  get hasUnits(): boolean {
    return !!this.unit
  }

  get useStartDate(): boolean {
    return !!this.startDate
  }

  get useEndDate(): boolean {
    return !!this.endDate
  }

  get awayTime(): number | undefined {
    return this.policy.curb_metadata?.away_time
  }

  flattenSortFeeVariablePricesSchedule(
    pricesMap: FeePropertiesPrices
  ): ParkingFeeVariablePricesSchedule[] {
    const schedulePricesArray = map(pricesMap, (prices, day) => {
      if (!dayOfWeekList.includes(day)) return []
      return map(prices, p => {
        return {
          day: day as Day,
          startTime: fromHHMM(p.start_time),
          endTime: fromHHMM(p.end_time),
          price: p.price,
        }
      })
    })
    const schedulePricesArrayFlattened = flatten(schedulePricesArray).filter(Boolean)
    const schedulePricesMapSorted = sortBy(schedulePricesArrayFlattened, ({ day, startTime }) => {
      return [dayOfWeekOrdinals(day), startTime]
    })
    return schedulePricesMapSorted
  }

  flattenSortFeeVariablePricesDuration(
    durationPricesMap: PricesByDuration[]
  ): ParkingFeeVariablePricesDuration[] {
    const durationPricesArray = map(durationPricesMap, price => {
      return {
        startDuration: price.start_duration,
        endDuration: price.end_duration,
        price: price.price,
      }
    })
    const durationPricesMapSorted = sortBy(durationPricesArray, 'startDuration')
    return durationPricesMapSorted
  }

  /*
  SET VALUES
  */

  setIsDisabled(value: boolean) {
    this.isDisabled = value
  }

  setCreatedByEmail(value: SpatialPolicyDocument['created_by_email']) {
    this.policy.created_by_email = value
  }

  setPolicyUUID(value?: SpatialPolicyDocument['policy_uuid']) {
    this.policy.policy_uuid = value
  }

  setConstantPolicyUUID(value?: SpatialPolicyDocument['constant_policy_uuid']) {
    this.policy.constant_policy_uuid = value
  }

  setStartDate(value: DateTime | undefined) {
    this.policy.start_date = value ? (value.toISODate() ?? undefined) : value
  }

  setEndDate(value: DateTime | undefined) {
    this.policy.end_date = value ? (value.toISODate() ?? undefined) : value
  }

  setDefaultStartDate() {
    if (this.useEndDate) {
      this.setStartDate(this.endDate)
    } else {
      this.setStartDate(appUIStore.metro.yesterday)
    }
  }

  setDefaultEndDate() {
    if (this.startDate) {
      this.setEndDate(this.startDate)
    } else {
      this.setEndDate(appUIStore.metro.today)
    }
  }

  checkDates = () => {
    if (this.useEndDate && this.endDate && this.startDate && this.endDate < this.startDate)
      this.setEndDate(this.startDate)
  }

  setDays(value: SpatialPolicyDocument['days']) {
    this.policy.days = value
  }

  setShapeLayerUuid(value: SpatialPolicyDocument['shape_layer_uuid']) {
    this.policy.shape_layer_uuid = value
  }

  setPublished(value: SpatialPolicyDocument['published']) {
    this.policy.published = value
  }

  setAllocation(value: SpatialPolicyDocument['allocation']) {
    this.policy.allocation = value
  }

  setPriority(value: SpatialPolicyDocument['priority']) {
    this.policy.priority = value
  }

  setCurbMetadata(away_time: number | null) {
    const curb_metadata = {} as SpatialPolicyDocument['curb_metadata']
    if (away_time !== null) curb_metadata.away_time = away_time
    this.policy.curb_metadata = curb_metadata
  }

  setPolicyName(value: SpatialPolicyDocument['policy_name']) {
    this.policy.policy_name = value
  }

  setAgencyName(value: SpatialPolicyDocument['agency_name']) {
    this.policy.agency_name = value
  }

  setFeeProperties(value: SpatialPolicyDocument['fee_properties']) {
    this.policy.fee_properties = value
  }

  setDescription(value: SpatialPolicyDocument['description']) {
    this.policy.description = value
  }

  setValue(value: SpatialPolicyDocument['value']) {
    this.policy.value = value
  }

  setUnit(value: SpatialPolicyDocument['unit']) {
    this.policy.unit = value
  }

  setMaximum(value: SpatialPolicyDocument['maximum']) {
    this.policy.maximum = value
  }

  setMinimum(value: SpatialPolicyDocument['minimum']) {
    this.policy.minimum = value
  }

  setOperators(value: SpatialPolicyDocument['operators']) {
    this.policy.operators = value
  }

  setVehicleTypes(value: SpatialPolicyDocument['vehicle_types']) {
    this.policy.vehicle_types = value
  }

  /**
   * Sets vehicle state filters from provided vehicle state groupings (e.g., 'operational')
   * @param value - vehicle state group that the user has selected
   */
  setVehicleStatesFromGroup(value: VehicleStateGroupType) {
    switch (value) {
      case 'all':
        this.policy.vehicle_states = null
        break
      case 'operational':
        this.policy.vehicle_states = ['available', 'reserved', 'on_trip']
        break
      case 'non_operational':
        this.policy.vehicle_states = ['non_operational']
        break
    }
  }

  setStartTime(value: SpatialPolicyDocument['start_time']) {
    this.policy.start_time = value
  }

  setEndTime(value: SpatialPolicyDocument['end_time']) {
    this.policy.end_time = value
  }

  setMessages(value: SpatialPolicyDocument['messages']) {
    this.policy.messages = value
  }

  setPolicyType(policyType: SpatialPolicyDocument['policy_type']) {
    this.policy.policy_type = policyType

    // clear certain fields when switching
    this.setMinimum(null)
    this.setMaximum(null)
    if (policyType !== PolicyType.DISTRIBUTION.id) {
      this.setAllocation(null)
    }

    if (policyType === PolicyType.PARKING_TIME_LIMIT.id) {
      this.setUnit('hours')
    } else if (policyType === PolicyType.SLOW_RIDE.id) {
      this.setUnit(Intl.DateTimeFormat().resolvedOptions().locale === 'en-US' ? 'mph' : 'kph')
    }

    if (policyType === PolicyType.PARKING_FEES.id) {
      this.setPriceStructure('fixed_price_per_event')
    }

    if (!this.timeDaySelectionIsValid) {
      this.setDays(undefined)
      this.setStartTime(undefined)
      this.setEndTime(undefined)
    }
  }

  setAsDuplicate() {
    this.setPolicyUUID()
    this.setConstantPolicyUUID()
    this.setPolicyName(`${this.policyName} (copy)`)
    this.setPublished(false)
  }

  convertPricesToDatabaseModel(variablePriceMethod?: 'variablePriceBySchedule'): FeeProperties {
    const feeProperties: FeeProperties = cloneDeep(this.feeProperties)
    if (variablePriceMethod === 'variablePriceBySchedule') {
      // cast to FeePropertiesPrices since we know the object will have keys in type Day
      const groupedByDayOfWeek = mapValues(
        groupBy(this.parkingFeeVariablePricesSchedule, 'day'),
        prices =>
          prices.map(p => {
            return {
              start_time: toHHMM(p.startTime as Time),
              end_time: toHHMM(p.endTime as Time),
              price: parseFloat(p.price?.toString()),
            }
          })
      ) as unknown as FeePropertiesPrices
      feeProperties.prices = groupedByDayOfWeek
    } else {
      const feesByDuration = this.parkingFeeVariablePricesDuration.map(prices => {
        const startDuration = prices.startDuration
          ? parseFloat(prices.startDuration.toString())
          : null
        const endDuration = prices.endDuration ? parseFloat(prices.endDuration.toString()) : null
        return {
          start_duration: startDuration,
          end_duration: endDuration,
          price: parseFloat(prices.price.toString()),
        }
      })
      feeProperties.prices_by_duration = feesByDuration
    }
    return feeProperties
  }

  get json() {
    const { hasUnits, isEntireRegion, timeDaySelectionIsValid, riderMessageIsValid } = this

    return {
      policy_type: this.policyType.id,
      policy_uuid: this.policyUUID === 'new_policy' ? undefined : this.policyUUID,
      constant_policy_uuid: this.constantPolicyUUID,
      policy_name: this.policyName,
      description: this.description,
      shape_layer_uuid:
        !isEntireRegion && this.shapeLayerUUID !== CITYWIDE_LAYER_UUID ? this.shapeLayerUUID : null,
      shape_uuids: this.shapeUUIDs,
      unit: hasUnits ? this.unit : null,
      allocation: this.allocation,
      operators: !isEmpty(this.operators) ? toJS(this.operators) : null,
      vehicle_types: !isEmpty(this.vehicleTypes) ? toJS(this.vehicleTypes) : null,
      vehicle_states: !isEmpty(this.vehicleStates) ? toJS(this.vehicleStates) : null,
      propulsion_types: !isEmpty(this.propulsionTypes) ? toJS(this.propulsionTypes) : null,
      minimum:
        this.policyType.basedOnVehicleCounts && this.minimum && this.minimum > 0
          ? +this.minimum
          : null,
      maximum:
        (this.policyType.basedOnVehicleCounts || hasUnits) && !isNull(this.maximum)
          ? +this.maximum
          : null,
      value: !isNull(this.value) ? +this.value : null,
      fee_properties:
        this.parkingFeeVariablePricesSchedule.length > 0
          ? this.convertPricesToDatabaseModel('variablePriceBySchedule')
          : this.parkingFeeVariablePricesDuration.length > 0
            ? this.convertPricesToDatabaseModel()
            : this.feeProperties,
      start_time: timeDaySelectionIsValid && this.startTime !== '00:00:00' ? this.startTime : null,
      end_time: timeDaySelectionIsValid && this.endTime !== '00:00:00' ? this.endTime : null,
      days: timeDaySelectionIsValid && this.days ? toJS(this.days) : undefined,
      messages: riderMessageIsValid && this.messages ? toJS(this.messages) : undefined,
      start_date: this.startDate ? this.startDate.toISODate() : null,
      end_date: this.endDate ? this.endDate.toISODate() : null,
      published: this.published,
      agency_name: this.agencyName,
      policy_category: this.policyCategory,
      curb_metadata: toJS(this.policy.curb_metadata),
    }
  }

  /**
   * The below are additions to the model for the policies table \
   * After the policy map is removed the above and below code can be consolidated
   */

  setVehicleCountMethod(value?: VehicleCount) {
    this.setFeeProperties({ vehicle_count_method: value })
  }

  setPriceStructure(value: SpatialPolicyDocument['fee_properties']['parking_fee_type']) {
    this.setFeeProperties({ parking_fee_type: value })
    this.variablePriceSchedule = undefined
    if (value === 'variable_price_schedule') this.setVariablePriceSchedule()
    if (value === 'variable_price_parking_duration') this.setVariablePriceDuration()
  }

  setVariablePriceSchedule() {
    this.variablePriceSchedule = new VariablePriceSchedule()
  }

  setVariablePriceDuration() {
    this.variablePriceDuration = new VariablePriceDuration()
  }

  get variablePrices(): VariablePriceSchedule | VariablePriceDuration | undefined {
    if (this.parkingFeeType === 'variable_price_parking_duration') return this.variablePriceDuration
    else if (this.parkingFeeType === 'variable_price_schedule') return this.variablePriceSchedule
    else return undefined
  }

  get minMaxUnitLabel(): string | undefined {
    const { t } = i18n

    switch (this.policyType) {
      case PolicyType.DISTRIBUTION:
        return t('policiesLibrary.unitLabelVehicles', 'Vehicles')
      case PolicyType.OPERATOR_DROP_OFFS:
        return t('policiesLibrary.unitLabelVehiclesPerOperator', 'Vehicles per Operator')
      case PolicyType.UTILIZATION:
        return t('policiesLibrary.unitLabelTripsPerVehicle', 'Trips per Vehicle')
      case PolicyType.VEHICLE_CAP:
        return t('policiesLibrary.unitLabelVehiclesPerOperator', 'Vehicles per Operator')
      default:
        return undefined
    }
  }

  get pricingUnit():
    | '$ per vehicle per day'
    | '$ per trip'
    | '$ per event'
    | '$ per hour'
    | undefined {
    switch (this.policyType) {
      case PolicyType.VEHICLE_FEES:
        return '$ per vehicle per day'
      case PolicyType.TRIP_FEES:
        return '$ per trip'
      case PolicyType.PARKING_FEES:
        if (this.parkingFeeType === 'fixed_price_per_event') return '$ per event'
        if (this.parkingFeeType) return '$ per hour'
    }
    return undefined
  }

  get policyGroup(): PolicyGroup {
    return this.policyType._group
  }

  get parkingFeeType(): ParkingFee | undefined {
    return this.feeProperties?.parking_fee_type
  }

  get vehicleCountMethod(): VehicleCount | undefined {
    return this.feeProperties?.vehicle_count_method
  }

  get statusText() {
    const { t } = i18n

    if (this.isExpired) return t('policiesLibrary.statusExpired', 'Expired')
    if (this.published) return t('policiesLibrary.statusPublished', 'Published')
    return t('policiesLibrary.statusSaved', 'Saved')
  }

  get status(): 'expired' | 'published' | 'saved' {
    if (this.isExpired) return 'expired'
    if (this.published) return 'published'
    return 'saved'
  }

  get isPublishable() {
    // variable fees can't be published
    return !(this.status === 'expired')
  }

  get shapeLayerOptions() {
    let options = layerStore.availableLayerOptions
    // add / remove citywide option depending on the policy type
    if (
      this.optionalEntireRegion &&
      !isEmpty(options) &&
      options[0]?.value !== CITYWIDE_LAYER_UUID
    ) {
      options.unshift(getCitywideLayerOption())
    } else if (!this.optionalEntireRegion) {
      options = options.filter(({ value }) => value !== CITYWIDE_LAYER_UUID)
    }
    return options
  }

  get shapeLayerName() {
    if (this.shapeLayerUUID) {
      return this.shapeLayerUUID === CITYWIDE_LAYER_UUID
        ? getCitywideLayerOption().text
        : layerStore.getLayer(this.shapeLayerUUID)?.layerName
    }
    return undefined
  }

  /**
   * JSON export with changes for the policies redesign
   */
  get toJson() {
    return {
      ...this.json,
      fee_properties: feePropertiesJson(this),
    }
  }

  get tableValues() {
    const {
      constantPolicyUUID,
      days,
      endDate,
      isDisabled,
      isPublishable,
      policyGroup,
      policyName,
      policyUUID,
      policyType,
      published,
      startDate,
      status,
    } = this
    return {
      constantPolicyUUID,
      days,
      endDate,
      // empty shapeLayerUUID === default citywide
      geographyName: layerStore.getLayer(this.shapeLayerUUID)?.layerName || getCitywideLayerName(),
      isDisabled,
      isPublishable,
      policyName,
      policyGroup,
      policyUUID,
      policyType,
      published,
      startDate,
      status,
    }
  }
}
