import googleAnalytics from '@analytics/google-analytics'
import Analytics from 'analytics'

const analytics = Analytics({
  app: 'Populus Terminal',
  debug: import.meta.env.DEV,
  version: import.meta.env.VITE_REACT_BUNDLE_VERSION,
  plugins: [
    googleAnalytics({
      measurementIds: [import.meta.env.VITE_GOOGLE_ANALYTICS_4],
      enabled: !import.meta.env.DEV,
      gtagConfig: {
        anonymize_ip: true, // GDPR compliance, mask last part of IP address
        debug: import.meta.env.DEV,
      },
    }),
  ],
})

export default analytics
