/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FeatureFlagResponse } from '../models/FeatureFlagResponse'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class FeatureFlagsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get Feature Flags
   * List all feature flags for the requesting user
   * @returns FeatureFlagResponse Successful Response
   * @throws ApiError
   */
  public getFeatureFlags(): CancelablePromise<Array<FeatureFlagResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/feature_flags',
    })
  }
}
