import { FC, PropsWithChildren, useEffect, useState } from 'react'
import HelpIcon from '@mui/icons-material/Help'
import LocalParkingIcon from '@mui/icons-material/LocalParking'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import PolicyIcon from '@mui/icons-material/Policy'
import SettingsIcon from '@mui/icons-material/Settings'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import TerrainIcon from '@mui/icons-material/Terrain'
import { Box, Stack, Typography } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import MuiDrawer from '@mui/material/Drawer'
import { CSSObject, styled, Theme } from '@mui/material/styles'
import { useDebounceValue } from 'usehooks-ts'

import CurbsNavDrawerPageSection from '@/components/layouts/CurbsNavDrawerPageSection'
import CurbsNavDrawerSettingsHelpSection from '@/components/layouts/CurbsNavDrawerSettingsHelpSection'
import CurbsNavDrawerSwitchSection from '@/components/layouts/CurbsNavSwitchSection'
import { APP_BAR_HEIGHT_PIXELS } from '@/components/layouts/utils'
import PopulusLogoLink from '@/components/PopulusLogoLink'
import { useAuth } from '@/modules/authentication'
import ImpersonatedUserBannerMUI from '@/modules/authentication/ImpersonatedUserBannerMUI'
import { useFeatureFlag } from '@/modules/featureFlags'
import { GlobalModalId, useGlobalModalProvider } from '@/modules/globalModal/context'
import { publicBucket } from '@/modules/initialization/config'
import {
  useCurbActivityAccess,
  useCurbFeesAndRevenueAccess,
  useCurbGeographiesMapAccess,
  useCurbMetricsAccess,
  useCurbOccupancyAccess,
  useCurbParkingInsightsAccess,
  useCurbParkingTransactionsAccess,
  useCurbPricingEngineAccess,
  useCurbRateAnalyzerAccess,
  useCurbRegulationsAccess,
  useCurbRegulationsLibraryAccess,
} from '@/modules/permissions/pageAccessHooks'
import { useCurrentRegion } from '@/modules/urlRouting/hooks'
import { PATHS } from '@/modules/urlRouting/paths'
import ProductSelectorMUI from '@/modules/urlRouting/ProductSelectorMUI'
import RegionSelectorMUI from '@/modules/urlRouting/RegionSelectorMUI'
import {
  generatePathWithRegionId,
  useOrganizationUsersPageAccess,
  useProfilePageAccess,
} from '@/modules/urlRouting/utils'

type PageItem = {
  title: string
  link: string
  disabled: boolean
}

export type PageSection = {
  title: string
  icon: any
  pages: PageItem[]
  testId: string
}

type BottomItem = {
  title: string
  onClick?: () => void
  component?: JSX.Element
}

export type BottomSection = {
  title: string
  icon: any
  items: BottomItem[]
}

const DRAWER_WIDTH_PX = 240
const DRAWER_STAY_OPEN_LIMIT_MS = 100
const DRAWER_CLOSE_DELAY_MS = 900

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH_PX,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme }) => ({
  width: DRAWER_WIDTH_PX,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  position: 'absolute',
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      },
    },
  ],
}))

const CurbsNav: FC<PropsWithChildren> = ({ children }) => {
  const [isHovering, setIsHovering] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)

  const { setId } = useGlobalModalProvider()
  const { signOut } = useAuth()
  const hasProfilePageAccess = useProfilePageAccess()
  const hasOrganizationUsersPageAccess = useOrganizationUsersPageAccess()
  const hasPricingEngine = useFeatureFlag('pricing_engine')

  const hasCurbMetricsAccess = useCurbMetricsAccess()
  const hasCurbActivityAccess = useCurbActivityAccess()
  const hasCurbRegulationAccess = useCurbRegulationsAccess()
  const hasCurbFeesAndRevenueAccess = useCurbFeesAndRevenueAccess()
  const hasCurbGeographiesAccess = useCurbGeographiesMapAccess()
  const hasCurbParkingInsightsAccess = useCurbParkingInsightsAccess()
  const hasCurbRateAnalyzerAccess = useCurbRateAnalyzerAccess()
  const hasCurbPricingEngineAccess = useCurbPricingEngineAccess()
  const hasCurbParkingTransactionsAccess = useCurbParkingTransactionsAccess()
  const hasCurbOccupancyAccess = useCurbOccupancyAccess()
  const hasCurbRegulationsLibraryAccess = useCurbRegulationsLibraryAccess()

  const {
    data: { regionId, logoFilename },
  } = useCurrentRegion()

  const [debouncedIsHovering] = useDebounceValue(
    isHovering,
    isHovering ? DRAWER_STAY_OPEN_LIMIT_MS : DRAWER_CLOSE_DELAY_MS
  )

  useEffect(() => {
    if (isHovering) {
      setOpen(true)
    } else if (!debouncedIsHovering) {
      setOpen(false)
    }
  }, [isHovering, debouncedIsHovering])

  const navDrawerPageSections: PageSection[] = [
    {
      title: 'Digital Smart Zones',
      icon: <LocalShippingIcon />,
      testId: 'digital-smart-zones',
      pages: [
        {
          title: 'Dashboard Metrics',
          link: generatePathWithRegionId(PATHS.CURB.MANAGEMENT.METRICS, regionId),
          disabled: !hasCurbMetricsAccess,
        },
        {
          title: 'Activity Map',
          link: generatePathWithRegionId(PATHS.CURB.MANAGEMENT.ACTIVITY, regionId),
          disabled: !hasCurbActivityAccess,
        },
        {
          title: 'Fees and Revenue',
          link: generatePathWithRegionId(PATHS.CURB.REVENUE.FEES_AND_REVENUE, regionId),
          disabled: !hasCurbFeesAndRevenueAccess,
        },
      ],
    },
    {
      title: 'Parking Insights',
      icon: <LocalParkingIcon />,
      testId: 'parking-insights',
      pages: [
        {
          title: 'Revenue',
          link: generatePathWithRegionId(PATHS.CURB.PARKING_INSIGHTS.REVENUE, regionId),
          disabled: !hasCurbParkingInsightsAccess,
        },
        {
          title: 'Transactions',
          link: generatePathWithRegionId(PATHS.CURB.PARKING_INSIGHTS.TRANSACTIONS, regionId),
          disabled: !hasCurbParkingTransactionsAccess,
        },
        {
          title: 'Rate Analyzer',
          link: generatePathWithRegionId(PATHS.CURB.PARKING_INSIGHTS.RATE_ANALYZER, regionId),
          disabled: !hasCurbRateAnalyzerAccess,
        },
        ...(hasPricingEngine
          ? [
              {
                title: 'Pricing Engine',
                link: generatePathWithRegionId(
                  PATHS.CURB.PARKING_INSIGHTS.PRICING_ENGINE,
                  regionId
                ),
                disabled: !hasCurbPricingEngineAccess,
              },
            ]
          : []),
        {
          title: 'Occupancy',
          link: generatePathWithRegionId(PATHS.CURB.PARKING_INSIGHTS.OCCUPANCY, regionId),
          disabled: !hasCurbOccupancyAccess,
        },
      ],
    },
    {
      title: 'Regulations',
      icon: <PolicyIcon />,
      testId: 'regulations',
      pages: [
        {
          title: 'Regulations Library',
          link: generatePathWithRegionId(PATHS.CURB.REGULATIONS.LIBRARY, regionId),
          disabled: !hasCurbRegulationsLibraryAccess,
        },
        {
          title: 'Regulations Map',
          link: generatePathWithRegionId(PATHS.CURB.REGULATIONS.MAP, regionId),
          disabled: !hasCurbRegulationAccess,
        },
      ],
    },
    {
      title: 'Geographies',
      icon: <TerrainIcon />,
      testId: 'geographies',
      pages: [
        {
          title: 'Geographies Map',
          link: generatePathWithRegionId(PATHS.CURB.GEOGRAPHIES, regionId),
          disabled: !hasCurbGeographiesAccess,
        },
      ],
    },
  ]

  const navDrawerSettingsHelp: BottomSection[] = [
    {
      title: 'Settings',
      icon: <SettingsIcon />,
      items: [
        {
          title: 'Profile Settings',
          onClick: () => hasProfilePageAccess && setId(GlobalModalId.PROFILE_SETTINGS),
        },
        {
          title: 'Sign Out',
          onClick: signOut,
        },
        {
          title: 'Manage Users',
          onClick: () => hasOrganizationUsersPageAccess && setId(GlobalModalId.MANAGE_USERS),
        },
      ],
    },
    {
      title: 'Help',
      icon: <HelpIcon />,
      items: [
        {
          title: 'Populus Help',
          onClick: () => window.open('https://help.populus.ai/en/', '_blank'),
        },
      ],
    },
  ]

  const navDrawerSwitch: BottomSection[] = [
    {
      title: 'Switch',
      icon: <SyncAltIcon />,
      items: [
        {
          title: 'Product',
          component: <ProductSelectorMUI />,
        },
        {
          title: 'Region',
          component: <RegionSelectorMUI />,
        },
      ],
    },
  ]

  return (
    <>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={open}
        onMouseOver={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <Stack justifyContent="space-between" height="100vh">
          <Box>
            <Stack direction="row" alignItems="center" sx={{ minHeight: APP_BAR_HEIGHT_PIXELS }}>
              <Box
                sx={[
                  {
                    px: 0.5,
                  },
                  open
                    ? {
                        justifyContent: 'initial',
                      }
                    : {
                        justifyContent: 'center',
                      },
                ]}
              >
                <PopulusLogoLink />
              </Box>
              <Typography
                variant="h5"
                sx={[
                  {
                    fontWeight: 600,
                  },
                  open
                    ? {
                        opacity: 1,
                      }
                    : {
                        opacity: 0,
                      },
                ]}
              >
                Populus
              </Typography>
            </Stack>
            {navDrawerPageSections.map(section => (
              <Box key={section.title}>
                <Divider />
                <CurbsNavDrawerPageSection
                  drawerOpen={open}
                  section={section}
                  testId={section.testId}
                />
              </Box>
            ))}
            {navDrawerSettingsHelp.map(section => (
              <Box key={section.title}>
                <Divider />
                <CurbsNavDrawerSettingsHelpSection drawerOpen={open} section={section} />
              </Box>
            ))}
            <Divider />
          </Box>
          <Box display="flex" alignItems="center">
            <ImpersonatedUserBannerMUI open={open} />
          </Box>
          <Stack>
            {logoFilename && !open && (
              <Box width="100%" display="flex" flexDirection="column" alignItems="center">
                <img
                  style={{ height: '45px' }}
                  src={`${publicBucket}/regions/${regionId}/${logoFilename}`}
                  crossOrigin="anonymous"
                />
              </Box>
            )}
            <Box>
              {navDrawerSwitch.map(section => (
                <Box key={section.title}>
                  <Divider />
                  <CurbsNavDrawerSwitchSection drawerOpen={open} section={section} />
                </Box>
              ))}
            </Box>
          </Stack>
        </Stack>
      </Drawer>
      {children}
    </>
  )
}

export default CurbsNav
