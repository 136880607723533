/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MdsAgencyPayloadStatus } from '../models/MdsAgencyPayloadStatus'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class MdsAgencyService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Add Vehicle
   * Register a vehicle for use in the Agency jurisdiction.
   * @returns MdsAgencyPayloadStatus Successful Response
   * @throws ApiError
   */
  public addVehicle({
    regionId,
    requestBody,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    requestBody: Record<string, any>
  }): CancelablePromise<MdsAgencyPayloadStatus> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/{region_id}/mds/agency/vehicles',
      path: {
        region_id: regionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Update Vehicle
   * Update a mutable aspect of a vehicle. For now, only `vehicle_id`.
   * @returns MdsAgencyPayloadStatus Successful Response
   * @throws ApiError
   */
  public updateVehicle({
    regionId,
    deviceId,
    requestBody,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Provided by Operator to uniquely identify a vehicle
     */
    deviceId: string
    requestBody: Record<string, any>
  }): CancelablePromise<MdsAgencyPayloadStatus> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/{region_id}/mds/agency/vehicles/{device_id}',
      path: {
        region_id: regionId,
        device_id: deviceId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Add Vehicle Event
   * Allows the Provider to control the state of the vehicle including deregister a vehicle from the fleet.
   * @returns MdsAgencyPayloadStatus Successful Response
   * @throws ApiError
   */
  public addVehicleEvent({
    regionId,
    deviceId,
    requestBody,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Provided by Operator to uniquely identify a vehicle
     */
    deviceId: string
    requestBody: Record<string, any>
  }): CancelablePromise<MdsAgencyPayloadStatus> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/{region_id}/mds/agency/vehicles/{device_id}/event',
      path: {
        region_id: regionId,
        device_id: deviceId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Add Vehicle Telemetry
   * Allows a Provider to send vehicle telemetry data in a batch for any number of vehicles in the fleet.
   * @returns MdsAgencyPayloadStatus Successful Response
   * @throws ApiError
   */
  public addVehicleTelemetry({
    regionId,
    requestBody,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    requestBody: Record<string, any>
  }): CancelablePromise<MdsAgencyPayloadStatus> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/{region_id}/mds/agency/vehicles/telemetry',
      path: {
        region_id: regionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Add Stops
   * Allows an agency to register city-managed Stops, or a provider to register self-managed Stops.
   * @returns MdsAgencyPayloadStatus Successful Response
   * @throws ApiError
   */
  public addStops({
    regionId,
    requestBody,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    requestBody: Array<any>
  }): CancelablePromise<MdsAgencyPayloadStatus> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/{region_id}/mds/agency/stops',
      path: {
        region_id: regionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Update Stops
   * Update a mutable aspects of stops.
   * @returns MdsAgencyPayloadStatus Successful Response
   * @throws ApiError
   */
  public updateStops({
    regionId,
    stopId,
    requestBody,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Unique ID for stop
     */
    stopId: string
    requestBody: Array<any>
  }): CancelablePromise<MdsAgencyPayloadStatus> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/{region_id}/mds/agency/stops/{stop_id}',
      path: {
        region_id: regionId,
        stop_id: stopId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
