/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ComplianceSummaryReason = {
  count: number
  count_type: ComplianceSummaryReason.count_type
}
export namespace ComplianceSummaryReason {
  export enum count_type {
    EVENTS = 'events',
    TRIPS = 'trips',
    VEHICLES = 'vehicles',
    VIOLATIONS = 'violations',
  }
}
