import { FC, FormEvent, useEffect, useState } from 'react'
import { useSendPasswordResetEmail, useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth'
import { useTranslation } from 'react-i18next'
import { Location, Navigate } from 'react-router-dom'
import {
  AuthErrorCodes,
  browserLocalPersistence,
  getMultiFactorResolver,
  MultiFactorError,
  MultiFactorResolver,
  setPersistence,
  useDeviceLanguage,
} from 'firebase/auth'
import { Form, Message } from 'semantic-ui-react'

import { fbAuth } from '@/modules/authentication'
import LoginFormMFA from '@/modules/authentication/LoginFormMFA'
import { useLocationState } from '@/modules/urlRouting/hooks'
import { PATHS } from '@/modules/urlRouting/paths'

fbAuth.useDeviceLanguage()

const toHome = (from: Location<any> | undefined) => {
  const navigateUserTo =
    from?.pathname && from?.pathname !== PATHS.ROOT ? `${from.pathname}${from.search}` : PATHS.ROOT
  return navigateUserTo
}

const LoginFormPassword: FC<{ initialEmail: string }> = ({ initialEmail }) => {
  const { t } = useTranslation()
  const [signInWithEmailAndPassword, user, authLoading, authError] =
    useSignInWithEmailAndPassword(fbAuth)

  const [sendPasswordResetEmail, resetSending, resetError] = useSendPasswordResetEmail(fbAuth)
  const [passwordResetSent, setPasswordResetSent] = useState(false)

  const [email, setEmail] = useState(initialEmail || '')
  const [password, setPassword] = useState('')

  const [mfaResolver, setMfaResolver] = useState<MultiFactorResolver | null>()

  const { from } = useLocationState()

  useEffect(() => {
    if (authError?.code === AuthErrorCodes.MFA_REQUIRED)
      setMfaResolver(getMultiFactorResolver(fbAuth, authError as MultiFactorError))
  }, [authError])

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setPasswordResetSent(false)

    await setPersistence(fbAuth, browserLocalPersistence)
    signInWithEmailAndPassword(email, password)
  }

  const passwordReset = async () => {
    await sendPasswordResetEmail(email)
    setPasswordResetSent(true)
  }

  if (user) {
    return <Navigate to={toHome(from)} replace state={{ from: undefined }} />
  } else if (!authLoading && !resetSending && mfaResolver) {
    return <LoginFormMFA mfaResolver={mfaResolver} />
  }

  return (
    <Form size="large" onSubmit={onSubmit}>
      {!authLoading &&
        !resetSending &&
        (authError || resetError) &&
        authError?.code !== AuthErrorCodes.MFA_REQUIRED && (
          <Message negative>
            <Message.Header>{t('login.loginError', 'Login Error')}</Message.Header>
            {authError && <p>{t(`login.authError_${authError?.code}`, authError.message)}</p>}
            {resetError && <p>{t('login.resetError', 'Error sending reset email.')}</p>}
          </Message>
        )}

      {passwordResetSent && !resetSending && !resetError && (
        <Message positive>
          <Message.Header>
            {t('login.resetEmailHeader', 'Password Reset Email Sent')}
          </Message.Header>
          {t(
            'login.resetEmailMessage',
            'An email with a link to reset your password has been sent to {{user_email}}.',
            { user_email: email || 'your email' }
          )}
        </Message>
      )}

      <Form.Input
        fluid
        icon="user"
        name="email"
        value={email}
        iconPosition="left"
        placeholder={t('login.emailAddress', 'EMAIL ADDRESS')}
        onChange={({ target: { value } }) => setEmail(value)}
      />
      <Form.Input
        fluid
        icon="lock"
        name="password"
        value={password}
        iconPosition="left"
        placeholder={t('login.password', 'PASSWORD')}
        type="password"
        onChange={({ target: { value } }) => setPassword(value)}
      />
      <Form.Button color="teal" fluid size="large" content={t('common.login', 'Login')} />
      <Form.Button size="small" type="button" onClick={passwordReset}>
        {t('login.resetPassword', 'Reset Password')}
      </Form.Button>
    </Form>
  )
}

export default LoginFormPassword
