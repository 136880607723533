import { InvalidateQueryFilters, Query, QueryClient, QueryFilters } from '@tanstack/react-query'

import { MILLISECONDS_IN_A_MINUTE } from '@/common/utils/time'

export const queryClientInstance = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 10 * MILLISECONDS_IN_A_MINUTE,
      gcTime: 30 * MILLISECONDS_IN_A_MINUTE,
    },
  },
})

/**
 * Creates predicate function that can be passed into queryClient.invalidateQueries to determine if string matches query key.
 * See https://tanstack.com/query/latest/docs/react/guides/query-invalidation
 */
export function createPredicateMatchPathQueryKey(pathToMatch: string): (query: Query) => boolean {
  return query => {
    const queryKeyPath = query.queryKey[0] as string
    return queryKeyPath.includes(pathToMatch)
  }
}

/**
 * Marks queries as stale that match a given path. NOTE: This is not ideal for edited data as this still keeps the cache around.
 * See https://tanstack.com/query/latest/docs/react/guides/query-invalidation
 */
export const invalidateQueryCachePredicate = (path: string) =>
  queryClientInstance.invalidateQueries({
    predicate: createPredicateMatchPathQueryKey(path),
  })

/**
 * Removes queries' cache entirely. Great for refetching data that has just been edited where you don't want to see the old copy.
 * See: https://tanstack.com/query/v4/docs/react/reference/QueryClient#queryclientremovequeries
 * @param queryKey the exact query key for the original query
 */
export const removeQueryCache = async (queryFilters: QueryFilters) =>
  await queryClientInstance.removeQueries(queryFilters)
