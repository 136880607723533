import { FC } from 'react'
import { Checkbox, Form } from 'semantic-ui-react'

import { formatText } from '@/common/utils/text'

import './PermissionsGroup.scss'

interface PermissionItem {
  key: string
  enabled: boolean
}

interface PermissionsGroupProps {
  componentDisabled?: boolean
  groupId: string
  groupLabel: string
  groupEnabled: boolean
  groupPermissions: PermissionItem[]
  onChangePermission: (permissionsKey: string, enable: boolean) => void
  onChangePermissionGroup: (groupKey: string, enable: boolean) => void
}

const PermissionsGroup: FC<PermissionsGroupProps> = ({
  componentDisabled,
  groupLabel,
  groupId,
  groupEnabled,
  groupPermissions,
  onChangePermissionGroup,
  onChangePermission,
}) => {
  return (
    <>
      <Form.Field key={groupId}>
        <Checkbox
          label={groupLabel}
          toggle
          checked={groupEnabled}
          onChange={() => onChangePermissionGroup(groupId, !groupEnabled)}
          disabled={componentDisabled}
        />
      </Form.Field>
      {!groupEnabled && (
        <Form.Group className="flex flex-wrap">
          {groupPermissions.map(permission => (
            <Form.Field key={permission.key}>
              <Checkbox
                className="permission-checkbox"
                label={formatText(permission.key)}
                checked={permission.enabled}
                onChange={() => onChangePermission(permission.key, !permission.enabled)}
                disabled={componentDisabled}
              />
            </Form.Field>
          ))}
        </Form.Group>
      )}
    </>
  )
}

export default PermissionsGroup
