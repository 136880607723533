import camelcaseKeys from 'camelcase-keys'
import { DateTime } from 'luxon'
import {
  BooleanParam,
  createEnumParam,
  DateParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

import { toJSDateLocal } from '@/common/utils/date'
import { useAllowedPolicyOperators, useMobilityPolicyFromPath } from '@/modules/policiesV1/hooks'
import { QueryParam } from '@/modules/queryParams/constants'

import { DATE_HEADER } from './constants'

const DEFAULT_START_DATE = toJSDateLocal(DateTime.now().minus({ days: 7 }))
const DEFAULT_END_DATE = toJSDateLocal(DateTime.now())

export const useComplianceDetailsQueryParams = () => {
  const { policy } = useMobilityPolicyFromPath()
  const allowedOperators = useAllowedPolicyOperators(policy)
  const operators = allowedOperators.map(({ slug }) => slug)
  return camelcaseKeys(
    useQueryParams({
      [QueryParam.START_DATE]: withDefault(DateParam, DEFAULT_START_DATE),
      [QueryParam.END_DATE]: withDefault(DateParam, DEFAULT_END_DATE),
      [QueryParam.OPERATOR]: withDefault(createEnumParam(operators), operators?.[0]),
      [QueryParam.ONLY_SHOW_VIOLATIONS]: withDefault(BooleanParam, false),
      [QueryParam.SORT_COLUMN_KEY]: withDefault(StringParam, DATE_HEADER),
      [QueryParam.SORT_COLUMN_DESCENDING]: withDefault(BooleanParam, false),
      [QueryParam.SORT_ROW_KEY]: withDefault(StringParam, undefined),
      [QueryParam.SORT_ROW_DESCENDING]: withDefault(BooleanParam, undefined),
    })
  )
}
