import { FC } from 'react'
import { observer } from 'mobx-react'
import { Button, Checkbox, Confirm, Form, Icon, Table } from 'semantic-ui-react'

import { startCase } from '@/common/utils/text'
import { Frequency, PolicyComplianceNotification } from '@/models/policyComplianceNotification'
import permissionsModalStore from '@/modules/permissions/adminPanel/permissionsModalStore'
import policyComplianceNotificationsStore from '@/modules/permissions/adminPanel/policyComplianceNotificationsStore'
import metroStore from '@/stores/metroStore'

const PolicyComplianceCheckbox: FC = observer(() => (
  <Checkbox
    toggle
    label="Policy Compliance"
    checked={policyComplianceNotificationsStore.hasNotifications}
    onChange={() => {
      policyComplianceNotificationsStore.toggleHasNotifications()
      policyComplianceNotificationsStore.updateNotificationsStartDate()
    }}
  />
))

const PolicyComplianceTable: FC = observer(() => {
  const notifications = policyComplianceNotificationsStore.allNotifications
  const getRegionText = (regionId: string): string => metroStore.getByRegionId(regionId).text

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Region</Table.HeaderCell>
          <Table.HeaderCell>Operator Notifications</Table.HeaderCell>
          <Table.HeaderCell>Frequency</Table.HeaderCell>
          <Table.HeaderCell>Delete</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {notifications.map((notification: PolicyComplianceNotification) => (
          <Table.Row key={`${notification.regionId}${notification.email}`}>
            <Table.Cell>{notification.email}</Table.Cell>
            <Table.Cell>{getRegionText(notification.regionId)}</Table.Cell>
            <Table.Cell>
              {policyComplianceNotificationsStore.operatorDisplayNames(
                notification.operatorNotifications
              )}
            </Table.Cell>
            <Table.Cell>{startCase(notification.frequency)}</Table.Cell>
            <Table.Cell>
              <Button
                basic
                icon
                color="red"
                onClick={() => policyComplianceNotificationsStore.deleteNotification(notification)}
              >
                <Icon name="delete" />
              </Button>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
})

const NewPolicyComplianceNotification: FC = observer(() => (
  <>
    <Form>
      <Form.Group widths="equal">
        <Form.Input
          type="email"
          required
          value={policyComplianceNotificationsStore.newNotificationEmail}
          fluid
          label="Email"
          onChange={(_e, data) =>
            policyComplianceNotificationsStore.setNewNotificationEmail(data.value)
          }
          data-testid="email"
        />
        <Form.Select
          required
          fluid
          multiple
          clearable
          value={policyComplianceNotificationsStore.newNotificationOperators}
          label="Operator Notifications"
          options={policyComplianceNotificationsStore.operatorOptions}
          onChange={(_e, data) =>
            policyComplianceNotificationsStore.setNewNotificationOperators(data.value as string[])
          }
          data-testid="operators"
        />
        <Form.Select
          fluid
          value={policyComplianceNotificationsStore.newNotificationFrequency}
          label="Frequency"
          options={policyComplianceNotificationsStore.frequencyOptions}
          onChange={(_e, data) =>
            policyComplianceNotificationsStore.setNewNotificationFrequency(data.value as Frequency)
          }
          data-testid="frequency"
        />
      </Form.Group>
      <Form.Button
        type="submit"
        disabled={
          !policyComplianceNotificationsStore.newNotificationEmail ||
          policyComplianceNotificationsStore.newNotificationOperators.length === 0
        }
        onClick={() => policyComplianceNotificationsStore.setConfirmOpen(true)}
      >
        Add Notification
      </Form.Button>
    </Form>
    <Confirm
      content={policyComplianceNotificationsStore.confirmContent}
      open={policyComplianceNotificationsStore.confirmOpen}
      onCancel={() => policyComplianceNotificationsStore.setConfirmOpen(false)}
      onConfirm={() => {
        policyComplianceNotificationsStore.setConfirmOpen(false)
        policyComplianceNotificationsStore.createNotification()
      }}
    />
  </>
))

export const Notifications: FC = observer(() => {
  return (
    <>
      {permissionsModalStore.organizationType === 'City' ? (
        <PolicyComplianceCheckbox />
      ) : (
        <p>This feature is only available to city organizations.</p>
      )}
      {policyComplianceNotificationsStore.hasNotifications && (
        <>
          <h5>Notifications</h5>
          <PolicyComplianceTable />
          <h5>New Notification</h5>
          <NewPolicyComplianceNotification />
        </>
      )}
    </>
  )
})
