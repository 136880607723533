import { Geometry } from 'geojson'
import { computed, makeObservable, observable, toJS } from 'mobx'

import { ListShapesResultModel } from '@/modules/api/openapi/models/ListShapesResultModel'

export default class Shape {
  shapeUUID: string // unique shape uuid
  shapeId: string // internal to client
  layerUUID: string // this is the UUID of the layer that contains this shape
  name?: string
  geometry: Geometry
  description?: string
  sideOfStreet?: 'left' | 'right'
  createdTime: string // isoformat
  modifiedTime: string // isoformat
  timezone?: string // this is from appUIStore.metro.timezone

  constructor(doc: ListShapesResultModel, id: string, timezone?: string) {
    makeObservable(this, {
      shapeUUID: observable,
      shapeId: observable,
      name: observable,
      geometry: observable,
      description: observable,
      sideOfStreet: observable,
      geojson: computed,
    })
    this.name = doc.shape_name
    this.description = doc.shape_description
    this.layerUUID = doc.shape_layer_uuid
    this.shapeUUID = id
    this.shapeId = doc.shape_id
    this.geometry = doc.geom as Geometry
    this.createdTime = doc.created_time
    this.modifiedTime = doc.modified_time
    this.sideOfStreet = doc.side_of_street as 'left' | 'right'
    this.timezone = timezone
  }

  get geojson() {
    return {
      type: 'Feature',
      geometry: toJS(this.geometry),
      id: this.shapeUUID,
      properties: {
        name: this.name,
        description: this.description,
        created_time: this.createdTime,
        modified_time: this.modifiedTime,
        color: '#5281ff',
        id: this.shapeUUID,
        shape_uuid: this.shapeUUID,
        shape_id: this.shapeId,
      },
    }
  }
}
