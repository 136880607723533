import { action, makeObservable, observable } from 'mobx'

import { formatText } from '@/common/utils/text'
import { VehicleTypeOption } from '@/common/utils/types'
import { Metro } from '@/models/metro'
import evaluationMapStore from '@/modules/evaluationMap/evaluationMapStore'
import i18n from '@/modules/i18n/i18n'
import layerStore from '@/modules/layers/layerStore'
import { monitorBreadcrumb } from '@/modules/monitoring'
import metroStore from '@/stores/metroStore'

class AppUIStore {
  metro: any

  constructor() {
    makeObservable(this, {
      metro: observable,
      setRegionId: action,
      setMetro: action,
      clearMetro: action,
    })
  }

  setRegionId(regionId: string) {
    this.setMetro(metroStore.getByRegionId(regionId))
  }

  setMetro(metro: Metro) {
    this.metro = metro

    layerStore.initializeRegion().finally(() => {
      if (metro.hasMap) {
        evaluationMapStore.updateMetro()
      }
    })

    monitorBreadcrumb('metro set', { regionId: metro.regionId })
  }

  clearMetro() {
    this.metro = undefined
    monitorBreadcrumb('metro cleared')
    layerStore.clearRegion()
  }

  getVehicleTypesOptions(allValue: string | null = 'all') {
    const { t } = i18n

    const defaultOptions = [
      { value: 'bicycle', text: t('common.vehicleType_bicycle', 'Bicycle') },
      { value: 'car', text: t('common.vehicleType_car', 'Car') },
      { value: 'moped', text: t('common.vehicleType_moped', 'Moped') },
      { value: 'scooter', text: t('common.vehicleType_scooter', 'Scooter') },
      { value: 'other', text: t('common.vehicleType_other', 'Other') },
    ]

    const vehicleTypes = this.metro.doc?.routesVehicleTypes

    const vehicleTypesOptions: VehicleTypeOption[] = vehicleTypes
      ? vehicleTypes.map((vehicleType: string) => {
          return {
            value: vehicleType,
            text: t(`common.vehicleType_${vehicleType}`, formatText(vehicleType)),
          }
        })
      : defaultOptions

    if (allValue) vehicleTypesOptions.unshift({ value: allValue, text: formatText(allValue) })
    return vehicleTypesOptions.map(option => ({
      value: option.value,
      text: i18n.t(`common.vehicleType_${option.value}`, option.text),
    }))
  }

  getVehicleTypesMap(textOrValueKey: 'text' | 'value' = 'value') {
    const vehicleTypes: any = {}
    this.getVehicleTypesOptions(null).forEach(v => {
      vehicleTypes[textOrValueKey === 'text' ? v.text : v.value] = v.text
    })

    return vehicleTypes
  }
}

/**
 * @deprecated Please use the useCurrentRegion or useRegion hooks
 */
export default new AppUIStore()
