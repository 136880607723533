import { forwardRef, PropsWithChildren } from 'react'
import classNames from 'classnames'

import { OnClickEvent, TextStyle } from '../sharedTypes'

import './index.scss'

interface TextProps {
  className?: string
  link?: boolean
  onClick?: OnClickEvent
  onMouseEnter?: (event: any) => void
  onMouseLeave?: (event: any) => void
  styleType: TextStyle
}

const Text = forwardRef<HTMLElement, PropsWithChildren<TextProps>>(
  ({ children, className, link, onClick, styleType, onMouseEnter, onMouseLeave }, ref) => {
    return (
      <span
        className={classNames(`populus-text-${styleType}`, link && 'link', className)}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={ref}
      >
        {children}
      </span>
    )
  }
)

Text.displayName = 'Text'

export { Text }
