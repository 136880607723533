import { startCase } from '@/common/utils/text'
import { MdsVehicleState } from '@/common/utils/types'
import i18n from '@/modules/i18n/i18n'

/*
TYPE DEFINITIONS
*/

export type PolicyTypeId =
  | 'unknown'
  | 'distribution'
  | 'no_parking'
  | 'no_ride'
  | 'operator_drop_offs'
  | 'parking_fees'
  | 'parking_time_limit'
  | 'preferred_parking'
  | 'slow_ride'
  | 'trip_fees'
  | 'utilization'
  | 'vehicle_cap'
  | 'vehicle_fees'

export enum ParkingTimeUnitEnum {
  days = 'Days',
  hours = 'Hours',
  minutes = 'Minutes',
}
export type ParkingTimeUnit = keyof typeof ParkingTimeUnitEnum

export enum SpeedUnitEnum {
  mph = 'mph',
  kph = 'kph',
}
export type SpeedUnit = keyof typeof SpeedUnitEnum

export type Unit = ParkingTimeUnit | SpeedUnit

export enum DayEnum {
  sunday = 'Sunday',
  monday = 'Monday',
  tuesday = 'Tuesday',
  wednesday = 'Wednesday',
  thursday = 'Thursday',
  friday = 'Friday',
  saturday = 'Saturday',
  weekday = 'Weekdays',
  all = 'All',
}
export type Day = keyof typeof DayEnum

export enum VehicleStateGroupEnum {
  all = 'All Vehicles',
  operational = 'Operational Vehicles',
  non_operational = 'Non-Operational Vehicles',
}
export type VehicleStateGroupType = keyof typeof VehicleStateGroupEnum

export enum ParkingFeeEnum {
  fixed_price_per_hour = 'Fixed Price per Hour',
  fixed_price_per_event = 'Fixed Price per Event',
  variable_price_parking_duration = 'Variable Price Based on Parking Duration',
  variable_price_schedule = 'Variable Price Based on Schedule',
}
export type ParkingFee = keyof typeof ParkingFeeEnum

export enum VehicleCountEnum {
  max = 'Max (Minute)',
  sae_max = 'Max (Hourly)',
  avg = 'Average',
  daytime_avg = 'Daytime Average',
}
export type VehicleCount = keyof typeof VehicleCountEnum

export enum AllocationEnum {
  vehicles_per_operator_per_shape = 'Vehicles per Operator per Shape',
  percent_vehicles_per_operator_per_shape = '% Vehicles per Operator per Shape',
}
export type Allocation = keyof typeof AllocationEnum

type PropulsionType = 'human' | 'electric_assist' | 'electric' | 'combustion'

export type FeeSchedule = {
  price: number
  end_time: string
  start_time: string
}

export type FeeProperties = {
  prices?: FeePropertiesPrices
  parking_fee_type?: ParkingFee
  vehicle_count_method?: VehicleCount
  prices_by_duration?: PricesByDuration[]
}

export type FeePropertiesPrices = Record<Day, FeeSchedule[]>

type PricesByDuration = {
  price: number
  end_duration: number | null
  start_duration: number | null
}

export type ParkingFeeVariablePricesSchedule = {
  day: Day
  startTime: {
    hour: any
    minute: any
  } | null
  endTime: {
    hour: any
    minute: any
  } | null
  price: number
}

export type SpatialPolicyDocument = {
  region_id: string
  policy_type: PolicyTypeId
  policy_uuid?: string // can be undefined for new policies
  constant_policy_uuid?: string
  policy_name: string
  agency_name: string
  unit: Unit
  allocation: Allocation | null
  vehicle_types: string[]
  vehicle_states: MdsVehicleState[] | null
  propulsion_types: PropulsionType[]
  minimum: number | null
  maximum: number | null
  value: number | null
  fee_properties: FeeProperties
  operators: string[]
  start_time: string | undefined // isoformat '00:00:00'
  end_time: string | undefined // isoformat '00:00:00'
  days: number[] | undefined
  description: string
  messages: {
    [key: string]: string
  }
  shape_layer_uuid: string | null
  shape_uuids: string[]
  start_date: string | undefined // isoformat
  end_date: string | undefined // isoformat
  published_time: string // isoformat
  published: boolean
  created_by_email: string
  priority: string
  imported_source: string
  curb_metadata: {
    away_time?: number
  }
  policy_category: string
}

export const generateEnumDropdownOptions = (
  enumObject: {
    [key: string]: string
  },
  keyId: string
): DropdownOption[] => {
  return Object.keys(enumObject).map(key => ({
    text: i18n.t(
      `policiesLibrary.form${keyId}DropdownOption${startCase(key).replaceAll(' ', '')}`,
      enumObject[key as keyof typeof enumObject]
    ),
    value: key,
    key,
  }))
}

export type DropdownOption = {
  text: string
  value: string
}
