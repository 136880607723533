/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum PermissionsObjType {
  USER = 'user',
  USER_ROLE = 'user_role',
  ORGANIZATION = 'organization',
}
