import { createContext, useContext } from 'react'
import _ from 'lodash'
import { action, makeObservable, observable } from 'mobx'

import { SpatialPolicy } from '@/models/spatialPolicy'
import { PolicyTypeId } from '@/models/spatialPolicyTypes'
import { invalidateQueryCachePredicate } from '@/modules/api/queryClient'
import { LIST_POLICIES_ENDPOINT } from '@/modules/policiesV1/complianceDetails/utils'

import { POLICIES_COMPLIANCE_SUMMARY_ENDPOINT } from '../compliance/utils'
import { COMPLIANCE_FOR_POLICY_ENDPOINT } from '../complianceDetails/utils'

import { getFields } from './components/PolicyDetails/Form/policyTypeFieldValidations'
import { PolicyInfoModalProps } from './components/PolicyInfoModal'
import { getModalText } from './utils/modalText'
import { FormErrors, PolicyFetchProps, PolicyFormFieldName } from './utils/types'
import { PoliciesService } from './service'

interface PolicyEditFetchProps extends PolicyFetchProps {
  duplicate?: boolean
}

export class PolicyEditStore {
  isFetching = false
  isSaving = false
  isNew?: boolean
  formErrors: FormErrors = {}
  modalProps?: PolicyInfoModalProps
  originalPolicy?: SpatialPolicy
  policy?: SpatialPolicy
  policyNotFound?: boolean
  savedPolicyId?: string
  policiesService: PoliciesService

  constructor() {
    makeObservable(this, {
      isFetching: observable,
      isSaving: observable,
      isNew: observable,
      formErrors: observable,
      modalProps: observable,
      originalPolicy: observable,
      policy: observable,
      policyNotFound: observable,
      savedPolicyId: observable,
      setIsFetching: action,
      setIsSaving: action,
      setIsNew: action,
      setFormErrors: action,
      clearFormErrors: action,
      setModalProps: action,
      clearModalProps: action,
      setOriginalPolicy: action,
      setPolicy: action,
      setPolicyNotFound: action,
      setSavedPolicyId: action,
      validatePolicy: action,
      fetch: action,
      save: action,
      create: action,
    })

    this.policiesService = new PoliciesService()
  }

  setIsFetching = (value: boolean) => (this.isFetching = value)

  setIsSaving = (value: boolean) => (this.isSaving = value)

  setIsNew = (value: boolean) => {
    this.isNew = value
  }

  setFormErrors = (value: FormErrors) => {
    this.formErrors = { ...this.formErrors, ...value }
  }

  clearFormErrors = () => (this.formErrors = {})

  setModalProps = (value?: PolicyInfoModalProps) => {
    this.modalProps = value ? { onConfirm: this.clearModalProps, ...value } : undefined
  }

  clearModalProps = () => (this.modalProps = undefined)

  setOriginalPolicy = (value: SpatialPolicy) => {
    this.originalPolicy = value
  }

  setPolicy = (value: SpatialPolicy) => {
    this.policy = value
  }

  setPolicyNotFound = (value: boolean) => {
    this.policyNotFound = value
  }

  setSavedPolicyId = (value?: string) => {
    this.savedPolicyId = value
  }

  validatePolicy = () => {
    if (!this.policy || !this.policy?.policyType) return
    // get fields associated with current policyType
    const fields = getFields(this.policy.policyType)
    let newErrors = {} as FormErrors
    // iterate through each field
    Object.keys(fields).forEach(field => {
      // get associated validator function with each field
      const validator = field in fields && fields[field as PolicyFormFieldName]?.validator
      // if validator -> use it to check for an error
      const error = validator && validator(this.policy!)
      newErrors = { ...newErrors, [field]: error || undefined }
    })
    this.setFormErrors(newErrors)
    return newErrors
  }

  fetch = async ({ uuid, policyJson, duplicate = false }: PolicyEditFetchProps) => {
    if (!uuid) {
      this.setPolicyNotFound(true)
      return
    }
    this.setIsFetching(true)
    try {
      if (policyJson) {
        const cachedPolicy = new SpatialPolicy(policyJson)
        if (duplicate) cachedPolicy.setAsDuplicate()
        this.setPolicy(cachedPolicy)
      }
      const policyResponse = await this.policiesService.getConstant(uuid)
      const newPolicy = new SpatialPolicy(policyResponse.policy)
      if (duplicate) newPolicy.setAsDuplicate()
      this.setPolicy(newPolicy)
      this.setOriginalPolicy(new SpatialPolicy(policyResponse.policy))
    } catch {
      this.setPolicyNotFound(true)
    } finally {
      this.setIsFetching(false)
    }
  }

  save = async () => {
    if (!this.policy) {
      this.setModalProps({ ...getModalText().ERROR_MISSING_POLICY })
      return
    }

    const validatedErrors = this.validatePolicy()
    // if invalid fields don't bother calling API
    if (!_.values(validatedErrors).every(_.isEmpty)) {
      const errorFields = _.keys(_.pickBy(validatedErrors))
      console.error(`Invalid fields ${errorFields.join(', ')}`)
      return
    }
    this.setIsSaving(true)

    try {
      const response = await this.policiesService.save(this.policy.toJson)
      // Must invalidate react-query cache for any data reliant on policies (compliance).
      invalidateQueryCachePredicate(LIST_POLICIES_ENDPOINT)
      invalidateQueryCachePredicate(POLICIES_COMPLIANCE_SUMMARY_ENDPOINT)
      invalidateQueryCachePredicate(COMPLIANCE_FOR_POLICY_ENDPOINT)
      const newPolicy = new SpatialPolicy(response.plcy)
      this.setOriginalPolicy(newPolicy)
      this.setSavedPolicyId(newPolicy?.constantPolicyUUID)
    } catch {
      this.setModalProps({ ...getModalText().ERROR_SAVE })
      return Promise.reject()
    } finally {
      this.setIsSaving(false)
    }
  }

  create({ policyType, policyName }: { policyType?: string | null; policyName?: string | null }) {
    this.setIsNew(true)
    const newPolicy = new SpatialPolicy()
    policyName && newPolicy.setPolicyName(policyName)
    policyType && newPolicy.setPolicyType(policyType as PolicyTypeId)
    this.setPolicy(newPolicy)
  }
}

export const PolicyEditContext = createContext<PolicyEditStore | undefined>(undefined)

export const usePolicyEditContext = () => {
  const context = useContext(PolicyEditContext)
  if (context === undefined) {
    throw new Error('usePolicyEditContext must be used within PolicyEditContext.Provider')
  }
  return context
}
