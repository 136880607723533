import { csvFormat } from 'd3-dsv'
import { saveAs } from 'file-saver'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { RequestParameters, ResourceType, TransformRequestFunction } from 'mapbox-gl'

import analytics from '@/modules/analytics'
import { FASTAPI_INTERNAL_URL, FASTAPI_V2_URL, PUBLIC_API_KEY } from '@/modules/api/request'
import { token } from '@/modules/authentication'
import { getImpersonatedUserId } from '@/modules/authentication/impersonation'

export const DEFAULT_NUM_ITEMS_TO_SHOW_IN_TABLE = 10
export const MOBILE_NUM_ITEMS_TO_SHOW_IN_TABLE = 5

export function shapeName(shapeData: any, shapeId: string) {
  if (!shapeId) return null
  if (shapeId === 'Not in Districts') return shapeId
  if (shapeId === 'NOT_IN_SHAPE') return 'Outside of Area'
  const shape = shapeData.filter(
    (shape: any) => shape.properties!.id?.toString() === shapeId.toString()
  )
  const shapeProperties = shape[0].properties
  return shapeProperties!.name || shapeProperties!.id
}

export function showTop10(features: any[], numberToShow: number) {
  return features.length > numberToShow ? features.slice(0, numberToShow) : features
}

export async function download(filename: string, geojson: any, format: any, options?: any) {
  if (!options) options = {}
  let blob

  const { header = null } = options

  analytics.track(`Download ${format} ${_.get(options, 'eventDesc', '')}`, {
    category: options.eventName || 'No name',
  })

  // Allows for a customized download function
  const fnc = _.get(options, 'downloadFunction')
  if (fnc) {
    fnc()
  } else if (format === 'geojson' || format === 'json') {
    const text = JSON.stringify(geojson)
    blob = new Blob([text], { type: 'application/json' })
  } else if (format === 'csv') {
    let onlyProperties = _(geojson.features).map('properties').sortBy('id').value()

    // getting rid of color and radius fields from all csv downloads
    onlyProperties = _.map(onlyProperties, p => _.omit(p, ['color', 'radius', 'id']))
    onlyProperties = _.map(onlyProperties, p =>
      _.mapValues(p, value => {
        if ((typeof value === 'object' && value !== null) || Array.isArray(value)) {
          return JSON.stringify(value)
        }
        return value
      })
    )
    let text = csvFormat(onlyProperties)
    if (header) text = header + '\n' + text
    blob = new Blob([text], { type: 'text/csv' })
  }

  if (blob) saveAs(blob, filename + '.' + format)
}

export function downloadAsCSV(objArr: object[], fileName: string) {
  const rows = csvFormat(objArr)
  return saveAs(new Blob([rows], { type: 'text/csv' }), fileName)
}

/**
 * Returns true if value should be considered an "empty" value. Useful for inputs.
 * - `undefined` => true
 * - `NULL` => true
 * - `""` => true
 * - `{}` => true
 * - `false` => false
 * - `0` => false
 */
export function isEmpty(value: any) {
  // undefined / null
  if (_.isNil(value)) return true
  switch (typeof value) {
    case 'string':
      return value.trim().length === 0
    case 'object':
      return _.isEmpty(value)
    // boolean, number
    default:
      return false
  }
}

/**
 * Transform the request if hitting vector tile endpoint
 * @param {string} url
 * @param {ResourceType} resourceType
 * @returns {RequestParameters}
 */
export const transformPopulusTileRequest: TransformRequestFunction = (
  url: string,
  resourceType: ResourceType
): RequestParameters => {
  if (!(url.includes(FASTAPI_INTERNAL_URL) || url.includes(FASTAPI_V2_URL))) return { url }

  const tileUrl = new URL(url)

  const impersonated_user = getImpersonatedUserId()

  if (resourceType === 'Tile') {
    const dt = DateTime.now()
    const wholeMinute = 30
    const cacheBuster = dt
      .set({
        minute: Math.floor(dt.minute / wholeMinute) * wholeMinute,
        second: 0,
        millisecond: 0,
      })
      .toMillis()

    tileUrl.searchParams.append('dt', cacheBuster.toString())

    tileUrl.searchParams.sort()
  }

  return {
    url: tileUrl.href,
    headers: {
      'X-API-KEY': PUBLIC_API_KEY,
      'X-TOKEN': token,
      ...(impersonated_user ? { 'IMPERSONATED-USER': impersonated_user } : {}),
    },
  }
}
