import { FC } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Box, Button, Paper, Stack, Typography } from '@mui/material'

import {
  getImpersonatedUserEmail,
  stopImpersonatingUser,
} from '@/modules/authentication/impersonation'

interface ImpersonatedUserBannerMUIProps {
  open: boolean
}

const ImpersonatedUserBannerMUI: FC<ImpersonatedUserBannerMUIProps> = ({ open }) => {
  const impersonatedUserEmail = getImpersonatedUserEmail()

  if (!impersonatedUserEmail) return null

  if (!open)
    return (
      <Paper elevation={2} sx={{ width: '100%', mx: 1, backgroundColor: '#ffb5b5', p: 1 }}>
        <Stack justifyContent="center" alignItems="center">
          <VisibilityIcon />
        </Stack>
      </Paper>
    )

  return (
    <Paper elevation={2} sx={{ width: '100%', mx: 1, backgroundColor: '#ffb5b5', p: 1 }}>
      <Stack spacing={2}>
        <Box>
          <Typography variant="caption">Viewing as:</Typography>
          <Typography variant="body1">{impersonatedUserEmail}</Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              stopImpersonatingUser()
              window.location.reload()
            }}
          >
            End Session
          </Button>
        </Box>
      </Stack>
    </Paper>
  )
}

export default ImpersonatedUserBannerMUI
