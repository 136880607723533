import { useQuery } from '@tanstack/react-query'

import { internalApi } from '@/modules/api/request'
import { useCurrentRegion } from '@/modules/urlRouting/hooks'

export const featureFlagsQueryObject = {
  queryKey: ['/feature_flags'],
  queryFn: async () => await internalApi.featureFlags.getFeatureFlags(),
}

export const useFeatureFlags = () => useQuery(featureFlagsQueryObject)

export const useFeatureFlag = (flagName: string) => {
  const { data: featureFlags, isSuccess: featureFlagsLoaded } = useFeatureFlags()

  const {
    data: { regionId },
  } = useCurrentRegion()

  return (
    featureFlagsLoaded &&
    featureFlags
      .filter(flag => flag.region_id === regionId || !flag.region_id)
      .some(flag => flag.feature_slug === flagName)
  )
}
