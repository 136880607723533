import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

import { useMobilityLiveMapMobileAccess } from '@/modules/permissions/pageAccessHooks'
import { useNavigate } from '@/modules/urlRouting/hooks'
import { PATHS } from '@/modules/urlRouting/paths'
import { useScreenSize } from '@/modules/windowSize'

const WindowSizeModalMui = () => {
  const { isDesktopHeight, isDesktopWidth } = useScreenSize()
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const hasLiveMapMobileAccess = useMobilityLiveMapMobileAccess()

  useEffect(() => {
    setOpen(!isDesktopHeight || !isDesktopWidth)
  }, [isDesktopHeight, isDesktopWidth])

  const { t } = useTranslation()

  const handleClose = () => setOpen(false)

  const DialogContentNew = () => (
    <>
      <DialogTitle data-testid="dialog-title">
        {t(
          'windowSize.visitMobileLiveMap',
          'Did you want to go to the mobile version of the Live Map?'
        )}
      </DialogTitle>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          data-testid="full-app-button"
          onClick={handleClose}
          sx={{ backgroundColor: '#9c95a1' }}
          variant="contained"
        >
          {t('windowSize.viewFullApp', 'View the full app')}
        </Button>
        <Button
          data-testid="live-map-button"
          onClick={() => {
            handleClose()
            navigate(PATHS.MOBILE.LIVE)
          }}
          sx={{ backgroundColor: '#182f46' }}
          variant="contained"
        >
          {t('windowSize.viewLiveMapMobile ', 'View the mobile Live Map')}
        </Button>
      </DialogActions>
    </>
  )

  const DialogContentOld = () => (
    <>
      <DialogTitle>{t('windowSize.mobileBeta', 'Mobile Beta')}</DialogTitle>
      <DialogContent>
        <DialogContentText data-testid="dialog-content-text">
          {t(
            'windowSize.expandBrowserWindow',
            'The Populus app is not yet optimized for this screen size. We recommend expanding your browser window until this message disappears. Click "Continue to Populus" to use the app with your current screen size.'
          )}
          <br />
          <br />
          <Trans i18nkey="windowSize.questionsMessage">
            Questions? Reach out to us at <a href="mailto:support@populus.ai">support@populus.ai</a>{' '}
            for help.
          </Trans>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button onClick={handleClose} sx={{ backgroundColor: '#9c95a1' }} variant="contained">
          {t('windowSize.continueButton', 'Continue to Populus')}
        </Button>
      </DialogActions>
    </>
  )

  return (
    <Dialog open={open} sx={{ position: 'absolute' }}>
      {hasLiveMapMobileAccess ? <DialogContentNew /> : <DialogContentOld />}
    </Dialog>
  )
}

export default WindowSizeModalMui
