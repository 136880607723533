import { ReactNode } from 'react'
import classNames from 'classnames'

import { Divider } from '../../componentLibrary'

import './index.scss'

interface PolicyDetailsTemplateProps {
  backLinkContent?: ReactNode
  detailsContent?: ReactNode
  disabled?: boolean
  headerContent?: ReactNode
  mapContent?: ReactNode
}

export const PolicyDetailsTemplate = ({
  backLinkContent,
  detailsContent,
  disabled,
  headerContent,
  mapContent,
}: PolicyDetailsTemplateProps) => {
  return (
    <div className={classNames('policy-details-template', { disabled })}>
      {backLinkContent && <div className="back-link-content">{backLinkContent}</div>}
      {headerContent && <div className="header-content">{headerContent}</div>}
      <Divider className="details-divider" color="accent" weight="heavy" />
      {mapContent}
      {detailsContent && <div className="details-content">{detailsContent}</div>}
    </div>
  )
}
