import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { observer } from 'mobx-react'

import { Dropdown } from '@/components/componentLibrary'
import { SpatialPolicy as Policy } from '@/models/spatialPolicy'
import { getOperatorDisplayName } from '@/modules/operator'
import appUIStore from '@/stores/appUIStore'

import { HideableField, SelectionTags } from './components'
import { EditableFieldProps } from '.'

const OPERATORS_TITLE = 'Operators'

export const Operators = observer(({ policy, ...props }: { policy: Policy }) => {
  const { t } = useTranslation()

  return (
    <HideableField
      hidden={_.isEmpty(policy.operators)}
      titleText={t('policiesLibrary.formOperatorsTitle', OPERATORS_TITLE)}
      {...props}
    >
      {policy.operators && (
        <SelectionTags values={policy.operators.map(op => getOperatorDisplayName(op))} />
      )}
    </HideableField>
  )
})

export const EditableOperators = observer(
  ({ editable, onValidate, policy, ...props }: EditableFieldProps) => {
    const { t } = useTranslation()

    const options = appUIStore.metro.mdsOperators.map((value: string) => ({
      key: value,
      text: getOperatorDisplayName(value),
      value,
    }))
    return editable ? (
      <HideableField
        editable
        titleText={t('policiesLibrary.formOperatorsTitle', OPERATORS_TITLE)}
        {...props}
      >
        <Dropdown
          error={props.error}
          name="operators"
          onBlur={onValidate}
          onChange={operators =>
            Array.isArray(operators) && policy.setOperators(operators as string[])
          }
          options={options}
          placeholder={t('policiesLibrary.formOperatorPlaceholder', 'Select applicable operators')}
          value={policy.operators || []}
        />
      </HideableField>
    ) : (
      <Operators policy={policy} {...props} />
    )
  }
)
