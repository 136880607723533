import { Text } from '../../componentLibrary'

import './index.scss'

interface DrawerTitleProps {
  title?: string
}

export const DrawerTitle = ({ title }: DrawerTitleProps) => {
  return (
    <div className="drawer-title">
      <Text styleType="primary">{title}</Text>
    </div>
  )
}
