/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppendShapesToLayer } from '../models/AppendShapesToLayer'
import type { LayerResponse } from '../models/LayerResponse'
import type { ListShapesPayload } from '../models/ListShapesPayload'
import type { ListShapesResponseModel } from '../models/ListShapesResponseModel'
import type { PatchShape } from '../models/PatchShape'
import type { PolicyCategoryEnum } from '../models/PolicyCategoryEnum'
import type { PolicyType } from '../models/PolicyType'
import type { PopulusPage_MatchedPolicyRuleSchedule_ } from '../models/PopulusPage_MatchedPolicyRuleSchedule_'
import type { PopulusPage_PolicyV2RuleResponse_ } from '../models/PopulusPage_PolicyV2RuleResponse_'
import type { PopulusPage_ShapesResultModel_ } from '../models/PopulusPage_ShapesResultModel_'
import type { PostShape } from '../models/PostShape'
import type { ShapeCalendarResponse } from '../models/ShapeCalendarResponse'
import type { ShapesResultModel } from '../models/ShapesResultModel'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class ShapesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get Shapes By Layer
   * @returns PopulusPage_ShapesResultModel_ Successful Response
   * @throws ApiError
   */
  public getShapesByLayer({
    regionId,
    shapeLayerUuid,
    page = 1,
    size = 50,
    shapeUuid,
    shapeId,
    shapeName,
    shapeDescription,
    sideOfStreet,
    search,
    orderBy = 'shape_uuid',
  }: {
    /**
     * Region identifier
     */
    regionId: string
    shapeLayerUuid: string
    page?: number
    size?: number
    shapeUuid?: string
    shapeId?: string
    shapeName?: string
    shapeDescription?: string
    sideOfStreet?: string
    search?: string
    orderBy?: string
  }): CancelablePromise<PopulusPage_ShapesResultModel_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/shapes',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
      },
      query: {
        page: page,
        size: size,
        shape_uuid: shapeUuid,
        shape_id: shapeId,
        shape_name: shapeName,
        shape_description: shapeDescription,
        side_of_street: sideOfStreet,
        search: search,
        order_by: orderBy,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Upload Shapes To Layer
   * Process a user-uploaded shape file, adding the shapes to the specified layer.
   * @returns LayerResponse Successful Response
   * @throws ApiError
   */
  public uploadShapesToLayer({
    regionId,
    shapeLayerUuid,
    requestBody,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
    requestBody: AppendShapesToLayer
  }): CancelablePromise<LayerResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/shapes/upload',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * List Shapes Deprecated
   * Returns shapes for a specific layer uuid.  region_id is also required
   * to make sure the user has access to this layer.
   * @returns ListShapesResponseModel Successful Response
   * @throws ApiError
   */
  public listShapesDeprecated({
    requestBody,
  }: {
    requestBody: ListShapesPayload
  }): CancelablePromise<ListShapesResponseModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/shapes/list',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Shape
   * Get a single shape from a UUID.
   * @returns ShapesResultModel Successful Response
   * @throws ApiError
   */
  public getShape({
    shapeUuid,
  }: {
    /**
     * Shape identifier
     */
    shapeUuid: string
  }): CancelablePromise<ShapesResultModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/shapes/{shape_uuid}',
      path: {
        shape_uuid: shapeUuid,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Delete Shape
   * Delete a shape from the shapes table. Returns the new Shape Layer version that the shape was removed from.
   * @returns LayerResponse Successful Response
   * @throws ApiError
   */
  public deleteShape({
    shapeUuid,
  }: {
    /**
     * Shape identifier
     */
    shapeUuid: string
  }): CancelablePromise<LayerResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/shapes/{shape_uuid}',
      path: {
        shape_uuid: shapeUuid,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Patch Shape
   * Modify an existing shape in the shapes table. Can be used to modify the shape name.
   * @returns ShapesResultModel Successful Response
   * @throws ApiError
   */
  public patchShape({
    shapeUuid,
    requestBody,
  }: {
    /**
     * Shape identifier
     */
    shapeUuid: string
    requestBody: PatchShape
  }): CancelablePromise<ShapesResultModel> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/shapes/{shape_uuid}',
      path: {
        shape_uuid: shapeUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Post Shape
   * Returns shapes for a specific layer uuid.  region_id is also required
   * to make sure the user has access to this layer.
   * @returns ShapesResultModel Successful Response
   * @throws ApiError
   */
  public postShape({
    requestBody,
  }: {
    requestBody: PostShape
  }): CancelablePromise<ShapesResultModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/shapes',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Policies By Shape
   * Get Policies relating to a single shape from a UUID.
   * @returns PopulusPage_MatchedPolicyRuleSchedule_ Successful Response
   * @throws ApiError
   */
  public getPoliciesByShape({
    shapeUuid,
    startDate,
    endDate,
    purposes,
    vehicleTypes,
    userClasses,
    types,
    hasRate,
    maximum,
    unit,
    daysOfWeek,
    startTime,
    endTime,
    page = 1,
    size = 50,
  }: {
    /**
     * Shape identifier
     */
    shapeUuid: string
    /**
     * Filter for policies and compliance records that end after the provided ISO start date (inclusive)
     */
    startDate: string
    /**
     * Filter for policies and compliance records that started on or before the provided ISO end date (inclusive)
     */
    endDate: string
    /**
     * Filter for purposes
     */
    purposes?: Array<string>
    /**
     * Filter for vehicle_types
     */
    vehicleTypes?: Array<string>
    /**
     * Filter for user_classes
     */
    userClasses?: Array<string>
    /**
     * Filter for types
     */
    types?: Array<string>
    /**
     * True to filter for policy rules with rates
     */
    hasRate?: boolean
    /**
     * Filter policy rule maximum by providing two numbers representing a range (inclusive)
     */
    maximum?: Array<number>
    /**
     * Filter by policy rule unit
     */
    unit?: string
    /**
     * Filter policy rules for those that apply weekly on ISO days of week
     */
    daysOfWeek?: Array<number>
    /**
     * Filter policy rules that apply after the provided time in format HH:mm (inclusive)
     */
    startTime?: string
    /**
     * Filter policy rules that apply before the provided time in format HH:mm (exclusive)
     */
    endTime?: string
    page?: number
    size?: number
  }): CancelablePromise<PopulusPage_MatchedPolicyRuleSchedule_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/shapes/{shape_uuid}/all_policies',
      path: {
        shape_uuid: shapeUuid,
      },
      query: {
        purposes: purposes,
        vehicle_types: vehicleTypes,
        user_classes: userClasses,
        types: types,
        has_rate: hasRate,
        maximum: maximum,
        unit: unit,
        days_of_week: daysOfWeek,
        start_date: startDate,
        end_date: endDate,
        start_time: startTime,
        end_time: endTime,
        page: page,
        size: size,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Calendar By Shape
   * Generates a flat calendar representation of schedules that apply to the provided shape.
   * @returns ShapeCalendarResponse Successful Response
   * @throws ApiError
   */
  public getCalendarByShape({
    shapeUuid,
    startDate,
    endDate,
  }: {
    /**
     * Shape identifier
     */
    shapeUuid: string
    /**
     * Filter for policies and compliance records that end after the provided ISO start date (inclusive)
     */
    startDate: string
    /**
     * Filter for policies and compliance records that started on or before the provided ISO end date (inclusive)
     */
    endDate: string
  }): CancelablePromise<ShapeCalendarResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/shapes/{shape_uuid}/calendar',
      path: {
        shape_uuid: shapeUuid,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Potential Rules By Shape
   * Get Rules that could be potentially applied to a single shape from a UUID.
   * Results are limited to rules associated with policies that are not expired
   * (i.e. have an end date after today) or do not expire at all.
   * @returns PopulusPage_PolicyV2RuleResponse_ Successful Response
   * @throws ApiError
   */
  public getPotentialRulesByShape({
    shapeUuid,
    policyPurpose,
    policyUserClass,
    daysOfWeek,
    startTime,
    endTime,
    page = 1,
    size = 50,
    unit,
    search,
    maximum,
    orderBy = 'id',
    policyId,
    policyType,
    policyVehicleTypes,
    policyCategory,
    policyRegionId,
    policyShapeLayerId,
    policySearch,
    policyStartDateLt,
    policyStartDateGte,
    policyEndDateLt,
    policyEndDateGte,
    policyOrderBy = 'name',
  }: {
    /**
     * Shape identifier
     */
    shapeUuid: string
    /**
     * Policy purpose
     */
    policyPurpose?: string
    /**
     * Policy user class, which combines vehicle_type, propulsion and purpose
     */
    policyUserClass?: string
    /**
     * Filter policy rules for those that apply weekly on ISO days of week
     */
    daysOfWeek?: Array<number>
    /**
     * Filter metrics of evaluation records by the start_time in isoformat (inclusive). Only hour increments are supported currently. If an evaluation record started before the start_time but ended after it, the portion of the evaluation record after the start_time will be included in the metric.
     */
    startTime?: string
    /**
     * Filter metrics of evaluation records by the end_time in format isoformat (exclusive). Only hour increments are supported currently. If an evaluation record started before the end_time but ended after it, the portion of the evaluation record before the end_time will be included in the metric.
     */
    endTime?: string
    page?: number
    size?: number
    unit?: string
    search?: string
    maximum?: number
    orderBy?: string
    policyId?: string
    policyType?: PolicyType
    policyVehicleTypes?: string
    policyCategory?: PolicyCategoryEnum
    policyRegionId?: string
    policyShapeLayerId?: string
    policySearch?: string
    policyStartDateLt?: string
    policyStartDateGte?: string
    policyEndDateLt?: string
    policyEndDateGte?: string
    policyOrderBy?: string
  }): CancelablePromise<PopulusPage_PolicyV2RuleResponse_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/shapes/{shape_uuid}/potential_rules',
      path: {
        shape_uuid: shapeUuid,
      },
      query: {
        policy_purpose: policyPurpose,
        policy_user_class: policyUserClass,
        days_of_week: daysOfWeek,
        start_time: startTime,
        end_time: endTime,
        page: page,
        size: size,
        unit: unit,
        search: search,
        maximum: maximum,
        order_by: orderBy,
        policy__id: policyId,
        policy__type: policyType,
        policy__vehicle_types: policyVehicleTypes,
        policy__category: policyCategory,
        policy__region_id: policyRegionId,
        policy__shape_layer_id: policyShapeLayerId,
        policy__search: policySearch,
        policy__start_date__lt: policyStartDateLt,
        policy__start_date__gte: policyStartDateGte,
        policy__end_date__lt: policyEndDateLt,
        policy__end_date__gte: policyEndDateGte,
        policy__order_by: policyOrderBy,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Does Shape Have Policies V2
   * Returns whether the provided shape is associated with any policies v2.
   * Will include all non-deleted policies, regardless of published status
   * or date range.
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public getDoesShapeHavePoliciesV2({
    shapeUuid,
  }: {
    /**
     * Shape identifier
     */
    shapeUuid: string
  }): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/shapes/{shape_uuid}/has_policies_v2',
      path: {
        shape_uuid: shapeUuid,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
