import { DateTime } from 'luxon'

import { apipyRequest } from '@/modules/api/request'

import { PolicyViolation, ViolationQueryRow } from './models'

export default function PolicyComplianceService() {
  async function getViolationsForPolicy(args: {
    policyUUID: string
    startDate: DateTime
    endDate: DateTime
    operator?: string
  }): Promise<PolicyViolation[]> {
    const params = {
      policy_uuid: args.policyUUID,
      start_date: args.startDate.toISODate(),
      end_date: args.endDate.toISODate(),
      operators: args.operator ? [args.operator] : undefined,
      detail: true,
    }

    const res = await apipyRequest('/policy_compliance/for_policy', params, { reject: true })
    const violations = res.data.map(
      (violation: ViolationQueryRow) => new PolicyViolation(violation)
    )
    return violations.filter(
      (violation: PolicyViolation) => !args.operator || violation.operator === args.operator
    )
  }

  return {
    getViolationsForPolicy,
  }
}
