import classNames from 'classnames'

import { Icon, Tooltip } from '@/components/componentLibrary'

import './index.scss'

interface PopulusLogoLinkProps {
  onClick?: () => void
  tooltipText?: string
}

/**
 * Component that renders the Populus Logo in the top Navbar
 * @param onClick - callback to invoke when the logo is clicked; this will style the logo as clickable
 * @param tooltipText - text to show when hovering over the logo
 */
const PopulusLogoLink = ({ onClick, tooltipText }: PopulusLogoLinkProps) => {
  const logoContent = (
    <div
      className={classNames('populus-logo-link', !!onClick && 'link')}
      data-testid="populus-logo-link"
      onClick={onClick}
    >
      <Icon icon="PopulusLogo" />
    </div>
  )
  return tooltipText ? (
    <Tooltip
      dark
      content={tooltipText}
      mouseEnterDelay={500}
      orientation="right"
      trigger={logoContent}
    />
  ) : (
    logoContent
  )
}

export default PopulusLogoLink
