import { ChangeEvent, FocusEvent } from 'react'
import classNames from 'classnames'

import './index.scss'

export interface TextAreaProps {
  className?: string
  disabled?: boolean
  error?: boolean
  name?: string
  onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>, data: { value: string }) => void
  placeholder?: string
  value?: string
}

export const TextArea = ({
  className,
  disabled,
  error,
  onBlur,
  onChange,
  ...props
}: TextAreaProps) => {
  return (
    <textarea
      className={classNames(
        'populus-text-area',
        disabled && 'disabled',
        error && 'error',
        className
      )}
      disabled={disabled}
      onBlur={e => onBlur && onBlur(e)}
      onChange={e => onChange && onChange(e, { value: e.target.value })}
      {...props}
    ></textarea>
  )
}
