import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { observer } from 'mobx-react'

import { generateEnumTranslations } from '@/common/utils/enum'
import { Dropdown } from '@/components/componentLibrary'
import { SpatialPolicy as Policy } from '@/models/spatialPolicy'
import { generateEnumDropdownOptions, VehicleCountEnum } from '@/models/spatialPolicyTypes'

import { HideableField, SelectionTags } from './components'
import { EditableFieldProps } from '.'

const VEHICLE_COUNT_METHOD_TITLE = 'Vehicle Count Method'

export const VehicleCountMethod = observer(({ policy, ...props }: { policy: Policy }) => {
  const { t } = useTranslation()

  return (
    <HideableField
      hidden={!policy.vehicleCountMethod}
      titleText={t('policiesLibrary.formVehicleCountTitle', VEHICLE_COUNT_METHOD_TITLE)}
      {...props}
    >
      {policy.vehicleCountMethod && (
        <SelectionTags
          values={[
            generateEnumTranslations(VehicleCountEnum, 'VehicleCountEnum')[
              policy.vehicleCountMethod
            ],
          ]}
        />
      )}
    </HideableField>
  )
})

export const EditableVehicleCountMethod = observer(
  ({ editable, onValidate, policy, ...props }: EditableFieldProps) => {
    const { t } = useTranslation()

    return editable ? (
      <HideableField
        editable
        titleText={t('policiesLibrary.formVehicleCountTitle', VEHICLE_COUNT_METHOD_TITLE)}
        {...props}
      >
        <Dropdown
          error={props.error}
          name="vehicleCountMethod"
          onBlur={onValidate}
          onChange={vehicleCountMethod => {
            !_.isArray(vehicleCountMethod) &&
              policy.setVehicleCountMethod(vehicleCountMethod as Policy['vehicleCountMethod'])
          }}
          options={generateEnumDropdownOptions(VehicleCountEnum, 'Vehicle')}
          placeholder={t(
            'policiesLibrary.formVehicleCountPlaceholder',
            'Select a vehicle count method'
          )}
          value={policy.vehicleCountMethod}
        />
      </HideableField>
    ) : (
      <VehicleCountMethod policy={policy} {...props} />
    )
  }
)
