import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'

import CurbsNav from '@/components/layouts/CurbsNav'
import NavigationLayout from '@/components/layouts/NavigationLayout'
import { DRAWER_WIDTH_PIXELS } from '@/components/layouts/utils'
import { useFeatureFlag } from '@/modules/featureFlags'
import GlobalModal from '@/modules/globalModal'

import './NavigationLayout.scss'

const NavigationLayoutMUI = () => {
  const hasCursbMuiNav = useFeatureFlag('curbs_mui_nav')

  if (!hasCursbMuiNav) return <NavigationLayout />

  return (
    <>
      <GlobalModal />
      <CurbsNav>
        <Box sx={{ ml: `${DRAWER_WIDTH_PIXELS}px`, height: '100vh', overflow: 'auto' }}>
          <Outlet />
        </Box>
      </CurbsNav>
    </>
  )
}
export default NavigationLayoutMUI
