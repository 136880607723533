/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ComplianceStatus } from './ComplianceStatus'
export type ComplianceData = {
  status: ComplianceStatus
  daysCount?: number
  violationType?: ComplianceData.violationType
  violationCount?: number
}
export namespace ComplianceData {
  export enum violationType {
    EVENTS = 'events',
    TRIPS = 'trips',
    VEHICLES = 'vehicles',
    VIOLATIONS = 'violations',
  }
}
