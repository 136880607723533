import { useEffect, useState } from 'react'
import { action, computed, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { Dropdown, Form, Message, Modal } from 'semantic-ui-react'

import { startCase } from '@/common/utils/text'
import { apipyRequest } from '@/modules/api/request'
import authStore from '@/stores/authStore'

type TableName = 'trips' | 'status_changes'
type TableYear = string // '2019', '2020', etc
type TableMonth = '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09' | '10' | '11' | '12'
type MonthlyZipData = {
  [key in TableName]: {
    [key in TableYear]: TableMonth[]
  }
}

export class RawDataExportModalStore {
  data?: MonthlyZipData
  ready: boolean = false

  constructor() {
    makeObservable(this, {
      data: observable,
      ready: observable,
      setData: action,
      fetchData: action,
      tableNames: computed,
    })
  }

  setData(data: MonthlyZipData) {
    this.ready = true
    this.data = data
  }

  fetchData() {
    // won't get access unless access to all operators - will generate errors
    if (!authStore.allOperators) return

    this.ready = false

    apipyRequest('/monthly_zip/list').then(response => {
      this.setData(response.data)
    })
  }

  get tableNames(): TableName[] | undefined {
    return this.data && (Object.keys(this.data) as TableName[])
  }
}
const rawDataExportModalStore = new RawDataExportModalStore()
export default rawDataExportModalStore

export const RawDataExportModal = observer(({ onClose }: { onClose: () => void }) => {
  const [table, setTable] = useState<TableName>()
  const [year, setYear] = useState<TableYear>()
  const [month, setMonth] = useState<TableMonth>()
  const [error, setError] = useState<string>()

  useEffect(() => {
    rawDataExportModalStore.fetchData()
  }, [])

  const { ready, data } = rawDataExportModalStore

  const onSubmit = () => {
    setError(undefined)
    if (!table || !year || !month) setError('Please select Table, Year and Month.')
    else {
      apipyRequest('/monthly_zip/download_url', {
        table: table,
        year: year,
        month: month,
      }).then(response => {
        window.location = response.url
      })
    }
  }

  const makeOption = (v: string) => {
    return {
      text: startCase(v.replace('_', ' ')),
      value: v,
    }
  }

  const tableOptions = rawDataExportModalStore.tableNames?.map(value => makeOption(value))

  const years = data && table && table in data && data[table]
  const yearOptions = years && Object.keys(years).map(value => makeOption(value))
  const months = years && year && year in years && years[year]
  const monthOptions = months && months.map(value => makeOption(value))

  const form = (
    <Form onSubmit={onSubmit}>
      <Form.Group>
        <Form.Field>
          <Dropdown
            className="agg-option"
            selection
            placeholder="Select a Table"
            value={table}
            options={tableOptions || []}
            onChange={(event, { value }) => setTable(value as TableName)}
            style={{ width: '160px' }}
          />
        </Form.Field>

        <Form.Field>
          <Dropdown
            className="agg-option"
            selection
            placeholder="Year"
            value={year}
            options={yearOptions || []}
            onChange={(event, { value }) => setYear(value as TableYear)}
            style={{ width: '125px' }}
          />
        </Form.Field>

        <Form.Field>
          <Dropdown
            className="agg-option"
            selection
            placeholder="Month"
            value={month}
            options={monthOptions || []}
            onChange={(event, { value }) => setMonth(value as TableMonth)}
            style={{ width: '125px' }}
          />
        </Form.Field>

        <Form.Field>
          <Form.Button color="teal" content="Download" />
        </Form.Field>
      </Form.Group>
    </Form>
  )

  return (
    <Modal open={true} onClose={onClose}>
      <Modal.Content>
        <h3>Raw data</h3>
        {!ready && <div>LOADING</div>}
        {ready && form}
        {error ? (
          <Message negative>
            <Message.Header>Error exporting data</Message.Header>

            <p>{error}</p>
          </Message>
        ) : null}
      </Modal.Content>
    </Modal>
  )
})
