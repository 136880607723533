import { useState } from 'react'
import { Popup } from 'semantic-ui-react'

import { DrawerItem, DrawerItemProps } from '../DrawerItem'

import './index.scss'

interface DrawerItemDropdownProps extends DrawerItemProps {
  options: DrawerItemProps[]
}

/**
 * Drawer Item that shows a dropdown menu when clicked. Used for exporting reports.
 * Uses Popup instead of Dropdown so that it appears on top of all other content on the screen.
 */
export const DrawerItemDropdown = ({ active, options, ...props }: DrawerItemDropdownProps) => {
  const [open, setOpen] = useState(false)
  return (
    <Popup
      basic
      className="drawer-item-dropdown"
      icon={null}
      onClick={() => setOpen(false)}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      position="left center"
      // in-line style needed to overwrite popper styling
      style={{ left: '236px', top: '22px' }}
      trigger={
        <DrawerItem
          {...props}
          active={active || open}
          collapsed={!open}
          expanded={open}
          onClick={() => setOpen(!open)}
        />
      }
    >
      {options.map(({ disabled, onClick, text }) => (
        <DrawerItem disabled={disabled} onClick={onClick} key={text} text={text} />
      ))}
    </Popup>
  )
}
