import { FC } from 'react'
import classNames from 'classnames'

import { OnClickEvent, PopulusColor } from '../sharedTypes'
import { Icon, Text } from '..'

import './index.scss'

export interface TagProps {
  className?: string
  color?: PopulusColor
  fillColor?: PopulusColor
  fillColorHex?: string
  large?: boolean
  largeOceanOutline?: boolean
  onClick?: OnClickEvent
  onClose?: OnClickEvent
  text?: string
  textColor?: PopulusColor
  transparent?: boolean
  warning?: boolean
}

/**
 * @param color - sets the color of text and border
 * @param fillColor - sets the color of background fill and border
 * @param onClose - adds the clickable X icon
 * @param transparent - makes the background transparent
 */
export const Tag: FC<TagProps> = ({
  className,
  color,
  fillColorHex,
  fillColor,
  large,
  largeOceanOutline,
  onClick,
  onClose,
  text,
  textColor,
  transparent,
}) => {
  return (
    <div className={classNames('populus-tag', className)}>
      <div
        // NOTE: order of classNames may affect styling
        style={fillColorHex ? { background: fillColorHex } : {}}
        className={classNames(
          'content',
          onClose && 'close',
          largeOceanOutline && 'large-ocean-outline',
          fillColor && `fill-${fillColor}`,
          (color || largeOceanOutline) && `color-${color || 'ocean-0'}`,
          transparent && 'transparent'
        )}
        onClick={onClick ? onClick : undefined}
      >
        <Text
          className={classNames(
            'tag-text',
            ((textColor ? textColor : color) || largeOceanOutline) &&
              `text-${(textColor ? textColor : color) || 'ocean-0'}`
          )}
          styleType={large ? 'body' : 'tooltip'}
        >
          {text}
        </Text>
        {!!onClose && (
          <Icon
            className="tag-close"
            color={largeOceanOutline ? 'ocean-0' : 'black-1'}
            hoverColor={'white'}
            icon="CloseRoundedSmall"
            onClick={onClose}
            small
          />
        )}
      </div>
    </div>
  )
}
