import { createRoot } from 'react-dom/client'
import { LicenseInfo } from '@mui/x-license'
import { configure } from 'mobx'

import topsheetStore from '@/stores/topsheetStore'

import '@/modules/i18n/i18n'

import App from './app'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import 'react-day-picker/lib/style.css'
import 'semantic-ui-css/semantic.css'
import './scss/stylesheet.scss'

// Required for assets/send_weekly_email_update.js script
// @ts-ignore
window._debug = { topsheetStore }

// MobX strict mode
configure({ enforceActions: 'observed' })

LicenseInfo.setLicenseKey(import.meta.env.VITE_MATERIAL_UI_LICENSE_KEY)

// This is to be used when using MSW in development in the browser.
// if (import.meta.env.DEV && !import.meta.env.VITEST) {
//   const { worker } = await import('@test/worker')
//   worker.start()
// }

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<App />)
