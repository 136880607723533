import i18n from '@/modules/i18n/i18n'

export type EvaluationMode = 'trips' | 'parking' | 'operator_drop_offs'

export class EvaluationAggregationType {
  private static asMap: { [key: string]: EvaluationAggregationType } = {}
  private static asArray: EvaluationAggregationType[] = []

  public static fromValue(value: string): EvaluationAggregationType | undefined {
    return EvaluationAggregationType.asMap[value]
  }

  public static values = (): readonly EvaluationAggregationType[] => {
    return EvaluationAggregationType.asArray
  }

  public static byMode = (mode: EvaluationMode): readonly EvaluationAggregationType[] => {
    return EvaluationAggregationType.values().filter(value => value.mode === mode)
  }

  //#region Trips Aggregation
  static readonly TRIPS_ORIGINS = new EvaluationAggregationType({
    mode: 'trips',
    id: 'origins',
    geojsonAttribute: 'orig',
    title: 'Trip Origins',
    legendTitle: 'Trips',
    statTitle: 'Trip Origins',
    tableHeader: 'Trip Origins',
    requiresLayerIntersect: false,
    supportsHourFilters: true,
  })
  static readonly TRIPS_DESTINATIONS = new EvaluationAggregationType({
    mode: 'trips',
    id: 'destinations',
    geojsonAttribute: 'dest',
    title: 'Trip Destinations',
    legendTitle: 'Trips',
    statTitle: 'Trip Destinations',
    tableHeader: 'Destination',
    requiresLayerIntersect: false,
    supportsHourFilters: true,
  })
  static readonly TRIPS_DAILY_ORIGINS = new EvaluationAggregationType({
    mode: 'trips',
    id: 'daily_origins',
    geojsonAttribute: 'daily_orig',
    title: 'Daily Origins',
    legendTitle: 'Trips',
    statTitle: 'Daily Origins',
    tableHeader: 'Origins',
    requiresLayerIntersect: false,
    supportsHourFilters: true,
  })
  static readonly TRIPS_DAILY_DESTINATIONS = new EvaluationAggregationType({
    mode: 'trips',
    id: 'daily_destinations',
    geojsonAttribute: 'daily_dest',
    title: 'Daily Destinations',
    legendTitle: 'Trips',
    statTitle: 'Daily Destinations',
    tableHeader: 'Destination',
    requiresLayerIntersect: false,
    supportsHourFilters: true,
  })
  static readonly TRIPS_ALL_INTERSECTING = new EvaluationAggregationType({
    mode: 'trips',
    id: 'intersect',
    geojsonAttribute: 'intersect',
    title: 'All Intersecting Trips',
    legendTitle: 'Trips',
    statTitle: 'Intersecting Trips',
    tableHeader: 'Destination',
    requiresLayerIntersect: true,
    supportsHourFilters: true,
  })
  static readonly TRIPS_ORIGIN_OR_DESTINATION = new EvaluationAggregationType({
    mode: 'trips',
    id: 'orig_or_dest',
    geojsonAttribute: 'orig_or_dest_single',
    title: 'Trip Origin Or Trip Destination',
    legendTitle: 'Trips',
    statTitle: 'Trip Origins Or Trip Destinations',
    tableHeader: 'Destination',
    requiresLayerIntersect: false,
    supportsHourFilters: true,
  })
  static readonly TRIPS_AVERAGE_ORIGIN_DISTANCE = new EvaluationAggregationType({
    mode: 'trips',
    id: 'average_origin_distance',
    geojsonAttribute: 'average_distance',
    title: 'Average Origin Distance',
    legendTitle: 'Distance ({{units}})',
    statTitle: 'Average Origin Distance ({{units}})',
    tableHeader: 'Avg Distance ({{units}})',
    requiresLayerIntersect: false,
    supportsHourFilters: false,
  })
  static readonly TRIPS_AVERAGE_DESTINATION_DISTANCE = new EvaluationAggregationType({
    mode: 'trips',
    id: 'average_destination_distance',
    geojsonAttribute: 'average_distance',
    title: 'Average Destination Distance',
    legendTitle: 'Distance ({{units}})',
    statTitle: 'Distance ({{units}})',
    tableHeader: 'Avg Distance ({{units}})',
    requiresLayerIntersect: false,
    supportsHourFilters: false,
  })
  //#endregion
  //#region Parking Aggregation
  static readonly PARKING_TOTAL_EVENTS = new EvaluationAggregationType({
    mode: 'parking',
    id: 'total_parking_events',
    geojsonAttribute: 'total_parking_events',
    title: 'Total Events',
    legendTitle: 'Events',
    statTitle: 'Total Events',
    tableHeader: 'Parking Events',
    requiresLayerIntersect: false,
    supportsHourFilters: true,
  })
  static readonly PARKING_DAILY_EVENTS = new EvaluationAggregationType({
    mode: 'parking',
    id: 'daily_parking_events',
    geojsonAttribute: 'daily_parking_events',
    title: 'Daily Parking Events',
    legendTitle: 'Events',
    statTitle: 'Daily Parking Events',
    tableHeader: 'Parking Events',
    requiresLayerIntersect: false,
    supportsHourFilters: true,
  })
  static readonly PARKING_TOTAL_DURATION = new EvaluationAggregationType({
    mode: 'parking',
    id: 'total_parked_duration',
    geojsonAttribute: 'total_parked_duration',
    title: 'Total Duration (Hr)',
    legendTitle: 'Duration (Hr)',
    statTitle: 'Total Duration (Hr)',
    tableHeader: 'Hours',
    requiresLayerIntersect: false,
    supportsHourFilters: true,
  })
  static readonly PARKING_AVERAGE_DURATION = new EvaluationAggregationType({
    mode: 'parking',
    id: 'average_parked_duration',
    geojsonAttribute: 'average_parked_duration',
    title: 'Average Duration (Min)',
    legendTitle: 'Duration (Min)',
    statTitle: 'Average Duration (Min)',
    tableHeader: 'Minutes',
    requiresLayerIntersect: false,
    supportsHourFilters: true,
  })
  //#endregion
  //#region Operator Drop Offs Aggregation
  static readonly OPERATOR_DROP_OFFS_DEPLOYMENTS = new EvaluationAggregationType({
    mode: 'operator_drop_offs',
    id: 'deployments',
    geojsonAttribute: 'count',
    title: 'Operator Drop-offs',
    legendTitle: 'Drop-offs',
    statTitle: 'Drop-offs',
    tableHeader: 'Count',
    requiresLayerIntersect: false,
    supportsHourFilters: true,
  })
  //#endregion
  public readonly mode: EvaluationMode
  public readonly id: string
  public readonly geojsonAttribute: string
  private readonly _title: string
  private readonly _legendTitle: string
  private readonly _statTitle: string
  private readonly _tableHeader: string
  public readonly requiresLayerIntersect: boolean
  public readonly supportsHourFilters: boolean

  private constructor(config: {
    id: string
    geojsonAttribute: string
    title: string
    legendTitle: string
    statTitle: string
    tableHeader: string
    mode: EvaluationMode
    requiresLayerIntersect: boolean
    supportsHourFilters: boolean
  }) {
    this.id = config.id
    this.geojsonAttribute = config.geojsonAttribute
    this._title = config.title
    this._legendTitle = config.legendTitle
    this._statTitle = config.statTitle
    this._tableHeader = config.tableHeader
    this.mode = config.mode
    this.requiresLayerIntersect = config.requiresLayerIntersect
    this.supportsHourFilters = config.supportsHourFilters

    EvaluationAggregationType.asArray.push(this)
    EvaluationAggregationType.asMap[this.id] = this
  }

  get title(): string {
    return i18n.t(`evaluationMap.aggregationOption_${this.id}`, this._title)
  }

  legendTitle(units: string | undefined): string {
    return i18n.t(`evaluationMap.legendTitle_${this.id}`, this._legendTitle, { units })
  }

  statTitle(units: string | undefined): string {
    return i18n.t(`evaluationMap.statTitle_${this.id}`, this._statTitle, { units })
  }

  tableHeader(units: string | undefined): string {
    return i18n.t(`evaluationMap.tableHeader_${this.id}`, this._tableHeader, { units })
  }

  get isDistance(): boolean {
    return this.id.includes('distance')
  }

  get isDuration(): boolean {
    return this.id.includes('duration')
  }

  get isAverage(): boolean {
    return this.id.includes('average')
  }
}
