/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VehiclesByHourResponse } from '../models/VehiclesByHourResponse'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class VehiclesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Vehicles By Hour
   * every hour in range is included (missing ones are filled in with 0), sorted in ascending order
   * @returns VehiclesByHourResponse Successful Response
   * @throws ApiError
   */
  public vehiclesByHour({
    regionId,
    start,
    end,
    fields,
    accept = 'application/json',
  }: {
    /**
     * Region identifier
     */
    regionId: string
    start: string
    end: string
    fields?: Array<string>
    accept?: string
  }): CancelablePromise<VehiclesByHourResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/vehicles/by_hour',
      path: {
        region_id: regionId,
      },
      headers: {
        accept: accept,
      },
      query: {
        fields: fields,
        start: start,
        end: end,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
