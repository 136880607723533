/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CDSCurbsPoliciesResponse } from '../models/CDSCurbsPoliciesResponse'
import type { CDSCurbsZonesResponse } from '../models/CDSCurbsZonesResponse'
import type { CurbTicketDateRangeResponse } from '../models/CurbTicketDateRangeResponse'
import type { CurbTicketResponse } from '../models/CurbTicketResponse'
import type { CurbZoneStatisticsResponseModel } from '../models/CurbZoneStatisticsResponseModel'
import type { ISODOW } from '../models/ISODOW'
import type { PopulusPage_CurbTicketResponse_ } from '../models/PopulusPage_CurbTicketResponse_'
import type { PopulusPage_FilteredCurbPolicy_ } from '../models/PopulusPage_FilteredCurbPolicy_'
import type { PopulusPage_ShapesResultModel_ } from '../models/PopulusPage_ShapesResultModel_'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class CurbsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get Curb Policies Filters
   * Returns a list of policy_id, rule_ids that conform to applied filters
   * @returns PopulusPage_FilteredCurbPolicy_ Successful Response
   * @throws ApiError
   */
  public getCurbPoliciesFilters({
    regionId,
    purposes,
    vehicleTypes,
    userClasses,
    types,
    hasRate,
    maximum,
    unit,
    startDate,
    endDate,
    startTime,
    endTime,
    daysOfWeek,
    page = 1,
    size = 300,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Filter for purposes
     */
    purposes?: Array<string>
    /**
     * Filter for vehicle_types
     */
    vehicleTypes?: Array<string>
    /**
     * Filter for user classes
     */
    userClasses?: Array<string>
    /**
     * Filter for types
     */
    types?: Array<string>
    /**
     * True to filter for policy rules with rates
     */
    hasRate?: boolean
    /**
     * Filter policy rule maximum by providing two numbers representing a range (inclusive)
     */
    maximum?: Array<number>
    /**
     * Filter by policy rule unit
     */
    unit?: string
    /**
     * Filter policy rules that apply on or after the provided ISO date (inclusive)
     */
    startDate?: string
    /**
     * Filter policy rules that apply on or before the provided ISO date (inclusive)
     */
    endDate?: string
    /**
     * Filter policy rules that apply after the provided time in format HH:mm (inclusive)
     */
    startTime?: string
    /**
     * Filter policy rules that apply before the provided time in format HH:mm (exclusive)
     */
    endTime?: string
    /**
     * Filter policy rules for those that apply weekly on ISO days of week
     */
    daysOfWeek?: Array<number>
    page?: number
    size?: number
  }): CancelablePromise<PopulusPage_FilteredCurbPolicy_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curbs/policies/filter',
      path: {
        region_id: regionId,
      },
      query: {
        purposes: purposes,
        vehicleTypes: vehicleTypes,
        userClasses: userClasses,
        types: types,
        hasRate: hasRate,
        maximum: maximum,
        unit: unit,
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        daysOfWeek: daysOfWeek,
        page: page,
        size: size,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Curb Activity Statistics
   * Returns the count of shapes in the region that are associated
   * with each policy type (e.g. loading, no_stopping, parking, etc.)
   * @returns number Successful Response
   * @throws ApiError
   */
  public getCurbActivityStatistics({
    regionId,
    purposes,
    vehicleTypes,
    userClasses,
    types,
    hasRate,
    maximum,
    unit,
    startDate,
    endDate,
    startTime,
    endTime,
    daysOfWeek,
    policyIds,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Filter for purposes
     */
    purposes?: Array<string>
    /**
     * Filter for vehicle_types
     */
    vehicleTypes?: Array<string>
    /**
     * Filter for user classes
     */
    userClasses?: Array<string>
    /**
     * Filter for types
     */
    types?: Array<string>
    /**
     * True to filter for policy rules with rates
     */
    hasRate?: boolean
    /**
     * Filter policy rule maximum by providing two numbers representing a range (inclusive)
     */
    maximum?: Array<number>
    /**
     * Filter by policy rule unit
     */
    unit?: string
    /**
     * Filter policy rules that apply on or after the provided ISO date (inclusive)
     */
    startDate?: string
    /**
     * Filter policy rules that apply on or before the provided ISO date (inclusive)
     */
    endDate?: string
    /**
     * Filter policy rules that apply after the provided time in format HH:mm (inclusive)
     */
    startTime?: string
    /**
     * Filter policy rules that apply before the provided time in format HH:mm (exclusive)
     */
    endTime?: string
    /**
     * Filter policy rules for those that apply weekly on ISO days of week
     */
    daysOfWeek?: Array<number>
    /**
     * List of policy IDs to filter by
     */
    policyIds?: Array<string>
  }): CancelablePromise<Record<string, number>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curbs/policies/curb_activity_statistics',
      path: {
        region_id: regionId,
      },
      query: {
        purposes: purposes,
        vehicleTypes: vehicleTypes,
        userClasses: userClasses,
        types: types,
        hasRate: hasRate,
        maximum: maximum,
        unit: unit,
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        daysOfWeek: daysOfWeek,
        policyIds: policyIds,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Curb Shapes
   * @returns PopulusPage_ShapesResultModel_ Successful Response
   * @throws ApiError
   */
  public getCurbShapes({
    regionId,
    page = 1,
    size = 50,
    shapeLayerUuid,
    shapeUuid,
    shapeId,
    shapeName,
    shapeDescription,
    sideOfStreet,
    search,
    orderBy = 'shape_uuid',
  }: {
    /**
     * Region identifier
     */
    regionId: string
    page?: number
    size?: number
    shapeLayerUuid?: string
    shapeUuid?: string
    shapeId?: string
    shapeName?: string
    shapeDescription?: string
    sideOfStreet?: string
    search?: string
    orderBy?: string
  }): CancelablePromise<PopulusPage_ShapesResultModel_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curbs/shapes',
      path: {
        region_id: regionId,
      },
      query: {
        page: page,
        size: size,
        shape_layer_uuid: shapeLayerUuid,
        shape_uuid: shapeUuid,
        shape_id: shapeId,
        shape_name: shapeName,
        shape_description: shapeDescription,
        side_of_street: sideOfStreet,
        search: search,
        order_by: orderBy,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Curb Zone Length Lookup
   * Returns a dictionary with shape uuids as keys and values as dictionaries
   * containing the length and number of spaces from the cds_zones table.
   * @returns CurbZoneStatisticsResponseModel Successful Response
   * @throws ApiError
   */
  public getCurbZoneLengthLookup({
    regionId,
    shapeLayerUuid,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
  }): CancelablePromise<Record<string, CurbZoneStatisticsResponseModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/curbs/zone_length_lookup',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Curb Allocation By Time For Layer
   * Returns a dictionary in which the keys are a policy type and each
   * value is a list of 96 integers. Each value in the list is a curb length
   * in centimeters describing how much curb is allocated to that policy type
   * at that time, where the time component comes from breaking the day into
   * 15 minute chunks (24 * 60 / 15 = 96).
   * @returns number Successful Response
   * @throws ApiError
   */
  public getCurbAllocationByTimeForLayer({
    regionId,
    shapeLayerUuid,
    isoDow,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
    /**
     * Day of week to use for calculation as an ISO weekday number (1-7)
     */
    isoDow: ISODOW
  }): CancelablePromise<Record<string, Array<number>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/curbs/allocation_by_time',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
      },
      query: {
        iso_dow: isoDow,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Curb Allocation By Time For Shape
   * Returns a dictionary in which the keys are a policy type and each
   * value is a list of 96 integers. Each value in the list is a curb length
   * in centimeters describing how much curb is allocated to that policy type
   * at that time, where the time component comes from breaking the day into
   * 15 minute chunks (24 * 60 / 15 = 96).
   * @returns number Successful Response
   * @throws ApiError
   */
  public getCurbAllocationByTimeForShape({
    regionId,
    shapeUuid,
    isoDow,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Shape identifier
     */
    shapeUuid: string
    /**
     * Day of week to use for calculation as an ISO weekday number (1-7)
     */
    isoDow: ISODOW
  }): CancelablePromise<Record<string, Array<number>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/shapes/{shape_uuid}/curbs/allocation_by_time',
      path: {
        region_id: regionId,
        shape_uuid: shapeUuid,
      },
      query: {
        iso_dow: isoDow,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Cds Policies
   * @returns CDSCurbsPoliciesResponse Successful Response
   * @throws ApiError
   */
  public getCdsPolicies({
    regionId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
  }): CancelablePromise<CDSCurbsPoliciesResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curbs/cds/policies',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Cds Zones
   * @returns CDSCurbsZonesResponse Successful Response
   * @throws ApiError
   */
  public getCdsZones({
    regionId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
  }): CancelablePromise<CDSCurbsZonesResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curbs/cds/zones',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Curb Tickets
   * List all curb_tickets for the selected region
   * @returns PopulusPage_CurbTicketResponse_ Successful Response
   * @throws ApiError
   */
  public getCurbTickets({
    regionId,
    violationCode,
    violationType,
    localDatetimeGte,
    localDatetimeLte,
    search,
    orderBy = 'local_datetime,violation_type',
    page = 1,
    size = 50,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    violationCode?: string
    violationType?: string
    localDatetimeGte?: string
    localDatetimeLte?: string
    search?: string
    orderBy?: string
    page?: number
    size?: number
  }): CancelablePromise<PopulusPage_CurbTicketResponse_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curb_tickets',
      path: {
        region_id: regionId,
      },
      query: {
        violation_code: violationCode,
        violation_type: violationType,
        local_datetime__gte: localDatetimeGte,
        local_datetime__lte: localDatetimeLte,
        search: search,
        order_by: orderBy,
        page: page,
        size: size,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Curb Tickets Vector
   * Return all curb_tickets for the selected region as vector tiles
   * @returns string Successful Response
   * @throws ApiError
   */
  public getCurbTicketsVector({
    regionId,
    z,
    x,
    y,
    ifModifiedSince,
    violationCode,
    violationType,
    localDatetimeGte,
    localDatetimeLte,
    search,
    orderBy = 'local_datetime,violation_type',
  }: {
    /**
     * Region identifier
     */
    regionId: string
    z: number
    x: number
    y: number
    ifModifiedSince?: string
    violationCode?: string
    violationType?: string
    localDatetimeGte?: string
    localDatetimeLte?: string
    search?: string
    orderBy?: string
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curb_tickets/vector/{z}/{x}/{y}',
      path: {
        region_id: regionId,
        z: z,
        x: x,
        y: y,
      },
      query: {
        if_modified_since: ifModifiedSince,
        violation_code: violationCode,
        violation_type: violationType,
        local_datetime__gte: localDatetimeGte,
        local_datetime__lte: localDatetimeLte,
        search: search,
        order_by: orderBy,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Curb Ticket Violation Types
   * List all curb tickets violation_types for the selected region
   * @returns string Successful Response
   * @throws ApiError
   */
  public getCurbTicketViolationTypes({
    regionId,
    violationCode,
    violationType,
    localDatetimeGte,
    localDatetimeLte,
    search,
    orderBy = 'local_datetime,violation_type',
  }: {
    /**
     * Region identifier
     */
    regionId: string
    violationCode?: string
    violationType?: string
    localDatetimeGte?: string
    localDatetimeLte?: string
    search?: string
    orderBy?: string
  }): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curb_tickets/violation_types',
      path: {
        region_id: regionId,
      },
      query: {
        violation_code: violationCode,
        violation_type: violationType,
        local_datetime__gte: localDatetimeGte,
        local_datetime__lte: localDatetimeLte,
        search: search,
        order_by: orderBy,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Curb Ticket Date Range
   * Get Date Range for Curb Tickets for a region
   * @returns CurbTicketDateRangeResponse Successful Response
   * @throws ApiError
   */
  public getCurbTicketDateRange({
    regionId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
  }): CancelablePromise<CurbTicketDateRangeResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curb_tickets/date_range',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Curb Ticket By Id
   * Return curb_ticket by id
   * @returns CurbTicketResponse Successful Response
   * @throws ApiError
   */
  public getCurbTicketById({
    regionId,
    id,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Curb Ticket identifier
     */
    id: string
  }): CancelablePromise<CurbTicketResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curb_tickets/{id}',
      path: {
        region_id: regionId,
        id: id,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
