/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfigPublicResponse } from '../models/ConfigPublicResponse'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class ConfigService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Config Public
   * This is a *publicly* accessible endpoint that returns configuration
   * info used by the frontend.
   *
   * Returned info includes:
   *
   * - web code version number
   * - Whether to activate Sentry
   * - Backend datacenter this is running in
   * - Backend environment this is running in
   * - Firebase config
   * - regional config and geographic info
   * - regional operator info
   * - region/operator pairs
   * - regional geojson pointers
   * @returns ConfigPublicResponse Successful Response
   * @throws ApiError
   */
  public configPublic(): CancelablePromise<ConfigPublicResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/config/public',
    })
  }
}
