import { ReactNode } from 'react'
import classNames from 'classnames'

import { Icon, Tooltip } from '../../componentLibrary'
import { IconName } from '../../componentLibrary/Icon'
import { PopulusColor } from '../../componentLibrary/sharedTypes'

import './index.scss'

export interface ShelfItemProps {
  active?: boolean
  children?: ReactNode
  className?: string
  dataTestId?: string
  disabled?: boolean
  iconName: IconName
  iconColor?: PopulusColor
  onClick?: () => void
  text?: string
}

/**
 * Items that appear in the vertical shelf menu
 * @param text - text that will appear in the tooltip
 */
export const ShelfItem = ({
  active,
  children,
  className,
  dataTestId,
  disabled,
  iconColor,
  iconName,
  onClick,
  text,
}: ShelfItemProps) => {
  return (
    <Tooltip
      dark
      mouseEnterDelay={500}
      orientation="right"
      content={text}
      trigger={
        <div
          className={classNames(
            'shelf-item',
            className,
            active && 'active',
            disabled && 'disabled'
          )}
          data-testid={dataTestId}
          onClick={onClick}
        >
          <Icon className="shelf-item-icon" color={iconColor} icon={iconName} />
          {children}
        </div>
      }
    />
  )
}
