import { RegionResponse } from '@/modules/api/openapi/models/RegionResponse'
import { useFeatureFlag } from '@/modules/featureFlags'
import { useHasMapPermissions } from '@/modules/map/hooks'
import { MAP_CONSTANTS } from '@/modules/permissions/constants'
import { useCurrentRegion } from '@/modules/urlRouting/hooks'
import { useCurrentPermissions } from '@/modules/user/hooks'

export const useMobilityRoutesMapAccess = () => useHasMapPermissions(MAP_CONSTANTS.ROUTES)

export const useMobilityTripsMapAccess = () => useHasMapPermissions(MAP_CONSTANTS.TRIPS)

export const useMobilityParkingMapAccess = () => useHasMapPermissions(MAP_CONSTANTS.PARKING)

export const useMobilityLiveMapAccess = () => useHasMapPermissions(MAP_CONSTANTS.LIVE)

export const useMobilityDistributionMapAccess = () =>
  useHasMapPermissions(MAP_CONSTANTS.DISTRIBUTION)

export const useMobilityIssuesMapAccess = () => useHasMapPermissions(MAP_CONSTANTS.ISSUE)

export const useMobilityOperatorDropOffsMapAccess = () =>
  useHasMapPermissions(MAP_CONSTANTS.OPERATOR_DROP_OFFS)

export const useMobilityAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.mobilityManager
}

export const useMobilityActivitySummaryAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return (
    !!permissions?.organizationSettings.mobilityManager && !!permissions?.organizationSettings.home
  )
}

export const useMobilityInvoicingAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return (
    !!permissions?.organizationSettings.mobilityManager &&
    !!permissions?.organizationSettings.feeSummary
  )
}

export const useMobilityGeographiesMapAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  if (!permissions) return false
  if (!permissions.organizationSettings.mobilityManager) return false
  return useHasMapPermissions('shapes')
}

export const useMobilityLiveMapMobileAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return useMobilityLiveMapAccess() && !!permissions?.organizationSettings.liveMapMobile
}

export const useMobilityMDSHealthAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.mobilityManager
}

export const useMobilityReportingTimeDistanceAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  const { data: region } = useCurrentRegion<RegionResponse>()
  if (!permissions || !region?.hasMdsData) return false
  if (!permissions.organizationSettings.mobilityManager) return false
  if (!permissions.organizationSettings.mobilityDashboards) return true
  return !!permissions.organizationSettings.mobilityDashboards.includes('micromobility_use')
}

export const useMobilityReportingParkingAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  if (!permissions) return false
  if (!permissions.organizationSettings.mobilityManager) return false
  if (!permissions.organizationSettings.mobilityDashboards) return true
  return !!permissions.organizationSettings.mobilityDashboards.includes('micromobility_parking')
}

export const useMobilityReportingTripCountsAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  if (!permissions) return false
  return (
    permissions.organizationSettings.mobilityManager &&
    !!permissions.organizationSettings.vehicleAndTripCounts
  )
}

export const useMobilityReportingMobilityMetricsAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  if (!permissions) return false
  if (!permissions.organizationSettings.mobilityManager) return false
  return !!permissions?.organizationSettings.mobilityMetrics
}

export const useMobilityPoliciesAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  const { data: region } = useCurrentRegion<RegionResponse>()
  return (
    !!permissions?.organizationSettings.mobilityManager &&
    !!permissions?.organizationSettings.policiesLibrary &&
    !!region?.allowedMaps.includes('policy')
  )
}

export const useCurbParkingInsightsAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.curbParkingInsights
}

export const useCurbParkingTransactionsAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.curbTransactionsTable
}

export const useCurbParkingMapAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.curbParkingMap
}

export const useCurbTransactionsTableAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.curbTransactionsTable
}

export const useCurbRateAnalyzerAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.curbRateTester
}

export const useCurbPricingEngineAccess = () => {
  const hasPricingEngine = useFeatureFlag('pricing_engine')
  const { data: permissions } = useCurrentPermissions()
  if (!hasPricingEngine) return false
  return !!permissions?.organizationSettings.curbRateTester
}

export const useCurbOccupancyAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.curbOccupancy
}

export const useCurbRegulationsLibraryAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.curbRegulationsLibrary
}

export const useCurbMetricsAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.curbMetricsPage
}

export const useCurbGeographiesMapAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.curbManager
}

export const useCurbFeesAndRevenueAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.curbFeesAndRevenue
}

export const useCurbActivityAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.curbPerformance
}

export const useCurbRegulationsAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.curbRegulations
}

export const useMobilityPolicyComplianceAccess = () => {
  const { data: permissions } = useCurrentPermissions()

  return (
    !!permissions?.organizationSettings.mobilityManager &&
    !!permissions?.organizationSettings.complianceReporting
  )
}
