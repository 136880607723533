import { FC, PropsWithChildren } from 'react'
import { Navigate } from 'react-router-dom'

import InvalidRoutePage from '@/pages/invalid-route'

/**
 * Wraps a page to check for authorization to render children
 * @param hasAccess boolean that determines if a user has access
 */
const AccessGatekeeper: FC<PropsWithChildren<{ hasAccess: boolean; routeToLogin?: boolean }>> = ({
  hasAccess,
  routeToLogin = false,
  children,
}) => {
  if (hasAccess) return children
  return routeToLogin ? <Navigate to="/" replace /> : <InvalidRoutePage />
}

export default AccessGatekeeper
