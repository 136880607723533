/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ComplianceData } from './ComplianceData'
export type PolicySummary = {
  policyUuid: string
  constantPolicyUuid: string
  policyType: string
  policyName?: string
  status: PolicySummary.status
  operators?: Array<string>
  complianceData: Record<string, ComplianceData>
}
export namespace PolicySummary {
  export enum status {
    PUBLISHED = 'published',
    SAVED = 'saved',
    EXPIRED = 'expired',
  }
}
