import camelcaseKeys from 'camelcase-keys'
import { DateTime } from 'luxon'
import { createEnumArrayParam, DateParam, useQueryParams, withDefault } from 'use-query-params'

import { toJSDateLocal } from '@/common/utils/date'
import { enumStringValues } from '@/common/utils/enum'
import { useMdsOperators } from '@/modules/operator/hooks'
import { QueryParam } from '@/modules/queryParams/constants'
import { ValidatedStringParam } from '@/modules/queryParams/customParams'

import { PolicyType } from '../policyLibrary/utils/types'

export enum PolicyStatus {
  SAVED = 'saved',
  PUBLISHED = 'published',
  EXPIRED = 'expired',
}

export enum PolicyComplianceDetail {
  NUMBER_OF_DAYS = 'days',
  NUMBER_OF_VIOLATIONS = 'violations',
}

export const COMPLIANCE_POLICY_TYPES = [
  PolicyType.NO_RIDE,
  PolicyType.PARKING_TIME_LIMIT,
  PolicyType.NO_PARKING,
  PolicyType.VEHICLE_CAP,
  PolicyType.DISTRIBUTION,
  PolicyType.OPERATOR_DROP_OFFS,
]

const DEFAULT_START_DATE = toJSDateLocal(DateTime.now().minus({ days: 7 }))
const DEFAULT_END_DATE = toJSDateLocal(DateTime.now())

export const useComplianceQueryParams = () => {
  const { data } = useMdsOperators()
  const operators = (data?.items ?? []).map(op => op.slug)
  return camelcaseKeys(
    useQueryParams({
      [QueryParam.START_DATE]: withDefault(DateParam, DEFAULT_START_DATE),
      [QueryParam.END_DATE]: withDefault(DateParam, DEFAULT_END_DATE),
      [QueryParam.POLICY_COMPLIANCE_DETAIL]: withDefault(
        ValidatedStringParam(PolicyComplianceDetail),
        PolicyComplianceDetail.NUMBER_OF_DAYS
      ),
      [QueryParam.POLICY_TYPES]: withDefault(
        createEnumArrayParam(COMPLIANCE_POLICY_TYPES.map(({ id }) => id)),
        undefined
      ),
      [QueryParam.POLICY_STATUSES]: withDefault(
        createEnumArrayParam(enumStringValues(PolicyStatus)),
        undefined
      ),
      [QueryParam.OPERATORS]: withDefault(createEnumArrayParam(operators), undefined),
    })
  )
}
