import { Location, NavigateOptions } from 'react-router-dom'

/**
 * @param from - previous location to forward user after logging in
 */
export interface LocationState {
  from?: Location
  policyJson?: any
}

export enum ComposeSearchParam {
  CREATE = 'create',
}

export enum SearchParam {
  COMPOSE = 'compose',
  POLICY_ID = 'policy_id',
  POLICY_TYPE = 'policy_type',
  POLICY_NAME = 'policy_name',
}

interface SearchParams {
  [SearchParam.COMPOSE]?: ComposeSearchParam
  [SearchParam.POLICY_ID]?: string
  [SearchParam.POLICY_TYPE]?: string
  [SearchParam.POLICY_NAME]?: string
}

export interface NavigateOptionsWithParams extends NavigateOptions {
  searchParams?: SearchParams
}
