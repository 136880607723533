import Map, { Layer, Source } from 'react-map-gl'
import { observer } from 'mobx-react'

import { getTranslatedMapControls } from '@/common/utils/maps'
import { FASTAPI_INTERNAL_URL } from '@/modules/api/request'
import { CITYWIDE_LAYER_UUID } from '@/modules/layers/layerStore'
import { useLayers } from '@/modules/layers/queries'
import { DEFAULT_OPTIONS_REACT_MAP_GL } from '@/modules/map'
import { useCurrentRegion } from '@/modules/urlRouting/hooks'

import './index.scss'

export const PolicyDetailsMap = observer(({ shapeLayerUUID }: { shapeLayerUUID?: string }) => {
  const {
    data: { regionId, lat, lon, mapZoom: zoom },
  } = useCurrentRegion()
  const { data: l1ShapeLayers } = useLayers({ level: 'l1' })

  const districtLayerUUID = l1ShapeLayers?.items?.[0]?.shapeLayerUuid

  return (
    <Map
      id="policy-details-map"
      {...DEFAULT_OPTIONS_REACT_MAP_GL}
      initialViewState={{
        longitude: lon,
        latitude: lat,
        zoom,
      }}
      locale={getTranslatedMapControls()}
    >
      {districtLayerUUID && (
        <Source
          id="district-geography"
          type="vector"
          tiles={[
            `${FASTAPI_INTERNAL_URL}/regions/${regionId}/layers/${districtLayerUUID}/vector/{z}/{x}/{y}`,
          ]}
        >
          <Layer
            key="district-border"
            id="district-border"
            source-layer="default"
            type="line"
            paint={{
              'line-opacity': 0.3,
              'line-color': '#4e5156',
              'line-dasharray': [2, 2],
            }}
          />
        </Source>
      )}
      {shapeLayerUUID && shapeLayerUUID !== CITYWIDE_LAYER_UUID && (
        <Source
          id="shape-geography"
          type="vector"
          tiles={[
            `${FASTAPI_INTERNAL_URL}/regions/${regionId}/layers/${shapeLayerUUID}/vector/{z}/{x}/{y}`,
          ]}
        >
          <Layer
            key="shape-border"
            id="shape-border"
            source-layer="default"
            type="fill"
            paint={{
              'fill-color': '#6eb0cd',
              'fill-outline-color': '#4e84a0',
              'fill-opacity': 0.4,
            }}
          />
        </Source>
      )}
    </Map>
  )
})
