import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { PermissionKey } from '@/modules/api/openapi/models/PermissionKey'
import { UserUpdateRequest } from '@/modules/api/openapi/models/UserUpdateRequest'
import { queryClientInstance } from '@/modules/api/queryClient'
import { internalApi } from '@/modules/api/request'
import { useAuth } from '@/modules/authentication'
import { monitorException } from '@/modules/monitoring'

export const buildGetCurrentUserQueryOptions = () => ({
  queryKey: ['/users/me'],
  queryFn: async () => await internalApi.users.getCurrentUser(),
})

export const useCurrentUser = () =>
  useSuspenseQuery({
    ...buildGetCurrentUserQueryOptions(),
  })

export const buildGetCurrentUserPermissionsQueryOptions = () => ({
  queryKey: ['/users/me/permissions'],
  queryFn: async () => await internalApi.users.getCurrentUserPermissions(),
})

export const useCurrentPermissions = () => {
  const { loading } = useAuth()
  return useQuery({
    ...buildGetCurrentUserPermissionsQueryOptions(),
    enabled: !loading,
  })
}

export const usePermission = (permissionKey: PermissionKey) => {
  const { data: permissions } = useCurrentPermissions()
  const permission = permissions?.permissions.find(p => p.key === permissionKey)
  if (!permission) monitorException(`Permission doesn't exist: ${permissionKey}`)
  return !!permission?.enabled
}

export const usePatchCurrentUser = () =>
  useMutation({
    mutationFn: async (requestBody: UserUpdateRequest) =>
      await internalApi.users.updateUser({ requestBody }),
    onSuccess: data => queryClientInstance.setQueryData(['/users/me'], data),
  })
